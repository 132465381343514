import React from 'react';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';

const SmallIOSSwitch = styled((props) => (
    <Switch name={props.name} focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(() => ({
    width: 32,
    height: 16,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.9,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '3px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: '#f0f0f0',
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.95,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 12,
        height: 12,
        boxShadow: 'none',
    },
    '& .MuiSwitch-track': {
        borderRadius: 8,
        backgroundColor: '#E9E9EA',
        opacity: 1,
        transition: 'background-color 500ms',
    },
}));

// Reusable smaller component
const ReusableSmallIOSSwitch = ({ checked, onChange, disabled, name }) => {
    return <SmallIOSSwitch checked={checked} onChange={onChange} disabled={disabled} name={name} />;
};

export default ReusableSmallIOSSwitch;
