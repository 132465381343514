import { deleteApi, getApi, postApi, putApi } from "../config/apis";

export const getAllRestaurantInfo = ({ restId, tableId }) =>
  getApi(
    `/genie/menu/restaurant-info?restaurantId=${restId}&tableId=${tableId}`
  );

export const getAllRestaurantInfoWithoutTable = (restId) =>
  getApi(`/admin/partner/restaurant-info?restaurantId=${restId}`);

export const getAllDinerDetails = ({ partnerId, tableId, customDomain }) => getApi(`/genie/diner?tableId=${tableId}&customDomain=${customDomain}`);
export const getDinerMenuToken = ({ customDomain }) => getApi(`/genie/diner/view${customDomain ? `?customDomain=${customDomain}`: ''}`)
export const getAllFoodType = (id) => getApi(`/genie/menu/food-type/${id}`);

// export const getAllCartCount = ({ partnerId, cartId }) =>
//   getApi(`/genie/cart/count?partnerId=${partnerId}&cartId=${cartId}`);
export const getAllCartCount = ({ dinerId, status }) =>
  getApi(`/genie/get-all-orders-count/${status}?dinerId=${dinerId}`);

export const getAllSpiceLevel = ({ restId }) =>
  getApi(`/genie/menu/spice-level/${restId}`);

export const getPersonalizeEvent = (data) =>
  postApi(`/genie/personalize/itemInteractions`, data);

export const getAllOrderItem = (id) => getApi(`/genie/order?orderId=${id}`);
export const getAllFoodCategory = (id) =>
  getApi(`/genie/menu/food-category/${id}`);
// {
//   restId: restId,
//   allergiesIds: allergicData,
//   // allergiesIds: allergicPreferences,
//   foodTypeId: dietaryPreferences,
//   spiceLevelId:spicyPreferences
// }),
export const getAllGenieExp = (data) =>
  getApi(
    `/genie/menu/genie-experience?restaurantId=${data?.restId}&allergiesIds=["65f978189193e1d6a0fcd925", "660fcad173b5c88c993965da"]&foodTypeId=${data?.foodTypeId}&spiceLevelId=${data?.spiceLevelId}`
  );

export const getAllFoodMenuByCategory = ({
  restId,
  menuId,
  query,
  foodTypeId,
  spiceLevelId,
}) => {
  let queryParams = "";
  if (query) {
    queryParams += `&query=${query}`;
  }
  if (foodTypeId) {
    queryParams += `&foodTypeId=${foodTypeId}`;
  }
  if (spiceLevelId) {
    queryParams += `&spiceLevelId=${spiceLevelId}`;
  }

  return getApi(
    `/genie/menu/?restaurantId=${restId}&foodCategoryId=${menuId}${queryParams}`
  );
};

export const getItemByQuery = ({
  restId,
  menuId,
  query,
  foodTypeId,
  spiceLevelId,
}) => {
  let queryParams = "";
  if (menuId) {
    queryParams += `&foodCategoryId=${menuId}`;
  }
  if (query) {
    queryParams += `&query=${query}`;
  }
  if (foodTypeId) {
    queryParams += `&foodTypeId=${foodTypeId}`;
  }
  if (spiceLevelId) {
    queryParams += `&spiceLevelId=${spiceLevelId}`;
  }

  return getApi(
    `/genie/menu/?restaurantId=${restId}${queryParams}`
  );
};

// export const getAllCartItem = (cartId, partnerId) => getApi(`/genie/cart?cartId=${cartId}&partnerId=${partnerId}`);
// /genie/order/get-all-orders/{{pending|paid|all}}?dinerId={{dinerId}}
export const getAllCartItem = (dinerId) => getApi(`/genie/order/get-all-orders/all?dinerId=${dinerId}`);

// /v1/partner/menu/get-customizations/{{menuId}}?partnerId={{partnerId}}
export const getCustomizations = (data) => getApi(`/partner/menu/get-customizations/${data?.menuId}?partnerId=${data?.partnerId}`)

// export const AddCartMenu = (data) => postApi(`/genie/cart`, data);
export const AddCartMenu = (data) => postApi(`/genie/order/create-order`, data);

export const CreateOrder = (data) => postApi(`/genie/order/create-order`, data);

export const AddToOrder = (data) => postApi(`/genie/order/add-to-order/${data.orderId}`, data.data)

export const deleteOrder = (orderId) => deleteApi(`/genie/order/clear-order/${orderId}`)

// export const PlaceOrder = (data) => postApi(`/genie/order/`, data);
export const PlaceOrder = ({ orderId, tableId }) => putApi(`/genie/order/place-order/${orderId}/${tableId}`, {});

// payment API
export const payBill = ({ tableId, data }) => putApi(`/genie/order/pay-order/${tableId}`, data)
export const payInPerson = ({ tableId, data }) => putApi(`/genie/order/pay-in-person/${tableId}`, data)
export const createPaymentIntent = ({tableId}) => putApi(`/genie/order/create-payment-intent/${tableId}`, {})
export const cancelPaymentIntent = (data) => putApi(`/genie/order/cancel-payment-intent`, data)

export const updateDinerCount = ({ orderId, count }) => putApi(`/genie/order/update-diner-count/${orderId}`, { dinerCount: count })

export const Payment = (data) => postApi(`/genie/payment/`, data);

export const noteAdd = (data) => putApi(`/genie/cart/notes`, data);
export const Rating = (data) => putApi(`/genie/rating`, data);

export const getDeleteMenu = (data) => deleteApi(`/partner/menu/${data}`);

export const getCartItemDetails = (data) => postApi(``, data)