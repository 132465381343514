import React from 'react'
import { PhoneInput } from 'react-international-phone'
import 'react-international-phone/style.css';

const PhoneNumberInput = (props) => {
    const {
        label,
        placeholder,
        name,
        value,
        helperText,
        error,
        disabled,
        required,
        onChange,
        onFocus,
        onBlur,
        autoFocus,
    } = props;

    return (
        <div>
            {label && (
                <label
                    className="block mb-2 text-sm font-semibold text-[#757373]"
                    htmlFor={`custom-text-input-${name || "box"}`}
                >
                    {label}{required && <span className="text-[#B80E0C]">*</span>}
                </label>
            )}
            <PhoneInput
                placeholder={placeholder}
                name={name}
                value={value}
                disabled={disabled}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                autoFocus={autoFocus}
                defaultCountry="ae"
                countrySelectorStyleProps={{
                    buttonStyle: {
                        height: "42px",
                        width: "60px",
                        borderRadius: "4px",
                        marginRight: "20px",
                        background: "#F7F7F7",
                        border: "1px solid #E2E8F0"
                    },
                    buttonContentWrapperClassName: "focus-within:ring-2 focus-within:ring-blue-500 focus-within:border-blue-500"
                }}
                inputStyle={{
                    borderRadius: "4px",
                    height: "42px",
                    // flex: 1,
                    width: "100%",
                    background: "#F7F7F7",
                    border: error? "1px solid #ef4444" :"1px solid #E2E8F0"
                }}
                inputClassName='focus-within:ring-2 focus-within:ring-blue-500 focus-within:border-blue-500'
            />
            {error && helperText && (
                <p className="mt-1 text-xs text-red-500 ml-[80px]">{helperText}</p>
            )}
        </div>
    )
}

export default PhoneNumberInput