import React from "react";
import BasicButton from "../../components/Button";
import MobileHeader from "../../components/MobileHeader";
import {Grid, Step, StepLabel, Stepper } from "@mui/material";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import TimerSvg from "../../assets/Icons/Timer.svg";
import MapComponent from "../../Map";
import { useRestaurant } from "../../utils/hooks";
import Pointer from "../../assets/Icons/MapPointer.svg";
import StopWatch from "../../assets/Icons/StopWatch.svg";
import Profile from "../../assets/Icons/Profile.svg";
import Phone from "../../assets/Icons/Phone.svg";
import { Colors } from "../../utils/helper";

function WebSite() {
  const { setLocation, location } = useRestaurant();
  const handleDragEvent = (event) => {
    const latitude = event.latLng.lat();
    const longitude = event.latLng.lng();
    setLocation({ latitude, longitude });
  };

  const steps = [
    "Order Confirmed",
    "Order is being prepared",
    "Ready to picked up by rider",
    "Rider on the way",
    "Delivery Completed",
  ];

  return (
    <Grid container direction="column">
      <MobileHeader />

      <div
        style={{
          overflowY: "scroll",
          height: "calc(100dvh - 130px)",
        }}
      >
        <Grid container direction="column" paddingX={2} paddingY={1} gap={1}>
          <Grid item sx={{ display: "flex", alignItems: "center" ,marginY:"5px"}}>
            <ArrowBackIosIcon sx={{ width: 20, height: 20 }} />
            <img src={TimerSvg} alt="Timer Icon" width={18} height={18} />
            <h1 className="ml-1">Delivery Status</h1>
          </Grid>

          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <MapComponent
              location={location}
              handleDragEvent={handleDragEvent}
            />
          </Grid>

          <Grid
            container
            item
            direction="column"
            gap={1}
            sx={{
              backgroundColor: Colors?.lightblue,
              padding: 1,
              border: `1px solid ${Colors?.borderGrey}`,
              borderRadius: "8px",
            }}
          >
            <Grid
              item
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <div className="flex">
                <img src={Pointer} alt="Pointer Icon" width={20} height={20} />
                <h1 className="text-[#3E3E3E] font-[400]">Delivery distance</h1>
              </div>
              <h1 className="text-[16px] font-[600] text-textColor">4km</h1>
            </Grid>

            <Grid
              item
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <div className="flex">
                <img
                  src={StopWatch}
                  alt="StopWatch Icon"
                  width={20}
                  height={20}
                />
                <h1 className="text-[#3E3E3E] font-[400]">
                  Estimated delivery time
                </h1>
              </div>
              <h1 className="text-[16px] font-[600] text-textColor">14 min</h1>
            </Grid>
          </Grid>

          <Grid
            container
            item
            direction="column"
            gap={1}
            sx={{
              padding: "5px",
              border: "1px solid #EDEDED",
              borderRadius: "8px",
            }}
          >
            <Grid
              item
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <div className="flex px-2 py-3 gap-2">
                <div className="rounded bg-white shadow flex justify-center items-center">
                  <img
                    src={Profile}
                    alt="Profile Icon"
                    width={40}
                    height={37}
                    className="p-2"
                  />
                </div>
                <div className="flex flex-col justify-center">
                  <h1 className="text-[16px] font-[400] text-textColor">
                    John Doe
                  </h1>
                  <h1 className="text-[10px] font-[400] text-[#757373]">
                    Restaurant Manager
                  </h1>
                </div>
              </div>
              <div className="flex flex-col my-3 border-l-2 justify-center items-center">
                <img
                  src={Phone}
                  alt="Phone Icon"
                  width={40}
                  height={37}
                  className="mx-8 p-2"
                />
              </div>
            </Grid>
          </Grid>

          <Grid
            container
            item
            direction="column"
            gap={1}
            sx={{
              padding: "10px",
              border: "1px solid #EDEDED",
              borderRadius: "8px",
            }}
          >
            <Grid item sx={{ display: "flex" }}>
              <h1 className="text-[#636363] text-[12px] font-[400]">
                Track your Order
              </h1>
            </Grid>

            <Grid item sx={{ display: "flex" }}>
              <Stepper activeStep={2} orientation="vertical">
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel
                      sx={{
                        "& .MuiStepLabel-label": {
                          fontSize: "14px",
                          fontWeight: 400,
                          color: Colors.textColor,
                        },
                        "& .MuiStepLabel-iconContainer": {
                          "& .MuiStepIcon-root": {
                            color: "#FF615F",
                            fontWeight: 400,
                            fontStyle: "normal",
                          },
                          "& .MuiStepIcon-root.Mui-completed": {
                            color: "#FF615F",
                            fontWeight: 400,
                            fontStyle: "normal",
                          },
                          "& .MuiStepIcon-root.Mui-active": {
                            color: "#FF615F",
                            fontWeight: 400,
                            fontStyle: "normal",
                          },
                        },
                        "& .Mui-disabled .MuiStepIcon-root": {
                          color: "lightgrey",
                          fontWeight: 400,
                          fontStyle: "normal",
                        },
                      }}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Grid>
          </Grid>
        </Grid>
      </div>

      <Grid item sx={{ padding: 2,border:` 1px solid ${Colors.borderGrey} `}}>
        <BasicButton
          sx={{
            textTransform: "none",
            width: "100%", // Full width button
          }}
        >
          Order Received
        </BasicButton>
      </Grid>
    </Grid>
  );
}

export default WebSite;
