import React, { useEffect, useState } from 'react'
// import { PageLoader } from '../../../components/loader'
import { Grid, Skeleton } from '@mui/material'
import Table from '../../../components/CustomTable/TableHeader'
import OrdRow from '../../../components/CustomTable/DineInRow'
import { useAuth, useRestaurant } from '../../../utils/hooks'
import BasicButton from '../../../components/Button'
import receipt_bg from '../../../assets/Icons/receipt_bg.svg'
// import close from '../../../assets/Icons/close.svg'
import useToken from '../../../utils/hooks/useToken'
import dayjs from 'dayjs'


const DineInSection = ({ startDate, endDate, selectedOrder, orderViewActive, handleOrderSelect,handleOrderClose }) => {
    const { useGetOrderDetails, useGetOrderById } = useRestaurant();

    const [dineManagementSummary, setDineManagementSummary] = useState({})
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const { socket, userData } = useAuth()

    const { getUserDetails } = useToken()

    const headers = [
        "Order No.",
        "Table No.",
        "Items No.",
        "Order Total",
        "Payment Method",
        "Status",
    ];

    const { data: orderDetails, isLoading: orderDetailsLoading, } = useGetOrderDetails({ page: page, pageSize: pageSize, partnerId: getUserDetails()._id, fromDate: dayjs(startDate).format("YYYY-MM-DD"), toDate: dayjs(endDate).format("YYYY-MM-DD") });
    const { data: orderDetailsByIds, isLoading: orderDetailsByIdsLoading } = useGetOrderById(selectedOrder);

    const nextPage = () => {
        if (orderDetails?.result?.orders?.length === pageSize) {
            setPage(page + 1)
        }
    }

    const prevPage = () => {
        if (page > 1) {
            setPage(page - 1)
        }
    }

    useEffect(() => {
        setPage(1)
    }, [startDate, endDate])

    useEffect(() => {
        socket.emit("request", {
            en: "DS",
            data: {
                partnerId: userData?._id,
                fromDate: dayjs(startDate).format("YYYY-MM-DD"),
                toDate: dayjs(endDate).format("YYYY-MM-DD")
            }
        });

        socket.on("DS", (res) => {
            setDineManagementSummary(res?.data)
        })

        return () => {
            socket.off("DS")
        }
    }, [socket, userData, orderDetails])

    return (
        <>
            {false ? (
                <div className="flex flex-col justify-center items-center h-[calc(100vh-144px)] w-full">
                    <div className="h-14 w-14 border-4 border-gray-300 border-t-red-600 rounded-full animate-spin mb-4" />
                    <p className="text-gray-600">
                        Loading...
                    </p>
                </div>
            ) : (
                <div className="overflow-auto">
                    <Grid container sx={{ position: "relative" }}>
                        <div className='w-full px-8 mb-[21px] grid grid-cols-3 tablet:grid-cols-5 gap-2'>
                            <div className="bg-[#FCFAE9] border border-[#F4F1D7] flex flex-col gap-2 rounded p-5">
                                {dineManagementSummary?.summary ?
                                    <>
                                        <h1 className="font-normal text-xs">Orders to start</h1>
                                        <h1 className="font-semibold text-2xl ">{dineManagementSummary?.summary?.find(item => item?._id === "new")?.count || 0}</h1>
                                    </> :
                                    <>
                                        <Skeleton sx={{ fontSize: "12px", fontWeight: "400" }} />
                                        <Skeleton sx={{ fontSize: "24px", fontWeight: "600" }} width={"50%"} />
                                    </>
                                }
                            </div>
                            <div className="bg-[#FCFAE9] border border-[#F4F1D7] flex flex-col gap-2 rounded p-5">
                                {dineManagementSummary?.summary ?
                                    <>
                                        <h1 className="font-normal text-xs">Orders Preparing</h1>
                                        <h1 className="font-semibold text-2xl ">{dineManagementSummary?.summary?.find(item => item?._id === "preparing")?.count || 0}</h1>
                                    </> :
                                    <>
                                        <Skeleton sx={{ fontSize: "12px", fontWeight: "400" }} />
                                        <Skeleton sx={{ fontSize: "24px", fontWeight: "600" }} width={"50%"} />
                                    </>
                                }
                            </div>
                            <div className="bg-[#FCFAE9] border border-[#F4F1D7] flex flex-col gap-2 rounded p-5">
                                {dineManagementSummary?.summary ?
                                    <>
                                        <h1 className="font-normal text-xs">Orders Served</h1>
                                        <h1 className="font-semibold text-2xl ">{dineManagementSummary?.summary?.find(item => item?._id === "served")?.count || 0}</h1>
                                    </> :
                                    <>
                                        <Skeleton sx={{ fontSize: "12px", fontWeight: "400" }} />
                                        <Skeleton sx={{ fontSize: "24px", fontWeight: "600" }} width={"50%"} />
                                    </>
                                }
                            </div>
                            <div className="bg-[#FCFAE9] border border-[#F4F1D7] flex flex-col gap-2 rounded p-5">
                                {dineManagementSummary?.summary ?
                                    <>
                                        <h1 className="font-normal text-xs">To be billed</h1>
                                        <h1 className="font-semibold text-2xl ">
                                            {(
                                                (dineManagementSummary?.summary?.find(item => item?._id === "preparing")?.count || 0)
                                                + (dineManagementSummary?.summary?.find(item => item?._id === "served")?.count || 0)
                                                + (dineManagementSummary?.summary?.find(item => item?._id === "new")?.count || 0)
                                            ) || 0}
                                        </h1>
                                    </> :
                                    <>
                                        <Skeleton sx={{ fontSize: "12px", fontWeight: "400" }} />
                                        <Skeleton sx={{ fontSize: "24px", fontWeight: "600" }} width={"50%"} />
                                    </>
                                }
                            </div>
                            <div className="bg-[#E9FCF2] border border-[#D0EDDD] flex flex-col gap-2 rounded p-5">
                                {dineManagementSummary?.summary ?
                                    <>
                                        <h1 className="font-normal text-xs">Total Orders</h1>
                                        <h1 className="font-semibold text-2xl ">{dineManagementSummary?.totalOrderCount || 0}</h1>
                                    </> :
                                    <>
                                        <Skeleton sx={{ fontSize: "12px", fontWeight: "400" }} />
                                        <Skeleton sx={{ fontSize: "24px", fontWeight: "600" }} width={"50%"} />
                                    </>
                                }
                            </div>
                        </div>

                        <Grid container item >
                            <Grid
                                item
                                xs={12}
                                md={!orderViewActive ? 12 : 8.5}
                                sx={{
                                    padding: { xs: "0px 32px", md: `0px ${orderViewActive ? "21px" : "32px"} 0px 32px` },
                                    // maxHeight: "calc(100vh - 302px)",
                                    height: "fit-content",
                                    order: { xs: "1", md: "0" }
                                }}
                            >
                                <Table
                                    headers={headers}
                                    footer={!orderDetailsLoading}
                                    page={page}
                                    setPage={setPage}
                                    nextPage={nextPage}
                                    prevPage={prevPage}
                                    count={orderDetails?.result?.orders?.length || 0}
                                    pageSize={pageSize}
                                    setPageSize={setPageSize}
                                >
                                    {orderDetailsLoading ?
                                        Array.from({ length: 8 }).map((_, index) => (
                                            <tr key={index} className={`${index !== 7 && "border-b"}`}>
                                                <td className='px-3 py-3 border-r'>
                                                    <Skeleton />
                                                </td>
                                                <td className='px-3 py-3 border-r'>
                                                    <Skeleton width={"25%"} />
                                                </td>
                                                <td className='px-3 py-3 border-r'>
                                                    <Skeleton width={"25%"} />
                                                </td>
                                                <td className='px-3 py-3 border-r'>
                                                    <Skeleton width={"50%"} />
                                                </td>
                                                <td className='px-3 py-3 border-r'>
                                                    <Skeleton width={"50%"} />
                                                </td>
                                                <td className='px-3 py-3 border-r'>
                                                    <Skeleton width={"50%"} />
                                                </td>
                                            </tr>
                                        ))
                                        :
                                        orderDetails?.result?.orders?.length === 0 ?
                                            <tr>
                                                <td colSpan={headers.length}>
                                                    <div className="flex flex-col justify-center items-center w-full py-3">
                                                        <p className="text-gray-600">No orders found</p>
                                                    </div>
                                                </td>
                                            </tr>
                                            :
                                            orderDetails?.result?.orders?.map((ord, index) => (
                                                <OrdRow key={ord?.orderId} ord={ord} index={index}
                                                    onClick={() => {
                                                        handleOrderSelect(ord?.orderId)
                                                    }}
                                                    active={orderViewActive && selectedOrder === ord?.orderId}
                                                />
                                            ))
                                    }
                                </Table>
                            </Grid>
                            {orderViewActive && (
                                <Grid item xs sx={{ height: "fit-content", position: "sticky", top: "0", padding: { xs: "0px 32px 21px 32px", md: "0px" } }}>
                                    <div className="bg-[#FCF0F0] rounded-xl lg:rounded-r-none h-full pt-[25px] border border-[#E3E3E3] relative">
                                        <div className='flex justify-center items-center gap-2 px-4'>
                                            <img src={receipt_bg} alt="" />
                                            <h1 className="font-semibold text-[20px] text-[#B80E0C] flex items-center">
                                                <span className='text-nowrap'>Order #</span>
                                                <span className={`${!orderDetailsByIds?.result?.orderNumber && "max-w-[100px] truncate"} inline-block`}>
                                                    {orderDetailsByIds?.result?.orderNumber || orderDetailsByIds?.result?._id || "Loading..."}
                                                </span>
                                            </h1>
                                            {/* <img className='cursor-pointer ml-auto' src={close} alt="" /> */}
                                        </div>
                                        <h1 className="flex justify-center items-center font-normal text-sm mb-2 text-[#969696] ">
                                            Order List
                                        </h1>
                                        <hr className='border-[#E3E3E3]' />
                                        <div className="px-4 overflow-y-scroll py-4 max-h-[65vh] ">
                                            {orderDetailsByIds?.result?.orderDetails ? orderDetailsByIds?.result?.orderDetails?.map((item, index) => (
                                                <>
                                                    <div>
                                                        <div className="flex justify-between py-2">
                                                            <h1 className="flex   items-center  gap-2 font-normal text-sm text-[#000000] ">
                                                                {item.item_name}
                                                                <span className="h-[30px] w-[30px] rounded-full bg-white flex items-center justify-center text-[#32413F] font-semibold border border-[#EDEDED]">
                                                                    X{item.quantity}
                                                                </span>
                                                            </h1>
                                                            <h1 className="flex items-center font-semibold text-[16px]">
                                                                {/* TODO: Original or computed price? */}
                                                                {/* ${((item.oPrice - item.dPrice + item?.customization?.reduce((acc, customization) => acc + customization.items.reduce((itemAcc, item) => itemAcc + item.price, 0), 0)) * item?.quantity).toFixed(2)} */}
                                                                AED {(item.oPrice - item.dPrice).toFixed(2)}
                                                            </h1>
                                                        </div>
                                                        <div className='flex flex-col pl-[30px] gap-2'>
                                                            {item.customization?.map((customization, index) => {
                                                                return (
                                                                    customization?.items?.map((customizationItem, index) => (
                                                                        <div className='flex justify-between font-normal'>
                                                                            {/* TODO: Add quantity */}
                                                                            <div className='text-[#595454]'>1 {customizationItem.itemName}</div>
                                                                            {/* <div className='text-[#000000]'>{customizationItem.price !== 0 ? `AED ${customizationItem.price}` : "Free"}</div> */}
                                                                            <div className='text-[#000000]'>{`AED ${customizationItem.price}`}</div>
                                                                        </div>
                                                                    ))
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                    {index !== orderDetailsByIds?.result?.orderDetails?.length - 1 && <hr className='border-[#E3E3E3]' />}
                                                </>
                                            )) : <div className='flex justify-center items-center h-full'>Loading...</div>}
                                        </div>

                                        {!orderDetailsByIdsLoading &&
                                            (
                                                <>
                                                    <hr className='border-[#E3E3E3]' />
                                                    <div className='flex justify-between items-center px-4 py-2'>
                                                        <h1 className='font-semibold text-[16px]'>Total</h1>
                                                        <h1 className='font-semibold text-[16px]'>
                                                            AED {orderDetailsByIds?.result?.orderDetails?.reduce((acc, curr) => acc + (curr.oPrice - curr.dPrice + curr.customization.reduce((customizationAcc, customization) => customizationAcc + customization.items.reduce((itemAcc, item) => itemAcc + item.price, 0), 0)) * curr.quantity, 0).toFixed(2)}
                                                            {/* {console.log(orderDetailsByIds?.result?.orderDetails?.reduce((acc, curr) => acc + (curr.oPrice - curr.dPrice + curr.customization.reduce((customizationAcc, customization) => customizationAcc + customization.items.reduce((itemAcc, item) => itemAcc + item.price, 0), 0)) * curr.quantity, 0))} */}
                                                        </h1>
                                                    </div>
                                                </>
                                            )
                                        }

                                        <div className="px-4 pb-4 ">
                                            <BasicButton
                                                // color="primary"
                                                type="submit"
                                                color="genieRed"
                                                sx={{
                                                    color: "white",
                                                    textTransform: "none",
                                                    width: "100%",
                                                    height: "36px",
                                                }}
                                                onClick={handleOrderClose}
                                            >
                                                Close
                                            </BasicButton>
                                        </div>
                                    </div>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </div >
            )}
        </>
    )
}

export default DineInSection
