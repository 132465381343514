import { MenuItem, Select } from "@mui/material";
import React from "react";

const Table = ({ headers, children, footer, page, nextPage, prevPage, count, pageSize, setPageSize }) => {
  return (
    <div className="w-full relative overflow-auto rounded-[16px] border  h-full">
      <table className="table w-full border-collapse">
        <thead className={` sticky top-0 bg-white z-10 -mt-[1px] border-b`}>
          <tr>
            {headers.map((header, index) => (
              <th
                key={index}
                scope="col"
                className={`text-left text-xs font-normal ${index !== header.length - 1 ? "border-r" : ""} p-3 text-[#757373] text-center`}
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="mt-[50px] overflow-auto">{children}</tbody>
        {/* TODO: Add footer */}
        {footer && (
          <tfoot className="sticky bottom-0 bg-white border-t">
            <tr className="h-fit">
              {/* Empty cells for all columns except the last one */}
              <td colSpan="3" className="p-2">
                <div className="flex items-center gap-x-2">
                  <p>Entries per page</p>
                  <Select value={pageSize}
                    sx={{
                      ".MuiSelect-select": {
                        padding: "6px"
                      }
                    }}
                    onChange={(e) => setPageSize(e.target.value)}>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                  </Select>
                </div>
              </td>
              {headers.slice(3, -1).map((_, index) => (
                <td key={index}></td>
              ))}
              {/* Footer content in the last column */}
              <td colSpan="1" className="p-2">
                <nav className="flex justify-center items-center gap-x-1" aria-label="Pagination">
                  <button type="button" className="min-h-[38px] min-w-[38px] py-2 px-2.5 inline-flex justify-center items-center gap-x-2 text-sm rounded-lg text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none" aria-label="Previous"
                    onClick={prevPage}
                  >
                    <svg class="shrink-0 size-3.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <path d="m15 18-6-6 6-6"></path>
                    </svg>
                    {/* <span aria-hidden="true" className="hidden sm:block">Previous</span> */}
                  </button>
                  <div className="flex items-center gap-x-1">
                    <span class="min-h-[38px] min-w-[38px] flex justify-center items-center text-gray-800 py-2 px-3 text-sm rounded-lg focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none">{page}</span>
                  </div>
                  <button type="button" className="min-h-[38px] min-w-[38px] py-2 px-2.5 inline-flex justify-center items-center gap-x-2 text-sm rounded-lg text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none" aria-label="Next"
                    onClick={nextPage}
                  >
                    {/* <span aria-hidden="true" className="hidden sm:block">Next</span> */}
                    <svg className="shrink-0 size-3.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                      <path d="m9 18 6-6-6-6"></path>
                    </svg>
                  </button>
                </nav>
              </td>
            </tr>
          </tfoot>
        )}
      </table>
    </div>
  );
};

export default Table;
