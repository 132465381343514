import React, { useEffect, useRef, useState } from "react";
import RestaurantInfo from "../RestaurantInfo";
import { Badge, Grid } from "@mui/material";
import { useDiner } from "../../../utils/Context/Dinner";
import { useNavigate } from "react-router";
import { useAuth, useSuperAdmin } from "../../../utils/hooks";
import useToken from "../../../utils/hooks/useToken";
import getBrowserFingerprint from "get-browser-fingerprint";
import Isolation_Mode from "../../../assets/images/Isolation_Mode.svg";
import cart from "../../../assets/Icons/Cart.svg";
import food_image from "../../../assets/images/food_image.png";
import { useDinerStore } from "../../../stores";
import { useShallow } from "zustand/shallow";
import { useParams } from "react-router-dom"
import restaurantClosed from "../../../assets/Icons/restaurantClosed.svg"


function Diner() {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  // const [isViewOrder, setViewOrder] = useState(false);
  // const [isDrawerOrderOpen, setDrawerOrderOpen] = useState(true);
  const [isDrawerOpenValue, setDrawerOpenValue] = useState(false);
  const [showNewScreen, setShowNewScreen] = useState(false);
  const bannerImageRef = useRef(null)

  const restaurantId = useParams();
  const { getOrderId } = useToken();
  const { socket } = useAuth();

  const { tabValue } = useSuperAdmin();
  const navigate = useNavigate();;
  const {
    useGetDinerDetails,
    useGetRestaurantInfo,
    restaurantInfoHeight,
    useGetFoodType,
    useGetFoodCategory,
    useGetFoodMenuByCategory,
    OrderModel,
    setOrderModel,
    useGetCartItem,
    useGetFoodItemsByQuery,
  } = useDiner();

  const {
    cartList,
    partnerId,
    tableId,
    user,
    dinerCount,
    setDinerCount,
    scanStatus,
    setScanStatus,
    isAuthenticated,
  } = useDinerStore(useShallow(state => ({
    cartList: state.cartList,
    tableId: state.tableId,
    partnerId: state.partnerId,
    user: state.user,
    dinerCount: state.dinerCount,
    setDinerCount: state.setDinerCount,
    scanStatus: state.scanStatus,
    setScanStatus: state.setScanStatus,
    isAuthenticated: state.isAuthenticated
  })))

  const {
    getCartCount
  } = useDinerStore(useShallow(state => ({
    getCartCount: state.getCartCount
  })))

  useGetCartItem({
    // cartID: getCartId(),
    partnerId: partnerId,
    dinerId: user.id,
  });

  /**
   * Generates a unique fingerprint for the current browser.
   * @returns A string representing the browser fingerprint.
   */
  const fingerprint = getBrowserFingerprint();

  /**
   * Toggles the state of the drawer and order model between open and closed.
   * @returns None
   */
  const toggleDrawer = () => {
    setOrderModel(!OrderModel);
    setDrawerOpen(!isDrawerOpen);
    setDrawerOpenValue(!isDrawerOpenValue);
  };

  // const { data: dinerDetails, isLoading: dinerDetailsIsLoading } =
  useGetDinerDetails({
    dinerId: fingerprint.toString(),
    userAgent: window.navigator?.userAgent,
    partnerId: restaurantId?.id,
    tableId: tableId
  });

  const { data: restaurantInfo } = useGetRestaurantInfo({
    restId: partnerId,
    tableId: tableId,
  });

  const [isLoading, setIsLoading] = useState(true)


  useEffect(() => {
    const isDataLoaded =
      restaurantInfo
    setIsLoading(!isDataLoaded);
  }, [
    restaurantInfo,
  ]);


  useEffect(() => {
    if (bannerImageRef.current) {
      bannerImageRef.current.style.maxHeight = `${restaurantInfoHeight + 10}px`
    }
  }, [restaurantInfoHeight])

  return (
    <>
      <Grid container sx={12} direction="column" className="font-poppins">
        <Grid
          item
          sx={12}
          className=" bg-[#b80e0c]  flex justify-between items-end  px-[1.25rem] py-[0.75rem] sticky top-0 z-50"
        >
          <img
            src={Isolation_Mode}
            alt=""
            srcSet=""
            className=""
            height={"36px"}
          />
          <Badge
            badgeContent={getCartCount()}
            onClick={() => {
              if (tableId) {
                navigate(`cart/?tableId=${tableId}`)
              }
            }}
            sx={{ cursor: "pointer", "& .MuiBadge-badge": { backgroundColor: "white", color: "#B80E0C", fontWeight: 600, position: "absolute", top: "3px", right: "4px" } }}
          >
            <img src={cart} alt="cart" className={`${tableId ? "cursor-pointer" : "opacity-50"}`} />
          </Badge>
        </Grid>

        {isLoading ?
          <div className="flex flex-col justify-center items-center h-[calc(100vh-60px)]">
            <div className="h-14 w-14 border-4 border-gray-300 border-t-red-600 rounded-full animate-spin mb-4" />
            <p className="text-gray-600">
              Loading...
            </p>
          </div>
          :
          <Grid sx={{
            position: "relative",
            marginBottom: getCartCount() > 0 ? "72.67px" : "0px"
          }}>
            <Grid xs={12} sx={{ position: "absolute", zIndex: "-1" }} >
              {/* <LazyLoadComponent
                threshold={100}
                placeholder={
                  <div className="animate-pulse bg-gray-200 max-h-[120px] w-[100vw]" />
                }
              > */}
              <img
                src={restaurantInfo?.result?.bannerURL}
                alt={`${restaurantInfo?.result?.name || 'Restaurant'} banner`}
                ref={bannerImageRef}
                loading="eager"
                onError={(e) => {
                  e.target.src = food_image; // Fallback to default image
                  e.target.alt = 'Default restaurant banner';
                }}
                className={`
                    max-h-[120px] w-[100vw] 
                    object-cover 
                    transition-[max-height] ease-in-out duration-200
                    bg-gray-50
                  `}
              />
              {/* </LazyLoadComponent> */}
            </Grid>
            <Grid item sx={12} className={`${showNewScreen ? "hidden" : ""}`}>
              <RestaurantInfo
                data={restaurantInfo}
                toggleDrawer={toggleDrawer}
                isDrawerOpenValue={isDrawerOpenValue}
              />
            </Grid>




            <div className="w-full flex items-center flex-col justify-center h-[75vh]">
              <img src={restaurantClosed} alt="" />
              <p className="text-base font-normal">Sorry but we are currently closed</p>
            </div>




            {/* <Box display={"none"}>
              {(showNewScreen || !searchResultIsActive) &&
                <Grid
                  sx={{
                    marginBottom: "16px",
                    paddingLeft: "16px",
                    paddingRight: "16px",
                    display: "flex",
                    gap: "9px",
                    width: "100vw",
                    overflowX: "auto",
                    flexWrap: "nowrap",
                    marginTop: showNewScreen ? "18px" : "0px"
                  }}
                >
                  <OfferCard image={food_image}
                    text="Order from these restaurants and save"
                    colorHex="bg-[#ffd951]"
                    buttonText="Browse offer"
                  />
                  <OfferCard
                    image={food_image}
                    text="Have cleaning supplies and other convenience items delivered"
                    colorHex="bg-[#63f099]"
                    buttonText="Shop convenience"
                  />
                </Grid>
              }
            </Box> */}

          </Grid>
        }
      </Grid>
    </>
  );
}

export default Diner;

