import { styled, ToggleButton, ToggleButtonGroup } from '@mui/material'
import React from 'react'

const StyledToggleButtonGroup = styled(ToggleButtonGroup)({
    backgroundColor: '#ffffff',
    borderRadius: '9999px',
    padding: '6px',
    width: '100%',
    '& .MuiToggleButtonGroup-grouped': {
        border: 'none',
        borderRadius: '9999px',
        '&.Mui-selected': {
            backgroundColor: '#262626',
            color: '#ffffff',
            '&:hover': {
                backgroundColor: '#262626',
            },
        },
        '&:not(.Mui-selected)': {
            color: '#262626',
        },
    },
})

const StyledToggleButton = styled(ToggleButton)({
    padding: '8px 16px',
    textTransform: 'none',
    minWidth: '120px',
})

const CustomToggleButton = ({ value, handleChange, options, size = "normal" }) => {
    return (
        <StyledToggleButtonGroup
            value={value}
            exclusive
            onChange={handleChange}
            aria-label="Delivery or pickup option"
            sx={{ gap: size === "small" ? "8px" : "20px", padding: size === "small" ? "3.71px" : "6px"}}
        >
            {options.map((option) => (
                <StyledToggleButton
                    key={option.label.toLowerCase()}
                    value={option.label.toLowerCase()}
                    sx={{ flex: "1", padding: "2px 0px",minWidth: size === "small" ? "94.47px" : "120px"  }}
                >
                    <div className={`text-center ${size === "small" ? "text-[8.64px] leading-[12.35px]" : "leading-[20px]"}`}>
                        {option.label}<br /><span className={`text-[#808080] ${size === "small" ? "text-[8.64px] leading-[12.35px]" : "text-xs leading-4"}`}>{option.time}</span>
                    </div>
                </StyledToggleButton>
            ))}
        </StyledToggleButtonGroup>
    )
}

export default CustomToggleButton