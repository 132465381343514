import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { createJSONStorage, devtools, persist, subscribeWithSelector } from "zustand/middleware";
import { createDinerSlice } from "./diner/diner-slice"
import { createCommonSlice } from "./common/common-slice"

export const useDinerStore = create()(
    devtools(
        persist(
            subscribeWithSelector(
                immer((...a) => ({
                    ...createCommonSlice(...a),
                    ...createDinerSlice(...a),
                }))
            ),
            {
                name: `diner-table(${new URLSearchParams(window.location.search).get("tableId")})`,
                storage: createJSONStorage(() => sessionStorage)
            }
        ),
        {
            enabled: process.env.REACT_APP_ENVIRONMENT === "local",
        }
    )
)

const hashStorage = {
    getItem: (key) => {
        const searchParams = new URLSearchParams(window.location.hash.slice(1))
        const storedValue = searchParams.get(key) ?? ''
        return JSON.parse(storedValue)
    },
    setItem: (key, newValue) => {
        const searchParams = new URLSearchParams(window.location.hash.slice(1))
        searchParams.set(key, JSON.stringify(newValue))
        window.location.hash = searchParams.toString()
    },
    removeItem: (key) => {
        const searchParams = new URLSearchParams(window.location.hash.slice(1))
        searchParams.delete(key)
        window.location.hash = searchParams.toString()
    },
}

export const useUrlStore = create(
    persist(
        immer(
            (set, get) => ({
                paymentAmount: 0,
                setPaymentAmount: (data) => set(state => { state.paymentAmount = data }),
            })
        ),
        {
            name: 'data',
            storage: createJSONStorage(() => hashStorage),
        },
    ),
)