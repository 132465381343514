import React from "react";
import {
  Route,
  Routes,
  Outlet,
  Navigate,
  useParams,
  useLocation
} from "react-router-dom";
import LoginWrapper from "../pages/Login";
import Resetwrapper from "../pages/Reset";
import SuperAdminView from "../pages/SuperAdmin/Index";
import AddMenu from "../pages/Restaurant/AddMenu";
import RestaurantInformation from "../pages/AddRestaurant/AddRestaurant";
import PrivateRoute from "./Privateroute";
import MenuManagement from "../pages/Restaurant/MenuManagement";
import Unauthorized from "../pages/unauthorized";
import PublicRoute from "./PublicRoutes";
import Diner from "../pages/Dinner";
import RestaurantCart from "../pages/Dinner/Cart";
import TableManagement from "../pages/Restaurant/TableManagement";
import OrderHistory from "../pages/Restaurant/TableManagement/OrderHistory";
import RestaurantBill from "../pages/Dinner/Bill";
import RestaurantPaymentSuccess from "../pages/Dinner/PaymentSuccess";
import ClosedRestaurant from "../pages/Dinner/ClosedRestaurant";
import NoInternet from "../pages/NoInternet";
import RestaurantPaymentFailed from "../pages/Dinner/PaymentFailed";
import ResetPasswordwrapper from "../pages/ResetPassword";
import Insights from "../pages/Restaurant/Insights";
import SignUpWrapper from "../pages/Login/Signup";
import OnBoarding from "../pages/Restaurant/OnBoarding";
import WebSite from "../pages/Website";
import Animation from "../pages/Website/Animation";
import Rating from "../pages/Website/Rating";
import RiderPage from "../pages/Website/RiderPage";
import DeliveryManagement from "../pages/Restaurant/DeliveryManagement";
import Takeout from "../pages/Takeout"
import DineIn from "../pages/Restaurant/DineIn"
import TokenSetter from "./TokenSetter";
import InvalidTable from "../pages/Dinner/InvalidTable";
import CashPayment from "../pages/Dinner/CashPayment";
import SidebarLayout from "../layouts/SidebarLayout";
import DinerLayout from "../layouts/DinerLayout"
import MenuManagementLayout from "../layouts/MenuManagementLayout";
import StripePayment from "../pages/Dinner/StripePayment/StripePayment"

const AppRoutes = () => {
  const domain = window.location.hostname;

  // TODO: change the dev-frontend in production
  const isLoginDomain =
    domain === "portal.dev-frontend.grubgenie.ai" || domain === "portal.localhost";
  if (isLoginDomain) {
    return (
      <Routes>
        <Route
          path="/"
          element={
            <PublicRoute>
              <LoginWrapper />
            </PublicRoute>
          }
        />
        <Route path="/signup" element={<SignUpWrapper />} />
        <Route path="/resetpassword" element={<Resetwrapper />} />
        <Route path="/onBoarding" element={<OnBoarding />} />
        <Route
          path="/changepassword/:token"
          element={<ResetPasswordwrapper />}
        />
      </Routes>
    );
  }

  // TODO: change the dev-frontend in production
  const isLoginDomainSuperAdmin =
    domain === "superadmin.dev-frontend.grubgenie.ai" || domain === "superadmin.localhost";
  if (isLoginDomainSuperAdmin) {
    return (
      <Routes>
        <Route path="/" element={<Outlet />}>
          <Route
            index
            element={
              <PublicRoute>
                <LoginWrapper />
              </PublicRoute>
            }
          />

          <Route
            path="dashboard"
            element={
              <PrivateRoute roles={["super-admin"]}>
                <SuperAdminView />
              </PrivateRoute>
            }
          />
          <Route
            path="add"
            element={
              <PrivateRoute roles={["super-admin"]}>
                <RestaurantInformation />
              </PrivateRoute>
            }
          />
        </Route>
      </Routes>
    );
  }

  /* /**
   * Defines the routes for the restaurant section of the application.
   * - /restaurant
   *   - /dashboard: Displays the restaurant information for authorized restaurant partners.
   *   - /menu
   */

  // TODO: change the dev-frontend in production
  const hasSubdomain = domain.indexOf("dev-frontend.grubgenie.ai") !== -1 ? domain.split(".").length >= 4 : domain.split(".").length >= 2;
  if (hasSubdomain) {
    return (
      <Routes>
        <Route path="/" element={<SidebarLayout />}>
          {/* Root redirect to menu */}
          <Route index element={
            <PrivateRoute roles={["restaurant-partner"]}>
              <TokenSetter redirectTo="/menu-management" />
            </PrivateRoute>
          } />

          {/* Delivery Management */}
          <Route
            path="/delivery"
            element={
              <PrivateRoute roles={["restaurant-partner"]}>
                <DeliveryManagement />
              </PrivateRoute>
            }
          />
          <Route
            path="restaurant-profile"
            element={
              <PrivateRoute roles={["restaurant-partner"]}>
                <RestaurantInformation />
              </PrivateRoute>
            }
          />
          <Route path="menu-management" element={<MenuManagementLayout />}>
            <Route
              index
              element={
                <PrivateRoute roles={["restaurant-partner"]}>
                  <MenuManagement />
                </PrivateRoute>
              }
            />
            <Route
              path="create"
              element={
                <PrivateRoute roles={["restaurant-partner"]}>
                  <AddMenu />
                </PrivateRoute>
              }
            />
            <Route
              path="edit/:id"
              element={
                <PrivateRoute roles={["restaurant-partner"]}>
                  <AddMenu />
                </PrivateRoute>
              }
            />
          </Route>
          <Route path="insights" element={<Outlet />}>
            <Route
              index
              element={
                <PrivateRoute roles={["restaurant-partner"]}>
                  <Insights />
                </PrivateRoute>
              }
            />
          </Route>
          <Route path="dinein-management" element={<Outlet />}>
            <Route
              index
              element={
                <PrivateRoute roles={["restaurant-partner"]}>
                  <DineIn />
                </PrivateRoute>
              }
            />
          </Route>
          <Route path="table" element={<Outlet />}>
            <Route
              index
              element={
                <PrivateRoute roles={["restaurant-partner"]}>
                  <TableManagement />
                </PrivateRoute>
              }
            />
            <Route
              path="history"
              element={
                <PrivateRoute roles={["restaurant-partner"]}>
                  <OrderHistory />
                </PrivateRoute>
              }
            />
          </Route>
        </Route>
        {/* Diner routes */}
        <Route path="/menu" >
          <Route element={<DinerLayout />}>
            <Route index element={<Diner />} />
            <Route path="closed" element={<ClosedRestaurant />} />

            <Route path="invalid" element={<InvalidTable />} />
            <Route path="cart" element={<RestaurantCart />} />
            {/* <Route path="experience" element={<GenieExperience />} /> */}
            <Route path="bill" element={<RestaurantBill />} />
            <Route path="bill/stripe/:clientSecret" element={<StripePayment />} />
            <Route path="bill/cash" element={<CashPayment />} />
            <Route path="bill/success" element={<RestaurantPaymentSuccess />} />
            <Route path="bill/failed" element={<RestaurantPaymentFailed />} />
          </Route>
          {/* <Route path="takeout" element={<Outlet />}>
          <Route index element={<Takeout />} />
          <Route path=":id" element={<Diner />} />
          <Route path=":id/cart" element={<RestaurantCart />} />
          <Route path=":id/bill" element={<RestaurantBill />} />
          <Route path=":id/bill/success" element={<RestaurantPaymentSuccess />} />
          <Route path=":id/bill/failed" element={<RestaurantPaymentFailed />} />
          </Route> */}

          {/* <Route path=":id/takeout" element={<Takeout />} />
          <Route path=":id" element={<Diner />} />
          <Route path=":id/cart" element={<RestaurantCart />} />
          <Route path=":id/experience" element={<GenieExperience />} />
          <Route
            path=":id/experience/Recommendation"
            element={<Recommendations />}
          />
          <Route path=":id/bill" element={<RestaurantBill />} />
          <Route path=":id/bill/success" element={<RestaurantPaymentSuccess />} />
          <Route path=":id/bill/failed" element={<RestaurantPaymentFailed />} /> */}
        </Route>
      </Routes>
    );
  }

  return (
    <Routes>

      <Route path="/noInternet" element={<NoInternet />} />

      <Route path="/unauthorized" element={<Unauthorized />} />
      <Route path="/" element={<WebSite />} />
      <Route path="/animations" element={<Animation />} />
      <Route path="/Rating" element={<Rating />} />

      <Route path="/RiderPage" element={<RiderPage />} />

      <Route path="/changepassword/:token" element={<ResetPasswordwrapper />} />

      {/* /**
       * Defines nested routes for the '/genie' route, each with a specific path and corresponding element component.
       * @returns JSX elements for rendering the nested routes under the '/genie' route.
       */}

      {/* <Route path="/genie" >
        <Route path="dine" element={<DinerLayout />}> */}
          {/* <Route path=":id/closed" element={<ClosedRestaurant />} />

          <Route path=":id" element={<Diner />} />
          <Route path=":id/invalid" element={<InvalidTable />} />
          <Route path=":id/cart" element={<RestaurantCart />} /> */}
          {/* <Route path=":id/experience" element={<GenieExperience />} /> */}
          {/* <Route path=":id/bill" element={<RestaurantBill />} />
          <Route path=":id/bill/stripe/:clientSecret" element={<StripePayment />} />
          <Route path=":id/bill/cash" element={<CashPayment />} />
          <Route path=":id/bill/success" element={<RestaurantPaymentSuccess />} />
          <Route path=":id/bill/failed" element={<RestaurantPaymentFailed />} /> */}
        {/* </Route> */}
        {/* <Route path="takeout" element={<Outlet />}>
          <Route index element={<Takeout />} />
          <Route path=":id" element={<Diner />} />
          <Route path=":id/cart" element={<RestaurantCart />} />
          <Route path=":id/bill" element={<RestaurantBill />} />
          <Route path=":id/bill/success" element={<RestaurantPaymentSuccess />} />
          <Route path=":id/bill/failed" element={<RestaurantPaymentFailed />} />
        </Route> */}

        {/* <Route path=":id/takeout" element={<Takeout />} />
        <Route path=":id" element={<Diner />} />
        <Route path=":id/cart" element={<RestaurantCart />} />
        <Route path=":id/experience" element={<GenieExperience />} />
        <Route
          path=":id/experience/Recommendation"
          element={<Recommendations />}
        />
        <Route path=":id/bill" element={<RestaurantBill />} />
        <Route path=":id/bill/success" element={<RestaurantPaymentSuccess />} />
        <Route path=":id/bill/failed" element={<RestaurantPaymentFailed />} /> */}
      {/* </Route> */}
    </Routes>
  );
};

export default AppRoutes;
