import { Accordion, AccordionDetails, AccordionSummary, Badge, Grid, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import BasicButton from "../../../components/Button";
import { useDiner, useRestaurant } from "../../../utils/hooks";
import useToken from "../../../utils/hooks/useToken";
import { useNavigate, useParams } from "react-router";
import DrawerComponent from "../../../components/Drawer/Index";
import AddNote from "./addnote";
import Isolation_Mode from "../../../assets/images/Isolation_Mode.svg";
import cart from "../../../assets/Icons/Cart.svg";
import backArrow from "../../../assets/Icons/backArrow.svg";
import spiceIcon from "../../../assets/Icons/spiceIcon.png";
import broccoli from "../../../assets/Icons/broccoli.png";
import customization from "../../../assets/Icons/customization.svg";
import Counter from "../../../components/Counter";
import DetailsDropdown from "../../../components/DetailsDropdown";
import { ExpandMore, Star } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import emptyCart from "../../../assets/images/emptyCart.png";
import cancel from "../../../assets/Icons/cancel.svg"
import MenuCardDrawer from "../MenuCard/MenuCardDrawer";
import CloseIcon from "@mui/icons-material/Close";
import cancel_dark from "../../../assets/Icons/cancel_dark.svg"
import { useDinerStore } from "../../../stores";
import { useShallow } from "zustand/shallow";

function RestaurantCart() {

  const {
    useGetCartItem,
    isDrawerNoteOpen,
    toggleNoteDrawer,
    // cartList,
    setCartList,
    OnAddCart,
    // addCartMenuIsLoading,
    placeOrder,
    placeOrderIsLoading,
    PersonalizeEvent,
    cartListDiff,
    // useGetCartCount,
    cancelOrder,
    deleteOrderIsLoading,
    useGetCustomizations,
    toggleDrawer,
    setDrawerOpenValue,
    isDrawerOpen,
    isDrawerOpenValue,
    setDeleteOrderModel,
    deleteOrderModel,
    handleDeleteOrderCancel,
    handleDeleteOrderConfirm,
    useGetDinerDetails
  } = useDiner();

  const {
    getCartCount,
    getCartTotal,
    cartList,
    tableId,
    partnerId,
    user,
    customDomain
  } = useDinerStore(useShallow(state => ({
    getCartCount: state.getCartCount,
    getCartTotal: state.getCartTotal,
    cartList: state.cartList,
    tableId: state.tableId,
    partnerId: state.partnerId,
    user: state.user,
    customDomain: state.customDomain
  })))

  const [localCartList, setLocalCartList] = useState(cartList);
  const [itemIds, setItemIds] = useState([])

  useEffect(() => {
    setLocalCartList(cartList);
    setItemIds(cartList[cartList.length - 1]?.orderDetails?.map(item => item.itemId))
  }, [cartList]);

  const { getCartId, getOrderId, getUserDetails, getPartnerId, savePartnerId, removeToken } = useToken();
  const navigate = useNavigate();
  // const location = useLocation();
  // const tableId = new URLSearchParams(location.search).get("tableId");

  useGetCartItem({
    partnerId: partnerId,
    dinerId: user?.id,
  });

  useEffect(() => {
    if (!tableId) {
      navigate(`/menu`)
    }
  }, [tableId, navigate, partnerId]);

  const increment = (item) => {
    const currentQuantity = localCartList[localCartList.length - 1]?.orderDetails?.find(
      cartItem => cartItem.itemId === item.itemId
    )?.quantity ?? 0;
    const newQuantity = currentQuantity + 1;
    // updateLocalCart(item, newQuantity);

    // PersonalizeEvent({ itemIds: [item?.itemId], evenType: "CART" });
    OnAddCart({
      itemId: item?.itemId,
      itemName: item?.item_name,
      oPrice: item?.oPrice,
      dPrice: item?.dPrice,
      description: item?.description,
      customization: item?.customization || [],
      count: newQuantity,
      partnerId: partnerId,
      tableId: tableId,
    });
  };

  const decrement = (item) => {
    const currentQuantity = localCartList[localCartList.length - 1]?.orderDetails?.find(
      cartItem => cartItem.itemId === item.itemId
    )?.quantity ?? 0;
    if (currentQuantity >= 1) {
      const newQuantity = currentQuantity - 1;
      // updateLocalCart(item, newQuantity);

      // PersonalizeEvent({ itemIds: [item?.itemId], evenType: "CART" });
      OnAddCart({
        itemId: item?.itemId,
        itemName: item?.item_name,
        oPrice: item?.oPrice,
        dPrice: item?.dPrice,
        description: item?.description,
        customization: item?.customization || [],
        count: newQuantity,
        partnerId: partnerId,
        tableId: tableId,
      });
    }
  };

  const { data: customizations, isLoading: customizationsIsLoading } = useGetCustomizations({
    menuIds: itemIds,
    partnerId: partnerId
  })

  const handleClick = (item) => {
    toggleDrawer();
    PersonalizeEvent({ itemIds: [item?._id], evenType: "CLICK" });
    setDrawerOpenValue(item);
  };

  // Add state to track expanded accordion panels
  const [expandedPanel, setExpandedPanel] = useState(null);

  // Add effect to set initial expanded panel to the last order
  useEffect(() => {
    if (cartList.length > 0) {
      setExpandedPanel(cartList.length - 1);
    }
  }, [cartList]);

  // Add handler for accordion changes
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : null);
  };

  return (
    <>
      <Grid container direction="column">
        <Grid
          item
          sx={12}
          className=" bg-[#b80e0c]  flex justify-between items-end  px-[1.25rem] py-[0.75rem]"
        >
          <img
            src={Isolation_Mode}
            alt=""
            srcSet=""
            className=""
            height={"36px"}
          // width={"166px"}
          />
          {/* TODO: Add cart count functionality */}
          <Badge badgeContent={getCartCount()}
            // onClick={() => navigate("*")}
            sx={{ cursor: "pointer", "& .MuiBadge-badge": { backgroundColor: "white", color: "#B80E0C", fontWeight: 600, position: "absolute", top: "3px", right: "4px" } }}>
            <img src={cart} alt="cart" />
          </Badge>
        </Grid>

        <Grid
          container
          item
          xs={12}
          direction="row"
          gap="12px"
          alignItems="center"
          className="p-4"
        >
          <img className="h-[20px] w-[20px] cursor-pointer" src={backArrow} alt=""
            onClick={() => {
              // handleDiff()
              // navigate(-1)
              navigate(`/menu?tableId=${tableId}`)
            }}
          />
          <h1 className="  font-[500] text-[14px]">
            My Orders
          </h1>
        </Grid>

        {/* {(cartList.length >= 1) ? ( */}
        <>
          {/* {cartDataIsLoading || customizationsIsLoading || !customizations === undefined ? ( */}
          {customizationsIsLoading || !customizations === undefined ? (
            <div className="flex flex-col justify-center items-center h-[calc(100vh-232.17px)]">
              <div className="h-14 w-14 border-4 border-gray-300 border-t-red-600 rounded-full animate-spin mb-4" />
              <p className="text-gray-600">
                Loading...
              </p>
            </div>
          ) : (
            <>
              {cartList && cartList.length > 0 ?
                <Grid
                  container
                  item
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  className=" overflow-auto"
                  style={{
                    maxHeight: `calc(100vh - ${cartList[cartList.length - 1]?.isPlaced ? "177px" : "119.17px"})`,
                    height: "fit-content",
                    padding: "0 16px",
                    paddingBottom: "119.17px",
                    gap: "12px"
                  }}
                >
                  {cartList?.map((order, index) => (
                    // order?._id &&
                    <Accordion
                      key={index}
                      expanded={expandedPanel === index}
                      onChange={handleAccordionChange(index)}
                      sx={{
                        width: "100%",
                        boxShadow: "none",
                        border: "none",
                        borderRadius: "0px",
                        '&::before': {
                          top: "-6px",
                          opacity: '1 !important',
                          display: 'block !important'
                        },
                        '&.Mui-expanded': {
                          margin: 0,
                        },
                        '&.Mui-expanded + .MuiAccordion-root::before': {
                          opacity: '1 !important',
                          display: 'block !important'
                        },
                        '& .MuiAccordionDetails-root': {
                          paddingTop: '10px',
                        },
                      }}
                    >
                      {/* {console.log(order, "order")} */}
                      <AccordionSummary
                        expandIcon={order?.isPlaced === true ? <ExpandMore sx={{ color: "#1C1B1F", transform: "scale(1.3)" }} /> : null}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{
                          padding: 0,
                          fontWeight: "500",
                          fontSize: "16px",
                          minHeight: "0px",
                          margin: 0,
                          '& .MuiAccordionSummary-content': {
                            margin: "8px 0px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          },
                          '& .MuiAccordionSummary-content.Mui-expanded': {
                            margin: '8px 0px !important'
                          },
                          '&.Mui-expanded': {
                            minHeight: 'fit-content',
                            height: 'fit-content',
                            margin: '0 !important'
                          },
                          '& .MuiPaper-root': {
                            margin: "0"
                          }
                        }}
                      >
                        <span className="text-[#000000]">ORDER #{index + 1}</span>
                        {/* TODO: add order clear functionality */}
                        {index === cartList.length - 1 && order?.isPlaced === false &&
                          <div className={`flex items-center gap-1 text-[#B80E0C] ${(deleteOrderIsLoading || placeOrderIsLoading) ? "opacity-50" : "cursor-pointer"}`}
                            onClick={(e) => {
                              e.stopPropagation()
                              if (!deleteOrderIsLoading || !placeOrderIsLoading) {
                                cancelOrder(order?._id)
                              }
                            }}
                          >
                            <img className="h-[14px]" src={cancel} alt="" />
                            <span className="font-normal text-[14px]">Clear Order</span>
                          </div>
                        }
                      </AccordionSummary>
                      <AccordionDetails sx={{ padding: 0, gap: "12px", display: "flex", flexDirection: "column" }}>
                        {order?.orderDetails?.map((cartItem, index) => {
                          return (
                            <Grid
                              container
                              gap={2}
                              item
                              xs={12}
                              key={cartItem?._id}
                              className={`${cartItem === cartList[cartList.length - 1] ? '' : 'border-b-[1px] last:border-b-0 border-[#F3F0F3]'} pb-[10px] overflow-auto`}
                              sx={{ flexWrap: "nowrap" }}
                              justifyContent={"space-between"}
                            >
                              <div>
                                <div className="mb-[12px]">
                                  <div className="flex items-center max-h-3">
                                    <div className="mr-1">
                                      {[...Array(customizations?.find(customization => customization._id === cartItem.itemId)?.spicinessLevel)].map((_, index) => (
                                        <img
                                          key={index}
                                          src={spiceIcon}
                                          alt="Spice"
                                          className="w-3 h-3 inline-block"
                                        />
                                      ))}
                                    </div>
                                    {/* <div>
                                      {[...Array(1)].map((_, index) => (
                                        <img
                                          key={index}
                                          src={broccoli}
                                          alt="Veg"
                                          className="w-3 h-3 inline-block"
                                        />
                                      ))}
                                    </div> */}
                                  </div>

                                  {/* <h1 className="  font-quicksand font-[600] text-[16px] truncate w-[50%]  ">
                                    <span className="font-[700] text-[16px] text-genieRed  ">
                                      <span className="mr-1">{cartItem?.quantity}</span>
                                      <CloseIcon
                                        sx={{ fontSize: "16px", fontWeight: "700" }}
                                      />
                                    </span>
                                    <span className=" font-[600] text-[14px] ml-1  ">
                                      {cartItem?.item_name}
                                    </span>
                                  </h1> */}
                                  {order?.isPlaced === false ?
                                    <h1 className="font-normal text-[18px]">
                                      {cartItem?.item_name}
                                    </h1>
                                    :
                                    <>
                                      <h1 className="font-normal text-[18px] flex gap-3">
                                        <span>{cartItem?.item_name}</span>
                                        <span className="text-[#CD1719] font-semibold text-nowrap">X {cartItem?.quantity}</span>
                                      </h1>
                                      <span className="text-[#545454] font-normal">{cartItem?.customization?.map(customization => customization.items.map(item => item.itemName).join(", ")).join(", ")}</span>
                                    </>
                                  }


                                </div>
                                <DetailsDropdown headerText="More Details"
                                  content={cartItem?.description}
                                />
                                {customizations?.find(customization => customization._id === cartItem.itemId)?.customization?.length > 0 && order?.isPlaced === false &&
                                  <div className={`cursor-pointer pt-[8px] ${placeOrderIsLoading ? "opacity-50" : ""}`}
                                    onClick={() => {
                                      if (!placeOrderIsLoading)
                                        handleClick(customizations?.find(customization => customization._id === cartItem.itemId))
                                    }}
                                  >
                                    <div className="flex items-center gap-[4px]">
                                      <img src={customization} alt="" />
                                      <span className="text-[#b80e0c] underline">Customize</span>
                                    </div>
                                    <span className="text-[#545454] font-normal">{cartItem?.customization?.map(customization => customization.items.map(item => item.itemName).join(", ")).join(", ")}</span>
                                  </div>
                                }
                              </div>
                              <div className="flex flex-col items-end">
                                {order?.isPlaced === false ?
                                  <>
                                    <Counter
                                      // value={cartItem?.quantity}
                                      // value={localCartList ? localCartList[localCartList.length - 1]?.orderDetails?.find(localCartItem => localCartItem.itemId === cartItem.itemId)?.quantity ?? 0 : 0}
                                      value={!cartList[cartList.length - 1]?.isPlaced ? cartList[cartList.length - 1]?.orderDetails?.find(cartItemNew => cartItemNew.itemId === cartItem.itemId)?.quantity ?? 0 : 0}
                                      increment={() => increment(cartItem)}
                                      decrement={() => decrement(cartItem)}
                                      className="mx-4 my-[9px]"
                                      leftButtonColor="red"
                                      isLoading={placeOrderIsLoading}
                                    />

                                    <div className="flex justify-center items-center gap-3 h-auto mt-[7px] w-full">
                                      {/* <div className="text-[#377931] flex items-center gap-[3px]">
                                        <Star sx={{ height: "15px", width: "15px" }} />
                                        <span className="font-normal">4.6</span>
                                      </div> */}

                                      <div className="relative">
                                        <h1 className="font-semibold text-[18px] text-nowrap">
                                          <span className="text-xs text-[#4d4d4d]">AED </span>
                                          {((cartItem?.oPrice - cartItem?.dPrice + cartItem?.customization?.reduce((acc, customization) => acc + customization.items.reduce((itemAcc, item) => itemAcc + item.price, 0), 0)) * cartItem?.quantity).toFixed(2)}
                                        </h1>
                                        {/* {cartItem?.customization?.length > 0 &&
                                          <h1 className="font-semibold text-xs text-[#b80e0c] text-nowrap absolute -left-[10.8px]">
                                            <span className="text-xs text-[#b80e0c]">+ AED </span>
                                            {cartItem?.customization?.reduce((acc, customization) => acc + customization.items.reduce((itemAcc, item) => itemAcc + item.price, 0), 0).toFixed(2)}
                                          </h1>
                                          // <h1 className="text-[#b80e0c] font-semibold text-xs absolute -left-[10.5px]">
                                          //   + AED {cartItem?.customization?.reduce((acc, customization) => acc + customization.items.reduce((itemAcc, item) => itemAcc + item.price, 0), 0).toFixed(2)}
                                          // </h1>
                                        } */}
                                      </div>
                                    </div>
                                  </>

                                  : <div className={`flex flex-col justify-between items-center ${(user && user.userType === "diner") && "w-[106px]"}`}>
                                    {/* <div className="text-[#377931] flex items-center gap-[3px]">
                                  <Star sx={{ height: "15px", width: "15px" }} />
                                  <span className="font-normal">4.6</span>
                                </div> */}

                                    <div className="relative">

                                      <h1 className={`font-semibold text-[18px] text-nowrap ${(user && user.userType === "diner") && "py-[13px]"}`}>
                                        <span className="text-xs text-[#4d4d4d]">AED </span>
                                        {((cartItem?.oPrice - cartItem?.dPrice + cartItem?.customization?.reduce((acc, customization) => acc + customization.items.reduce((itemAcc, item) => itemAcc + item.price, 0), 0)) * cartItem?.quantity).toFixed(2)}
                                      </h1>
                                      {/* {cartItem?.customization?.length > 0 &&
                                        <h1 className="font-semibold text-xs text-[#b80e0c] text-nowrap absolute -left-[10.8px] bottom-[6px]">
                                          <span className="text-xs text-[#b80e0c]">+ AED </span>
                                          {cartItem?.customization?.reduce((acc, customization) => acc + customization.items.reduce((itemAcc, item) => itemAcc + item.price, 0), 0).toFixed(2)}
                                        </h1>
                                      } */}
                                    </div>

                                    {/* TODO: being prepared, served? */}
                                    {(user && user.userType === "diner") &&
                                      <div div className={`${cartItem?.status === "Served" ? "bg-[#91FF87]" : "bg-[#FFD166]"} text-xs font-normal px-[6px] py-[2px] rounded-full w-fit text-nowrap leading-[18px]`}>{cartItem?.status}</div>
                                    }
                                  </div>
                                }
                              </div>
                            </Grid>
                          );
                        })}
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </Grid>
                :
                <div className="flex flex-col items-center justify-center h-[calc(100vh-180px)] flex-grow px-4">
                  <img className="max-w-[231px]" src={emptyCart} alt="" />
                  <p className="font-medium text-[18px] mb-[20px]">Your basket is empty</p>
                  <BasicButton
                    color="genieRed"
                    sx={{
                      textTransform: "none",
                      color: "#fff",
                      padding: "12px 16px",
                      fontWeight: "500",
                      width: "100%",
                    }}
                    onClick={() => {
                      navigate(`/menu?tableId=${tableId}`)
                    }}
                  >
                    <span className="font-medium flex items-center gap-[8px]">
                      <AddIcon height={"14px"} width={"14px"} />
                      <span className="leading-snug">Add Items</span>
                    </span>
                  </BasicButton>
                </div>
              }
            </>
          )}
          <Grid
            container
            item
            xs={12}
            direction="column"
            display={cartList.length > 0 ? "flex" : "none"}
            className={`px-4 pt-2 pb-3 fixed bottom-0 border-t-[1px] bg-white border-[#d1d1d1] z-10`}
          >
            <div className="flex justify-between ">
              <div>
                <h1 className=" font-normal text-[16px] text-[#252525]">
                  Total
                </h1>
                <h3 className=" font-[500] text-[12px] text-[#777777]">
                  {/* {console.log(cartList)} */}
                  ({getCartCount()}{" "}
                  {getCartCount() > 1 ? "items" : "item"})
                </h3>
              </div>
              <h1 className="font-semibold text-[20px] text-[#252525]">
                <span className="text-[#4D4D4D] text-[14px]">AED </span>
                {getCartTotal()}
              </h1>
            </div>
            <div className="flex justify-between flex-col gap-2  items-center  pt-2">
              {/* <div className="flex flex-col">
                <div className="text-quicksand font-[600] text-[14px] max-w-[30vw] overflow-hidden ">
                  {cartData?.result?.notes}
                </div>
                <div
                  className="text-genieRed flex items-center "
                  onClick={toggleNoteDrawer}
                >
                  {cartData?.result?.notes ? (
                    <CreateIcon sx={{ color: "#ED1703" }} />
                  ) : (
                    <NoteOutlinedIcon sx={{ color: "#ED1703" }} />
                  )}
                  <span className="ml-2 font-[600] text-[14px] text-genieRed">
                    {cartData?.result?.notes ? "Edit a note" : " Add a note"}
                  </span>
                </div>
              </div> */}
              {/* {cartList?.find(order => order.isPlaced === false) ? */}
              <BasicButton
                color="genieRed"
                type="submit"
                // width: "236px", height: "36px"
                sx={{
                  textTransform: "none",
                  color: "#fff",
                  padding: "12px 16px",
                  fontWeight: "500",
                  width: "100%",
                  height: "48px",
                  display: cartList?.find(order => order.isPlaced === false) ? "block" : "none"
                }}
                disabled={placeOrderIsLoading}
                onClick={() => {
                  // handleDiff();
                  // placeOrder(getOrderId());
                  placeOrder({ tableId, restId: partnerId });
                }}
              >
                <span className="font-medium">
                  {placeOrderIsLoading ? "Placing Order..." : "Place Order"}
                </span>
              </BasicButton>
              {/* : */}
              <>
                <BasicButton
                  color="genieRed"
                  type="submit"
                  variant="outlined"
                  // width: "236px", height: "36px"
                  sx={{
                    textTransform: "none",
                    color: "#fff",
                    padding: "12px 16px",
                    fontWeight: "500",
                    width: "100%",
                    height: "48px",
                    display: !cartList?.find(order => order.isPlaced === false) ? "block" : "none"
                  }}
                  onClick={() => {
                    // handleDiff();
                    // placeOrder(getOrderId());
                    navigate(`/menu?tableId=${tableId}`)
                  }}
                >
                  <span className="font-medium text-black">
                    Order More
                  </span>
                </BasicButton>
                <div className={`w-full flex gap-2 ${!cartList?.find(order => order.isPlaced === false) ? "block" : "hidden"}`}>
                  <BasicButton
                    color="genieRed"
                    type="submit"
                    // width: "236px", height: "36px"
                    sx={{
                      textTransform: "none",
                      color: "#fff",
                      padding: "12px 16px",
                      fontWeight: "500",
                      width: "100%",
                      height: "48px",
                    }}
                    onClick={() => {
                      // handleDiff();
                      // placeOrder(getOrderId());
                      navigate(`/menu/bill/?tableId=${tableId}`);
                    }}
                  >
                    <span className="font-medium text-nowrap">
                      Pay Bill
                    </span>
                  </BasicButton>
                  {/* <BasicButton
                    color="genieRed"
                    type="submit"
                    // width: "236px", height: "36px"
                    sx={{
                      textTransform: "none",
                      color: "#fff",
                      padding: "12px 16px",
                      fontWeight: "500",
                      width: "100%",
                      height: "48px",
                    }}
                    // onClick={() => {
                    //   // handleDiff();
                    //   // placeOrder(getOrderId());
                    //   navigate(`/genie/dine/${restId}/bill/?tableId=${tableId}`);
                    // }}
                  >
                    <span className="font-medium text-nowrap">
                      Pay in Person
                    </span>
                  </BasicButton> */}
                </div>
              </>
              {/* } */}
            </div>
          </Grid>
        </>
        {/* ) : ( */}
        {/* <EmptyBasket navigate={navigate} /> */}
        {/* )} */}
      </Grid >

      <DrawerComponent
        position="bottom"
        open={isDrawerNoteOpen}
        sx={{
          "& .MuiDrawer-paper": {
            borderRadius: "20px 20px 0 0",
          },
        }}
        onClose={toggleNoteDrawer}
      >
        <AddNote />
      </DrawerComponent>

      <DrawerComponent
        position="bottom"
        open={isDrawerOpen}
        // visiblePuller={true}
        sx={{
          "& .MuiDrawer-paper": {
            borderRadius: "20px 20px 0 0",
            maxHeight: "90vh", // Added max height
            boxShadow: "none", // Remove shadow
          },
        }}
        onClose={() => {
          toggleDrawer({ restId: partnerId })
          // queryClient.invalidateQueries("getAllCartByID");
        }}
      >
        <MenuCardDrawer
          maxHeight="90vh"
          data={isDrawerOpenValue}
          // addToChart={toggleDrawer}
          addToChart={OnAddCart}
          cartList={cartList}
          restId={partnerId}
          tableId={tableId}
          onClose={() => {
            toggleDrawer({ restId: partnerId })
            // queryClient.invalidateQueries("getAllCartByID");
          }}
        />
      </DrawerComponent>

      <DrawerComponent
        position="bottom"
        visiblePuller={true}
        open={deleteOrderModel}
        onClose={() => {
          setDeleteOrderModel(false)
          handleDeleteOrderCancel()
        }}
        sx={{
          "& .MuiDrawer-paper": {
            borderRadius: "20px 20px 0 0",
            maxHeight: "90vh", // Added max height
            boxShadow: "none", // Remove shadow
          },
        }}
      >
        <div className={`flex flex-col justify-around w-[100vw] bg-[#f4f3f3] max-h-[90vh] overflow-hidden`}>
          <div className="self-start w-full">
            <div className="w-full px-4 pt-4 flex justify-between border-b-[2px] border-[#F8F8F8] pb-[8px] gap-2">
              <div>
                <p className="text-[16px] font-medium max-w-[228px]">
                  Are you sure you want to {cartList[cartList.length - 1]?.orderDetails?.length > 1 ? "delete this item" : "cancel the current order"}?
                </p>
              </div>
              <img className="cursor-pointer self-baseline" onClick={() => {
                setDeleteOrderModel(false)
                handleDeleteOrderCancel()
              }} src={cancel_dark} alt="" style={{ color: '#4A4747' }} />
            </div>
            <div className="px-4 flex gap-2 pb-4">
              <BasicButton variant="outlined"
                sx={{
                  width: "100%",
                  textTransform: "none"
                }}
                onClick={handleDeleteOrderCancel}
                color="genieRed"
              >
                Go Back
              </BasicButton>
              <BasicButton
                sx={{
                  width: "100%",
                  textTransform: "none",
                  color: "#fff"
                }}
                color="genieRed"
                onClick={handleDeleteOrderConfirm}
              >
                Confirm
              </BasicButton>
            </div>
          </div>
        </div>
      </DrawerComponent>
    </>
  );
}

export default RestaurantCart;
