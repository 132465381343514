import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';
import { useParams } from 'react-router-dom';
import { useDinerStore } from '../../../stores';
import { useShallow } from 'zustand/shallow';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

const StripePayment = () => {
  const {getCartTotal} = useDinerStore(useShallow(state => ({
    getCartTotal: state.getCartTotal,
  })))
  const { clientSecret } = useParams();

  const options = {
    clientSecret: clientSecret,
    loader: 'always',
    appearance: {
      theme: 'stripe',
    }
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm />
    </Elements>
  );
}

export default StripePayment