import QRCodeStyling from 'qr-code-styling';
import logo from "../../../assets/Icons/Grubgenie_Logo_circular.svg";
import React, { useEffect, useRef, useState } from 'react'

const QrCode = ({ link, qrCode }) => {
    const [url, setUrl] = useState(link);
    const ref = useRef(null);

    useEffect(() => {
        qrCode.append(ref.current);
    }, []);

    useEffect(() => {
        qrCode.update({
            data: url,
        });
    }, [url]);

    return (
        <div className='h-[300px] w-[300px] [&>svg]:h-[300px] [&>svg]:w-[300px]' ref={ref} />
    )
}

export default QrCode