import { Badge, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { TextInput } from '../components/InputBox'
import { useDebounce } from '../useDebaunce'
import { useRestaurant } from '../utils/hooks'
import LogOutButton from '../components/Logout'
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import SearchIcon from "@mui/icons-material/Search";
import closeIcon from "../assets/Icons/close.svg"
import { LazyLoadImage } from 'react-lazy-load-image-component'

const MenuManagementLayout = () => {
    const [searchValue, setSearchValue] = useState("");
    const { useRequestedOrders, useSearchByItemName, setNotificationDrawer } = useRestaurant()
    const navigate = useNavigate();

    const debouncedValue = useDebounce(searchValue, 300);


    const { data: requestedOrders } = useRequestedOrders()
    const { data: searchResult, isLoading: searchResultIsLoading } = useSearchByItemName(debouncedValue)

    return (
        <div
            className="overflow-auto"
            style={{
                height: "100vh"
                // height: "calc(100vh - 51px)",
            }}
        >
            <Grid container sx={12}>
                <Grid container xs={12} justifyContent="space-between">
                    <Grid item xs={12} sx={{ borderBottom: "1px solid #1C1C1C1A" }}>
                        <div className="  flex flex-col sm:flex-row  sm:items-center    sm:justify-between py-4 px-8 gap-2">
                            <h3 className="hidden sm:flex gap-3 items-center">
                                <span className="text-[#1C1C1C66]"> Dashboard </span>{" "}
                                <span className="text-[#1C1C1C66]">/</span>
                                <span>Menu Management</span>
                            </h3>
                            <div className="w-full sm:max-w-[500px] flex gap-4 items-center justify-end">
                                <div className="relative w-full h-full">
                                    <TextInput
                                        variant="outlined"
                                        preContent={<SearchIcon sx={{ color: "#c8c8c8" }} />}
                                        preContentSx={{ display: "flex", justifyContent: "center" }}
                                        transparentBorder
                                        placeholder="Search Menu Item"
                                        value={searchValue}

                                        borderRadius="8px"
                                        // inputStyleProps={{
                                        //   padding: "0px 0px 0px 8px"
                                        // }}

                                        sx={{
                                            fontFamily: "Poppins",
                                            width: "100%",
                                            backgroundColor: "#fff",
                                            '& .MuiInputBase-input::placeholder': {
                                                color: '#545454'
                                            },
                                            '& .MuiInputBase-input:-webkit-autofill': {
                                                '-webkit-box-shadow': '0 0 0 100px transparent inset',
                                                transition: 'background-color 5000s ease-in-out 0s'
                                            },
                                            // '& .css-1ua80n0-MuiInputBase-input-MuiOutlinedInput-input': {
                                            //   padding: "4px 0px"
                                            // }
                                        }}
                                        onChange={(e) => setSearchValue(e.target.value)}
                                    //   label="Description(optional)"
                                    />
                                    {
                                        searchValue &&
                                        <img className='absolute top-2 right-2 bg-[#f7f7f7] cursor-pointer' src={closeIcon} alt=""
                                            onClick={() => {
                                                setSearchValue("")
                                            }}
                                        />
                                    }
                                    {debouncedValue.length > 0 && searchValue &&
                                        <div
                                            className="absolute w-full bg-white top-11 rounded-[8px] p-4 border border-[#E3E3E3] shadow-md z-[100] flex flex-col gap-[6px]"
                                        >
                                            {debouncedValue && searchResult?.result?.length > 0 &&
                                                <>
                                                    <p className="font-medium text-[14px] text-[#757373]">Results for “{debouncedValue}”</p>
                                                    <hr />
                                                </>
                                            }
                                            <div className="flex flex-col max-h-[398px] overflow-auto scrollbar-thin">
                                                {searchResult?.result?.length ?
                                                    searchResult?.result?.map(item =>
                                                        <div className="flex items-center gap-4 hover:bg-gray-200 p-1 rounded cursor-pointer px-2"
                                                            onClick={() => {
                                                                navigate(`/menu/edit/${item?._id}`)
                                                                setSearchValue("")
                                                            }}
                                                        >
                                                            <LazyLoadImage
                                                                effect='opacity'
                                                                className="h-[57px] w-[57px] rounded-full"
                                                                src={item?.image?.url} alt=""
                                                            />
                                                            <div className="flex flex-col">
                                                                <p className="text-[#4D4D4D] text-base font-medium">{item?.item_name?.toString()}</p>
                                                                <p className="text-[#1C1B1F] text-[14px] font-semibold">AED {item?.oPrice - item?.dPrice}</p>
                                                            </div>
                                                        </div>
                                                    )
                                                    :
                                                    <div className="text-center text-[#757373]">{searchResultIsLoading ? "Searching..." : "Result not found"}</div>
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                                <Badge badgeContent={requestedOrders?.requestedOrders?.length} color="error">
                                    <NotificationsNoneOutlinedIcon sx={{ cursor: "pointer" }} onClick={() => setNotificationDrawer(true)} />
                                </Badge>
                                <div>
                                    <LogOutButton />
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>

                <Outlet />

            </Grid>
        </div>
    )
}

export default MenuManagementLayout