import React, { forwardRef } from "react";
import {
  TextField,
  InputAdornment,
  FormHelperText,
  Typography,
  IconButton,
} from "@mui/material";

export const  TextInput = forwardRef((props, ref) => {
  const {
    type,
    size,
    label,
    placeholder,
    name,
    value,
    defaultValue,
    helpertext,
    error,
    variant,
    disabled,
    required,
    multiline,
    rows,
    minRows,
    maxRows,
    preContent,
    postContent,
    onChange,
    autoComplete,
    sx,
    labelsx,
    preContentSx,
    postContentSx,
    onFocus,
    onBlur,
    inputRef,
    autoFocus,
    handleClear,
    transparentBorder,
    borderRadius,
    backgroundColor,
    className,
    inputProps,
    inputStyleProps
  } = props;

  return (
    <div className={`flex-1 items-center truncate ${borderRadius}`} data-testid="text-input">
      {label && (
        <label
          className=" font-[700] block mb-2 text-[14px] text-[#252525]"
          htmlFor={`text-input-${name || "box"}`}
          required={required}
          style={labelsx}
        >
          {label}
        </label>
      )}
      <TextField
        {...props}
        className={`w-full ${className}`}
        ref={ref}
        // autoComplete={autoComplete}
        sx={{
          ...sx,
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none", // removes the outline (fieldset) for outlined variant
          },
        }}
        fullWidth
        hiddenLabel
        label=""
        id={`text-input-${name || "box"}`}
        type={type || "text"}
        size={size || "small"}
        variant={variant || "filled"}
        placeholder={placeholder}
        name={name}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        disabled={disabled}
        multiline={multiline}
        rows={rows}
        minRows={minRows}
        maxRows={maxRows}
        onFocus={onFocus}
        onBlur={onBlur}
        autoFocus={autoFocus}
        InputProps={{
          autoComplete: 'new-password',
          readOnly: false,
          style: transparentBorder ? {
            background: backgroundColor || "#F7F7F7",
            borderRadius: borderRadius || "4px",
            border: "none",
            ...inputStyleProps,
          } : {
            background: backgroundColor || "#F7F7F7",
            borderRadius: borderRadius || "4px",
            border: "1px solid #e2e8f0",
            ...inputStyleProps,
          },
          startAdornment: preContent ? (
            <InputAdornment position="start" style={preContentSx}>
              {preContent}
            </InputAdornment>
          ) : null,
          endAdornment: postContent ? (
            <InputAdornment position="end" style={postContentSx}>
              <IconButton onClick={handleClear} edge="end" style={{ padding: 0, margin: 0 }}>
                {postContent}
              </IconButton>
            </InputAdornment>
          ) : null,
        }}
        inputRef={inputRef}
      />
      {error && helpertext && (
        <FormHelperText className="text-xs leading-5 text-red-600">
          <Typography variant="body2" sx={{ textWrap: "wrap" }}>
            {helpertext}
          </Typography>
        </FormHelperText>
      )}
    </div>
  );
});
