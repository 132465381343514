import React from 'react'
import increase from "../../assets/Icons/increase.svg";
import decrease from "../../assets/Icons/decrease.svg";
import decrease_red from "../../assets/Icons/decrease_red.svg"

const Counter = ({ increment, decrement, value, maxWidth = "min-w-[5.25rem]", className, isLoading = false, leftButtonColor }) => {
    return (
        <div className={`${maxWidth} h-[25px] flex justify-between items-center bg-transparent rounded-full relative ${className}`}>
            {value && value > 0 ?
                <>
                    <img src={leftButtonColor === "red" ? decrease_red : decrease} onClick={decrement} className={`cursor-pointer active:brightness-90 z-10 ${isLoading && "opacity-50 pointer-events-none"}`} alt="Decrease" />
                    <span className={`font-medium text-[#b80e0c] z-10 cursor-default ${isLoading && "opacity-50"}`}>{value}</span>
                    <img src={increase} onClick={increment} className={`cursor-pointer active:brightness-90 z-10 ${isLoading && "opacity-50 pointer-events-none"}`} alt="Increase" />
                    <div className='w-full h-full absolute z-0 bg-white rounded-full border-[1px] border-[#f3f0f3]'></div>
                </>
                :
                <div onClick={increment} className={`bg-[#cd1719] text-white w-full rounded-full text-[12px] h-full flex items-center justify-center cursor-pointer active:brightness-90 ${isLoading && 'pointer-events-none brightness-90'}`}>
                    Add
                </div>
            }
        </div>
    )
}

export default Counter