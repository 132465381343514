import React from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { styled } from "@mui/material";
import { grey } from "@mui/material/colors";

function DrawerComponent({
  position,
  children,
  open,
  onClose,
  sx,
  variant,
  visiblePuller,
}) {
  const Puller = styled("div")(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
    borderRadius: 3,
    position: "absolute",
    top: 8,
    left: "calc(50% - 15px)",
    zIndex: theme.zIndex.drawer + 1,
  }));
  return (
    <SwipeableDrawer
      anchor={position}
      open={open}
      onClose={onClose}
      onOpen={()=>{}}
      SwipeAreaProps={{
        edge: "bottom",
      }}
      variant={variant}
      sx={{
        textTransform: "none",
        maxWidth: "95vw",
        "& .MuiDrawer-paper": {
          padding: "9px 22px",
        },
        ...sx,
      }}
    >
      {visiblePuller && <Puller />}
      {children}
    </SwipeableDrawer>
  );
}

export default DrawerComponent;
