import { Outlet, useNavigate } from 'react-router-dom';
import { useDiner } from '../utils/hooks';
import { useDinerStore } from '../stores';
import { useShallow } from "zustand/shallow"

const DinerLayout = () => {
    const navigate = useNavigate();

    const {
        useGetDinerDetails,
        useGetCartItem
    } = useDiner();

    const {
        isAuthenticated,
        tableId,
        customDomain,
        partnerId,
        user
    } = useDinerStore(
        useShallow((state) => ({
            isAuthenticated: state.isAuthenticated,
            customDomain: state.customDomain,
            tableId: state.tableId,
            partnerId: state.partnerId,
            user: state.user
        }))
    )

    // Combine API calls into a single effect
    useGetDinerDetails({ tableId, customDomain })
    useGetCartItem({ partnerId, dinerId: user.id })

    // // Redirect unauthorized users immediately
    // if (!isAuthenticated) {
    //     const searchParams = tableId ? `?tableId=${tableId}` : ''
    //     navigate(`/menu${searchParams}`)
    //     return null
    // }

    return <Outlet />
}

export default DinerLayout