import React, { useState, useCallback, useRef } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useRestaurant, useSuperAdmin } from "../../utils/hooks";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { titleCase } from "../../utils/helper";
import EditIcon from '../../assets/Icons/edit.svg';
import { SMALL_SIDEBAR_WIDTH, LARGE_SIDEBAR_WIDTH } from "../../Constants/services";

// Function to reorder the list on drag end
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const DraggableTabs = ({ tabs, children }) => {
  const [items, setItems] = useState(tabs);
  const containerRef = useRef(null);
  const { tabValue, handleTabChange } = useSuperAdmin();
  const { OnChangeSequence, setOpenModal,useGetTabList,setListArray } = useRestaurant();

  const onDragEnd = useCallback(
    (result) => {
      if (!result.destination) {
        return;
      }
      const reorderedItems = reorder(
        items,
        result.source.index,
        result.destination.index
      );
      OnChangeSequence(reorderedItems);
      handleTabChange({}, result.destination.index);
      setItems(reorderedItems);
    },
    [items, handleTabChange, OnChangeSequence]
  );

  const scrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: -100, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: 100, behavior: "smooth" });
    }
  };

  const { data: categoryList, isLoading: categoryListIsLoading } =
  useGetTabList();

  const handleCategory = () => {
    if (!categoryListIsLoading) {
      setOpenModal(true);
      setListArray(
        categoryList?.result?.map((item) => ({
          label: item?.food_category,
          value: item?._id,
        }))
      );
    }
  };

  return (
    <>
      <div className={`flex items-center justify-between border-b border-[#E8E8E8] w-[calc(100vw-${SMALL_SIDEBAR_WIDTH})] tablet:w-[calc(100vw-${LARGE_SIDEBAR_WIDTH})]`}>
        <div className="relative overflow-hidden ">
          <button
            className="absolute  md:hidden left-0 top-1/2 transform -translate-y-1/3 bg-white p-2 "
            onClick={scrollLeft}
          >
            <ArrowBackIosIcon sx={{ color: "#b80e0c" }} />
          </button>
          <div
            ref={containerRef}
            className="flex overflow-x-auto after:content-[''] after:w-10 after:h-full after:absolute after:right-0 after:bg-gradient-to-r after:from-transparent after:to-[#fff]"
          >
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable" direction="horizontal">
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    className="flex pt-2 px-6  pr-10"
                    {...provided.droppableProps}
                  >
                    {items.map((item, index) => (
                      <Draggable
                        key={item._id}
                        draggableId={item._id}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            className={`h-10 font-poppins text-sm flex items-center justify-center mx-3 cursor-pointer ${tabValue === index
                              ? "text-[#B80E0C] font-semibold"
                              : "text-[#757373] font-medium"
                              }`}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            onClick={() => handleTabChange(provided, index)}
                          >
                            <span className="flex items-center w-max">
                              {tabValue === index && <DragIndicatorIcon />}
                              {titleCase(item.label)}
                            </span>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <button
            className="absolute md:hidden right-0 top-1/2 transform -translate-y-1/3 bg-white p-2 "
            onClick={scrollRight}
          >
            <ArrowForwardIosIcon sx={{ color: "#b80e0c" }} />
          </button>
        </div>
        <div className="flex items-center justify-center pt-2 underline text-[#b80e0c] gap-1 cursor-pointer pr-8 pl-4"
          onClick={handleCategory}
        >
          <img src={EditIcon} alt="" />
          <p>Edit</p>
        </div>
      </div>
      {children(tabValue)}
    </>
  );
};

export default DraggableTabs;
