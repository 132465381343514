import { Grid } from "@mui/material";
import { Controller } from "react-hook-form";
import { useAuth, useRestaurant } from "../../../utils/hooks";
import { TextInput } from "../../../components/InputBox";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import { Autocomplete, useLoadScript } from "@react-google-maps/api";
import MapComponent from "../../../Map";
import { toast } from "react-toastify";
import search from "../../../assets/Icons/search.svg";
import { CustomTextInput } from "../../../components/InputBox/CustomInput";
import { SearchableDropDown } from "../../../components/SearchableDropdown";
import { useMemo } from "react";
import countryList from "react-select-country-list";
import { checkCountryHasPostalCode, postalCodeVerification } from "../../../utils/helper/postalCodeVerification";

function LocationDetails({ control, setValue, trigger, watch }) {
  const { onLoad, autocomplete } = useRestaurant();
  const { location, setLocation } = useAuth();
  const countryOptions = useMemo(() => countryList().getData(), [])


  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        const newLocation = {
          latitude: place.geometry.location.lat(),
          longitude: place.geometry.location.lng(),
        };
        setLocation(newLocation);
        const location = `https://www.google.com/maps?q=${newLocation?.latitude},${newLocation?.longitude}`;

        const LocationObj = {
          url: location,
          lat: newLocation?.latitude.toString(),
          long: newLocation?.longitude.toString(),
        };
        setValue("Location", LocationObj);
        setValue("Address", place?.formatted_address);

        const apiKey = process.env.REACT_APP_MAP_API_KEY;
        const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${LocationObj.lat},${LocationObj.long}&key=${apiKey}&language=en`;

        fetch(geocodeUrl)
          .then((response) => response.json())
          .then((data) => {
            if (data.status === "OK") {
              const address = data.results[0].formatted_address;
              setValue("Location", LocationObj);
              setValue("Address", address);
              setLocation({ "latitude": LocationObj.lat, "longitude": LocationObj.long, address });
              parseGoogleAddress(data)
            } else {
              toast.error("Unable to fetch address.");
            }
          })
          .catch((error) => {
            console.error("Error fetching address:", error);
            toast.error("Error fetching address.");
          });
      }
    }
  };
  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          if (latitude && longitude) {
            const locationUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
            const locationObj = {
              url: locationUrl,
              lat: latitude.toString(),
              long: longitude.toString(),
            };

            const apiKey = process.env.REACT_APP_MAP_API_KEY;
            const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}&language=en`;

            fetch(geocodeUrl)
              .then((response) => response.json())
              .then((data) => {
                if (data.status === "OK") {
                  const address = data.results[0].formatted_address;
                  setValue("Location", locationObj);
                  setValue("Address", address);
                  setLocation({ latitude, longitude, address });
                  parseGoogleAddress(data)
                } else {
                  toast.error("Unable to fetch address.");
                }
              })
              .catch((error) => {
                console.error("Error fetching address:", error);
                toast.error("Error fetching address.");
              });
          }
        },

        (error) => {
          let errorMessage = "An unknown error occurred.";
          switch (error.code) {
            case error.PERMISSION_DENIED:
              errorMessage = "User denied the request for Geolocation.";
              break;
            case error.POSITION_UNAVAILABLE:
              errorMessage = "Location information is unavailable.";
              break;
            case error.TIMEOUT:
              errorMessage = "The request to get user location timed out.";
              break;
            default:
              errorMessage = "An unknown error occurred.";
              break;
          }
          toast.error(errorMessage);
        }
      );
    } else {
      toast.error("Geolocation is not supported by this browser.");
    }
  };

  function parseGoogleAddress(addressData) {
    const result = {
      formatted_address: '',
      city: '',
      country: '',
      state: '',
      zipCode: '',
      latitude: '',
      longitude: '',
      street_address: ''
    };

    if (addressData.results && addressData.results.length > 0) {
      const addressComponents = addressData.results[0].address_components;
      result.formatted_address = addressData.results[0].formatted_address;

      let street_number = '';
      let route = '';
      let neighborhood = '';
      let sublocality = '';

      addressComponents.forEach(component => {
        const types = component.types;

        if (types.includes('locality') || types.includes('sublocality')) {
          result.city = component.long_name;
        } else if (types.includes('administrative_area_level_1')) {
          result.state = component.long_name;
        } else if (types.includes('country')) {
          result.country = component.long_name;
        } else if (types.includes('postal_code')) {
          result.zipCode = component.long_name;
        } else if (types.includes('street_number')) {
          street_number = component.long_name;
        } else if (types.includes('route')) {
          route = component.long_name;
        } else if (types.includes('neighborhood')) {
          neighborhood = component.long_name;
        } else if (types.includes('sublocality')) {
          sublocality = component.long_name;
        } else if (types.includes('establishment')) {
          result.establishment = component.long_name;
        }
      });

      result.street_address = [result.establishment, street_number, route, neighborhood, sublocality]
        .filter(Boolean)
        .join(' ');

      if (!result.city) {
        const cityComponent = addressComponents.find(component =>
          component.types.includes('administrative_area_level_2') ||
          component.types.includes('administrative_area_level_3') ||
          component.types.includes('locality') ||
          component.types.includes('sublocality') ||
          component.types.includes('neighborhood')
        );
        if (cityComponent) {
          result.city = cityComponent.long_name;
        }
      }

      if (!result.state) {
        const stateComponent = addressComponents.find(component =>
          component.types.includes('administrative_area_level_1') ||
          component.types.includes('administrative_area_level_2')
        );
        if (stateComponent) {
          result.state = stateComponent.long_name;
        }
      }

      if (addressData.results[0].geometry && addressData.results[0].geometry.location) {
        result.latitude = addressData.results[0].geometry.location.lat;
        result.longitude = addressData.results[0].geometry.location.lng;
      }
    }

    // result.formatted_address = result.formatted_address.split(result.city)[0].trim()

    setValue("AddressObj.addressLine1", result.street_address)
    setValue("AddressObj.city", result.city)
    setValue("AddressObj.zip", result.zipCode)
    setValue("AddressObj.state", result.state)
    setValue("AddressObj.country", result.country)

    return result;
  }
  const handleDragEvent = (event) => {
    const latitude = event.latLng.lat();
    const longitude = event.latLng.lng();
    const location = `https://www.google.com/maps?q=${latitude},${longitude}`;
    const LocationObj = {
      url: location,
      lat: latitude.toString(),
      long: longitude.toString(),
    };
    setValue("Location", LocationObj);
    setLocation({ latitude: latitude, longitude: longitude });

    const apiKey = process.env.REACT_APP_MAP_API_KEY;
    const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}&language=en`;

    fetch(geocodeUrl)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "OK") {
          const address = data.results[0].formatted_address;
          setValue("Location", LocationObj);
          setValue("Address", address);
          setLocation({ latitude, longitude, address });
          parseGoogleAddress(data)
        } else {
          toast.error("Unable to fetch address.");
        }
      })
      .catch((error) => {
        console.error("Error fetching address:", error);
        toast.error("Error fetching address.");
      });
  };

  const handleClick = (event) => {
    const latitude = event.latLng.lat();
    const longitude = event.latLng.lng();
    const location = `https://www.google.com/maps?q=${latitude},${longitude}`;
    const LocationObj = {
      url: location,
      lat: latitude.toString(),
      long: longitude.toString(),
    };
    setValue("Location", LocationObj);
    setLocation({ latitude: latitude, longitude: longitude });

    const apiKey = process.env.REACT_APP_MAP_API_KEY;
    const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}&language=en`;

    fetch(geocodeUrl)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "OK") {
          const address = data.results[0].formatted_address;
          setValue("Location", LocationObj);
          setValue("Address", address);
          setLocation({ latitude, longitude, address });

          console.log(data)

          console.log(parseGoogleAddress(data))
        } else {
          toast.error("Unable to fetch address.");
        }
      })
      .catch((error) => {
        console.error("Error fetching address:", error);
        toast.error("Error fetching address.");
      });
  }

  useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY,
    libraries: ["places"],
  });

  // useEffect(() => {
  //   if (!locationFetched) {
  //     getCurrentLocation()
  //     setLocationFetched(true)
  //   }
  // }, [])

  return (
    <Grid container sx={{ height: "calc(100vh - 201px)" }}>
      {/* <Grid item sx={12}>
        <h1 className=" text-3xl mt-[20px]  mx-5">Location Details</h1>
        <h3 className="text-sm font-medium mt-4 mx-5">Select your location</h3>
      </Grid> */}

      <Grid
        item
        // container
        // xs={12}
        sx={{
          width: "100%",
          // marginX: "1.25rem",
          overflowY: "scroll",
          // marginTop: "10px",
          // height: "calc(100vh - 116.24px)",
          // display: "flex",
          // flexDirection: "column",
          // justifyContent: "center",
          // margin: "auto"
        }}
      >
        <Grid container xs={12} sm={10} direction={"column"} sx={{ gap: "20px", margin: "auto" }}>
          <Grid item>
            <div className="w-full">
              <Controller
                name="Address"
                control={control}
                rules={{
                  required: " Address is Required",
                }}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <div className="flex w-full gap-2">
                      <Autocomplete
                        onLoad={onLoad}
                        onPlaceChanged={onPlaceChanged}
                        className="w-full"
                      // restrictions={{
                      //   country: "AE",
                      // }}
                      >
                        <TextInput
                          {...field}
                          variant="outlined"
                          placeholder="Search your restaurant location"
                          // value={searchValue}
                          // onChange={handleSearchChange}
                          // onFocus={handleSearchFocus}
                          // onBlur={handleSearchBlur}
                          // onKeyDown={handleSearchKeyDown}
                          borderRadius="30px"
                          transparentBorder
                          backgroundColor="#fff"
                          preContent={<img src={search} alt="" />}
                          // postContent={
                          //   <img src={close} alt=""
                          //     onClick={() => {
                          //       setSearchValue("")
                          //       setShowNewScreen(false)
                          //       setSearchResultIsActive(false)
                          //     }}
                          //   />
                          // }
                          sx={{
                            width: "100%",
                            borderRadius: "30px",
                            backgroundColor: "#fff",
                            '& .MuiInputBase-input::placeholder': {
                              color: '#545454'
                            },
                            border: "1px solid #D9D9D9"
                          }}
                        />
                      </Autocomplete>
                      <div
                        className="text-[#b80e0c] self-center"
                        onClick={() => {
                          getCurrentLocation();
                        }}
                      >
                        <FmdGoodIcon />
                      </div>
                    </div>
                  );
                }}
              />
            </div>
          </Grid>
          <Grid item container xs={12}>

            <Grid item xs={12} md={7} sx={{ paddingRight: { xs: "0px", md: "20px" }, order: { xs: "1", md: "0" }, marginTop: { xs: "20px", md: "0px" } }}>
              <label className=" font-[600]  block mb-2 text-[14px] text-[#757373]">
                ADDRESS<span className="text-[#b80e0c]">*</span>
              </label>
              <Controller
                name="AddressObj.addressLine1"
                control={control}
                rules={{
                  required: "Address is required",
                }}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <div className="mb-2">
                      <CustomTextInput
                        placeholder="Address Line 1"
                        {...field}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    </div>
                  )
                }}
              />
              <Controller
                name="AddressObj.addressLine2"
                control={control}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <div className="mb-2">
                      <CustomTextInput
                        placeholder="Address Line 2 (Optional)"
                        {...field}
                      />
                    </div>
                  )
                }}
              />
              <label className=" font-[600]  block mb-2 text-[14px] text-[#757373]">
                CITY<span className="text-[#b80e0c]">*</span>
              </label>
              <Controller
                name="AddressObj.city"
                control={control}
                rules={{
                  required: "City is required",
                }}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <div className="mb-2">
                      <CustomTextInput
                        placeholder="City"
                        {...field}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    </div>
                  )
                }}
              />
              <label className=" font-[600]  block mb-2 text-[14px] text-[#757373]">
                STATE/PROVINCE<span className="text-[#b80e0c]">*</span>
              </label>
              <Controller
                name="AddressObj.state"
                control={control}
                rules={{
                  required: "State/Province is required",
                }}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <div className="mb-2">
                      <CustomTextInput
                        placeholder="State/Province/Region/Emirate"
                        {...field}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    </div>
                  )
                }}
              />
              <label className=" font-[600]  block mb-2 text-[14px] text-[#757373]">
                ZIP CODE {checkCountryHasPostalCode(watch("AddressObj.country")?.value || watch("AddressObj.country")) ? <span className="text-[#b80e0c]">*</span> : <></>}
              </label>
              <Controller
                name="AddressObj.zip"
                control={control}
                rules={{
                  required: {
                    value: checkCountryHasPostalCode(watch("AddressObj.country")?.value || watch("AddressObj.country")) || false,
                    message: "Zip code is required"
                  },
                  validate: (value) => {
                    if (!value || value === '') return true;
                    const country = watch("AddressObj.country")?.value || watch("AddressObj.country");
                    if (!checkCountryHasPostalCode(country)) return 'Your country does not require a postal code';
                    return postalCodeVerification(country, value) || "Invalid postal code format for selected country";
                  }
                }}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <div className="mb-2">
                      <CustomTextInput
                        placeholder={`Zip Code ${checkCountryHasPostalCode(watch("AddressObj.country")?.value || watch("AddressObj.country")) ? "" : "(optional)"}`}
                        required={checkCountryHasPostalCode(watch("AddressObj.country")?.value || watch("AddressObj.country")) || false}
                        {...field}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    </div>
                  )
                }}
              />
              <label className=" font-[600]  block mb-2 text-[14px] text-[#757373]">
                COUNTRY<span className="text-[#b80e0c]">*</span>
              </label>
              <Controller
                name="AddressObj.country"
                control={control}
                rules={{
                  required: "Country is required",
                }}
                render={({ field, fieldState: { error } }) => {
                  // Convert string value to option object format
                  const value = field.value ? {
                    value: field.value,
                    label: field.value
                  } : null;

                  return (
                    <div className="mb-2">
                      {/* <CustomTextInput
                        placeholder="Country"
                        {...field}
                        error={!!error}
                        helperText={error ? error.message : null}
                      /> */}
                      <SearchableDropDown
                        {...field}
                        value={value} // Pass formatted value
                        onChange={(option) => {
                          field.onChange(option?.value || '')
                          trigger("Address.zip")
                        }} // Extract value from option
                        sx={{ width: "auto" }}
                        required
                        variant="outlined"
                        placeholder="Select Country"
                        options={countryOptions?.map((item) => ({
                          value: item?.label,
                          label: item?.label,
                        }))}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    </div>
                  )
                }}
              />
            </Grid>


            <Grid item xs={12} md={5} className="w-full flex items-center gap-2">
              <Controller
                name="Location"
                control={control}
                rules={{
                  required: "Location is Required",
                }}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <div className="flex w-full flex-col gap-2">
                      <MapComponent
                        location={location}
                        handleClick={handleClick}
                        handleDragEvent={handleDragEvent}
                        customHeight={true}
                      />
                    </div>
                  );
                }}
              />
            </Grid>
          </Grid>

          {/* <Grid item xs={12}>
            <BasicButton color="genieRed" sx={{ width: "100%", padding: "12px 0px" }}><span className="normal-case text-white font-medium leading-6 text-base">Add Location</span></BasicButton>
          </Grid> */}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default LocationDetails;
