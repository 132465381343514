import React, { forwardRef } from 'react';

export const CustomTextInput = forwardRef((props, ref) => {
  const {
    type,
    label,
    placeholder,
    name,
    value,
    defaultValue,
    helperText,
    error,
    disabled,
    required,
    preContent,
    postContent,
    onChange,
    onFocus,
    onBlur,
    autoFocus,
    handleClear,
    rows,
    multiline,
    autoComplete
  } = props;

  return (
    <div className="w-full font-poppins" data-testid="custom-text-input">
      {label && (
        <label
          className="block mb-1 text-sm font-semibold text-[#757373] uppercase"
          htmlFor={`custom-text-input-${name || "box"}`}
        >
          {label}{required && <span className="text-[#B80E0C]">*</span>}
        </label>
      )}
      <div className={`relative flex items-center w-full rounded-[4px] border h-100 ${error ? 'border-red-500' : 'border-[#e2e8f0]'
        } ${disabled ? 'bg-gray-100' : 'bg-white'} focus-within:ring-2 focus-within:ring-blue-500 focus-within:border-blue-500`}>
        {preContent && (
          <div className="flex items-center h-full px-2">
            {preContent}
          </div>
        )}
        {multiline ? (
          <textarea
            ref={ref}
            id={`custom-text-input-${name || "box"}`}
            name={name}
            value={value}
            defaultValue={defaultValue}
            placeholder={placeholder}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            disabled={disabled}
            required={required}
            autoFocus={autoFocus}
            rows={rows}
            autoComplete={autoComplete}
            className={`w-full ${preContent ? "rounded-r-[4px]" : postContent ? "rounded-l-[4px]" : "rounded-[4px]"} px-3 py-2 text-gray-700 bg-[#F7F7F7]  focus:outline-none ${disabled ? 'cursor-not-allowed' : ''
              }`}
          />
        ) : (
          <input
            ref={ref}
            type={type || "text"}
            id={`custom-text-input-${name || "box"}`}
            name={name}
            value={value}
            defaultValue={defaultValue}
            placeholder={placeholder}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            disabled={disabled}
            required={required}
            autoFocus={autoFocus}
            autoComplete={autoComplete}
            className={`w-full ${preContent ? "rounded-r-[4px]" : postContent ? "rounded-l-[4px] mr-[50px]" : "rounded-[4px]"} px-3 py-2 text-gray-700 bg-[#F7F7F7]  focus:outline-none ${disabled ? 'cursor-not-allowed' : ''
              }`}
          />
        )}
        {postContent && (
          <div className="flex items-center px-3 absolute right-0 h-full bg-[#FFFFFF] rounded-[3px] outline outline-1 outline-[#E2E8F0] w-[61px]">
            <button
              type="button"
              onClick={handleClear}
              className="text-[#262626] font-normal"
            >
              {postContent}
            </button>
          </div>
        )}
      </div>
      {error && helperText && (
        <p className="mt-1 text-xs text-red-500">{helperText}</p>
      )}
    </div>
  );
});
