import React, { useEffect, useState } from "react";
import { useDiner } from "../../../utils/hooks";
import BasicButton from "../../../components/Button";
import Counter from "../../../components/Counter";
import cancel_dark from "../../../assets/Icons/cancel_dark.svg"
import { Checkbox } from "@mui/material";
import spiceIcon from "../../../assets/Icons/spiceIcon.png";
import broccoli from "../../../assets/Icons/broccoli.png";
import checked_box from "../../../assets/Icons/checked_box.svg"
import { useForm } from "react-hook-form";
import { useDinerStore } from "../../../stores";
import { useShallow } from "zustand/shallow";

function MenuCardDrawer({ data, addToChart, cartList, restId, tableId, onClose, maxHeight = "90vh", triggerError = false }) {
  const { setCount, PersonalizeEvent,isDrawerOpen } = useDiner();
  const [isExpanded, setIsExpanded] = useState(false);
  const [itemCount, setItemCount] = useState(1)
  const [currentCartItemState, setCurrentCartItem] = useState({})
  const { register, formState: { errors }, trigger, reset } = useForm();

  // Prefetch checkbox image
  useEffect(() => {
    const img = new Image();
    img.src = checked_box;
    reset();
    trigger();
  }, []);

  /**
   * useEffect hook that triggers a personalize event when the drawer is open.
   * @param {Function} PersonalizeEvent - The function to trigger the personalize event.
   * @param {boolean} isDrawerOpen - A boolean indicating whether the drawer is open.
   * @param {Object} data - The data object containing the _id.
   */
  // useEffect(() => {
  //   if (isDrawerOpen)
  //     PersonalizeEvent({ itemIds: [data?._id], evenType: "VIEW" });
  // }, [isDrawerOpen]);

  // useEffect(() => {
  //   increment()
  //   PersonalizeEvent({ itemIds: [data?._id], evenType: "CART" })
  // }, [data?._id])

  const [customizationArray, setCustomizationArray] = useState([]);
  const handleCustomization = async (customization, item) => {
    await setCustomizationArray(prevArray => {
      const existingCustomizationIndex = prevArray.findIndex(c => c.customizationId === customization?._id)

      if (existingCustomizationIndex !== -1) {
        const newArray = [...prevArray]
        const existingCustomization = newArray[existingCustomizationIndex]
        const itemIndex = existingCustomization.items.findIndex(i => i.itemId === item?._id)

        if (itemIndex !== -1) {
          existingCustomization.items = [
            ...existingCustomization.items.slice(0, itemIndex),
            ...existingCustomization.items.slice(itemIndex + 1)
          ]
          return existingCustomization.items.length === 0
            ? newArray.filter((_, index) => index !== existingCustomizationIndex)
            : newArray
        } else {
          const newItemsArray = [...existingCustomization.items, {
            itemName: item?.itemName,
            price: item?.price,
            itemId: item?._id,
          }];
          existingCustomization.items = newItemsArray;
          return newArray;
        }
      } else {
        return [
          ...prevArray,
          {
            customizationId: customization?._id,
            title: customization?.title,
            items: [{
              itemName: item?.itemName,
              price: item?.price,
              itemId: item?._id,
            }]
          }
        ]
      }
    })
    trigger();
  }

  const validateRequiredCustomizations = async () => {
    const requiredCustomizationIds = data.customization
      ?.filter(c => c.isRequired)
      .map(c => c._id);

    if (requiredCustomizationIds.length === 0) return true;

    if (customizationArray.length === 0) {
      await trigger("customizations");
      const firstRequired = document.querySelector(`[data-customization-id="${requiredCustomizationIds[0]}"]`);
      firstRequired?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      return false;
    }

    const selectedCustomizationIds = customizationArray.map(c => c.customizationId);
    const missingRequiredId = requiredCustomizationIds.find(
      id => !selectedCustomizationIds.includes(id)
    );

    if (missingRequiredId) {
      await trigger("customizations");
      const missingElement = document.querySelector(`[data-customization-id="${missingRequiredId}"]`);
      missingElement?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      return false;
    }

    return true;
  }

  const handleAddToCart = async () => {
    if (itemCount > 0 && !await validateRequiredCustomizations()) return;

    PersonalizeEvent({ itemIds: [data?._id], evenType: "CART" })
    // TODO: fix this
    // addToChart({ restId, tableId, customizationArray }, currentCartItemState.quantity > 0 ? itemCount - currentCartItemState.quantity : itemCount)
    setCustomizationArray([])
    addToChart(
      {
        itemId: data?._id,
        itemName: data?.item_name,
        oPrice: data?.oPrice,
        dPrice: data?.dPrice,
        description: data?.description,
        // 
        customization: customizationArray || [],
        count: itemCount,
        partnerId: restId,
        tableId: tableId,
      }
    )
  }

  useEffect(() => {
    const currentCartItem = cartList
      ? cartList[cartList.length - 1]?.orderDetails.find(cartItem => cartItem.itemId === data?._id)
      : null
    if (currentCartItem && !cartList[cartList.length - 1].isPlaced) {
      setCurrentCartItem(currentCartItem)
      setCustomizationArray(currentCartItem.customization.map(customization => {
        return {
          customizationId: customization.customizationId,
          title: customization.title,
          items: customization.items
        }
      }) || [])
      // setCount(currentCartItem.quantity)
      setItemCount(currentCartItem.quantity)
    } else {
      setItemCount(1)
    }
  }, [isDrawerOpen])

  return (
    <div className={`flex flex-col justify-around w-[100vw] bg-[#f4f3f3] max-h-[${maxHeight}] overflow-hidden`}>
      <div className="self-start w-full">
        <div className="w-full px-4 pt-4 flex justify-between border-b-[2px] border-[#F8F8F8] pb-[8px]">
          <div>
            <p className="text-[14px] text-[#595454]">{data?.item_name}</p>
            <p className="text-[16px] font-medium">Customize as per your taste</p>
          </div>
          <img className="cursor-pointer self-baseline" onClick={() => {
            onClose()
            setCustomizationArray([])
          }} src={cancel_dark} alt="" style={{ color: '#4A4747' }} />
        </div>
      </div>

      <div className="overflow-y-auto scroll-shadows">
        <div className={`flex justify-between border-b-[2px] border-[#F8F8F8] pb-[10px] px-4 gap-8 py-[8px] ${data?.customization?.length > 0 ? "border-b-[2px]" : "border-none"}`}>
          <div className=" flex flex-col justify-start truncate">
            {/* TODO: spice & veg logo */}
            <div className="flex items-center">
              <div className="mr-1 mb-[4px]">
                {[...Array(data?.spicinessLevel)].map((_, index) => (
                  <img
                    key={index}
                    src={spiceIcon}
                    alt="Spice"
                    className="w-3 h-3 inline-block"
                  />
                ))}
              </div>
              <div>
                {[...Array(1)].map((_, index) => (
                  <img
                    key={index}
                    src={broccoli}
                    alt="Veg"
                    className="w-3 h-3 inline-block"
                  />
                ))}
              </div>
            </div>
            <h1 className=" font-quickSand text-[18px] text-black font-normal leading-[20px] mb-[18px]">
              {data?.item_name}
            </h1>
            <p
              className={`text-wrap text-[0.75rem] text-[#3E3E3E] ${isExpanded ? 'line-clamp-none' : 'line-clamp-3'} font-normal cursor-pointer`}
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {data?.description}
              {/* {data?.ingredients?.reduce((acc, ingredient, index, array) => {
                      return acc + ingredient + (index === array.length - 1 ? "" : ", ");
                    }, "")} */}
            </p>
          </div>
          <img
            src={data?.image?.url}
            alt="Menu"
            className="rounded-[10px] h-[102px] w-[115px] object-cover"
          />
        </div>

        {/* Customization Section */}
        <div className={`px-4 py-2 ${data?.customization?.length > 0 ? "block" : "hidden"}`}>
          {/* <Button onClick={() => { console.log(customizationArray) }}>Check customization</Button> */}
          {data?.customization?.map((customization, index) => {
            return (
              <div
                key={index}
                data-customization-id={customization._id}
              >
                <div className="flex justify-between customizations-center my-[10px]">
                  <p className="font-medium text-black">{customization.title}{customization.isRequired && <span className="text-[#B80E0C]">*</span>}</p>
                  {customization?.isRequired && <p className="font-light text-[#B80E0C]">Required</p>}
                </div>

                <div className="bg-white px-3 flex flex-col gap-2 py-[10px] rounded-[10px] border border-[#EEEEEE]">
                  <input
                    type="hidden"
                    {...register(`customizations.${customization._id}`, {
                      validate: () => {
                        if (!customization.isRequired) return true;
                        return customizationArray.some(c => c.customizationId === customization._id) ||
                          "This customization is required";
                      }
                    })}
                  />
                  {customization?.items.map((item, index) => {
                    return (
                      <div className="flex justify-between items-center">
                        <p className="font-normal text-black">{item?.itemName}</p>

                        <div className="flex gap-[6px] items-center font-normal text-black">
                          {/* TODO: Set items to free if price is 0 */}
                          {item?.price > 0 && <p>+AED {item?.price}</p>}
                          {tableId && (
                            <Checkbox
                              checkedIcon={<img className="h-[24px]" src={checked_box} alt="" />}
                              sx={{
                                color: "#595454",
                                '&.Mui-checked': {
                                  color: "#377931",
                                },
                                padding: "0px",
                              }}
                              // TODO: fix this
                              checked={customizationArray.some((customizationItem) =>
                                customizationItem.customizationId === customization?._id &&
                                customizationItem.items.some(selectedItem => selectedItem.itemId === item?._id)
                              )}
                              onClick={() => {
                                handleCustomization(customization, item)
                              }}
                            />
                          )}
                        </div>
                      </div>
                    )
                  })}
                </div>

                {customization?.isRequired && errors?.customizations?.[customization._id] && (
                  <p className="mt-1 text-xs text-[#B80E0C]">
                    Please select a customization option from the above list
                  </p>
                )}
              </div>
            )
          })}
        </div>
      </div>

      {tableId && (
        <div className="self-end flex items-center justify-between w-full px-4 py-3 gap-[12px] bg-white border-t border-[#d1d1d1">
          {/* {itemCount > 0 && currentCartItemState.quantity > 0 && cartList[cartList.length - 1]?.orderDetails?.find(cartItem => cartItem.itemId === data?._id) && */}
          {currentCartItemState.quantity > 0 && cartList[cartList.length - 1]?.orderDetails?.find(cartItem => cartItem.itemId === data?._id) &&
            <Counter
              value={itemCount}
              increment={() => {
                // increment()
                // PersonalizeEvent({ itemIds: [data?._id], evenType: "CART" })
                setItemCount(prev => prev + 1)
              }}
              decrement={() => setItemCount(prev => prev - 1)}
            />
          }

          <BasicButton
            color="genieRed"
            sx={{
              textTransform: "none",
              width: "100%",
              height: "36px",
              padding: "9px 20px",
              color: "white",
            }}
            onClick={() => {
              handleAddToCart()
            }}
          >
            {/* TODO: Add computed price */}
            <div className="font-medium">
              {cartList[cartList.length - 1]?.orderDetails.find(cartItem => cartItem.itemId === data?._id) && !cartList[cartList.length - 1].isPlaced ? "Update" : "Add"} Item | <span className="text-[10px] leading-[15px] self-end">AED</span> {(data?.oPrice - data?.dPrice + customizationArray.reduce((acc, customization) => acc + customization.items.reduce((itemAcc, item) => itemAcc + item.price, 0), 0)) * itemCount}
            </div>
          </BasicButton>
        </div>
      )}
    </div>
  );
}

export default MenuCardDrawer;
