import { Grid, Badge } from '@mui/material'
import Isolation_Mode from "../../../assets/images/Isolation_Mode.svg";
import cart from "../../../assets/Icons/Cart.svg";
import React from 'react'

const index = () => {
  return (
    <>
      <Grid container xs={12} direction="column" className="font-poppins" sx={{ height: "100vh" }}>
        <Grid
          item
          sx={12}
          className=" bg-[#b80e0c]  flex justify-between items-end  px-[1.25rem] py-[0.75rem] sticky top-0 z-50"
        >
          <img
            src={Isolation_Mode}
            alt=""
            srcSet=""
            className=""
            height={"36px"}
          />
          <Badge
            sx={{ cursor: "pointer", "& .MuiBadge-badge": { backgroundColor: "white", color: "#B80E0C", fontWeight: 600, position: "absolute", top: "3px", right: "4px" } }}
          >
            {/* <Badge badgeContent={cartCount || 0} onClick={() => navigate(`cart/?tableId=${tableId}`)} sx={{ cursor: "pointer", "& .MuiBadge-badge": { backgroundColor: "white", color: "#B80E0C", fontWeight: 600, position: "absolute", top: "3px", right: "4px" } }}> */}
            <img src={cart} alt="cart" className={`opacity-50`} />
          </Badge>
        </Grid>

        <Grid className='p-2' item container direction="column" alignItems="center" justifyContent="center" flex={1}>
          <h1 className='text-center text-3xl font-semibold text-gray-800 mb-4'>
            Oops! Table not found
          </h1>
          <p className='text-center text-gray-600 mb-6'>
            The table you're looking for doesn't exist or may have been moved.
          </p>
        </Grid>

      </Grid>
    </>
  )
}

export default index