import React, { useState, useEffect } from "react";
import { Avatar, Grid, Skeleton } from "@mui/material";
import { PageLoader } from "../../../components/loader";
import DrawerComponent from "../../../components/Drawer/Index";
import MenuCardDrawer from "./MenuCardDrawer";
import { useDiner } from "../../../utils/hooks";
import NoDataComponent from "../../../components/NoDataComponent";
import { useLocation, useParams } from "react-router-dom";
import spiceIcon from "../../../assets/Icons/spiceIcon.png";
import broccoli from "../../../assets/Icons/broccoli.png";
import { Star } from "@mui/icons-material";
import Counter from "../../../components/Counter";
import DetailsDropdown from "../../../components/DetailsDropdown";
import customization from "../../../assets/Icons/customization.svg";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import cancel_dark from "../../../assets/Icons/cancel_dark.svg"
import BasicButton from "../../../components/Button";
import { useDebounce } from "../../../useDebaunce";
import { LazyLoadComponent, LazyLoadImage } from "react-lazy-load-image-component";

function MenuCard({ list, isLoading, cartList, disableDrawer = false }) {
  const queryClient = useQueryClient();
  const { id: restId } = useParams();
  const location = useLocation();
  const tableId = new URLSearchParams(location.search).get("tableId");
  // const [loadingItem, setLoadingItem] = useState(false)
  const {
    toggleDrawer,
    isDrawerOpenValue,
    setDrawerOpenValue,
    isDrawerOpen,
    // OrderModel,
    PersonalizeEvent,
    OnAddCart,
    // cartResponseLoading
    addCartMenuIsLoading,
    setDeleteOrderModel,
    deleteOrderModel,
    handleDeleteOrderConfirm,
    handleDeleteOrderCancel,
    createOrderIsLoading,
  } = useDiner();

  const [localCartList, setLocalCartList] = useState(cartList);

  useEffect(() => {
    if (cartList.length > 0) {
      if (!cartList[cartList.length - 1].isPlaced) {
        setLocalCartList(cartList);
      } else {
        setLocalCartList([]);
      }
    } else {
      setLocalCartList([]);
    }
  }, [cartList]);

  const updateLocalCart = (item, newQuantity) => {
    setLocalCartList(prevCart => {
      const updatedCart = [...prevCart];
      const lastOrder = { ...updatedCart[updatedCart.length - 1] };
      const updatedOrderDetails = lastOrder?.orderDetails?.map(detail => {
        if (detail.itemId === item._id) {
          return { ...detail, quantity: newQuantity };
        }
        return detail;
      });
      lastOrder.orderDetails = updatedOrderDetails;
      updatedCart[updatedCart.length - 1] = lastOrder;
      return updatedCart;
    });
  };

  /**
   * Handles the click event for a specific item.
   * @param {{object}} item - The item that was clicked.
   * @returns None
   */
  const handleClick = (item) => {
    toggleDrawer();
    PersonalizeEvent({ itemIds: [item?._id], evenType: "CLICK" });
    setDrawerOpenValue(item);
  };

  const handleIncrement = async (item) => {
    // console.log(cartList[cartList.length - 1].orderDetails)
    // console.log(cartList[cartList.length - 1].isPlaced)
    if (item.customization.some(customization => customization.isRequired === true) && (!cartList[cartList.length - 1]?.isPlaced ? !cartList[cartList.length - 1]?.orderDetails.find(cartItem => cartItem.itemId === item._id) : true)) {
      handleClick(item);
      return
    }

    const currentQuantity = localCartList[localCartList.length - 1]?.orderDetails?.find(
      cartItem => cartItem.itemId === item._id
    )?.quantity ?? 0;
    const newQuantity = currentQuantity + 1;
    // updateLocalCart(item, newQuantity);

    // PersonalizeEvent({ itemIds: [item?._id], evenType: "CART" });
    OnAddCart({
      itemId: item?._id,
      itemName: item?.item_name,
      oPrice: item?.oPrice,
      dPrice: item?.dPrice,
      description: item?.description,
      customization: [],
      count: newQuantity,
      partnerId: restId,
      tableId: tableId,
    });
  };

  const handleDecrement = (item) => {
    const currentQuantity = localCartList[localCartList.length - 1]?.orderDetails?.find(
      cartItem => cartItem.itemId === item._id
    )?.quantity ?? 0;
    if (currentQuantity >= 1) {
      const newQuantity = currentQuantity - 1;
      // updateLocalCart(item, newQuantity);

      // PersonalizeEvent({ itemIds: [item?._id], evenType: "CART" });
      OnAddCart({
        itemId: item?._id,
        itemName: item?.item_name,
        oPrice: item?.oPrice,
        dPrice: item?.dPrice,
        description: item?.description,
        customization: [],
        count: newQuantity,
        partnerId: restId,
        tableId: tableId,
      });
    }
  };

  return (
    <>
      {isLoading || !list || isLoading ? (
        <div className="h-[50vh] flex justify-center items-center">
          <PageLoader />
        </div>
      ) : (
        <div>
          <Grid
            container
            direction="column"
            sm={12}
            // className={`${OrderModel ? "mb-[58px]" : ""} px-4 mt-2`}
            className={` px-4 mt-2`}
            rowGap={2}
          >
            {list.length === 0 || list.every(item => !item.dineIn) ? (
              <NoDataComponent tittle={"Menu Not Found "} />
            ) : (
              list?.map((item, index) => (
                item?.dineIn && (
                  <Grid item key={index}>
                    {/* {console.log(item)} */}
                    <div
                      key={item?._id}
                      className="flex justify-between border-b border-[#f3f0f3] pb-[10px]"
                    >
                      <div className="flex  truncate">
                        <div className=" flex flex-col justify-start  truncate ">
                          {/* TODO: Veg logo */}
                          <div className="flex items-center">
                            <div className="mr-1 mb-[4px]">
                              {[...Array(item?.spicinessLevel)].map((_, index) => (
                                <img
                                  key={index}
                                  src={spiceIcon}
                                  alt="Spice"
                                  className="w-3 h-3 inline-block"
                                />
                              ))}
                            </div>
                            {/* <div>
                              {[...Array(1)].map((_, index) => (
                                <img
                                  key={index}
                                  src={broccoli}
                                  alt="Veg"
                                  className="w-3 h-3 inline-block"
                                />
                              ))}
                            </div> */}
                          </div>
                          <h1 className=" font-quickSand text-[18px] text-black font-normal leading-[20px] mb-[6px] text-wrap">
                            {item?.item_name}
                          </h1>
                          <div className="min-w-[6.5rem] flex items-center justify-between mr-auto mb-[12px]">
                            <h2 className="font-quickSand text-[14px] text-black font-bold">
                              AED {(item?.oPrice - item?.dPrice).toFixed(2)}
                            </h2>

                            {/* <div className="text-[#377931] flex items-center gap-[3px] ml-2">
                              <Star sx={{ height: "15px", width: "15px" }} />
                              <span className="font-normal">4.6</span>
                            </div> */}
                          </div>

                          <DetailsDropdown headerText="More Details"
                            // content={item?.ingredients?.reduce((acc, ingredient, index, array) => {
                            //   return acc + ingredient + (index === array.length - 1 ? "" : ", ");
                            // }, "")}
                            content={item?.description}
                            className="mb-[8px]"
                          />

                          <div className={`flex items-center gap-[4px] cursor-pointer ${item?.customization?.length > 0 ? "" : "hidden"}`}
                            onClick={() => {
                              if (!disableDrawer)
                                handleClick(item)
                            }}
                          >
                            <img src={customization} alt="" />
                            <span className="text-[#b80e0c] underline">Customize</span>
                          </div>
                        </div>
                      </div>
                      <div className="relative h-fit min-w-[115px] ml-4">
                        {/* <img
                          src={item?.image?.url}
                          alt="Menu"
                          className="rounded-[10px] h-[102px] w-[115px] object-cover cursor-pointer"
                          onClick={() => handleClick(item)}
                        /> */}
                        <LazyLoadImage
                          src={item?.image?.url}
                          effect="opacity"
                          alt="Menu"
                          className="rounded-[10px] h-[102px] w-[115px] object-cover cursor-pointer"
                          onClick={() => {
                            if (!disableDrawer)
                              handleClick(item)
                          }}
                        />
                        {/* TODO: cart integration */}
                        {tableId && (
                          <div className="w-full flex justify-center items-center absolute -bottom-3">
                            <Counter
                              value={!cartList[cartList.length - 1]?.isPlaced ? cartList[cartList.length - 1]?.orderDetails?.find(cartItem => cartItem.itemId === item?._id)?.quantity ?? 0 : 0}
                              isLoading={createOrderIsLoading}
                              increment={() => handleIncrement(item)}
                              decrement={() => handleDecrement(item)}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </Grid>
                )
              )
              )
            )}
          </Grid>
        </div>
      )}
      <DrawerComponent
        position="bottom"
        open={isDrawerOpen}
        // visiblePuller={true}
        sx={{
          "& .MuiDrawer-paper": {
            borderRadius: "20px 20px 0 0",
            maxHeight: "90vh", // Added max height
            boxShadow: "none", // Remove shadow
          },
        }}
        onClose={() => {
          toggleDrawer({ restId })
          // queryClient.invalidateQueries("getAllCartByID");
        }}
      >
        <MenuCardDrawer
          maxHeight="90vh"
          data={isDrawerOpenValue}
          // addToChart={toggleDrawer}
          addToChart={OnAddCart}
          cartList={cartList}
          restId={restId}
          tableId={tableId}
          onClose={() => {
            toggleDrawer({ restId })
            // queryClient.invalidateQueries("getAllCartByID");
          }}
        />
      </DrawerComponent>

      <DrawerComponent
        position="bottom"
        visiblePuller={false}
        open={deleteOrderModel}
        onClose={() => {
          setDeleteOrderModel(false)
          handleDeleteOrderCancel()
        }}
        sx={{
          "& .MuiDrawer-paper": {
            borderRadius: "20px 20px 0 0",
            maxHeight: "90vh", // Added max height
            boxShadow: "none", // Remove shadow
          },
        }}
      >
        <div className={`flex flex-col justify-around w-[100vw] bg-[#f4f3f3] max-h-[90vh] overflow-hidden`}>
          <div className="self-start w-full">
            <div className="w-full px-4 pt-4 flex justify-between border-b-[2px] border-[#F8F8F8] pb-[8px] gap-2">
              <div>
                <p className="text-[16px] font-medium max-w-[228px]">
                  Are you sure you want to {cartList[cartList.length - 1]?.orderDetails?.length > 1 ? "delete this item" : "cancel the current order"}?
                </p>
              </div>
              <img className="cursor-pointer self-baseline" onClick={() => {
                setDeleteOrderModel(false)
                handleDeleteOrderCancel()
              }} src={cancel_dark} alt="" style={{ color: '#4A4747' }} />
            </div>
            <div className="px-4 flex gap-2 pb-4">
              <BasicButton variant="outlined"
                sx={{
                  width: "100%",
                  textTransform: "none"
                }}
                onClick={handleDeleteOrderCancel}
                color="genieRed"
              >
                Go Back
              </BasicButton>
              <BasicButton
                sx={{
                  width: "100%",
                  textTransform: "none",
                  color: "#fff"
                }}
                color="genieRed"
                onClick={handleDeleteOrderConfirm}
              >
                Confirm
              </BasicButton>
            </div>
          </div>
        </div>
      </DrawerComponent>
    </>
  );
}

export default MenuCard;
