import React from "react";
import logo from "../../assets/images/Frame.png";
import { TextInput } from "../../components/InputBox";
import BasicButton from "../../components/Button";
import { Controller } from "react-hook-form";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import grubgenieText from "../../assets/images/Isolation_Mode.svg"
import eyeOn from "../../assets/Icons/eye-on.svg"
import eyeOff from "../../assets/Icons/eye-off.svg"
import genieLogo from "../../assets/Icons/NewGenie.svg"
import { useNavigate } from "react-router-dom";
import { Box, FormControlLabel, Grid, TextField } from "@mui/material";
import { CheckBox } from "@mui/icons-material";

export default function Login({
  toggleIcon,
  togglePasswordIcon,
  onSubmit,
  control,
  handleSubmit,
  loading,
}) {
  const navigate = useNavigate();
  const [passwordHasValue, setPasswordHasValue] = React.useState(false);
  const [emailHasValue, setEmailHasValue] = React.useState(false);
  const [emailFocused, setEmailFocused] = React.useState(false);
  const [passwordFocused, setPasswordFocused] = React.useState(false);

  const makeAnimationStartHandler = (stateSetter) => (e) => {
    const autofilled = !!e.target?.matches("*:-webkit-autofill");
    if (e.animationName === "mui-auto-fill" || e.animationName === "mui-auto-fill-cancel") {
      stateSetter(autofilled);
    }
  };

  return (
    <>
      <Grid container xs={12}>
        <Grid item lg={5}
          sx={{
            display: { xs: "none", lg: "flex" },
            backgroundColor: "#B80E0C",
            height: "100vh",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "0px 20px 20px 0px"
          }}
        >
          <div className="flex flex-col items-center justify-center gap-[20px]">
            <img className="h-[225px]" src={genieLogo} alt="" />
            <img className="w-[226px]" src={grubgenieText} alt="" />
          </div>
        </Grid>
        <Grid item xs={12} sx={{ display: { xs: "flex", lg: "none" }, backgroundColor: "#b80e0c", padding: "12px", alignItems: "center", justifyContent: "center" }}>
          <img className="w-[120px]" src={grubgenieText} alt="" />
        </Grid>
        <Grid item xs={12} lg={7}
          sx={{
            height: { xs: "calc(100vh - 86.3px)", lg: "100vh" },
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Box sx={{ boxShadow: { xs: "none", lg: "0px 4px 6px 0px #86868640" } }} className={`rounded-[20px] px-[24px] py-[20px] w-[447px] flex flex-col gap-[20px]`}>
            <div className="flex flex-col gap-3">
              <h1 className="text-[40px] font-bold">Sign in</h1>
              <p className="text-[18px] text-[#969696]">Please login to continue to your account.</p>
            </div>

            <form className="" onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-col gap-[20px]">
                <Controller
                  name="username"
                  control={control}
                  rules={{
                    required: "Username is Required",
                  }}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <TextField
                        {...field}
                        inputProps={{
                          onAnimationStart: makeAnimationStartHandler(setEmailHasValue)
                        }}
                        InputLabelProps={{
                          shrink: emailHasValue || field.value || emailFocused ? true : false,
                          sx: {
                            textTransform: emailHasValue || field.value || emailFocused ? "uppercase" : "none"
                          }
                        }}
                        onFocus={() => setEmailFocused(true)}
                        onBlur={(e) => {
                          setEmailFocused(false);
                          field.onBlur(e);
                        }}
                        type="email"
                        sx={{
                          width: "100%",
                          height: "auto",
                          borderRadius: "6px",
                          borderColor: "#D9D9D9",
                          marginBottom: error ? "20px" : "0px",
                          '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': {
                              borderColor: '#757373',
                            },
                          },
                          '& .MuiInputLabel-root.Mui-focused': {
                            color: '#757373',
                            fontWeight: 600,
                            textTransform: "uppercase"
                          },
                          '&. MuiInputLabel-shrink': {
                            textTransform: "uppercase"
                          },
                          '& .MuiFormHelperText-root': {
                            position: 'absolute',
                            bottom: '-20px',
                          },
                          '& .MuiInputBase-input::placeholder': {
                            color: '#545454'
                          },
                          '& .MuiInputBase-input:-webkit-autofill': {
                            '-webkit-box-shadow': '0 0 0 100px transparent inset',
                            transition: 'background-color 5000s ease-in-out 0s'
                          }
                        }}
                        label="Email"
                        variant="outlined"
                        error={!!error}
                        helperText={error ? error.message : null}
                        onChange={(e) => {
                          field.onChange(e);
                          setEmailHasValue(!!e.target.value);
                        }}
                      />
                    );
                  }}
                />
                <div className="relative">
                  <Controller
                    name="password"
                    control={control}
                    rules={{
                      required: "Password is Required",
                    }}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <TextField
                          {...field}
                          type={toggleIcon ? "Password" : "text"}
                          inputProps={{
                            onAnimationStart: makeAnimationStartHandler(setPasswordHasValue)
                          }}
                          InputLabelProps={{
                            shrink: passwordHasValue || field.value || passwordFocused ? true : false,
                            sx: {
                              textTransform: passwordHasValue || field.value || passwordFocused ? "uppercase" : "none"
                            }
                          }}
                          onFocus={() => setPasswordFocused(true)}
                          onBlur={(e) => {
                            setPasswordFocused(false);
                            field.onBlur(e);
                          }}
                          sx={{
                            width: "100%",
                            height: "auto",
                            borderRadius: "6px",
                            borderColor: "#D9D9D9",
                            '& .MuiOutlinedInput-root': {
                              '&.Mui-focused fieldset': {
                                borderColor: '#757373',
                              },
                            },
                            '& .MuiInputLabel-root.Mui-focused': {
                              color: '#757373',
                              fontWeight: 600,
                              textTransform: "uppercase"
                            },
                            '&. MuiInputLabel-shrink': {
                              textTransform: "uppercase"
                            },
                            '& .MuiInputBase-input::placeholder': {
                              color: '#545454'
                            },
                            '& .MuiInputBase-input:-webkit-autofill': {
                              '-webkit-box-shadow': '0 0 0 100px transparent inset',
                              transition: 'background-color 5000s ease-in-out 0s'
                            }
                          }}
                          label="Password &nbsp;&nbsp;"
                          variant="outlined"
                          error={!!error}
                          helperText={error ? error.message : null}
                          onChange={(e) => {
                            field.onChange(e);
                            setPasswordHasValue(!!e.target.value);
                          }}
                        />
                      );
                    }}
                  />
                  <img
                    className="absolute right-4 top-4 cursor-pointer"
                    src={toggleIcon ? eyeOn : eyeOff}
                    alt=""
                    onClick={togglePasswordIcon}
                  />
                </div>

                {/* <div className="flex items-center gap-2">
                    <Controller
                      name="keepLoggedIn"
                      control={control}
                      render={({ field }) => (
                        <CheckBox
                        
                          sx={{ color: "#000000" }}
                        />
                      )}
                    />
                    <p className="text-[16px] text-[#232323]">Keep me logged in</p>
                  </div> */}

                <div className="flex flex-col gap-3 w-full items-center">
                  <BasicButton sx={{ height: "40px", width: "100%" }} color="genieRed"
                    type="submit"
                  >
                    <span className="text-white capitalize font-medium text-base">
                      Sign in
                    </span>
                  </BasicButton>

                  <p className="text-[#B80E0C] font-bold text-base cursor-pointer" onClick={() => navigate("/resetpassword")}>Forgot Password</p>
                </div>

                <div class="flex items-center gap-4">
                  <span class="h-px w-full bg-[#D9D9D9]"></span>
                  <span class="text-base text-[#6E6E6E]">or</span>
                  <span class="h-px w-full bg-[#D9D9D9]"></span>
                </div>


                <BasicButton sx={{ height: "40px" }} variant="outlined" color="genieRed"
                  onClick={() => navigate("onBoarding")}
                >
                  <span className="text-black capitalize font-medium text-base">
                    Sign up
                  </span>
                </BasicButton>
              </div>
            </form>


          </Box>
        </Grid>

      </Grid >
    </>
  );
}
