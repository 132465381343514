import { Grid } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { CustomTextInput } from "../../../components/InputBox/CustomInput";
import { useAuth } from "../../../utils/hooks";
import { PageLoader } from "../../../components/loader";
import ReusableSmallIOSSwitch from "../../../components/switch/IOSSwitch"

function SocialMediaDetails({ control }) {
  const { useGetServiceType, selectedServiceType, setSelectedServiceType } = useAuth();
  const { data: serviceType, isLoading: serviceTypeIsLoading } = useGetServiceType();

  return (
    <Grid item container>
      {/* <Grid item xs={12}>
        <h1 className=" text-3xl mt-[20px]  mx-5">
          Description & Social media Links
        </h1>
        <h3 className="text-sm font-medium mt-4 mx-5">
          You can Generate the Description
        </h3>
      </Grid> */}

      <Grid
        item
        sx={{
          width: "100%",
          // marginX: "1.25rem",
          overflowY: "scroll",
          // marginTop: "10px",
        }}
      >
        <Grid container xs={12} sx={{ justifyContent: "center" }}>
          <Grid container xs={12} className=" justify-center">
            <Grid item xs={12} sm={10} md={8} sx={{ display: "flex", flexDirection: "column", paddingBottom: "30px", borderBottom: "1px solid #EDEDED" }}>
              <label className="font-[600] block mb-2 text-[14px] text-[#757373]">
                SELECT ALL SERVICE TYPE<span className="text-[#b80e0c]">*</span>
              </label>
              <div className="flex justify-between">
                {serviceTypeIsLoading ? (
                  <div>
                    <PageLoader />
                  </div>
                ) : (
                  serviceType?.result?.map((itemsList, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        setSelectedServiceType((prevSelectedType) => {
                          if (!prevSelectedType.includes(itemsList?.service_type)) {
                            return [...prevSelectedType, itemsList?.service_type];
                          } else {
                            return prevSelectedType.filter(
                              (item) => item !== itemsList?.service_type
                            );
                          }
                        });
                      }}
                    >
                      {/* <div
                  className={`rounded ${selectedServiceType?.includes(itemsList?.service_type)
                      ? "bg-red-50"
                      : "bg-white"
                    } border p-10 flex justify-center gap-2 hover:bg-red-50`}
                >
                  <RestaurantIcon sx={{ color: "#ED1703" }} />
                  <h1>{itemsList?.service_type}</h1>
                </div> */}
                      <div className="flex gap-2">
                        <ReusableSmallIOSSwitch checked={selectedServiceType?.includes(itemsList?.service_type)} />
                        <p>{itemsList?.service_type}</p>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </Grid>
          </Grid>

          <Grid container xs={12} className="justify-center gap-[20px]">
            <Grid container xs={12} sm={10} md={8} sx={{ justifyContent: "space-between", alignItems: "center", paddingTop: "30px" }}>
              <Grid item xs={3} className="text-wrap w-[174px]">
                <label className="font-[600]  block text-[14px] text-[#757373]">
                  CUSTOM ORDERING LINK<span className="text-[#b80e0c]">*</span>
                </label>
              </Grid>
              <Grid item xs={8} className="flex-grow flex gap-2 items-center">
                <Controller
                  name="customDomain"
                  control={control}
                  rules={{
                    required: "Custom domain is required"
                  }}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <CustomTextInput
                        {...field}
                        variant="outlined"
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    );
                  }}
                />
                <h1 className=" h-full text-base"> .grubgenie.ai</h1>
              </Grid>
            </Grid>

            <Grid container xs={12} sm={10} md={8} sx={{ justifyContent: "space-between", alignItems: "center", paddingBottom: "30px", borderBottom: "1px solid #EDEDED" }}>
              <Grid xs={3} item className="text-wrap w-[174px]">
                <label className="font-[600]  block text-[14px] text-[#757373]">
                  DELIVERY RANGE<span className="text-[#b80e0c]">*</span>
                </label>
              </Grid>
              <Grid xs={8} item className="flex-grow flex gap-2 items-center">
                <Controller
                  name="deliveryRadius"
                  control={control}
                  rules={{
                    required: "Delivery radius is required",
                    valueAsNumber: true,
                    min: { value: 1, message: "Delivery radius must be greater than 0" }
                  }}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <CustomTextInput
                        {...field}
                        variant="outlined"
                        error={!!error}
                        type="number"
                        helperText={error ? error.message : null}
                        postContent={<span>Kms</span>}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container xs={12} className="justify-center gap-[20px]">
            <Grid container xs={12} sm={10} md={8} sx={{ justifyContent: "space-between", alignItems: "center", padding: "30px 0", borderBottom: "1px solid #EDEDED" }}>
              <Grid xs={3} item className="text-wrap w-[174px]">
                <label className="font-[600]  block text-[14px] text-[#757373]">
                  PAYMENT (DINE IN)<span className="text-[#b80e0c]">*</span>
                </label>
              </Grid>
              <Grid xs={8} item className="flex-grow flex gap-2 items-center">
                <Controller
                  name="dineInPayment"
                  control={control}
                  defaultValue={false}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <div className="h-[42px] w-full flex items-center rounded-[4px] bg-[#F7F7F7] border border-[#E2E8F0] font-medium">
                        <div
                          onClick={() => field.onChange(true)}
                          className={`w-full h-full flex items-center justify-center cursor-pointer ${field.value === true ? 'text-[#FFFFFF] bg-[#262626] rounded-[3px] outline outline-1 outline-[#E2E8F0]' : ''}`}
                        >
                          Yes
                        </div>
                        <div
                          onClick={() => field.onChange(false)}
                          className={`w-full h-full flex items-center justify-center cursor-pointer ${field.value === false ? 'text-[#FFFFFF] bg-[#262626] rounded-[3px] outline outline-1 outline-[#E2E8F0]' : ''}`}
                        >
                          No
                        </div>
                      </div>
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item xs={12}>
            <div className="max-w-[472px]">
              <Controller
                name="Description"
                control={control}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <TextInput
                      {...field}
                      variant="outlined"
                      label="Description(optional)"
                      error={!!error}
                      multiline
                      rows={3}
                      helperText={error ? error.message : null}
                    />
                  );
                }}
              />
            </div>
          </Grid> */}
          <Grid container xs={12} className="justify-center gap-[20px]">
            <Grid container xs={12} sm={10} md={8} sx={{ justifyContent: "space-between", alignItems: "center", paddingTop: "30px" }}>
              <Grid xs={3} item className="text-wrap w-[174px]">
                <label className="font-[600]  block text-[14px] text-[#757373]">
                  YOUTUBE LINK
                </label>
              </Grid>
              <Grid xs={8} item className="flex-grow flex gap-2 items-center">
                <Controller
                  name="youtubeURL"
                  control={control}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <CustomTextInput
                        {...field}
                        variant="outlined"
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>

            <Grid container xs={12} sm={10} md={8} sx={{ justifyContent: "space-between", alignItems: "center" }}>
              <Grid xs={3} item className="text-wrap w-[174px]">
                <label className="font-[600]  block text-[14px] text-[#757373]">
                  INSTAGRAM LINK
                </label>
              </Grid>
              <Grid xs={8} item className="flex-grow flex gap-2 items-center">
                <Controller
                  name="instagramURL"
                  control={control}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <CustomTextInput
                        {...field}
                        variant="outlined"
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>

            <Grid container xs={12} sm={10} md={8} sx={{ justifyContent: "space-between", alignItems: "center" }}>
              <Grid xs={3} item className="text-wrap w-[174px]">
                <label className="font-[600]  block text-[14px] text-[#757373]">
                  FACEBOOK LINK
                </label>
              </Grid>
              <Grid xs={8} item className="flex-grow flex gap-2 items-center">
                <Controller
                  name="facebookURL"
                  control={control}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <CustomTextInput
                        {...field}
                        variant="outlined"
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>

            <Grid container xs={12} sm={10} md={8} sx={{ justifyContent: "space-between", alignItems: "center" }}>
              <Grid xs={3} item className="text-wrap w-[174px]">
                <label className="font-[600]  block text-[14px] text-[#757373]">
                  GOOGLE BUSINESS LINK
                </label>
              </Grid>
              <Grid xs={8} item className="flex-grow flex gap-2 items-center">
                <Controller
                  name="googleBusinessURL"
                  control={control}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <CustomTextInput
                        {...field}
                        variant="outlined"
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>

            <Grid container xs={12} sm={10} md={8} sx={{ justifyContent: "space-between", alignItems: "center", paddingBottom: "30px" }}>
              <Grid xs={3} item className="text-wrap w-[174px]">
                <label className="font-[600]  block text-[14px] text-[#757373]">
                  ZOMATO LINK
                </label>
              </Grid>
              <Grid xs={8} item className="flex-grow flex gap-2 items-center">
                <Controller
                  name="zomatoUrl"
                  control={control}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <CustomTextInput
                        {...field}
                        variant="outlined"
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SocialMediaDetails;
