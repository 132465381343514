import React from 'react'

const CustomProgress = ({ count, current = 1 }) => {
    return (
        <div className="flex w-full gap-[20px]">
            {[...Array(count)].map((_, index) => (
                <div key={index} className={`h-[5px] flex-1 ${current === index + 1 ? "bg-[#d46e6d]" : "bg-[#EDEDED]"} rounded-full`}></div>
            ))
            }
        </div >
    )
}

export default CustomProgress