import { Grid } from "@mui/material"
import closeIcon from "../../../../assets/Icons/close.svg"
import { useRestaurant } from "../../../../utils/hooks";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { TextInput } from "../../../../components/InputBox";
import cancel from "../../../../assets/Icons/cancel.svg";
import ButtonWithIcon from "../../../../components/ButtonWithIcon";
import add from "../../../../assets/Icons/add.svg";
import plus from '../../../../assets/Icons/plus.svg'
import BasicButton from "../../../../components/Button";
import { useState } from "react";
import { BasicModal } from "../../../../components/Modal/Modal";

const TypeModal = ({ data, close, handleDelete, handleSubmit }) => {
    const { addListFormValue, setAddListFormValue, addList, setAddList } = useRestaurant();
    const [disabled, setDisabled] = useState(false)
    const [deleteModelActive, setDeleteModelActive] = useState(false)
    const [deleteItem, setDeleteItem] = useState(null)

    const { control } = useForm({
        defaultValues: {
            type: data ? data : ""
        }
    })

    const { fields: typeList, remove, append } = useFieldArray({
        control,
        name: "type",
    });

    return (
        <>
            <div className="flex justify-between items-center">
                <p className="font-semibold">Type</p>
                <img className="cursor-pointer" onClick={close} src={closeIcon} alt="" />
            </div>
            <Grid container xs={12} className='my-[18px]'>
                <Grid xs={4} sx={{ display: "flex" }}>
                    <p className="text-[#757373] basis-40 font-semibold mt-[10.3px]">ADD TYPE<span className="text-[#b80e0c]">*</span></p>
                </Grid>
                <Grid xs={8} sx={{ display: "flex", flexDirection: "column", gap: "10px", maxHeight: "50vh", overflowY: "auto" }} className='scrollbar scrollbar-thumb-[#757373] scrollbar-track-gray-200'>
                    {typeList.map(((item, index) => (
                        <div key={item.id}>
                            <Controller
                                name={`type[${index}].label`}
                                control={control}
                                render={({ field }) => (
                                    <div className='flex items-center gap-[10px] w-100'>
                                        <TextInput
                                            {...field}
                                            placeholder="Enter category"
                                            variant="outlined"
                                            disabled={true}
                                        />
                                        <img
                                            className="cursor-pointer"
                                            src={cancel}
                                            alt="delete"
                                            onClick={() => {
                                                setDeleteItem({ item: item, index: index })
                                                setDeleteModelActive(true)
                                            }}
                                        />
                                    </div>
                                )}
                            />
                        </div>
                    )))}
                    {!addList ?
                        <ButtonWithIcon icon={plus} text="Add new Item" onClick={() => setAddList(true)} />
                        :
                        <TextInput
                            variant="outlined"
                            placeholder="Type other categories..."
                            value={addListFormValue}
                            onChange={(e) => setAddListFormValue(e.target.value)}
                            disabled={disabled}
                            postContent={
                                <img
                                    className={`cursor-pointer ${disabled ? "opacity-50" : ""}`}
                                    src={add}
                                    alt="add"
                                    onClick={async () => {
                                        try {
                                            setDisabled(true)
                                            await handleSubmit()
                                            append({ label: addListFormValue })
                                        } catch (err) { }
                                        finally {
                                            setDisabled(false)
                                        }
                                    }}
                                />
                            }
                        />
                    }
                </Grid>
            </Grid>
            <Grid xs={12}>
                <BasicButton
                    color="genieRed"
                    type="submit"
                    sx={{
                        textTransform: "none",
                        display: "flex",
                        color: "white",
                        width: "100%"
                    }}
                    onClick={close}

                >
                    Close
                </BasicButton>
            </Grid>

            <BasicModal open={deleteModelActive} close={() => {
                setDeleteItem(null)
                setDeleteModelActive(false)
            }} maxWidth='auto'>
                <div className='flex flex-col gap-2'>
                    <p>Are you sure you want to delete {deleteItem?.label || "this"} item?</p>
                    <div className='flex gap-2'>
                        <BasicButton variant='outlined' color="genieRed" sx={{ width: "100%", textTransform: "none" }}
                            onClick={() => {
                                setDeleteItem(null)
                                setDeleteModelActive(false)
                            }}
                        >No</BasicButton>
                        <BasicButton color='genieRed' sx={{ width: "100%", color: "#fff", textTransform: "none" }}
                            onClick={async () => {
                                try {
                                    setDeleteModelActive(false)
                                    await handleDelete(deleteItem?.item?.value)
                                    remove(deleteItem?.index)
                                    setDeleteItem(null)
                                } catch (err) { }
                            }}
                        >Yes</BasicButton>
                    </div>
                </div>
            </BasicModal>
        </>
    )
}

export default TypeModal