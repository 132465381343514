import spiceIcon from '../../assets/Icons/spiceIcon.png'

export const SpiceIcon = ({ currentSpice, hover, rating, setHover }) => {
    return (
        <img
            src={spiceIcon}
            alt="spice level"
            className={`w-[37.91px]`}
            style={currentSpice <= (hover || rating) ? {} : {
                filter: "grayscale(100%)",
                opacity: "0.5",
            }}
            onMouseEnter={() => setHover(currentSpice)}
            onMouseLeave={() => setHover(null)}
        />
    )
}