import React, { useEffect, useMemo, useRef, useState } from "react";
import { BasicModal } from "../../../components/Modal/Modal";
import BasicButton from "../../../components/Button";
import { QRCode } from "react-qrcode-logo";
import logo from "../../../assets/Icons/Grubgenie_Logo_circular.svg";
import generatePDF, { Margin } from "react-to-pdf";
import QRCodeStyling from "qr-code-styling";
import QrCode from "./QrCode";

const qrCode = new QRCodeStyling({
  width: 2000,
  height: 2000,
  type: "svg",
  image: logo,
  margin: 0,
  qrOptions: {
      typeNumber: 0,
      mode: "Byte",
      errorCorrectionLevel: "Q",
  },
  imageOptions: {
      hideBackgroundDots: true,
      imageSize: 0.4,
      margin: 8,
      crossOrigin: "anonymous",
  },
  dotsOptions: {
      type: "dots",
      color: "#cc2027",
      roundSize: true,
  },
  backgroundOptions: {
      color: "#fff",
  },
  cornersSquareOptions: {
      color: "#cc2027",
      type: "rounded",
  },
  cornersDotOptions: {
      color: "#cc2027",
      type: "dot",
  },
});

function QrCodeModal({ open, handleCancel, handleConfirmClick, link, tableNumber }) {
  const targetRef = useRef();

  const onDownloadClick = async () => {
    // await qrCode.update({
    //   height: 2048,
    //   width: 2048,
    //   imageOptions: {
    //     margin: 40,
    //   },
    // });
    qrCode.download();
  };

  var queryTableId = link?.split("=")[1];
  // var queryParams = new URLSearchParams(queryTableId);
  // var tableId = queryParams.get('tableId');

  const options = {
    //  resolution: Resolution.HIGH,
    page: {
      margin: Margin.SMALL,
      // margin is in MM, default is Margin.NONE = 0
      unit: "px",
      format: [50, 50],
    },
    canvas: {
      // default is 'image/jpeg' for better size performance
      mimeType: "image/png",
      qualityRatio: 1,
    },
  };

  return (
    <>
      <BasicModal open={open} close={handleCancel}>
        <div className="flex flex-col gap-6 p-6">
          <p className="font-bold text-lg font-Quicksand font-poppins">
            Scan or Download the Qr code
          </p>
          <div className="flex justify-center flex-col items-center font-poppins" ref={targetRef}>
            {/* Table Number: {tableNumber} */}
            {/* <div className="p-1"></div> */}
            {/* <QRCode
              value={link}
              fgColor="#cd1619"
              logoImage={logo}
              removeQrCodeBehindLogo={true}
              qrStyle={"squares"}
              eyeRadius={0}
            /> */}
            <QrCode qrCode={qrCode} link={link} />
          </div>
          <div className="flex justify-end items-center gap-2">
            <BasicButton
              onClick={handleCancel}
              variant="outlined"
              sx={{
                textTransform: "none",
                // width: "236px",
                width: "100%",
                height: "36px",
              }}
              color="genieRed"
            >
              Cancel
            </BasicButton>
            <BasicButton
              // onClick={handleConfirmClick}
              // onClick={() => generatePDF(targetRef, options)}
              onClick={onDownloadClick}
              sx={{
                textTransform: "none",
                // width: "236px",
                width: "100%",
                height: "36px",
                color: "white"
              }}
              color="genieRed"
            >
              Download
            </BasicButton>
          </div>
        </div>
      </BasicModal>
    </>
  );
}

export default QrCodeModal;
