import React, { useRef } from "react";
import { BasicModal } from "../../../components/Modal/Modal";
import BasicButton from "../../../components/Button";
import { QRCode } from "react-qrcode-logo";
import logo from "../../../assets/images/favicongin-modified.png";

import generatePDF, { Margin } from "react-to-pdf";

function QrCodeModal({ open, handleCancel, handleConfirmClick, link }) {
  const targetRef = useRef();

  var queryTableId = link?.split("=")[1];
  // var queryParams = new URLSearchParams(queryTableId);
  // var tableId = queryParams.get('tableId');

  const options = {
    //  resolution: Resolution.HIGH,
    page: {
      margin: Margin.SMALL,
      // margin is in MM, default is Margin.NONE = 0
      unit: "px",
      format:   [50,50],
    },
    canvas: {
      // default is 'image/jpeg' for better size performance
      mimeType: "image/png",
      qualityRatio: 1,
    },
  };

  return (
    <>
      <BasicModal open={open} close={handleCancel}>
        <div className="flex flex-col gap-6 p-6 min-w-[360px]">
          <p className="font-bold text-lg font-Quicksand">
            Scan or Download the Qr code
          </p>
          <div className="flex justify-center flex-col items-center">
            {/* Table Number :1 */}
            <QRCode
              value={link}
              fgColor="#FF0000"
              logoImage={logo}
              removeQrCodeBehindLogo={true}
              qrStyle={"dots"}
              eyeRadius={[
                5, // top/left eye
                10, // top/right eye
                5, // bottom/left eye
              ]}
            />
          </div>
          <div className="flex justify-end items-center gap-2">
            <BasicButton
              onClick={handleCancel}
              variant="outlined"
              sx={{
                textTransform: "none",
                width: "236px",
                height: "36px",
              }}
            >
              Cancel
            </BasicButton>
            <BasicButton
              // onClick={handleConfirmClick}
              onClick={() => generatePDF(targetRef, options)}
              sx={{
                textTransform: "none",
                width: "236px",
                height: "36px",
              }}
            >
              DownLoad
            </BasicButton>
          </div>
        </div>
      </BasicModal>

      <div
        className="  flex justify-center flex-col items-center bg-red-50 h-[100vh] w-[100vw]  border-lg "
        ref={targetRef}
      >
        <h1 className="font-quicksand m-5 font-[700] text-[20px]">
          Table Number :{queryTableId}
        </h1>

        <QRCode
          value={link}
          fgColor="#FF0000"
          logoImage={logo}
          removeQrCodeBehindLogo={true}
          qrStyle={"dots"}
          size={300}
          eyeRadius={[
            5, // top/left eye
            10, // top/right eye
            5, // bottom/left eye
          ]}
        />
      </div>
    </>
  );
}

export default QrCodeModal;
