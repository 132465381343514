import { Grid } from "@mui/material"
import closeIcon from "../../../../assets/Icons/close.svg"
import BasicButton from "../../../../components/Button"
import { Controller, useFieldArray, useForm } from "react-hook-form"
import { TextInput } from "../../../../components/InputBox"
import cancel from "../../../../assets/Icons/cancel.svg";
import { useState } from "react"
import add from "../../../../assets/Icons/add.svg";

const IngredientsModal = ({ data, setData, handleSubmit, close }) => {
  const [newIngredient, setNewIngredient] = useState("");

  const { control, getValues, watch } = useForm({
    defaultValues: {
      ingredients: data ? data : ""
    }
  })

  const { fields: ingredientsList, remove, append } = useFieldArray({
    control,
    name: "ingredients",
  });

  return (
    <>
      <div className="flex justify-between items-center">
        <p className="font-semibold">Ingredients List</p>
        <img className="cursor-pointer" onClick={close} src={closeIcon} alt="" />
      </div>

      <Grid container xs={12} className='my-[18px]'>
        <Grid xs={4} sx={{ display: "flex" }}>
          <p className="text-[#757373] basis-40 font-semibold mt-[10.3px]">ADD ITEM<span className="text-[#b80e0c]">*</span></p>
        </Grid>
        <Grid xs={8} sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <div className={`max-h-[400px] overflow-y-auto gap-[10px] flex-col ${watch("ingredients").length > 0 ? "flex" : "hidden"}`} style={{ scrollbarWidth: 'thin', scrollbarColor: '#e5e7eb #fff', scrollbarRadius: '8px' }}>
            {ingredientsList.map(((item, index) => (
              <div key={item.id}>
                <Controller
                  name={`ingredients[${index}]`}
                  control={control}
                  render={({ field }) => (
                    <div className='flex items-center gap-[10px] w-full'>
                      <TextInput
                        {...field}
                        placeholder="Enter ingredient"
                        variant="outlined"
                      />
                      <img
                        className="cursor-pointer mr-2"
                        src={cancel}
                        alt="delete"
                        onClick={() => remove(index)}
                      />
                    </div>
                  )}
                />
              </div>
            )))}
          </div>
          <TextInput
            variant="outlined"
            placeholder="Type other categories..."
            value={newIngredient}
            onChange={(e) => setNewIngredient(e.target.value)}
            postContent={
              <img
                className="cursor-pointer"
                src={add}
                alt="add"
                onClick={() => {
                  append(newIngredient)
                  setNewIngredient("")
                }}
              />
            }
          />
        </Grid>
      </Grid>
      <Grid xs={12}>
        <BasicButton
          color="genieRed"
          type="submit"
          sx={{
            textTransform: "none",
            display: "flex",
            color: "white",
            width: "100%"
          }}
          onClick={() => handleSubmit(getValues("ingredients"))}

        >
          Update
        </BasicButton>
      </Grid>
    </>
  )
}

export default IngredientsModal