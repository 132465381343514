import React, { forwardRef } from "react";
import { Box, FormHelperText, Typography } from "@mui/material";
import ReactSelect, { components } from "react-select";

import EllipsisText from "react-ellipsis-text";


export const SearchableDropDown = forwardRef(
  (
    {
      label,
      options,
      onChange,
      value,
      placeholder,
      error,
      helperText,
      menuPortalTarget,
      isDisabled,
      avatarLabel,
      isClearable,
      isSearchable = true,
      hideIndicator,
      isMulti,
      getOptionValue,
      getOptionLabel,
      controlShouldRenderValue,
      hideSelectedOptions,
      defaultValue,
      type,
      loadOptions,
      cacheOptions,
      defaultOptions,
      debounceTimeout,
      additional,
      menuHeight = "150px",
      closeMenuOnScroll,
      menuShouldBlockScroll,
      menuPlacement,
      backspaceRemovesValue,
      cacheUniqs,
      noBorder,
      view,
      labelLength = "20",
      emailLength = "20",
      preContent,
      borderRadius = "4px",
      fontSize = "14px",
      required,
      name,
      ...props
    },
    ref
  ) => {
    const customStyles = {
      maxHeight: "10px",
      backgroundColor: "#f7f7f7",
      fontFamily: "quicksand",
      borderColor: "#e2e8f0",


      menu: (base) => ({
        ...base,
        marginTop: "5px",
        backgroundColor: "#f7f7f7",
        zIndex: 9999,
      }),
      option: (provided, state) => ({
        ...provided,
        borderColor: "#e2e8f0",
        color: state.isSelected ? "white" : "black",
        minHeight: "35px",
        paddingTop: "10px",
        fontSize: "15px",
        opacity: state.isDisabled ? "0.5" : "1",
        cursor: state.isDisabled ? "not-allowed" : "pointer",
        backgroundColor: state.isSelected ? "#6F7787FF" : "#f7f7f7",
        fontFamily: "quicksand",
        "&:hover": {
          color: !state.isDisabled && "black",
          background: !state.isDisabled && "#BCC1CAFF",
        },
      }),
      valueContainer: (base) => ({
        ...base,
        padding: "2px",
      }),
      indicatorSeparator: () => ({
        display: "none",
      }),

      control: (base, state) => ({
        ...base,
        width: "100%",
        paddingRight: "6px",
        paddingLeft: "8px",
        fontSize: fontSize,
        lineHeight: "22px",
        borderWidth: "1px",
        outline: "none",
        minHeight: "40px",
        cursor: "pointer",
        fontFamily: "QuickSand",
        pointerEvents: "auto",
        borderColor: "#e2e8f0",
        backgroundColor: state.isDisabled ? "#F8F9FA" : "#f7f7f7",
        borderRadius: borderRadius,
        color: "#fff !important",

        boxShadow: noBorder && "none",
        "&:focus": {
          color: "#BCC1CAFF",
          background: "#f7f7f7",
          borderColor: "#5c5d5e",
          cursor: "pointer",
        },
        "&:hover": {
          color: "#BCC1CAFF",
          background: "#f7f7f7",
          borderColor: "#5c5d5e",
        },

        "&:disabled": {
          border: state.isDisabled ? "none !important" : "1px solid #9095A0",
          color: "#BCC1CAFF",
          background: "#FFFFFF",
        },
        input: {
          height: "auto !important",
        },
      }),
      placeholder: (base, state) => ({
        ...base,
        backgroundColor: state.isDisabled ? "#F8F9FA" : "#f7f7f7",
        marginTop: "2px",
        color: "#252525",
        fontFamily: "quicksand",
        fontWeight: 700,
        fontSize: "14px",
      }),
      menuList: (base) => ({
        ...base,
        textAlign: "start",
        maxHeight: menuHeight,
      }),
      singleValue: (base) => ({
        ...base,
        marginTop: "2px",
        color: "#171A1FFF",
        fontFamily: "QuickSand",
      }),
      multiValueRemove: (base, state) => ({
        ...base,
        ...(state.isDisabled
          ? {
            visibility: "hidden",
            width: "4px",
          }
          : {}),
      }),
      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
      dropdownIndicator: (base) => ({
        ...base,
        padding: "0px",
        display: hideIndicator ? "none" : undefined,
        height: "20px",
      }),
    };

    const Control = ({ children, ...props }) => {
      return (
        <components.Control {...props}>
          {preContent || null}
          {children}
        </components.Control>
      );
    };

    const handleFormatOptionLabel = (data) => {
      if (avatarLabel) {
        return (
          <Box>
            {data && (
              <div className="flex items-center">
                <div className="font-semibold">
                  <div>
                    {data?.label && (
                      <EllipsisText
                        text={data?.label}
                        length={labelLength}
                        tooltip={data?.label}
                      />
                    )}
                  </div>
                  <div className="text-sm">
                    {data?.email && (
                      <EllipsisText
                        text={data?.email}
                        length={emailLength}
                        tooltip={data?.email}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </Box>
        );
      }
      return (
        <div className="flex items-center">
          {data?.icon}
          {data.label}
        </div>
      );
    };

    return (
      <div className="flex-1 font-poppins">
        {label && (
          <label
            className="block mb-1 text-sm font-semibold text-[#757373] uppercase"
            htmlFor={`custom-text-input-${name || "box"}`}
          >
            {label}{required && <span className="text-[#B80E0C]">*</span>}
          </label>
        )}
        <ReactSelect
          className={`${error ? "border border-red-500 rounded" : ""}`}
          data-testid="dropDown-input"
          {...props}
          components={{ Control }}
          isMulti={isMulti}
          controlShouldRenderValue={controlShouldRenderValue}
          styles={customStyles}
          placeholder={placeholder}
          options={options}
          value={value}
          onChange={onChange}
          cacheUniqs={cacheUniqs}
          isSearchable={isSearchable}
          isClearable={isClearable}
          isDisabled={isDisabled}
          getOptionValue={getOptionValue}
          getOptionLabel={getOptionLabel}
          hideSelectedOptions={hideSelectedOptions}
          defaultValue={defaultValue}
          formatOptionLabel={(data) => handleFormatOptionLabel(data)}
          loadOptions={loadOptions}
          cacheOptions={cacheOptions}
          defaultOptions={defaultOptions}
          backspaceRemovesValue={backspaceRemovesValue}
          debounceTimeout={debounceTimeout}
          additional={additional}
          menuHeight={menuHeight}
          menuPlacement={menuPlacement}
          menuPortalTarget={menuPortalTarget}
          closeMenuOnScroll={closeMenuOnScroll}
          menuShouldBlockScroll={menuShouldBlockScroll}
        />
        {error && helperText && (
          <FormHelperText className="">
            <Typography variant="body2" className="text-red-500">
              {helperText}
            </Typography>
          </FormHelperText>
        )}
      </div>
    );
  }
);
