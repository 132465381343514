import { autocompleteClasses, Badge, Grid, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import BasicButton from "../../../components/Button";
import { useNavigate } from "react-router-dom";
// import HeaderWrapper from "../../../components/headerwrapper/HeaderWrapper";
import { TextInput } from "../../../components/InputBox";
import SearchIcon from "@mui/icons-material/Search";
import { useRestaurant, useSuperAdmin } from "../../../utils/hooks";
import CustomTabPanel from "../../../components/Tabs/CustomtabPanels";
import MenuComponent from "./MenuList";
import { PageLoader } from "../../../components/loader";
import NoDataComponent from "../../../components/NoDataComponent";
import DraggableTabs from "../../../components/Tabs/DragableComponent";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import AddIcon from '@mui/icons-material/Add';
import LogOutButton from "../../../components/Logout";
import { BasicModal } from "../../../components/Modal/Modal";
import CategoryModal from "../AddMenu/modals/CategoryModal";
import { RWebShare } from "react-web-share";
import share from "../../../assets/Icons/shareAlt.svg"
import useToken from "../../../utils/hooks/useToken";
import { useDebounce } from "../../../useDebaunce";
import { Padding } from "@mui/icons-material";
import searchIcon from "../../../assets/Icons/search.svg"

function MenuManagement() {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const { useGetTabList, useGetTabListContent, setNotificationDrawer, openModal, setOpenModal, listArray, AddCategory, HandleDeleteCategoryList, useRequestedOrders, useSearchByItemName, changeSequenceIsLoading } = useRestaurant();
  const { tabValue } = useSuperAdmin();
  const { data: tabData, loading: tabDataIsLoading } = useGetTabList();
  const { getUserDetails } = useToken()

  const [menuTabList, setMenuTabList] = useState(tabData?.result.map((item) => ({
    label: `${item.food_category} (${item?.count})`,
    _id: item?._id,
  })) || [])

  const { data: menuList } = useGetTabListContent(
    tabData?.result[tabValue]?._id
  );

  useEffect(() => {
    setMenuTabList(
      tabData?.result.map((item) => ({
        label: `${item.food_category} (${item?.count})`,
        _id: item?._id,
      }))
    )
  }, [tabData, tabDataIsLoading])

  /**
   * Maps over the tabData result array to create a new array of objects with label and id properties.
   * The label is generated by combining the food category and count of each item.
   * @returns An array of objects with label and id properties.
   */
  // const menuTabList = tabData?.result.map((item) => ({
  //   label: `${item.food_category} (${item?.count})`,
  //   _id: item?._id,
  // }));

  const debouncedValue = useDebounce(searchValue, 300);


  const { data: requestedOrders } = useRequestedOrders()
  const { data: searchResult, isLoading: searchResultIsLoading } = useSearchByItemName(debouncedValue)

  useEffect(() => {
    console.log(searchResult)
  }, [searchResult])
  return (
    <>
      {/* <div
        className="overflow-auto"
        style={{
          height: "100vh"
          // height: "calc(100vh - 51px)",
        }}
      > */}
      {/* <Grid container sx={12}> */}
      {/* <Grid container xs={12} justifyContent="space-between">
            <Grid item xs={12} sx={{ borderBottom: "1px solid #1C1C1C1A" }}>
              <div className="  flex flex-col sm:flex-row  sm:items-center    sm:justify-between py-4 px-8 gap-2">
                <h3 className="hidden sm:flex gap-3 items-center">
                  <span className="text-[#1C1C1C66]"> Dashboard </span>{" "}
                  <span className="text-[#1C1C1C66]">/</span>
                  <span>Menu Management</span>
                </h3>
                <div className="w-full sm:max-w-[500px] flex gap-4 items-center justify-end">
                  <div className="relative w-full">
                    <TextInput
                      variant="outlined"
                      preContent={<SearchIcon sx={{ color: "#c8c8c8" }} />}
                      preContentSx={{ display: "flex", justifyContent: "center" }}
                      transparentBorder
                      placeholder="Search"
                      value={searchValue}

                      borderRadius="8px"
                      // inputStyleProps={{
                      //   padding: "0px 0px 0px 8px"
                      // }}

                      sx={{
                        fontFamily: "Poppins",
                        width: "100%",
                        backgroundColor: "#fff",
                        '& .MuiInputBase-input::placeholder': {
                          color: '#545454'
                        },
                        '& .MuiInputBase-input:-webkit-autofill': {
                          '-webkit-box-shadow': '0 0 0 100px transparent inset',
                          transition: 'background-color 5000s ease-in-out 0s'
                        },
                        // '& .css-1ua80n0-MuiInputBase-input-MuiOutlinedInput-input': {
                        //   padding: "4px 0px"
                        // }
                      }}
                      onChange={(e) => setSearchValue(e.target.value)}
                    //   label="Description(optional)"
                    />
                    {debouncedValue.length > 0 &&
                      <div
                        className="absolute w-full bg-white top-11 rounded-[8px] p-4 border border-[#E3E3E3] shadow-md z-[100] flex flex-col gap-[6px]"
                      >
                        {debouncedValue && searchResult?.result?.length > 0 &&
                          <>
                            <p className="font-medium text-[14px] text-[#757373]">Results for “{debouncedValue}”</p>
                            <hr />
                          </>
                        }
                        <div className="flex flex-col max-h-[398px] overflow-auto scrollbar-thin">
                          {searchResult?.result?.length ?
                            searchResult?.result?.map(item =>
                              <div className="flex items-center gap-4 hover:bg-gray-200 p-1 rounded cursor-pointer"
                                onClick={() => {
                                  navigate(`/menu/edit/${item?._id}`)
                                }}
                              >
                                <img className="h-[57px] w-[57px] rounded-full" src={item?.image?.url} alt="" />
                                <div className="flex flex-col">
                                  <p className="text-[#4D4D4D] text-base font-medium">{item?.item_name?.toString()}</p>
                                  <p className="text-[#1C1B1F] text-[14px] font-semibold">AED {item?.oPrice - item?.dPrice}</p>
                                </div>
                              </div>
                            )
                            :
                            <div className="text-center text-[#757373]">{searchResultIsLoading ? "Searching..." : "Result not found"}</div>
                          }
                        </div>
                      </div>
                    }
                  </div>
                  <Badge badgeContent={requestedOrders?.length} color="error">
                    <NotificationsNoneOutlinedIcon sx={{ cursor: "pointer" }} onClick={() => setNotificationDrawer(true)} />
                  </Badge>
                  <div>
                    <LogOutButton />
                  </div>
                </div>
              </div>
            </Grid>
          </Grid> */}

      <Grid container xs={12} justifyContent="space-between" className="my-2 mx-8" alignItems="center">
        <Grid item sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <h1 className=" text-[30px] text-[#3e3e3e] font-medium">
            Menu List
          </h1>
          <RWebShare
            // TODO: fix url
            data={{
              url: `https://${window.location.hostname.split('.')[0]}.dev-frontend.grubgenie.ai/menu`
            }}
            onClick={() => console.info("share successful!")}
          >
            <img className="cursor-pointer" src={share} alt="" />
          </RWebShare>
        </Grid>
        <Grid item sx={{ display: "flex" }}>
          <BasicButton
            color="genieRed"
            sx={{
              textTransform: "none",
              width: "126px",
              height: "36px",
              marginRight: "10px",
              color: "white",
              display: "flex",
              gap: "5px",
              margin: "auto"
            }}
            onClick={() => navigate("create")}
          >
            <AddIcon />
            Add Item
          </BasicButton>
        </Grid>
      </Grid>

      {/* <Button onClick={()=>{console.log(menuList)}}>Check Data</Button> */}

      <Grid item xs={12}>
        {!menuTabList ? (
          <PageLoader />
        ) : tabData?.result.length === 0 ? (
          <NoDataComponent tittle={"Add Menu to Restaurant "} />
        ) : (
          <>
            <DraggableTabs tabs={menuTabList || []}>
              {(value) => {
                return (
                  <>
                    <CustomTabPanel value={value} index={value}>
                      {menuList?.result.length === 0 ? (
                        <NoDataComponent tittle={"No Items Added"} />
                      ) : (
                        <MenuComponent list={menuList?.result} isLoading={changeSequenceIsLoading} />
                      )}
                    </CustomTabPanel>
                  </>
                );
              }}
            </DraggableTabs>
          </>
        )}
      </Grid>

      {openModal && (
        <BasicModal open={openModal} close={() => { setOpenModal(false) }} maxWidth={"37.5rem"}>
          <CategoryModal data={listArray} close={() => setOpenModal(false)}
            handleSubmit={AddCategory}
            handleDelete={HandleDeleteCategoryList}
          />
        </BasicModal>
      )}
      {/* </Grid> */}
      {/* </div> */}
    </>
  );
}

export default MenuManagement;
