import React from "react";
import Logo from "../../assets/images/Isolation_Mode.svg";
import { Grid } from "@mui/material";
import CartIcon from "../../assets/Icons/Cart.svg";

function MobileHeader({ icons }) {
  return (
    <Grid
      container
      item
      sx={12}
      className={` bg-[#B80E0C]  flex items-center ${
        icons ? " justify-between px-[20px]" : " justify-center "
      }  h-[60px]   `}
    >
      <Grid item>
        <img
          src={Logo}
          alt=""
          srcSet=""
          className=""
          height={"36px"}
          width={"103px"}
        />
      </Grid>
      {icons && (
        <Grid item>
          <img
            src={CartIcon}
            alt=""
            srcSet=""
            className=""
            height={"26px"}
            width={"26px"}
          />
        </Grid>
      )}
    </Grid>
  );
}

export default MobileHeader;
