import { QueryClient } from 'react-query';

const twentyFourHoursInMs = 1000 * 60 * 60 * 24;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    //   retry: false,
      retry: 2,
      staleTime: twentyFourHoursInMs,
    },
  },
});

const realtimeQueryConfig = {
  refetchOnWindowFocus: true,
  refetchOnMount: true,
  refetchOnReconnect: true,
  staleTime: 0,
};

const realtimeQueries = [
  'getAllDinerDetails',
  'getAllDetails',
  'getOrderById',
  'getAllTable',
  // 'getAllFoodMenuByCategory',
//   'getAllCartByID'
];

realtimeQueries.forEach((queryKey) => {
  queryClient.setQueryDefaults([queryKey], realtimeQueryConfig);
});

queryClient.setQueryDefaults(["getAllRestaurantInfo"], {
  retry: 0,
});
