import React from "react";

import { Grid } from "@mui/material";
import { useAuth, useSuperAdmin } from "../../../utils/hooks";
import { PageLoader } from "../../../components/loader";
import cuisine_type from "../../../assets/Icons/cuisine_type.svg"

function CousineDetails() {
  const { useGetAllCuisine } = useSuperAdmin();
  const { data: cousine, isLoading: cousineIsLoading } = useGetAllCuisine();
  const { selectedCousine, setSelectedCousine } = useAuth();

  return (
    <Grid item container className="overflow-auto flex flex-col gap-[20px]">
      {/* <Grid item>
        <h1 className=" text-3xl mt-[20px]  mx-5">Cuisine Details</h1>
        <h3 className="text-sm font-medium mt-4 mx-5">
          You Can Select multiple cuisine!
        </h3>
      </Grid> */}
      <label className=" font-[600]  block text-[14px] text-[#757373]">
        SELECT MULTIPLE CUISINES<span className="text-[#b80e0c]">*</span>
      </label>

      <Grid
        item
        sx={{
          // marginTop: "30px",
          width: "100%",
          // marginX: "1.25rem",
          overflowY: "scroll",
          // height: "calc(100vh - 250px)",
        }}
      >
        {cousineIsLoading ? (
          <PageLoader />
        ) : (
          <Grid container item>
            {cousine?.result?.map((itemsList, index) => (
              <Grid
                item
                key={index}
                xs={6}
                // sm={6}
                md={4}
                onClick={() => {
                  setSelectedCousine((prevSelectedCousine) => {
                    if (!prevSelectedCousine.includes(itemsList?.cuisine_type)) {
                      return [...prevSelectedCousine, itemsList?.cuisine_type];
                    } else {
                      return prevSelectedCousine.filter(
                        (item) => item !== itemsList?.cuisine_type
                      );
                    }
                  });
                }}
              >
                <Grid
                  xs={12}
                  className={`rounded  border font-poppins  
                    ${selectedCousine.includes(itemsList?.cuisine_type)
                      ? "bg-[#c63e3d] text-white shadow-[0px_6px_6px_0px_#D14C4A40] border-none"
                      : "bg-[#f7f7f7] border-[#D9D9D9] hover:shadow-[0px_6px_6px_0px_#D14C4A40] delay-75 transition-all"
                    }  m-2 flex border justify-center gap-2 select-none relative items-center h-[100px]`}
                >
                  {/* <itemsList.icons sx={{ color: "#ED1703" }} /> */}
                  {/* {selectedCousine?.includes(itemsList?._id) && (
                    <CheckIcon sx={{ color: "#ED1703" }} />
                  )} */}
                  {/* <Grid xs={4}>
                    <img src={cuisine_type} alt="" />
                  </Grid> */}
                  <Grid xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <h1>{itemsList?.cuisine_type}</h1>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default CousineDetails;
