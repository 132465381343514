import React from "react";
import { Grid } from "@mui/material";
import Switch from "@mui/material/Switch";
// import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
// import EditCalendarOutlinedIcon from "@mui/icons-material/EditCalendarOutlined";
import { useNavigate } from "react-router-dom";
import { PageLoader } from "../../components/loader/index";
import { useSuperAdmin } from "../../utils/hooks";

function RestaurantList({ list, isLoading }) {
  const navigate = useNavigate();
  const { OnEditIsActivePartner } = useSuperAdmin();

  // TODO:Switch Loader ui expirence 
  return (
    <>
      {isLoading || !list ? (
        <PageLoader />
      ) : (
        <div
          className="p-5 overflow-auto "
          style={{
            height: "calc(100vh - 51px)",
          }}
        >
          <Grid container spacing={4} sm={12} justifyContent={"center"}>
            {list?.map((Restaurant) => (
              <Grid item key={Restaurant?._id}>
                <div className="block  w-[226px] h-[170px] max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 ">
                  <div className="flex justify-between">
                    <h5 className="mb-2 text-2xl font-bold  text-gray-900 truncate ">
                      {Restaurant.name}
                    </h5>
                    {/* <div className="flex  items-center  gap-2 pb-1 ">
                        <EditCalendarOutlinedIcon style={{ fontSize: 20 }} />
                        <DeleteOutlineOutlinedIcon style={{ fontSize: 20 }} />
                      </div> */}
                  </div>
                  <p className="font-normal text-gray-700 ">
                    {Restaurant.workingHours?.from} -
                    {Restaurant.workingHours?.to}
                  </p>

                  <a
                    className="font-normal text-blue-700 block my-2 "
                    href={Restaurant.locationURL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Location
                  </a>

                  <Switch
                    className=" "
                    checked={Restaurant.isActive}
                    onChange={() => {
                      OnEditIsActivePartner({
                        ...Restaurant,
                        isActive: !Restaurant?.isActive,
                      });
                    }}
                  />
                </div>
              </Grid>
            ))}
            <Grid item>
              <div
                className="w-[226px]  h-[170px] max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 flex justify-center items-center "
                onClick={() => navigate("/superadmin/add")}
              >
                +
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
}

export default RestaurantList;
