import React, { useEffect } from 'react'
import { useRestaurant } from '../utils/hooks';
import useToken from '../utils/hooks/useToken';
import { useNavigate } from 'react-router-dom';

const TokenSetter = ({ children, redirectTo }) => {

    const { tokenAvailable, setTokenAvailable, onGetUserDetailsFromAccessToken } = useRestaurant();
    const { token } = useToken();
    const navigate = useNavigate();

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const tokenFromUrl = params.get("token");

        if (tokenFromUrl) {
            onGetUserDetailsFromAccessToken(tokenFromUrl);
            window.history.replaceState({}, document.title, window.location.pathname);
        }

        if (token) {
            setTokenAvailable(true);
            // QueryClient.refetchQueries("getPartnerInfo");
        }

        if (tokenAvailable) {
            navigate(redirectTo);
        }
    }, [onGetUserDetailsFromAccessToken, setTokenAvailable, token, navigate, redirectTo, tokenAvailable]);

    return (<>{children}</>)
}

export default TokenSetter