import { Grid } from "@mui/material";
import React from "react";
import MobileHeader from "../../components/MobileHeader";
import BasicButton from "../../components/Button";
import { Colors } from "../../utils/helper";
import AnimationGif from "../../assets/Icons/Animation - 1724135372294.gif";

function Animation() {
  return (
    <Grid container direction="column">
      <MobileHeader />

      <div
        style={{
          overflowY: "scroll",
          height: "calc(100dvh - 130px)",
        }}
      >
        <Grid item sx={{ padding: 2, width: "100dvw", height: "100%" }}>
          <div className="flex  flex-col h-full justify-center items-center ">
            <img src={AnimationGif} alt="" />

            <h1 className="text-textColor ">Your Order Delivered!</h1>
          </div>
        </Grid>
      </div>

      <Grid
        item
        sx={{ padding: 2, border: ` 1px solid ${Colors.borderGrey} ` }}
      >
        <BasicButton
          sx={{
            textTransform: "none",
            width: "100%", // Full width button
          }}
        >
          Share Feedback
        </BasicButton>
      </Grid>
    </Grid>
  );
}

export default Animation;
