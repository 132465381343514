import React, { useContext, createContext, useState, useEffect, useCallback } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import useToken from "../../hooks/useToken";
import {
  getAllDinerDetails,
  getAllRestaurantInfo,
  getAllRestaurantInfoWithoutTable,
  getAllFoodType,
  getAllSpiceLevel,
  getAllFoodCategory,
  getAllFoodMenuByCategory,
  // AddCartMenu,
  getAllCartItem,
  noteAdd,
  PlaceOrder,
  getAllOrderItem,
  Payment,
  Rating,
  getAllCartCount,
  getPersonalizeEvent,
  getAllGenieExp,
  getItemByQuery,
  getCartItemDetails,
  CreateOrder,
  AddToOrder,
  deleteOrder,
  getCustomizations,
  updateDinerCount,
  payBill,
  payInPerson,
  getDinerMenuToken,
  createPaymentIntent,
  cancelPaymentIntent
} from "../../controllers/diner";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { debounce } from "lodash";
import { useAuth } from "../auth";
import usePersistState, { rehydrateState } from "../../hooks/usePersistState"
// Zustand imports
import { useDinerStore } from "../../../stores";
import { useShallow } from "zustand/shallow"

const DinerContext = createContext(undefined);

export const useDiner = () => useContext(DinerContext);

function useProvideDiner() {
  const queryClient = useQueryClient();
  const location = useLocation();
  const tableId = new URLSearchParams(location.search).get("tableId");


  const {
    setToken,
    getToken,
    saveUser,
    // savePartnerId,
    // getPartnerId,
    // saveCartID,
    getCartId,
    getUserDetails,
    token,
    saveOrderID,
    getOrderId,
    // RemoveCartID,
    RemoveOrderID,
    getDinerCount,
    saveDinerCount,
    removeToken
  } = useToken();
  const { socket } = useAuth()
  // Zustand states
  const {
    setUser,
    setUserId,
    isAuthenticated,
    setIsAuthenticated,
    zCartList,
    setZCartList,
    zResetAllStates,
    zTableId,
    zPartnerId,
    setZPartnerId,
    user,
    dinerCount,
    getCartTotal,
    serviceFee,
    userId,
    setPaymentIntentSecret
  } = useDinerStore(useShallow(state => ({
    setUser: state.setUser,
    setUserId: state.setUserId,
    isAuthenticated: state.isAuthenticated,
    setIsAuthenticated: state.setIsAuthenticated,
    zCartList: state.cartList,
    setZCartList: state.setCartList,
    zResetAllStates: state.resetAllStates,
    zTableId: state.tableId,
    zPartnerId: state.partnerId,
    setZPartnerId: state.setPartnerId,
    user: state.user,
    dinerCount: state.dinerCount,
    getCartTotal: state.getCartTotal,
    serviceFee: state.serviceFee,
    userId: state.userId,
    setPaymentIntentSecret: state.setPaymentIntentSecret
  })))

  const [isDrawerOpenValue, setDrawerOpenValue] = useState("");
  const [noteValue, setNoteValue] = useState("");
  // const [cartList, setCartList] = usePersistState([], tableId ? "cart" : "emptyCart");
  const [cartListDiff] = useState([]);
  const [count, setCount] = useState(0);
  const [OrderModel, setOrderModel] = useState(true);
  const [ratingId, setRatingId] = useState("");
  const [isDrawerNoteOpen, setDrawerNoteOpen] = useState(false);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [orderResult, setOrderResult] = useState({});
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const [rating2, setRating2] = useState(0);
  const [hover2, setHover2] = useState(0);
  const [suggestion, setSuggestion] = useState("");
  const [OrderId] = useState();
  const [pageCount, setPageCount] = useState(1);
  const [genieCheck, setGenieCheck] = useState(false);
  const [genieRecommendation, setGenieRecommendation] = useState([]);
  const [countDiner, setCountDiner] = useState(Number(getDinerCount()) || 1);
  const [dietaryPreferences, setDietaryPreferences] = useState({
    id: "",
    value: "",
  });
  const [allergicPreferences, setAllergicPreferences] = useState("");
  const [spicyPreferences, setSpicyPreferences] = useState({
    id: "",
    value: "",
  });
  const [cartResponseLoading, setCartResponseLoading] = useState(false);
  const [deleteOrderModel, setDeleteOrderModel] = useState(false);
  const [deleteOrderPromise, setDeleteOrderPromise] = useState(null);
  const [restaurantInfoHeight, setRestaurantInfoHeight] = useState(0)
  const [cashOrderIds, setCashOrderIds] = useState(null)
  const [placeOrderIsLoading, setPlaceOrderIsLoading] = useState(false)
  // const [dietaryPreferencesText, setDietaryPreferencesText] = useState("");

  const [allergicData, setAllergicData] = useState([
    { id: 1, label: "Dairy", checked: false },
    { id: 2, label: "Gluten", checked: false },
    { id: 3, label: "Shellfish", checked: false },
    { id: 4, label: "Soy", checked: false },
    { id: 5, label: "Nuts", checked: false },
    { id: 6, label: "Eggs", checked: false },
  ]);
  const [paymentAmount, setPaymentAmount] = useState(0)

  const navigate = useNavigate();

  /**
   * Increments the adult count in the diner state by 1.
   */
  const incrementAdultCount = () => {
    setCountDiner((prevCount) => prevCount + 1);
    saveDinerCount(countDiner + 1)
    if (getOrderId()) {
      dinerCountUpdate({ orderId: getOrderId(), count: countDiner + 1 })
    }
  };

  /**
   * Decrements the adult count in the diner state by 1.
   * @returns None
   */
  const decrementAdultCount = () => {
    setCountDiner((prevCount) => Math.max(0, prevCount - 1));
    saveDinerCount(countDiner - 1)
    if (getOrderId()) {
      dinerCountUpdate({ orderId: getOrderId(), count: countDiner - 1 })
    }
  };

  /**
   * Increments the count of kids in the diner state by 1.
   * @returns None
   */
  const incrementKidsCount = () => {
    setCountDiner((prevCount) => ({
      ...prevCount,
      kidsCount: prevCount.kidsCount + 1,
    }));
  };

  /**
   * Decrements the count of kids in the diner by 1.
   * @returns None
   */
  const decrementKidsCount = () => {
    setCountDiner((prevCount) => ({
      ...prevCount,
      kidsCount: Math.max(0, prevCount.kidsCount - 1),
    }));
  };

  /**
   * Handles the change event for a checkbox with the given id.
   * Toggles the 'checked' property of the item with the matching id in the data array.
   * @param {{number}} id - The id of the checkbox item to toggle.
   * @returns None
   */
  const handleCheckboxChange = (id) => {
    setAllergicData((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, checked: !item.checked } : item
      )
    );
  };

  const increment = () => {
    setCount(prev => prev + 1);
  };

  const decrement = () => {
    setCount(prev => prev - 1);
  };

  // TODO: fix this logic
  const toggleDrawer = (restData, itemCount) => {
    setOrderModel(!OrderModel);
    setDrawerOpen(!isDrawerOpen);
    setCount(0);
  };

  const toggleNoteDrawer = () => {
    setDrawerNoteOpen(!isDrawerNoteOpen);
  };

  const handleNoteChange = (e) => {
    setNoteValue(e.target.value);
  };

  const useGetDinerDetails = ({ partnerId, tableId, customDomain }) =>
    useQuery(["getAllDinerDetails"], () => {
      if (!tableId)
        return getDinerMenuToken({ customDomain })
      else
        return getAllDinerDetails({ partnerId, tableId, customDomain })
    }, {
      enabled: !!!isAuthenticated,
      select: (data) => data?.data,
      onSuccess: async (res) => {
        if (!isAuthenticated) {
          if (res?.result) {
            axios.defaults.headers.common.Authorization = `Bearer ${res?.result?.accessToken}`;
            // Zustand Implementation
            setUser(res?.result);
            setUserId(res?.result?.id)
            setIsAuthenticated(true)
            setZPartnerId(res?.result?.partnerId)
          }
        }
        if (!res?.result?.isNewDiner) {
          try {
            // Replace fetchQuery with refetchQueries and handle the response
            const { data } = await queryClient.fetchQuery({
              queryKey: ['getAllCartByID', user.id],
              queryFn: () => getAllCartItem(user.id)
            });

            // Update cartList with the new data
            if (data?.result) {
              // Zustand implementation
              setZCartList(data?.result)
            }
          } catch (error) {
            // console.log(error)
          }
        }
      },
      onError: (err) => {
        const statusCode = err.response?.status;
        if (statusCode === 401) {
          setIsAuthenticated(false)
          queryClient.invalidateQueries(["getAllDinerDetails"])
        } else
          navigate(`menu/invalid`, { state: err?.response?.data?.result });
      }
    });

  const useGetRestaurantInfo = ({ restId, tableId }) =>
    useQuery(
      ["getAllRestaurantInfo", restId, tableId],
      () => {
        if (tableId) {
          return getAllRestaurantInfo({ restId, tableId })
        } else {
          return getAllRestaurantInfoWithoutTable(restId)
        }
      },
      {
        enabled: !!isAuthenticated && !!zPartnerId,
        select: (data) => data?.data,
        onSuccess: (res) => {
          if (!res?.result?.serviceType?.includes("Dining")) {
            navigate(`menu/closed`, { state: res?.result });
          }
        },
        onError: (err) => {
          const statusCode = err.response?.status;
          if (statusCode === 401) {
            setIsAuthenticated(false)
            queryClient.refetchQueries(["getAllDinerDetails"])
          }
          //   navigate(`genie/dine/${restId}/invalid`, { state: err?.response?.data?.result });
        }
      }
    );

  const useGetFoodType = (id) =>
    useQuery(["getAllFoodType", id], () => getAllFoodType(id), {
      enabled: !!isAuthenticated && !!id,
      select: (data) => data?.data,
      onSuccess: (res) => { },
    });

  const useGetCartCount = ({ status, dinerId }) =>
    useQuery(
      ["getAllCartCount", dinerId],
      () => getAllCartCount({ dinerId, status }),
      {
        enabled: !!token && !!dinerId,
        select: (data) => data?.data,
        onSuccess: (res) => {
          console.log(res)
        },
      }
    );

  const useGetSpiceLevel = ({ restId }) =>
    useQuery(["getAllSpiceLevel", restId], () => getAllSpiceLevel({ restId }), {
      enabled: !!token && !!restId,
      select: (data) => data?.data,
      onSuccess: (res) => { },
    });

  const useGetFoodCategory = (id) =>
    useQuery(["getAllFoodCategory", id], () => getAllFoodCategory(id), {
      enabled: !!isAuthenticated && !!id,
      select: (data) => data?.data,
      onSuccess: (res) => { },
    });

  const useGetFoodMenuByCategory = ({
    restId,
    menuId,
    // query,
    foodTypeId,
    // spiceLevelId,
  }) =>
    useQuery(
      [
        "getAllFoodMenuByCategory",
        restId,
        menuId,
        // query,
        foodTypeId,
        // spiceLevelId,
      ],
      () =>
        getAllFoodMenuByCategory({
          restId,
          menuId,
          // query,
          foodTypeId,
          // spiceLevelId,
        }),
      {
        enabled: !!restId && !!menuId,
        select: (data) => data?.data,
        onSuccess: (res) => { },
      }
    );

  const useGetFoodItemsByQuery = ({
    restId,
    menuId,
    query,
    foodTypeId,
    spiceLevelId,
  }) =>
    useQuery(
      [
        "getItemByQuery",
        restId,
        query,
        menuId,
        foodTypeId,
        spiceLevelId,
      ],
      () =>
        getItemByQuery({
          restId,
          menuId,
          query,
          foodTypeId,
          spiceLevelId,
        }),
      {
        enabled: !!restId && !!user.id && !!(query || menuId || foodTypeId || spiceLevelId),
        select: (data) => data?.data,
        onSuccess: (res) => { },
      }
    );
  const useGetCartItem = ({ dinerId, partnerId }) =>
    useQuery(
      ["getAllCartByID", dinerId],
      () => getAllCartItem(dinerId),
      {
        enabled: !!dinerId && !!tableId && !!isAuthenticated && (dinerId !== undefined),
        select: (data) => data?.data,
        onSuccess: async (res) => {
          // TODO: Add service fee
          // setPaymentAmount((Number(getCartTotal()) + Number(serviceFee)).toFixed(2))

          if (res?.result.length > 0 && res?.result.every(order => order?.orderStatus === "payment_done")) {
            // removeToken()
            navigate(`/menu/bill/success/?tableId=${tableId}`)
            // Zustand implementation
            // setZCartList([])
            // await queryClient.refetchQueries("getAllDinerDetails");
            // await queryClient.resetQueries("getAllCartByID");
            return
          }

          setCountDiner(
            res?.result[res?.result?.length - 1]?.dinerCount || Number(getDinerCount()) || 1
          );

          // Zustand implementation
          if (zCartList?.find(orders => orders._id === "new_order_id")) {
            setZCartList([
              ...res?.result.map(({ _id, orderDetails, isPlaced }) =>
                ({ _id, orderDetails, isPlaced })),
              zCartList.find(orders => orders._id === "new_order_id")
            ])
          } else {
            setZCartList(res?.result.map(({ _id, orderDetails, isPlaced }) => ({ _id, orderDetails, isPlaced })))
          }
        },
        cacheTime: 0,
      }
    );
  const useGetOrderDetails = () =>
    useQuery(
      ["getAllOrderByID", getOrderId()],
      () => getAllOrderItem(getOrderId()),
      {
        enabled: !!getOrderId(),
        select: (data) => data?.data,
        onSuccess: (res) => { },
      }
    );

  const useGetCustomizations = ({ menuIds, partnerId }) =>
    useQuery(
      ["getAllCustomizations", menuIds],
      () =>
        Promise.all(
          menuIds.map((menuId) =>
            getCustomizations({ menuId, partnerId })
          )
        ),
      {
        enabled: !!menuIds?.length && !!zPartnerId,
        select: (data) => data.map((item) => item?.data?.result),
        onSuccess: (res) => {
          // console.log(res);
        },
      }
    );

  const useGetGenieExp = ({ restId }) =>
    useQuery(
      ["getAllGenieExp", genieCheck],
      () =>
        getAllGenieExp({
          restId: restId,
          allergiesIds: allergicData,
          // allergiesIds: allergicPreferences,
          foodTypeId: dietaryPreferences?.id,
          spiceLevelId: spicyPreferences?.id,
        }),
      {
        enabled: true,
        cacheTime: 0,
        select: (data) => data?.data,
        onSuccess: (res) => {
          setGenieRecommendation(res?.result);
          setGenieCheck(false);
        },
      }
    );

  // Create a memoized debounced function that properly cleans up
  const debouncedInvalidateCart = useCallback(
    debounce((queryClient) => {
      if (queryClient) {
        queryClient.invalidateQueries("getAllCartByID");
      }
    }, 800, { leading: false, trailing: true }),
    [] // Empty dependency array since we want this to be stable
  );

  // Cleanup the debounced function when component unmounts
  useEffect(() => {
    return () => {
      debouncedInvalidateCart.cancel();
    };
  }, [debouncedInvalidateCart]);

  const { mutateAsync: mutateCreateOrder, isLoading: createOrderIsLoading } =
    useMutation(CreateOrder,
      {
        onMutate: async (data) => {
          await queryClient.cancelQueries(['getAllCartByID', user.id]);
          // const previousCart = queryClient.getQueryData(['getAllCartByID', user.id]);
          const previousCartList = zCartList;

          // Zustand implementation
          setZCartList([
            ...zCartList.slice(0, zCartList.length - 1),
            {
              ...zCartList.slice(-1)[0],
              isPlaced: true,
              orderDetails: zCartList.slice(-1)[0].orderDetails.map(item => ({
                ...item,
                status: "Order Created"
              }))
            }
          ])
          return { previousCartList };
        },
        onSuccess: (res) => {
          // saveOrderID(res?.data?.result?._id);
          // queryClient.invalidateQueries("getAllCartByID");
          if (socket)
            socket.emit("request", {
              en: "CREATE_ORDER",
              data: {
                partnerId: res?.data?.result?.partnerId,
                tableId: res?.data?.result?.tableId,
                orderId: res?.data?.result?._id
              }
            });
          // queryClient.invalidateQueries("getAllCartByID")
          return res?.data?.result?._id
        },
        onError: async (err, variables, context) => {
          // On error, revert to previous state
          queryClient.setQueryData(
            ['getAllCartByID', user.id],
            context.previousCart
          );
          setZCartList(context.previousCartList);
        }
      }
    );

  const { mutate: mutateUpdateDinerCount } = useMutation(updateDinerCount, {
    onSuccess: async (res) => { },
  });

  const dinerCountUpdate = ({ orderId, count }) => {
    mutateUpdateDinerCount({ orderId, count })
  }

  const showDeleteOrderModel = useCallback(() => {
    return new Promise((resolve) => {
      setDeleteOrderModel(true);
      setDeleteOrderPromise(() => resolve);
    });
  }, []);

  const handleDeleteOrderConfirm = useCallback(() => {
    if (deleteOrderPromise) deleteOrderPromise(true);
    setDeleteOrderModel(false);
  }, [deleteOrderPromise]);

  const handleDeleteOrderCancel = useCallback(() => {
    if (deleteOrderPromise) deleteOrderPromise(false);
    setDeleteOrderModel(false);
  }, [deleteOrderPromise]);

  const cancelOrder = async (orderId, removeToken = true) => {
    return new Promise(async (resolve) => {
      const confirmed = await showDeleteOrderModel();
      if (confirmed) {
        // zustand implementation
        setZCartList(zCartList.slice(0, -1))
      }
    });
  }

  const OnAddCart = async (data) => {
    setCartResponseLoading(true)
    // Handle deletion confirmation first if count of an item is 0 and there is more than one item in the order
    if (data?.count === 0 && zCartList[zCartList.length - 1].orderDetails.length > 1) {
      const confirmDelete = await showDeleteOrderModel();
      if (!confirmDelete) {
        setCartResponseLoading(false);
        return;
      }
    }

    const lastOrder = zCartList.slice(-1)[0];
    if (lastOrder && lastOrder.isPlaced === false) {
      const updatedOrderDetails = zCartList.slice(-1)[0]?.orderDetails.reduce((acc, item) => {
        // Skip if the order is already placed
        if (item.isPlaced) {
          return acc;
        }

        // If this is the item being updated
        if (item.itemId === data?.itemId) {
          // Skip if count is 0 (removing item)
          if (data?.count === 0) {
            return acc;
          }
          // Update existing item
          return [...acc, {
            ...item,
            quantity: data?.count,
            customization: data?.customization,
          }];
        }
        // Keep existing item unchanged
        return [...acc, item];
      }, []);

      const itemExists = zCartList[zCartList.length - 1]?.orderDetails
        .some(item => item.itemId === data?.itemId);

      // Add new item if it doesn't exist and count > 0
      if (!itemExists && data?.count > 0) {
        updatedOrderDetails.push({
          itemId: data?.itemId,
          item_name: data?.itemName,
          oPrice: data?.oPrice,
          dPrice: data?.dPrice,
          quantity: data?.count,
          description: data?.description,
          customization: data?.customization || [],
        });
      }

      // If orderDetails is empty, remove the entire cart object
      if (updatedOrderDetails.length === 0) {
        const confirmed = await showDeleteOrderModel();
        if (confirmed) {
          // setCartList(cartList.slice(0, -1));
          // Zustand implementation
          setZCartList(zCartList.slice(0, -1))
        }
      } else {
        // Otherwise update with new orderDetails
        // zustand implementation
        setZCartList([
          ...zCartList.slice(0, zCartList.length - 1),
          {
            ...zCartList.slice(-1)[0],
            orderDetails: updatedOrderDetails
          }
        ]);
      }
    } else {
      // zustand implementation
      setZCartList([
        ...zCartList,
        {
          _id: "new_order_id",
          isPlaced: false,
          orderDetails: [{
            itemId: data?.itemId,
            item_name: data?.itemName,
            oPrice: data?.oPrice,
            dPrice: data?.dPrice,
            quantity: data?.count,
            description: data?.description,
            customization: data?.customization ? data.customization : [],
          }]
        }
      ])
    }

    if (isDrawerOpen) {
      setDrawerOpen(false)
    }
  };

  const { mutate: mutateCartItemDetails } = useMutation(getCartItemDetails, {
    onSuccess: async (res) => { },
    onError: (error) => {
      console.log(error);
    },
  });

  const onCartItemDetails = (data) => {
    mutateCartItemDetails(data)
  }

  const { mutate: mutateAddNotes, isLoading: addNotesIsLoading } = useMutation(
    noteAdd,
    {
      onSuccess: async (res) => {
        setDrawerNoteOpen(false);
        queryClient.refetchQueries("getAllCartByID");
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const OnNoteAdd = (data) => {
    let transformedData = {
      partnerId: data?.restId,
      cartId: getCartId(),
      notes: noteValue,
    };

    mutateAddNotes(transformedData);
  };

  const { mutate: mutatePersonalizeEvent } = useMutation(getPersonalizeEvent, {
    onSuccess: async (res) => { },
    onError: (error) => {
      console.log(error);
    },
  });

  const PersonalizeEvent = (data) => {
    mutatePersonalizeEvent(data);
  };

  const { mutate: mutatePlaceOrder } =
    useMutation(PlaceOrder, {
      onMutate: async (data) => {
        await queryClient.cancelQueries(['getAllCartByID', user.id]);
        const previousCart = queryClient.getQueryData(['getAllCartByID', user.id]);

        // Zustand implementation
        setZCartList(
          [
            ...zCartList.slice(0, zCartList.length - 1),
            {
              ...zCartList.slice(-1)[0],
              isPlaced: true,
              orderDetails: zCartList.slice(-1)[0].orderDetails.map(item => ({
                ...item,
                status: "Order Placed"
              }))
            }
          ]
        )
        return { previousCart }
      },
      onSuccess: async (res) => {
        setZCartList(
          [
            ...zCartList.slice(0, zCartList.length - 1),
            {
              _id: res?.data?.result?._id,
              isPlaced: res?.data?.result?.isPlaced,
              orderDetails: res?.data?.result?.orderDetails
            }
          ]
        )
      },
      onError: (error, context) => {
        queryClient.setQueryData(['getAllCartByID', user.id], context?.previousCart);
      },
    });

  const placeOrder = async ({ tableId, restId }) => {
    setPlaceOrderIsLoading(true)
    const lastOrder = zCartList.slice(-1)[0];

    const createOrderData = {
      partnerId: restId,
      dinerCount: dinerCount,
      tableId: tableId,
      orderDetails: lastOrder?.orderDetails.map(item => ({
        itemId: item.itemId,
        item_name: item.item_name,
        oPrice: item.oPrice,
        dPrice: item.dPrice,
        quantity: item.quantity,
        description: item.description,
        customization: item.customization ? item.customization.map(customGroup => ({
          ...customGroup,
          items: customGroup.items.map(({ _id, ...itemRest }) => itemRest)
        })) : [],
      }))
    };

    try {
      const response = await mutateCreateOrder(createOrderData);
      const newOrderId = response?.data?.result?._id;

      if (newOrderId) {
        await mutatePlaceOrder({
          orderId: newOrderId,
          tableId: tableId
        });
      } else {
        console.error('Failed to get order ID from create order response');
      }
    } catch (error) {
      console.error('Error in place order flow:', error);
    }
    setPlaceOrderIsLoading(false)
  };

  const { mutate: mutatePayment } = useMutation(Payment, {
    onSuccess: async (res) => {
      setOrderResult(res?.data.result);
      RemoveOrderID();
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const addPayment = () => {
    let transformedData = {
      paymentMode: "cash",
      orderId: getOrderId(),
    };

    mutatePayment(transformedData);
  };

  const { mutateAsync: mutatePayBill, isLoading: payBillIsLoading } = useMutation(payBill, {
    onSuccess: async (res) => {
      // console.log("payment successful")
    }
  })

  const PayBill = async (data, partnerId, tableId) => {
    try {
      await mutatePayBill({ tableId: tableId, data: { orderIds: data?.map(order => order?._id) } })
      // removeToken()
      // zResetAllStates()
      navigate(`/menu/bill/success/?tableId=${tableId}`)
      // await queryClient.refetchQueries("getAllDinerDetails");
      // await queryClient.resetQueries("getAllCartByID");
      // Zustand implementation
    } catch (error) {
      console.log(error)
    }
  }

  const { mutate: mutatePayInPerson, isLoading: payInPersonIsLoading, isSuccess: payInPersonIsSuccess, error: payInPersonError } = useMutation(payInPerson, {
    onSuccess: async (res) => {
      // console.log("payment successful")
    },
    onError: (err) => {
      console.log(err)
    }
  })

  const PayInPerson = async (orderArray, tableId) => {
    await mutatePayInPerson({ tableId, data: { orderIds: orderArray } })
  }

  const { mutateAsync: mutateCreatePaymentIntent, isLoading: createPaymentIntentIsLoading } = useMutation(createPaymentIntent, {
    onSuccess: async (res) => {
    },
    
  })

  const createStripePaymentIntent = async (tableId) => {
    try {
      const res = await mutateCreatePaymentIntent({ tableId })
      navigate(`/menu/bill/stripe/${res?.data?.result?.clientSecret}?tableId=${tableId}`)
      setPaymentIntentSecret(res?.data?.result?.clientSecret)
    } catch (error) {
      console.log(error)
    }
  }

  const { mutateAsync: mutateCancelPaymentIntent } = useMutation(cancelPaymentIntent, {
    onSuccess: async (res) => {

    }
  })

  const cancelStripePaymentIntent = async (clientSecret) => {
    try { 
      await mutateCancelPaymentIntent({ clientSecret })
      setPaymentIntentSecret('')
    }
    catch (error) {
      console.log(error)
    }
  }

  const { mutate: mutateRating } = useMutation(Rating, {
    onSuccess: async (res) => {
      setRatingId(res?.data?.result?._id);
      // toast.success("Order Placed Successfully");
      // queryClient.refetchQueries("getAllCartByID");
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const addRating = (data) => {
    let transformedData = {
      ...data,
      orderId: OrderId,
    };

    if (ratingId) {
      transformedData.ratingId = ratingId;
    }

    mutateRating(transformedData);
  };

  useEffect(() => {
    socket.on('orders-update', async (data) => {
      if (data?.data?.order) {
        // console.log(data?.data?.order?.dinerId, "data?.data?.order?.dinerId")
        // console.log(userId, "userId")
        if (data?.data?.order?.dinerId === userId) {
          // console.log('refetch trigger')
          queryClient.refetchQueries(['getAllCartByID', userId])
        }
      }
    })

    return () => {
      socket.off('orders-update')
    }
  }, [socket, userId])

  return {
    // DinnerDetails
    useGetDinerDetails,
    // Restaurant Info
    useGetRestaurantInfo,
    restaurantInfoHeight,
    setRestaurantInfoHeight,
    //food type
    useGetFoodType,
    // Spice Level
    useGetSpiceLevel,
    //Menu Category
    useGetFoodCategory,
    // menu Category with id
    useGetFoodMenuByCategory,
    // add menu to cart
    OnAddCart,
    // get customizations in cart
    useGetCustomizations,
    // delete order
    cancelOrder,
    // get Cart Item
    decrement,
    increment,
    count,
    setCount,
    useGetCartItem,
    toggleDrawer,
    isDrawerOpenValue,
    setDrawerOpenValue,
    isDrawerOpen,
    setDrawerOpen,
    isDrawerNoteOpen,
    toggleNoteDrawer,
    noteValue,
    setNoteValue,
    setDrawerNoteOpen,
    useGetCartCount,
    handleNoteChange,
    cartListDiff,

    // add notes
    OnNoteAdd,
    // cartList,
    // setCartList,
    // addCartMenuIsLoading,
    addNotesIsLoading,

    // place order
    placeOrder,
    placeOrderIsLoading,
    OrderModel,
    setOrderModel,
    PayBill,
    payBillIsLoading,

    useGetOrderDetails,

    // addPayment
    addPayment,
    orderResult,
    // rating
    addRating,
    hover2,
    setHover2,
    rating2,
    setRating2,
    hover,
    setHover,
    rating,
    setRating,
    suggestion,
    setSuggestion,

    // personalize

    PersonalizeEvent,

    // genie experience
    pageCount,
    setPageCount,
    countDiner,
    setCountDiner,
    dietaryPreferences,
    setDietaryPreferences,
    allergicPreferences,
    setAllergicPreferences,
    spicyPreferences,
    setSpicyPreferences,
    // dietaryPreferencesText,
    // setDietaryPreferencesText,
    allergicData,
    setAllergicData,
    useGetGenieExp,
    genieCheck,
    setGenieCheck,
    decrementKidsCount,
    incrementKidsCount,
    decrementAdultCount,
    incrementAdultCount,
    handleCheckboxChange,
    genieRecommendation,
    setGenieRecommendation,

    cartResponseLoading,
    setCartResponseLoading,
    useGetFoodItemsByQuery,
    onCartItemDetails,

    // temporary payment amount
    paymentAmount,
    setPaymentAmount,

    // delete order
    deleteOrderModel,
    setDeleteOrderModel,
    handleDeleteOrderCancel,
    handleDeleteOrderConfirm,

    createOrderIsLoading,
    PayInPerson,
    payInPersonIsLoading,
    payInPersonIsSuccess,
    payInPersonError,
    cashOrderIds,
    setCashOrderIds,
    createStripePaymentIntent,
    createPaymentIntentIsLoading,
    cancelStripePaymentIntent
  };
}

export function ProvideDiner({ children }) {
  const diner = useProvideDiner();
  return (
    <DinerContext.Provider value={diner}>{children}</DinerContext.Provider>
  );
}
