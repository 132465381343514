import React from "react";
import { Grid } from "@mui/material";
import { useAuth } from "../../../utils/hooks";
import { Controller } from "react-hook-form";
import { PageLoader } from "../../../components/loader";
import DragAndDropLogoPic from "../../../components/Dropzone/DropAndCrop";
import { CustomTextInput } from "../../../components/InputBox/CustomInput";
import MultiSelectDropdown from "../../../components/InputBox/MultiSelect.tsx";

function RestaurantDetails({ control }) {
  const {
    useGetEstablishmentType,
    // selectedEstablishment,
    // selectedType,
    // setSelectedType,
    // s3URl
  } = useAuth();

  const { data: establishmentType, isLoading: EstablishmentTypeIsLoading } = useGetEstablishmentType();

  return (
    <>
      <Grid item container>
        <Grid
          item
          sx={{
            width: "100%",
            overflowY: "scroll",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "20px",
            // height: "calc(100vh - 201px)"
          }}
        >
          <Grid item xs={12} sm={8} md={6.5} sx={{ width: "100%"}} style={{flex: "0"}}>
            <Controller
              name="name"
              control={control}
              rules={{
                required: "Restaurant Name  is Required",
              }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <CustomTextInput
                    variant="outlined"
                    label="RESTAURANT NAME"
                    required
                    {...field}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={8} md={6.5} sx={{ width: "100%" }} style={{flex: "0"}}>
            <label className=" font-[600]  block mb-2 text-[14px] text-[#757373]">
              RESTAURANT LOGO<span className="text-[#b80e0c]">*</span>
            </label>

            <Controller
              name="logo"
              control={control}
              rules={{
                required: "Logo is Required",
              }}
              render={({ field: { ref, onChange, value, ...fieldProps }, fieldState: { error } }) => {
                return (
                  <DragAndDropLogoPic
                    {...fieldProps}
                    inputRef={ref}
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    className="!border-solid !border-[1px] !rounded-[4px] !border-[#E2E8F0] !bg-[#F7F7F7] !font-poppins !text-[14px] cursor-pointer"
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={8} md={6.5} sx={{ width: "100%" }} style={{flex: "0"}}>
            <label className="font-[600]  block mb-2 text-[14px] text-[#757373]">
              ESTABLISHMENT TYPE<span className="text-[#b80e0c]">*</span>
            </label>
            {/* TODO: fix this */}
            {EstablishmentTypeIsLoading ? (
              <div className=" w-1/2 h-1/2  flex justify-center items-baseline">
                <PageLoader />
              </div>
            ) : (
              <Controller
                name="establishmentType"
                control={control}
                rules={{
                  required: "Establishment type is required",
                }}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <MultiSelectDropdown
                      name="establishmentType"
                      {...field}
                      control={control}
                      options={establishmentType?.result.map((item) => ({
                        value: item?._id,
                        label: item?.establishment_type,
                      }))}
                      placeholder="Select"
                      width="w-full"
                    />
                  );
                }}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={8} md={6.5} sx={{ width: "100%"}} style={{flex: "0"}}>
            <Controller
              name="Description"
              control={control}
              rules={{
                required: "Description  is Required",
              }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <CustomTextInput
                    variant="outlined"
                    label="DESCRIPTION"
                    required
                    {...field}
                    error={!!error}
                    helperText={error ? error.message : null}
                    multiline
                  />
                );
              }}
            />
          </Grid>
        </Grid>
      </Grid>

    </>
  );
}

export default RestaurantDetails;
