import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { getBackgroundColor, getStatusName } from '../../utils/helper';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

// const names = [
//     'Oliver Hansen',
//     'Van Henry',
//     'April Tucker',
//     'Ralph Hubbard',
//     'Omar Alexander',
//     'Carlos Abbott',
//     'Miriam Wagner',
//     'Bradley Wilkerson',
//     'Virginia Andrews',
//     'Kelly Snyder',
// ];

function getStyles(name, personName, theme) {
    return {
        fontWeight: personName.includes(name)
            ? theme.typography.fontWeightMedium
            : theme.typography.fontWeightRegular,
    };
}

const StatusDropDown = ({ names, value, type, disabled, onChange }) => {
    const theme = useTheme();
    const [personName, setPersonName] = React.useState([value]);

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
                display: disabled && "none"
            },
        },
    };

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
        onChange(typeof value === 'string' ? value.split(',') : value);
    };

    React.useEffect(()=>{
        setPersonName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    },[value])

    return (
        <div style={{ width: '100%', pointerEvents: 'auto' }}>
            <FormControl
                sx={{
                    m: 0,
                    width: '100% !important',
                    pointerEvents: disabled ? 'none' : 'auto',
                    overflow: 'visible',
                    '& .MuiSelect-select': {
                        pointerEvents: disabled ? 'none' : 'auto',
                        padding: 0,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        overflow: 'visible'
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        pointerEvents: 'none'
                    }
                }}
            >
                <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    value={personName}
                    // disabled={disabled}
                    onChange={handleChange}
                    input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                    sx={{
                        pointerEvents: disabled ? 'none' : 'auto',
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none'
                        },
                        '& .MuiSelect-icon': {  // Add this style for the dropdown icon
                            color: '#1C1B1F',
                            display: disabled && "none"
                        },
                        '& .MuiSelect-select': {
                            overflow: 'visible !important'
                        }
                    }}
                    renderValue={(selected) => (
                        <Box sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 0.5,
                            overflow: 'visible'
                        }}>
                            {selected.map((value) => (
                                <Chip key={value} label={getStatusName(value)}
                                    sx={{
                                        backgroundColor: getBackgroundColor(value || ""),
                                        border: value.toLowerCase() === "requested" ? "1px solid #FFEBEB" : "none",
                                        boxShadow: value.toLowerCase() === "requested" ? "0px 0px 8.4px 0px #B80E0CCF" : "none",
                                        fontSize: "12px",
                                        overflow: 'visible',
                                        lineHeight: "18px",
                                        fontWeight: "400",
                                        color: "white",
                                        height: "fit-content",
                                        '& .MuiChip-label': {
                                            padding: '2px 10px', // Adjust this value as needed
                                        },
                                    }}
                                />
                            ))}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                >
                    {names.map((name) => (
                        <MenuItem
                            key={name}
                            value={name}
                            style={getStyles(name, personName, theme)}
                        >
                            {getStatusName(name)}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    )
}

export default StatusDropDown
