import { Grid } from "@mui/material";
import React, { useState } from "react";
import MobileHeader from "../../components/MobileHeader";
import BasicButton from "../../components/Button";
import {
  Colors,
  StarFive,
  StarFour,
  StarOne,
  StarThree,
  StarTwo,
} from "../../utils/helper";

function Rating() {
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  return (
    <Grid container direction="column">
      <MobileHeader />

      <div
        style={{
          overflowY: "scroll",
          height: "calc(100dvh - 130px)",
        }}
      >
        <Grid item sx={{ padding: 2, width: "100dvw", height: "100%" }}>
          <div className="flex  flex-col h-full justify-center items-center ">
            <div className="flex  flex-col justify-center items-center text-textColor text-[16px] font-[400]">
              Rate your experience with{" "}
              <span className="text-textColor text-[18px] font-[500] leading-[148.86%]">
                GrubGenie!
              </span>
            </div>

            <div className="mt-5 flex">
              {[...Array(5)].map((star, index) => {
                const currentRating = index + 1;
                return (
                  <label key={index}>
                    <input
                      type="radio"
                      name="rating"
                      value={currentRating}
                      className="hidden"
                      onClick={() => setRating(currentRating)}
                    />
                    {index === 0 && (
                      <StarOne
                        currentRating={currentRating}
                        hover={hover}
                        rating={rating}
                        setHover={setHover}
                      />
                    )}
                    {index === 1 && (
                      <StarTwo
                        currentRating={currentRating}
                        hover={hover}
                        rating={rating}
                        setHover={setHover}
                      />
                    )}

                    {index === 2 && (
                      <StarThree
                        currentRating={currentRating}
                        hover={hover}
                        rating={rating}
                        setHover={setHover}
                      />
                    )}
                    {index === 3 && (
                      <StarFour
                        currentRating={currentRating}
                        hover={hover}
                        rating={rating}
                        setHover={setHover}
                      />
                    )}
                    {index === 4 && (
                      <StarFive
                        currentRating={currentRating}
                        hover={hover}
                        rating={rating}
                        setHover={setHover}
                      />
                    )}
                  </label>
                );
              })}
            </div>
          </div>
        </Grid>
      </div>

      <Grid
        item
        sx={{ padding: 2, border: ` 1px solid ${Colors.borderGrey} ` }}
      >
        <BasicButton
          sx={{
            textTransform: "none",
            width: "100%", // Full width button
          }}
        >
          Skip
        </BasicButton>
      </Grid>
    </Grid>
  );
}

export default Rating;
