import React from 'react'
import HeaderWrapper from '../components/headerwrapper/HeaderWrapper'
import { Outlet } from 'react-router-dom'

const SidebarLayout = () => {
  return (
    <HeaderWrapper>
        <Outlet />
    </HeaderWrapper>
  )
}

export default SidebarLayout