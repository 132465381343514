import React from "react";
import logo from "../../assets/images/Frame.png";
import { TextInput } from "../../components/InputBox";
import BasicButton from "../../components/Button";
import { useForm } from "react-hook-form";
import { Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/hooks";
import { Box, Grid, TextField } from "@mui/material";
import genieLogo from "../../assets/Icons/NewGenie.svg"
import grubgenieText from "../../assets/images/Isolation_Mode.svg"

export default function Resetwrapper() {
  const { onResetPassword } = useAuth();
  const [emailHasValue, setEmailHasValue] = React.useState(false);
  const [emailFocused, setEmailFocused] = React.useState(false);
  const navigate = useNavigate();
  const { handleSubmit, control } = useForm({
    mode: "all",
  });

  const makeAnimationStartHandler = (stateSetter) => (e) => {
    const autofilled = !!e.target?.matches("*:-webkit-autofill");
    if (e.animationName === "mui-auto-fill" || e.animationName === "mui-auto-fill-cancel") {
      stateSetter(autofilled);
    }
  };

  const onSubmit = (data) => {
    // TODO:Handle Submit
    onResetPassword(data)
  };

  return (
    // <div className="flex  flex-col justify-center items-center h-[100vh]">
    //   <div className=" w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow  ">
    //     <form className="" onSubmit={handleSubmit(onSubmit)}>
    //       <div className="bg-[#ED1703]  ">
    //         <img src={logo} alt="Main Logo" />
    //       </div>

    //       <div className=" flex  flex-col gap-5  p-4 sm:p-6 md:p-8 ">
    //         <div>
    //           <Controller
    //             name="email"
    //             control={control}
    //             rules={{
    //               required: "Email is Required",
    //             }}
    //             render={({ field, fieldState: { error } }) => {
    //               return (
    //                 <TextInput
    //                   {...field}
    //                   variant="outlined"
    //                   placeholder="youremail@domain.com"
    //                   label="Email"
    //                   error={!!error}
    //                   helperText={error ? error.message : null}
    //                 />
    //               );
    //             }}
    //           />
    //         </div>

    //         <BasicButton
    //           color="primary"
    //           type="submit"
    //           sx={{ textTransform: "none" }}
    //         >
    //           Reset password
    //         </BasicButton>
    //       </div>
    //     </form>
    //   </div>
    //   <div className="text-sm mt-5 font-medium text-gray-500 flex justify-center">
    //     <p
    //       className="text-genieRed hover:underline "
    //       onClick={() => {
    //         navigate("/");
    //       }}
    //     >
    //       Back to Login{" "}
    //     </p>
    //   </div>
    // </div>
    <>
      <Grid container xs={12}>
        <Grid item lg={5}
          sx={{
            display: { xs: "none", lg: "flex" },
            backgroundColor: "#B80E0C",
            height: "100vh",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "0px 20px 20px 0px"
          }}
        >
          <div className="flex flex-col items-center justify-center gap-[20px]">
            <img className="h-[225px]" src={genieLogo} alt="" />
            <img className="w-[226px]" src={grubgenieText} alt="" />
          </div>
        </Grid>
        <Grid item xs={12} sx={{ display: { xs: "flex", lg: "none" }, backgroundColor: "#b80e0c", padding: "12px", alignItems: "center", justifyContent: "center" }}>
          <img className="w-[120px]" src={grubgenieText} alt="" />
        </Grid>
        <Grid item xs={12} lg={7}
          sx={{
            height: { xs: "calc(100vh - 86.3px)", lg: "100vh" },
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Box sx={{ boxShadow: { xs: "none", lg: "0px 4px 6px 0px #86868640" } }} className={`rounded-[20px] px-[24px] py-[20px] w-[447px] flex flex-col gap-[20px]`}>
            <div className="flex flex-col gap-3">
              <h1 className="text-[40px] font-bold">Reset Password</h1>
              <p className="text-[18px] text-[#969696]">Please your email address.</p>
            </div>

            <form className="" onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-col gap-[20px]">
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: "Email is Required",
                  }}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <TextField
                        {...field}
                        inputProps={{
                          onAnimationStart: makeAnimationStartHandler(setEmailHasValue)
                        }}
                        InputLabelProps={{
                          shrink: emailHasValue || field.value || emailFocused ? true : false,
                          sx: {
                            textTransform: emailHasValue || field.value || emailFocused ? "uppercase" : "none"
                          }
                        }}
                        onFocus={() => setEmailFocused(true)}
                        onBlur={(e) => {
                          setEmailFocused(false);
                          field.onBlur(e);
                        }}
                        type="email"
                        sx={{
                          width: "100%",
                          height: "auto",
                          borderRadius: "6px",
                          borderColor: "#D9D9D9",
                          marginBottom: error ? "20px" : "0px",
                          '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': {
                              borderColor: '#757373',
                            },
                          },
                          '& .MuiInputLabel-root.Mui-focused': {
                            color: '#757373',
                            fontWeight: 600,
                            textTransform: "uppercase"
                          },
                          '&. MuiInputLabel-shrink': {
                            textTransform: "uppercase"
                          },
                          '& .MuiFormHelperText-root': {
                            position: 'absolute',
                            bottom: '-20px',
                          },
                          '& .MuiInputBase-input::placeholder': {
                            color: '#545454'
                          },
                          '& .MuiInputBase-input:-webkit-autofill': {
                            '-webkit-box-shadow': '0 0 0 100px transparent inset',
                            transition: 'background-color 5000s ease-in-out 0s'
                          }
                        }}
                        label="Email"
                        variant="outlined"
                        error={!!error}
                        helperText={error ? error.message : null}
                        onChange={(e) => {
                          field.onChange(e);
                          setEmailHasValue(!!e.target.value);
                        }}
                      />
                    );
                  }}
                />

                {/* <div className="flex items-center gap-2">
                    <Controller
                      name="keepLoggedIn"
                      control={control}
                      render={({ field }) => (
                        <CheckBox
                        
                          sx={{ color: "#000000" }}
                        />
                      )}
                    />
                    <p className="text-[16px] text-[#232323]">Keep me logged in</p>
                  </div> */}

                <div className="flex flex-col gap-3 w-full items-center">
                  <BasicButton sx={{ height: "40px", width: "100%" }} color="genieRed"
                    type="submit"
                  >
                    <span className="text-white capitalize font-medium text-base">
                      Reset Password
                    </span>
                  </BasicButton>

                  <p className="text-[#B80E0C] font-bold text-base cursor-pointer" onClick={() => navigate("/")}>Back to Login</p>
                </div>
              </div>
            </form>


          </Box>
        </Grid>

      </Grid >
    </>
  );
}
