import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import React from "react";
import TimePickerComponent from "../../../components/TimePicker";
import { Controller } from "react-hook-form";
import dayjs from "dayjs";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import plus from "../../../assets/Icons/plus.svg";
import checked_box from "../../../assets/Icons/checked_box.svg"

function TimingsDetails({ control, handleAppendSchedule, handleRemoveSchedule, timingList, watch }) {
  return (
    <Grid item container>
      {/* <Grid item sx={12}>
        <h1 className=" text-3xl mt-[20px]  mx-5">Timings Details</h1>
        <h3 className="text-sm font-medium mt-4 mx-5">Choose your Timings</h3>
      </Grid> */}

      <Grid
        item
        sx={{
          width: "100%",
          // marginX: "1.25rem",
          overflowY: "scroll",
          // marginTop: "10px",
        }}
      >
        <div className="flex flex-col md:flex-row justify-center md:gap-8">
          {/* <div
            className="flex flex-col  gap- mx-5 mt-5 
          "
          >
            {timingList?.map((days, dayIndex) => {
              const isClosed = watch(`Timing[${dayIndex}].isClosed`);
              return (
                <div className="flex gap-5">
                  <div className="mt-3">
                    <label className="font-quicksand font-[700] block mb-2 text-[14px] text-[#252525]">
                      {days.day}
                    </label>
                    <Controller
                      control={control}
                      name={`Timing[${dayIndex}].isClosed`}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              {...field}
                              inputProps={{
                                "aria-label": "controlled",
                              }}
                            />
                          }
                          label="closed"
                        />
                      )}
                    />
                  </div>
                  <div className="flex flex-col  my-2 ">
                    {days.schedule.map((timeSlot, slotIndex) => {
                      
                      return (
                        <div key={slotIndex} className="flex ml-auto  ">
                          <div>
                            <Controller
                              name={`Timing[${dayIndex}].schedule[${slotIndex}].fromTime`}
                              control={control}
                              render={({ field, fieldState: { error } }) => (
                                <TimePickerComponent
                                  {...field}
                                  label={`Opens at`}
                                  error={!!error}
                                  disabled={isClosed}
                                  helperText={error ? error.message : null}
                                  onChange={(time) => {
                                    const formattedTime =
                                      dayjs(time).format("hh:mm a");
                                    field.onChange(formattedTime);
                                  }}
                                />
                              )}
                            />
                          </div>
                          <div className="ml-2">
                            <Controller
                              name={`Timing[${dayIndex}].schedule[${slotIndex}].toTime`}
                              control={control}
                              render={({ field, fieldState: { error } }) => (
                                <TimePickerComponent
                                  {...field}
                                  label={`Closes at`}
                                  error={!!error}
                                  disabled={isClosed}
                                  helperText={error ? error.message : null}
                                  onChange={(time) => {
                                    const formattedTime =
                                      dayjs(time).format("hh:mm a");
                                    field.onChange(formattedTime);
                                  }}
                                />
                              )}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div
                    className={`flex  mt-7 mx-5 ${isClosed ? "invisible" : ""}`}
                  >
                    <AddCircleOutlineIcon
                      sx={{ marginTop: "25px" }}
                      onClick={() => handleAppendSchedule(dayIndex)}
                    />
                  </div>
                </div>
              );
            })}
          </div> */}
          <div className="pt-2">
            <label className=" font-[600]  block mb-2 text-[14px] text-[#757373]">
              WORKING HOURS<span className="text-[#b80e0c]">*</span>
            </label>
          </div>

          <div className="flex flex-col">
            {timingList?.map((days, dayIndex) => {
              const isClosed = watch(
                `Timing[${dayIndex}].isClosed`
              );

              return (
                <div key={days?.id} className="flex flex-col gap-2 pb-4 pt-2 border-b border-[#EDEDED] w-fit">
                  <label className="font-medium block text-[16px] text-[#4D4D4D]">
                    {days.day}
                  </label>
                  <div className="flex flex-col lg:flex-row lg:items-center gap-4">
                    <div className="flex flex-col gap-[12px]">
                      {days.schedule.map((timeSlot, slotIndex) => (
                        <div
                          key={slotIndex}
                          className="flex items-center gap-2"
                        >
                          <div>
                            <Controller
                              name={`Timing[${dayIndex}].schedule[${slotIndex}].fromTime`}
                              control={control}
                              render={({
                                field,
                                fieldState: { error },
                              }) => (
                                <TimePickerComponent
                                  {...field}
                                  error={!!error}
                                  value={field.value}
                                  disabled={isClosed}
                                  helperText={
                                    error ? error.message : null
                                  }
                                  onChange={(time) => {
                                    const formattedTime =
                                      dayjs(time).format("hh:mm a");
                                    field.onChange(formattedTime);
                                  }}
                                  postText="From"
                                />
                              )}
                            />
                          </div>
                          <div className="">
                            <Controller
                              name={`Timing[${dayIndex}].schedule[${slotIndex}].toTime`}
                              control={control}
                              render={({
                                field,
                                fieldState: { error },
                              }) => (
                                <TimePickerComponent
                                  {...field}
                                  error={!!error}
                                  disabled={isClosed}
                                  helperText={
                                    error ? error.message : null
                                  }
                                  onChange={(time) => {
                                    const formattedTime =
                                      dayjs(time).format("hh:mm a");
                                    field.onChange(formattedTime);
                                  }}
                                  postText="To"
                                />
                              )}
                            />
                          </div>
                          <div className="">
                            {isClosed ||
                              (days.schedule.length !== 1 && (
                                <DeleteOutlineOutlinedIcon
                                  className="text-[#7F7F7F]"
                                  onClick={() =>
                                    handleRemoveSchedule(
                                      dayIndex,
                                      slotIndex
                                    )
                                  }
                                />
                              ))}
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="flex gap-4 items-center">
                      <h1 className="flex items-center">OR</h1>
                      <div className="flex items-center">
                        <Controller
                          control={control}
                          name={`Timing[${dayIndex}].isClosed`}
                          render={({ field }) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  {...field}
                                  inputProps={{
                                    "aria-label": "controlled",
                                  }}
                                  checked={field.value}
                                  color="success"
                                  sx={{ width: "42px" }}
                                  checkedIcon={<img src={checked_box} className="w-[24px] h-[24px] shrink-0" alt="checked" />}
                                />
                              }
                              label="Closed"
                            />
                          )}
                        />
                      </div>
                      <div
                        className={` ${isClosed ? "invisible" : "flex-shrink-0"
                          }`}
                      >
                        <img
                          src={plus}
                          className="w-[25px] h-[25px] flex flex-shrink-0 items-center cursor-pointer"
                          onClick={() => handleAppendSchedule(dayIndex)}
                          alt="Add time slot"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

export default TimingsDetails;
