import React from "react";

function InlineLoader() {
  return (
    <div className="First  ">
      <div className="border-red-300 h-14 w-14 animate-spin rounded-full border-8 border-t-[#b80e0c]" />
    </div>
  );
}

export default InlineLoader;
