import React, { useState } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import zoom from "../assets/Icons/zoom.svg";
import { Box } from "@mui/material";
// const libraries = ["places"];

const MapComponent = ({ location, handleClick, handleDragEvent, customHeight = false, draggable = true }) => {
  const [, setMap] = useState(null);
  const [fullScreen, setFullScreen] = useState();
  // const handleClick = ({ lngLat: [longitude, latitude] }) => setLocation(markers => [...markers, { longitude, latitude }])

  // const centerMapOnMarker = () => {
  //   if (map) {
  //     map.panTo({
  //       lat: location?.latitude || -33.8688,
  //       lng: location?.longitude || 151.2195,
  //     });
  //     map.setZoom(13);
  //   }
  // };
  const onMapLoad = (map) => {
    setMap(map);
  };

  // if (loadError) return "Error loading maps";
  // if (!isLoaded) return "Loading Maps";
  return (
    <div style={{ position: "relative" }}>
      {/* <div
        style={{
          position: "absolute",
          top: "10px",
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: 1,
        }}
      >
        <BasicButton
          variant={"text"}
          color={"black"}
          sx={{ textTransform: "none", width: "136px", height: "36px" }}
          onClick={centerMapOnMarker}
        >
          Show Marker
        </BasicButton>
      </div> */}
      <div
        style={{
          position: "absolute",
          top: "10px",
          right: "-5px",
          // bottom: "10px",
          transform: "translateX(-50%)",
          zIndex: 1,
        }}
      >
        <Box sx={{ display: customHeight ? "none" : "" }}>
          <div className="rounded bg-white shadow p-2">
            <img src={zoom} alt="zoomIn" onClick={() => setFullScreen(!fullScreen)} />
          </div>
        </Box>
      </div>

      <GoogleMap
        mapContainerStyle={{
          width: "100%",
          // height: !fullScreen ? "423px" : "172px",
          height: customHeight ? "423px" : fullScreen ? "400px" : "172px",
          borderRadius: "8px",
        }}
        center={{
          lat: location?.latitude || 25.204849,
          lng: location?.longitude || 55.270782,
        }}
        zoom={13}
        onLoad={onMapLoad}
        options={{ disableDefaultUI: true, zoomControl: true }}
        onClick={handleClick}
      >
        <Marker
          key={0}
          position={{
            lat: location?.latitude,
            lng: location?.longitude,
          }}

          draggable={draggable}
          onDragEnd={(event) => handleDragEvent(event)}
        />
      </GoogleMap>
    </div>
  );
};
export default MapComponent;
