import React from "react";
import logo from "../../assets/images/Frame.png";
import { TextInput } from "../../components/InputBox";
import BasicButton from "../../components/Button";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAuth } from "../../utils/hooks";
import { useParams } from 'react-router-dom';
import { Box, Grid, TextField } from "@mui/material";
import genieLogo from "../../assets/Icons/NewGenie.svg"
import grubgenieText from "../../assets/images/Isolation_Mode.svg"
import eyeOn from "../../assets/Icons/eye-on.svg"
import eyeOff from "../../assets/Icons/eye-off.svg"

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>/?]).{8,}$/;
const schema = z.object({
  currentPassword: z
    .string()
    .min(8, { message: "Password must be at least 8 characters long" })
    .regex(passwordRegex, { message: "must contain A-Z ,a-z,0-9 and symbols" }),
  password: z
    .string()
    .min(8, { message: "Password must be at least 8 characters long" })
    .regex(passwordRegex, { message: "must contain A-Z ,a-z,0-9 and symbols" }),
  confirmPassword: z
    .string()
    .min(8, { message: "Password must be at least 8 characters long" })
    .regex(passwordRegex, { message: "must contain A-Z ,a-z,0-9 and symbols" }),
}).refine((data) => data.currentPassword !== data.password, {
  message: "New password cannot be same as current password",
  path: ["password"]
}
).refine((data) => data.password === data.confirmPassword, {
  message: "Passwords do not match",
  path: ["confirmPassword"],
});

export default function ResetPasswordwrapper() {
  const routeParams = useParams();
  const { token } = routeParams;
  const navigate = useNavigate();
  const { onChangePassword } = useAuth();
  const { handleSubmit, register, formState, trigger, control, getValue, watch } = useForm({
    defaultValues: {
      currentPassword: "",
      password: "",
      confirmPassword: "",
    },
    resolver: zodResolver(schema),
  });

  const [currentPasswordFocused, setCurrentPasswordFocused] = React.useState(false);
  const [currentPasswordHasValue, setCurrentPasswordHasValue] = React.useState(false);
  const [passwordFocused, setPasswordFocused] = React.useState(false);
  const [passwordHasValue, setPasswordHasValue] = React.useState(false);
  const [confirmPasswordFocused, setConfirmPasswordFocused] = React.useState(false);
  const [confirmPasswordHasValue, setConfirmPasswordHasValue] = React.useState(false);
  const [toggleIcons, setToggleIcons] = React.useState({
    currentPassword: true,
    password: true,
    confirmPassword: true
  })

  const { errors } = formState;
  const onSubmit = (data) => {

    // TODO:Handle Submit
    delete data?.confirmPassword;
    const values = {
      token: token,
      password: data?.password
    }
    onChangePassword(values)
  };

  const makeAnimationStartHandler = (stateSetter) => (e) => {
    const autofilled = !!e.target?.matches("*:-webkit-autofill");
    if (e.animationName === "mui-auto-fill" || e.animationName === "mui-auto-fill-cancel") {
      stateSetter(autofilled);
    }
  };

  return (
    <>
      <Grid container xs={12}>
        <Grid item lg={5}
          sx={{
            display: { xs: "none", lg: "flex" },
            backgroundColor: "#B80E0C",
            height: "100vh",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "0px 20px 20px 0px"
          }}
        >
          <div className="flex flex-col items-center justify-center gap-[20px]">
            <img className="h-[225px]" src={genieLogo} alt="" />
            <img className="w-[226px]" src={grubgenieText} alt="" />
          </div>
        </Grid>
        <Grid item xs={12} sx={{ display: { xs: "flex", lg: "none" }, backgroundColor: "#b80e0c", padding: "12px", alignItems: "center", justifyContent: "center" }}>
          <img className="w-[120px]" src={grubgenieText} alt="" />
        </Grid>
        <Grid item xs={12} lg={7}
          sx={{
            height: { xs: "calc(100vh - 86.3px)", lg: "100vh" },
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Box sx={{ boxShadow: { xs: "none", lg: "0px 4px 6px 0px #86868640" } }} className={`rounded-[20px] px-[24px] py-[20px] w-[447px] flex flex-col gap-[20px]`}>
            <div className="flex flex-col gap-3">
              <h1 className="text-[40px] font-bold">Reset Password</h1>
              <p className="text-[18px] text-[#969696]">Please enter your new password.</p>
            </div>

            <form className="" onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-col gap-[20px]">
                <div className="relative">
                  <Controller
                    name="currentPassword"
                    control={control}
                    rules={{
                      required: "Current Password is Required",
                    }}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <TextField
                          {...field}
                          type={toggleIcons.currentPassword ? "Password" : "text"}
                          inputProps={{
                            onAnimationStart: makeAnimationStartHandler(setCurrentPasswordHasValue)
                          }}
                          InputLabelProps={{
                            shrink: currentPasswordHasValue || field.value || currentPasswordFocused ? true : false,
                            sx: {
                              textTransform: currentPasswordHasValue || field.value || currentPasswordFocused ? "uppercase" : "none"
                            }
                          }}
                          onFocus={() => setCurrentPasswordFocused(true)}
                          onBlur={(e) => {
                            trigger("password")
                            setCurrentPasswordFocused(false);
                            field.onBlur(e);
                          }}
                          sx={{
                            width: "100%",
                            height: "auto",
                            borderRadius: "6px",
                            borderColor: "#D9D9D9",
                            '& .MuiOutlinedInput-root': {
                              '&.Mui-focused fieldset': {
                                borderColor: '#757373',
                              },
                            },
                            '& .MuiInputLabel-root.Mui-focused': {
                              color: '#757373',
                              fontWeight: 600,
                              textTransform: "uppercase"
                            },
                            '&. MuiInputLabel-shrink': {
                              textTransform: "uppercase"
                            },
                            '& .MuiInputBase-input::placeholder': {
                              color: '#545454'
                            },
                            '& .MuiInputBase-input:-webkit-autofill': {
                              '-webkit-box-shadow': '0 0 0 100px transparent inset',
                              transition: 'background-color 5000s ease-in-out 0s'
                            }
                          }}
                          label="Current Password &nbsp;&nbsp;"
                          variant="outlined"
                          error={!!error}
                          helperText={error ? error.message : null}
                          onChange={(e) => {
                            field.onChange(e);
                            setCurrentPasswordHasValue(!!e.target.value);
                          }}
                        />
                      )
                    }}
                  />
                  <img
                    className="absolute right-4 top-4 cursor-pointer"
                    src={toggleIcons.currentPassword ? eyeOn : eyeOff}
                    alt=""
                    onClick={() => {
                      setToggleIcons(prev => ({
                        ...prev,
                        currentPassword: !prev.currentPassword
                      }))
                    }}
                  />
                </div>
                <div className="relative">
                  <Controller
                    name="password"
                    control={control}
                    rules={{
                      required: "Password is Required",
                    }}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <TextField
                          {...field}
                          type={toggleIcons.password ? "Password" : "text"}
                          inputProps={{
                            onAnimationStart: makeAnimationStartHandler(setPasswordHasValue)
                          }}
                          InputLabelProps={{
                            shrink: passwordHasValue || field.value || passwordFocused ? true : false,
                            sx: {
                              textTransform: passwordHasValue || field.value || passwordFocused ? "uppercase" : "none"
                            }
                          }}
                          onFocus={() => setPasswordFocused(true)}
                          onBlur={(e) => {
                            trigger("password")
                            setPasswordFocused(false);
                            field.onBlur(e);
                          }}
                          sx={{
                            width: "100%",
                            height: "auto",
                            borderRadius: "6px",
                            borderColor: "#D9D9D9",
                            '& .MuiOutlinedInput-root': {
                              '&.Mui-focused fieldset': {
                                borderColor: '#757373',
                              },
                            },
                            '& .MuiInputLabel-root.Mui-focused': {
                              color: '#757373',
                              fontWeight: 600,
                              textTransform: "uppercase"
                            },
                            '&. MuiInputLabel-shrink': {
                              textTransform: "uppercase"
                            },
                            '& .MuiInputBase-input::placeholder': {
                              color: '#545454'
                            },
                            '& .MuiInputBase-input:-webkit-autofill': {
                              '-webkit-box-shadow': '0 0 0 100px transparent inset',
                              transition: 'background-color 5000s ease-in-out 0s'
                            }
                          }}
                          label="New Password &nbsp;&nbsp;"
                          variant="outlined"
                          error={!!error}
                          helperText={error ? error.message : null}
                          onChange={(e) => {
                            field.onChange(e);
                            setPasswordHasValue(!!e.target.value);
                          }}
                        />
                      )
                    }}

                  />
                  <img
                    className="absolute right-4 top-4 cursor-pointer"
                    src={toggleIcons.password ? eyeOn : eyeOff}
                    alt=""
                    onClick={() => {
                      setToggleIcons(prev => ({
                        ...prev,
                        password: !prev.password
                      }))
                    }}
                  />
                </div>
                <div className="relative">
                  <Controller
                    name="confirmPassword"
                    control={control}
                    rules={{
                      required: "Confirm Password is Required",
                    }}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <TextField
                          {...field}
                          type={toggleIcons.confirmPassword ? "Password" : "text"}
                          inputProps={{
                            onAnimationStart: makeAnimationStartHandler(setPasswordHasValue)
                          }}
                          InputLabelProps={{
                            shrink: confirmPasswordHasValue || field.value || confirmPasswordFocused ? true : false,
                            sx: {
                              textTransform: confirmPasswordHasValue || field.value || confirmPasswordFocused ? "uppercase" : "none"
                            }
                          }}
                          onFocus={() => setConfirmPasswordFocused(true)}
                          onBlur={(e) => {
                            trigger("confirmPassword")
                            setConfirmPasswordFocused(false);
                            field.onBlur(e);
                          }}
                          sx={{
                            width: "100%",
                            height: "auto",
                            borderRadius: "6px",
                            borderColor: "#D9D9D9",
                            '& .MuiOutlinedInput-root': {
                              '&.Mui-focused fieldset': {
                                borderColor: '#757373',
                              },
                            },
                            '& .MuiInputLabel-root.Mui-focused': {
                              color: '#757373',
                              fontWeight: 600,
                              textTransform: "uppercase"
                            },
                            '&. MuiInputLabel-shrink': {
                              textTransform: "uppercase"
                            },
                            '& .MuiInputBase-input::placeholder': {
                              color: '#545454'
                            },
                            '& .MuiInputBase-input:-webkit-autofill': {
                              '-webkit-box-shadow': '0 0 0 100px transparent inset',
                              transition: 'background-color 5000s ease-in-out 0s'
                            }
                          }}
                          label="Confirm New Password &nbsp;&nbsp;"
                          variant="outlined"
                          error={!!error}
                          helperText={error ? error.message : null}
                          onChange={(e) => {
                            field.onChange(e);
                            setConfirmPasswordHasValue(!!e.target.value);
                          }}
                        />
                      )
                    }}

                  />
                  <img
                    className="absolute right-4 top-4 cursor-pointer"
                    src={toggleIcons.confirmPassword ? eyeOn : eyeOff}
                    alt=""
                    onClick={() => {
                      setToggleIcons(prev => ({
                        ...prev,
                        confirmPassword: !prev.confirmPassword
                      }))
                    }}
                  />
                </div>

                {/* <div className="flex items-center gap-2">
                    <Controller
                      name="keepLoggedIn"
                      control={control}
                      render={({ field }) => (
                        <CheckBox
                        
                          sx={{ color: "#000000" }}
                        />
                      )}
                    />
                    <p className="text-[16px] text-[#232323]">Keep me logged in</p>
                  </div> */}

                <div className="flex flex-col gap-3 w-full items-center">
                  <BasicButton sx={{ height: "40px", width: "100%" }} color="genieRed"
                    type="submit"
                  >
                    <span className="text-white capitalize font-medium text-base">
                      Reset Password
                    </span>
                  </BasicButton>

                  <p className="text-[#B80E0C] font-bold text-base cursor-pointer"
                    onClick={() => {
                      const domain = window.location.hostname;
                      if (domain === "dev-frontend.grubgenie.ai") {
                        window.location.href = "https://portal.dev-frontend.grubgenie.ai";
                      } else if (domain === "localhost") {
                        window.location.href = "http://portal.localhost:3001";
                      } else {
                        navigate("/");
                      }
                    }}
                  >Back to Login</p>
                </div>
              </div>
            </form>


          </Box>
        </Grid>

      </Grid >
    </>
  );
}
