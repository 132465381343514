const countries = [
    { name: "Gabon", code: "GA", hasPostalCode: false, postalCodeRegex: null },
    { name: "Gambia", code: "GM", hasPostalCode: false, postalCodeRegex: null },
    { name: "Georgia", code: "GE", hasPostalCode: true, postalCodeRegex: /^[0-9]{4}$/ },
    { name: "Germany", code: "DE", hasPostalCode: true, postalCodeRegex: /^[0-9]{5}$/ },
    { name: "Ghana", code: "GH", hasPostalCode: true, postalCodeRegex: /^[0-9]{4}$/ },
    { name: "Greece", code: "GR", hasPostalCode: true, postalCodeRegex: /^[0-9]{3} ?[0-9]{2}$/ },
    { name: "Grenada", code: "GD", hasPostalCode: false, postalCodeRegex: null },
    { name: "Guatemala", code: "GT", hasPostalCode: true, postalCodeRegex: /^[0-9]{5}$/ },
    { name: "Guinea", code: "GN", hasPostalCode: false, postalCodeRegex: null },
    { name: "Guinea-Bissau", code: "GW", hasPostalCode: true, postalCodeRegex: /^[0-9]{4}$/ },
    { name: "Guyana", code: "GY", hasPostalCode: false, postalCodeRegex: null },
    { name: "Haiti", code: "HT", hasPostalCode: true, postalCodeRegex: /^[0-9]{4}$/ },
    { name: "Honduras", code: "HN", hasPostalCode: true, postalCodeRegex: /^[0-9]{5}$/ },
    { name: "Hungary", code: "HU", hasPostalCode: true, postalCodeRegex: /^[0-9]{4}$/ },
    { name: "Iceland", code: "IS", hasPostalCode: true, postalCodeRegex: /^[0-9]{3}$/ },
    { name: "India", code: "IN", hasPostalCode: true, postalCodeRegex: /^[0-9]{6}$/ },
    { name: "Indonesia", code: "ID", hasPostalCode: true, postalCodeRegex: /^[0-9]{5}$/ },
    { name: "Iran", code: "IR", hasPostalCode: true, postalCodeRegex: /^[0-9]{10}$/ },
    { name: "Iraq", code: "IQ", hasPostalCode: true, postalCodeRegex: /^[0-9]{5}$/ },
    { name: "Ireland", code: "IE", hasPostalCode: true, postalCodeRegex: /^[A-Z0-9]{3} ?[A-Z0-9]{4}$/ },
    { name: "Israel", code: "IL", hasPostalCode: true, postalCodeRegex: /^[0-9]{7}$/ },
    { name: "Italy", code: "IT", hasPostalCode: true, postalCodeRegex: /^[0-9]{5}$/ },
    { name: "Jamaica", code: "JM", hasPostalCode: true, postalCodeRegex: /^JM[A-Z]{3}[0-9]{2}$/ },
    { name: "Japan", code: "JP", hasPostalCode: true, postalCodeRegex: /^[0-9]{3}-[0-9]{4}$/ },
    { name: "Jordan", code: "JO", hasPostalCode: true, postalCodeRegex: /^[0-9]{5}$/ },
    { name: "Kazakhstan", code: "KZ", hasPostalCode: true, postalCodeRegex: /^[0-9]{6}$/ },
    { name: "Kenya", code: "KE", hasPostalCode: true, postalCodeRegex: /^[0-9]{5}$/ },
    { name: "Kiribati", code: "KI", hasPostalCode: false, postalCodeRegex: null },
    { name: "Kuwait", code: "KW", hasPostalCode: true, postalCodeRegex: /^[0-9]{5}$/ },
    { name: "Kyrgyzstan", code: "KG", hasPostalCode: true, postalCodeRegex: /^[0-9]{6}$/ },
    { name: "Laos", code: "LA", hasPostalCode: true, postalCodeRegex: /^[0-9]{5}$/ },
    { name: "Latvia", code: "LV", hasPostalCode: true, postalCodeRegex: /^LV-[0-9]{4}$/ },
    { name: "Lebanon", code: "LB", hasPostalCode: true, postalCodeRegex: /^[0-9]{4} ?[0-9]{4}$/ },
    { name: "Lesotho", code: "LS", hasPostalCode: true, postalCodeRegex: /^[0-9]{3}$/ },
    { name: "Liberia", code: "LR", hasPostalCode: true, postalCodeRegex: /^[0-9]{4}$/ },
    { name: "Libya", code: "LY", hasPostalCode: false, postalCodeRegex: null },
    { name: "Liechtenstein", code: "LI", hasPostalCode: true, postalCodeRegex: /^[0-9]{4}$/ },
    { name: "Lithuania", code: "LT", hasPostalCode: true, postalCodeRegex: /^LT-[0-9]{5}$/ },
    { name: "Luxembourg", code: "LU", hasPostalCode: true, postalCodeRegex: /^[0-9]{4}$/ },
    { name: "Madagascar", code: "MG", hasPostalCode: true, postalCodeRegex: /^[0-9]{3}$/ },
    { name: "Malawi", code: "MW", hasPostalCode: false, postalCodeRegex: null },
    { name: "Malaysia", code: "MY", hasPostalCode: true, postalCodeRegex: /^[0-9]{5}$/ },
    { name: "Maldives", code: "MV", hasPostalCode: true, postalCodeRegex: /^[0-9]{5}$/ },
    { name: "Mali", code: "ML", hasPostalCode: false, postalCodeRegex: null },
    { name: "Malta", code: "MT", hasPostalCode: true, postalCodeRegex: /^[A-Z]{3} ?[0-9]{4}$/ },
    { name: "Marshall Islands", code: "MH", hasPostalCode: true, postalCodeRegex: /^[0-9]{5}$/ },
    { name: "Mauritania", code: "MR", hasPostalCode: false, postalCodeRegex: null },
    { name: "Mauritius", code: "MU", hasPostalCode: true, postalCodeRegex: /^[0-9]{5}$/ },
    { name: "Mexico", code: "MX", hasPostalCode: true, postalCodeRegex: /^[0-9]{5}$/ },
    { name: "Moldova", code: "MD", hasPostalCode: true, postalCodeRegex: /^MD-?[0-9]{4}$/ },
    { name: "Monaco", code: "MC", hasPostalCode: true, postalCodeRegex: /^980[0-9]{2}$/ },
    { name: "Mongolia", code: "MN", hasPostalCode: true, postalCodeRegex: /^[0-9]{5}$/ },
    { name: "Montenegro", code: "ME", hasPostalCode: true, postalCodeRegex: /^[0-9]{5}$/ },
    { name: "Morocco", code: "MA", hasPostalCode: true, postalCodeRegex: /^[0-9]{5}$/ },
    { name: "Mozambique", code: "MZ", hasPostalCode: true, postalCodeRegex: /^[0-9]{4}$/ },
    { name: "Myanmar", code: "MM", hasPostalCode: true, postalCodeRegex: /^[0-9]{5}$/ },
    { name: "Namibia", code: "NA", hasPostalCode: true, postalCodeRegex: /^[0-9]{5}$/ },
    { name: "Nauru", code: "NR", hasPostalCode: false, postalCodeRegex: null },
    { name: "Nepal", code: "NP", hasPostalCode: true, postalCodeRegex: /^[0-9]{5}$/ },
    { name: "Netherlands", code: "NL", hasPostalCode: true, postalCodeRegex: /^[0-9]{4} ?[A-Z]{2}$/ },
    { name: "New Zealand", code: "NZ", hasPostalCode: true, postalCodeRegex: /^[0-9]{4}$/ },
    { name: "Nicaragua", code: "NI", hasPostalCode: true, postalCodeRegex: /^[0-9]{5}$/ },
    { name: "Niger", code: "NE", hasPostalCode: false, postalCodeRegex: null },
    { name: "Nigeria", code: "NG", hasPostalCode: true, postalCodeRegex: /^[0-9]{6}$/ },
    { name: "North Macedonia", code: "MK", hasPostalCode: true, postalCodeRegex: /^[0-9]{4}$/ },
    { name: "Norway", code: "NO", hasPostalCode: true, postalCodeRegex: /^[0-9]{4}$/ },
    { name: "Oman", code: "OM", hasPostalCode: true, postalCodeRegex: /^[0-9]{3}$/ },
    { name: "Pakistan", code: "PK", hasPostalCode: true, postalCodeRegex: /^[0-9]{5}$/ },
    { name: "Palau", code: "PW", hasPostalCode: true, postalCodeRegex: /^[0-9]{5}$/ },
    { name: "Panama", code: "PA", hasPostalCode: true, postalCodeRegex: /^[0-9]{4}$/ },
    { name: "Papua New Guinea", code: "PG", hasPostalCode: true, postalCodeRegex: /^[0-9]{3}$/ },
    { name: "Paraguay", code: "PY", hasPostalCode: true, postalCodeRegex: /^[0-9]{4}$/ },
    { name: "Peru", code: "PE", hasPostalCode: true, postalCodeRegex: /^[0-9]{5}$/ },
    { name: "Philippines", code: "PH", hasPostalCode: true, postalCodeRegex: /^[0-9]{4}$/ },
    { name: "Poland", code: "PL", hasPostalCode: true, postalCodeRegex: /^[0-9]{2}-[0-9]{3}$/ },
    { name: "Portugal", code: "PT", hasPostalCode: true, postalCodeRegex: /^[0-9]{4}-[0-9]{3}$/ },
    { name: "Qatar", code: "QA", hasPostalCode: false, postalCodeRegex: null },
    { name: "Romania", code: "RO", hasPostalCode: true, postalCodeRegex: /^[0-9]{6}$/ },
    { name: "Russia", code: "RU", hasPostalCode: true, postalCodeRegex: /^[0-9]{6}$/ },
    { name: "Rwanda", code: "RW", hasPostalCode: false, postalCodeRegex: null },
    { name: "Saint Kitts and Nevis", code: "KN", hasPostalCode: false, postalCodeRegex: null },
    { name: "Saint Lucia", code: "LC", hasPostalCode: false, postalCodeRegex: null },
    { name: "Saint Vincent and the Grenadines", code: "VC", hasPostalCode: false, postalCodeRegex: null },
    { name: "Samoa", code: "WS", hasPostalCode: false, postalCodeRegex: null },
    { name: "San Marino", code: "SM", hasPostalCode: true, postalCodeRegex: /^[0-9]{5}$/ },
    { name: "Sao Tome and Principe", code: "ST", hasPostalCode: false, postalCodeRegex: null },
    { name: "Saudi Arabia", code: "SA", hasPostalCode: true, postalCodeRegex: /^[0-9]{5}$/ },
    { name: "Senegal", code: "SN", hasPostalCode: true, postalCodeRegex: /^[0-9]{5}$/ },
    { name: "Serbia", code: "RS", hasPostalCode: true, postalCodeRegex: /^[0-9]{5}$/ },
    { name: "Seychelles", code: "SC", hasPostalCode: false, postalCodeRegex: null },
    { name: "Sierra Leone", code: "SL", hasPostalCode: false, postalCodeRegex: null },
    { name: "Singapore", code: "SG", hasPostalCode: true, postalCodeRegex: /^[0-9]{6}$/ },
    { name: "Slovakia", code: "SK", hasPostalCode: true, postalCodeRegex: /^[0-9]{3} ?[0-9]{2}$/ },
    { name: "Slovenia", code: "SI", hasPostalCode: true, postalCodeRegex: /^[0-9]{4}$/ },
    { name: "Solomon Islands", code: "SB", hasPostalCode: false, postalCodeRegex: null },
    { name: "Somalia", code: "SO", hasPostalCode: false, postalCodeRegex: null },
    { name: "South Africa", code: "ZA", hasPostalCode: true, postalCodeRegex: /^[0-9]{4}$/ },
    { name: "South Korea", code: "KR", hasPostalCode: true, postalCodeRegex: /^[0-9]{5}$/ },
    { name: "South Sudan", code: "SS", hasPostalCode: false, postalCodeRegex: null },
    { name: "Spain", code: "ES", hasPostalCode: true, postalCodeRegex: /^[0-9]{5}$/ },
    { name: "Sri Lanka", code: "LK", hasPostalCode: true, postalCodeRegex: /^[0-9]{5}$/ },
    { name: "Sudan", code: "SD", hasPostalCode: false, postalCodeRegex: null },
    { name: "Suriname", code: "SR", hasPostalCode: false, postalCodeRegex: null },
    { name: "Sweden", code: "SE", hasPostalCode: true, postalCodeRegex: /^[0-9]{3} ?[0-9]{2}$/ },
    { name: "Switzerland", code: "CH", hasPostalCode: true, postalCodeRegex: /^[0-9]{4}$/ },
    { name: "Syria", code: "SY", hasPostalCode: false, postalCodeRegex: null },
    { name: "Taiwan", code: "TW", hasPostalCode: true, postalCodeRegex: /^[0-9]{3}(?:[0-9]{2})?$/ },
    { name: "Tajikistan", code: "TJ", hasPostalCode: true, postalCodeRegex: /^[0-9]{6}$/ },
    { name: "Tanzania", code: "TZ", hasPostalCode: true, postalCodeRegex: /^[0-9]{5}$/ },
    { name: "Thailand", code: "TH", hasPostalCode: true, postalCodeRegex: /^[0-9]{5}$/ },
    { name: "Timor-Leste", code: "TL", hasPostalCode: true, postalCodeRegex: /^[0-9]{5}$/ },
    { name: "Togo", code: "TG", hasPostalCode: false, postalCodeRegex: null },
    { name: "Tonga", code: "TO", hasPostalCode: false, postalCodeRegex: null },
    { name: "Trinidad and Tobago", code: "TT", hasPostalCode: true, postalCodeRegex: /^[0-9]{6}$/ },
    { name: "Tunisia", code: "TN", hasPostalCode: true, postalCodeRegex: /^[0-9]{4}$/ },
    { name: "Turkey", code: "TR", hasPostalCode: true, postalCodeRegex: /^[0-9]{5}$/ },
    { name: "Turkmenistan", code: "TM", hasPostalCode: true, postalCodeRegex: /^[0-9]{6}$/ },
    { name: "Tuvalu", code: "TV", hasPostalCode: false, postalCodeRegex: null },
    { name: "Uganda", code: "UG", hasPostalCode: false, postalCodeRegex: null },
    { name: "Ukraine", code: "UA", hasPostalCode: true, postalCodeRegex: /^[0-9]{5}$/ },
    { name: "United Arab Emirates", code: "AE", hasPostalCode: false, postalCodeRegex: null },
    { name: "United Kingdom", code: "GB", hasPostalCode: true, postalCodeRegex: /^[A-Z]{1,2}[0-9]{1,2}[A-Z]? ?[0-9][A-Z]{2}$/ },
    { name: "United States", code: "US", hasPostalCode: true, postalCodeRegex: /^[0-9]{5}(-[0-9]{4})?$/ },
    { name: "Uruguay", code: "UY", hasPostalCode: true, postalCodeRegex: /^[0-9]{5}$/ },
    { name: "Uzbekistan", code: "UZ", hasPostalCode: true, postalCodeRegex: /^[0-9]{6}$/ },
    { name: "Vanuatu", code: "VU", hasPostalCode: false, postalCodeRegex: null },
    { name: "Vatican City", code: "VA", hasPostalCode: true, postalCodeRegex: /^00120$/ },
    { name: "Venezuela", code: "VE", hasPostalCode: true, postalCodeRegex: /^[0-9]{4}$/ },
    { name: "Vietnam", code: "VN", hasPostalCode: true, postalCodeRegex: /^[0-9]{5}$/ },
    { name: "Yemen", code: "YE", hasPostalCode: false, postalCodeRegex: null },
    { name: "Zambia", code: "ZM", hasPostalCode: true, postalCodeRegex: /^[0-9]{5}$/ },
    { name: "Zimbabwe", code: "ZW", hasPostalCode: true, postalCodeRegex: /^[0-9]{4}$/ }
];

function postalCodeVerification(countryName, postalCode) {
    const country = countries.find(c => c.name?.toLocaleLowerCase() === countryName?.toLocaleLowerCase());
    if (!country) return false;
    if (!country.hasPostalCode) return false;
    return country.postalCodeRegex.test(postalCode);
}

function getCountryCode(countryName) {
    const country = countries.find(c => c.name?.toLocaleLowerCase() === countryName?.toLocaleLowerCase());
    return country ? country.code : null;
}

function checkCountryHasPostalCode(countryName) {
    const country = countries.find(c => c.name?.toLocaleLowerCase() === countryName?.toLocaleLowerCase());
    return country ? country.hasPostalCode : false;
}

export {
    postalCodeVerification,
    getCountryCode,
    checkCountryHasPostalCode
};