import { ProvideAuth } from "./auth";
import { ProvideDelivery } from "./Delivery";
import { ProvideDiner } from "./Dinner";
import { ProvideRestaurant } from "./Restaurant";
import { ProvideSuperAdmin } from "./SuperAdmin";

function ContextContainer({ children }) {
  return (
    <ProvideAuth>
      <ProvideSuperAdmin>
        <ProvideRestaurant>
          <ProvideDelivery>
            <ProvideDiner>{children}</ProvideDiner>
          </ProvideDelivery>
        </ProvideRestaurant>
      </ProvideSuperAdmin>
    </ProvideAuth>
  );
}

export default ContextContainer;
