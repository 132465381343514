import { Badge, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Isolation_Mode from "../../../assets/images/Isolation_Mode.svg";
import cashPayment from "../../../assets/images/cashPayment.svg";
import cart from "../../../assets/Icons/Cart.svg";
import backArrow from "../../../assets/Icons/backArrow.svg";
import { useAuth, useDiner } from '../../../utils/hooks';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useToken from '../../../utils/hooks/useToken';
import { useQueryClient } from 'react-query';
import { useDinerStore } from '../../../stores';
import { useShallow } from 'zustand/shallow';
import BasicButton from '../../../components/Button';

const CashPayment = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const restaurantId = useParams();
  const location = useLocation();
  const [retry, setRetry] = useState(0)
  const {
    useGetCartItem,
    PayInPerson,
    payInPersonIsLoading,
    payInPersonIsSuccess,
    payInPersonError,
    cashOrderIds,
    setCashOrderIds
  } = useDiner();
  const {
    getCartCount,
    resetAllStates,
    user,
    cartList,
  } = useDinerStore(useShallow(state => ({
    getCartCount: state.getCartCount,
    resetAllStates: state.resetAllStates,
    user: state.user,
    cartList: state.cartList
  })))
  const { removeToken } = useToken();
  const { socket } = useAuth()

  const tableId = new URLSearchParams(location.search).get("tableId");

  const { isLoading: cartDataIsLoading } = useGetCartItem({
    // cartID: getCartId(),
    // partnerId: id,
    dinerId: user.id,
  });

  useEffect(() => {
    if (!cartDataIsLoading && cartList.length > 0) {
      PayInPerson(cartList.map(item => item._id), tableId)
    }
  }, [cartDataIsLoading, retry])


  useEffect(() => {
    const handleOrderPayRequest = (data) => {
      const isValidRequest = data?.partnerId === restaurantId?.id && !data.data.orders;
      if (!isValidRequest) return;

      const orderUpdate = {
        orderId: data?.data?.order?._id,
        complete: data?.data?.order?.payment_status === "done"
      };

      setCashOrderIds(prev => {
        const filteredOrders = prev.filter(order => order?.orderId !== orderUpdate.orderId);
        return [...filteredOrders, orderUpdate];
      });
    };

    socket.on("order-pay-request", handleOrderPayRequest);

    return () => {
      socket.off("order-pay-request");
    };
  }, [socket, restaurantId?.id]);

  const completePayment = async () => {
    // removeToken()
    // // setCartList([])
    // resetAllStates()
    // await queryClient.refetchQueries("getAllDinerDetails");
    // await queryClient.resetQueries("getAllCartByID");
    navigate(`/menu/bill/success/?tableId=${tableId}`)
  }

  useEffect(() => {
    if (cashOrderIds?.every(order => order.complete === true)) {
      completePayment()
    }
  }, [cashOrderIds])

  return (
    <>
      <Grid container direction="column">
        <Grid
          item
          sx={12}
          className=" bg-[#b80e0c]  flex justify-between items-end  px-[1.25rem] py-[0.75rem]"
        >
          <img
            src={Isolation_Mode}
            alt=""
            srcSet=""
            className=""
            height={"36px"}
          />
          {/* TODO: Add cart count functionality */}
          <Badge badgeContent={getCartCount()}
            onClick={() => navigate(`/menu/cart/?tableId=${tableId}`)}
            sx={{ cursor: "pointer", "& .MuiBadge-badge": { backgroundColor: "white", color: "#B80E0C", fontWeight: 600, position: "absolute", top: "3px", right: "4px" } }}
          >
            <img src={cart} alt="cart" />
          </Badge>
        </Grid>
        <Grid
          container
          item
          xs={12}
          direction="row"
          gap="12px"
          alignItems="center"
          className="p-4"
        >
          <img className="h-[20px] w-[20px] cursor-pointer" src={backArrow} alt=""
            // onClick={() => (handleDiff(), navigate(-1))}
            onClick={() =>
              navigate(`/menu/bill?tableId=${tableId}`)
            }
          />
          <h1 className="  font-[500] text-[14px]">
            {/* Your Order */}
            Cash Payment
          </h1>
        </Grid>

        {payInPersonIsLoading ?
          <div className="flex flex-col justify-center items-center h-[calc(100vh-60px)]">
            <div className="h-14 w-14 border-4 border-gray-300 border-t-red-600 rounded-full animate-spin mb-4" />
            <p className="text-gray-600">
              Processing...
            </p>
          </div>
          :
          payInPersonIsSuccess ?
            <div className='px-4 w-full h-[calc(100vh-113px)] flex flex-col items-center gap-[39px] justify-center'>
              <img src={cashPayment} className='w-[160px]' alt="" />
              <p className='font-medium text-[18px]'>Waiter on the way</p>
            </div>
            :
            <div className="flex flex-col justify-center gap-2 items-center h-[calc(100vh-113px)]">
              <h1 className='text-[#b80e0c] text-5xl font-bold'>!</h1>
              <p className="text-gray-600">
                There was an error, Please try again.
              </p>
              <BasicButton color={"genieRed"} sx={{ color: "#fff" }} onClick={() => setRetry(prev => prev + 1)}>
                Retry
              </BasicButton>
            </div>
        }



      </Grid>
    </>
  )
}

export default CashPayment