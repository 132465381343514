import React from "react";
import BasicButton from "../../components/Button";
import MobileHeader from "../../components/MobileHeader";
import { Grid } from "@mui/material";

import MapComponent from "../../Map";
import { useRestaurant } from "../../utils/hooks";
import Pointer from "../../assets/Icons/MapPointer.svg";
import StopWatch from "../../assets/Icons/StopWatch.svg";
import Profile from "../../assets/Icons/Profile.svg";
import Phone from "../../assets/Icons/Phone.svg";
import book from "../../assets/Icons/book.svg";
import { Colors } from "../../utils/helper";

function RiderPage() {
  const { setLocation, location } = useRestaurant();
  const handleDragEvent = (event) => {
    const latitude = event.latLng.lat();
    const longitude = event.latLng.lng();
    setLocation({ latitude, longitude });
  };

  // const steps = [
  //   "Order Confirmed",
  //   "Order is being prepared",
  //   "Ready to picked up by rider",
  //   "Rider on the way",
  //   "Delivery Completed",
  // ];

  return (
    <Grid container direction="column">
      <MobileHeader />

      <div
        style={{
          overflowY: "scroll",
          height: "calc(100dvh - 130px)",
        }}
      >
        <Grid container direction="column" paddingX={2} paddingY={1} gap={1}>
          <Grid
            item
            sx={{ display: "flex", alignItems: "center", marginY: "5px" }}
          >
            <span className=" text-lightGrey text-[12px] font-[500]">
              ORDER
            </span>
            <h1 className=" text-textColor text-[18px] font-[500] ml-2">
              #aa01255aa
            </h1>
          </Grid>

          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <MapComponent
              location={location}
              handleDragEvent={handleDragEvent}
            />
          </Grid>

          <Grid
            container
            item
            direction="column"
            gap={1}
            sx={{
              backgroundColor: Colors?.lightblue,
              padding: 1,
              border: `1px solid ${Colors?.borderGrey}`,
              borderRadius: "8px",
            }}
          >
            <Grid
              item
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <div className="flex gap-2">
                <img src={Pointer} alt="Pointer Icon" width={20} height={20} />
                <h1 className="text-[#3E3E3E] font-[400]">Delivery distance</h1>
              </div>
              <h1 className="text-[16px] font-[600] text-textColor">4km</h1>
            </Grid>

            <Grid
              item
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <div className="flex gap-2">
                <img
                  src={StopWatch}
                  alt="StopWatch Icon"
                  width={20}
                  height={20}
                />
                <h1 className="text-[#3E3E3E] font-[400]">
                  Estimated delivery time
                </h1>
              </div>
              <h1 className="text-[16px] font-[600] text-textColor">14 min</h1>
            </Grid>
          </Grid>

          <Grid
            container
            item
            gap={2}
            sx={{
              padding: "10px",
              border: "1px solid #EDEDED",
              borderRadius: "8px",
            }}
          >
            <Grid item xs={2}>
              <div className="rounded bg-white shadow">
                <img
                  src={Profile}
                  alt="Profile"
                  width={"100%"}
                  height={"37px"}
                  className="p-1"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="">
                <h1 className="text-[16px] font-[400] text-textColor">
                  John Doe
                </h1>
                <h1 className="text-[10px]  font-[400] text-[#757373] ">
                  ------------------------------------------------------------------------------
                </h1>
              </div>
            </Grid>
            {/* </div> */}
            <Grid item xs={2}>
              <div className=" my-3 border-l-2 ">
                <img
                  src={Phone}
                  alt="Phone Icon"
                  width={40}
                  height={37}
                  className=" ml-7 p-2"
                />
              </div>
            </Grid>
          </Grid>

          <Grid
            container
            item
            direction="column"
            gap={1}
            sx={{
              padding: "10px",
              border: "1px solid #EDEDED",
              borderRadius: "8px",
            }}
          >
            <Grid item sx={{ display: "flex" }}>
              <h1 className="text-[#636363] text-[12px] font-[400]">
                Delivery instructions
              </h1>
            </Grid>
            <Grid item sx={{ display: "flex" }}>
              <h1 className="text-textColor text-[14px] font-[400]">
                Lorem ipsum dolor sit amet consectetur. Tempus elementum a leo
                sit tellus lectus.
              </h1>
            </Grid>
          </Grid>

          <Grid
            container
            item
            direction="column"
            gap={1}
            sx={{
              padding: "10px",
              border: "1px solid #EDEDED",
              borderRadius: "8px",
              backgroundColor: Colors.beige,
            }}
          >
            <Grid item sx={{ display: "flex" }}>
              <h1 className="text-[#636363] text-[12px] font-[400]">
                Order details
              </h1>
            </Grid>

            <Grid
              item
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <h1 className="text-textColor text-[14px] not-italic font-[400]">
                order 1
              </h1>
              <h1 className="text-textColor text-[14px] font-[400]">*1</h1>
            </Grid>
            <Grid
              item
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <h1 className="text-textColor text-[14px] not-italic font-[400]">
                order 1
              </h1>
              <h1 className="text-textColor text-[14px] font-[400]">*1</h1>
            </Grid>
            <Grid
              item
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <h1 className="text-textColor text-[14px] not-italic font-[400]">
                order 1
              </h1>
              <h1 className="text-textColor text-[14px] font-[400]">*1</h1>
            </Grid>


            <Grid item sx={{ display: "flex" }}>
              --------------------------------
            </Grid>
            <Grid item sx={{ display: "flex" }}>
              <img src={book} alt="book" />
            </Grid>
          </Grid>
          <Grid item sx={{ display: "flex" }}>
            <BasicButton
              variant="outlined"
              sx={{
                textTransform: "none",
                width: "100%", // Full width button
              }}
            >
              Report an issue
            </BasicButton>
          </Grid>
        </Grid>
      </div>

      <Grid
        item
        sx={{ padding: 2, border: ` 1px solid ${Colors.borderGrey} ` }}
      >
        <BasicButton
          sx={{
            textTransform: "none",
            width: "100%", // Full width button
          }}
        >
          Start Delivery
        </BasicButton>
      </Grid>
    </Grid>
  );
}

export default RiderPage;
