import React, { useEffect, useState } from 'react'
import { PageLoader } from '../../../components/loader'
import { Grid, Skeleton } from '@mui/material'
import Table from '../../../components/CustomTable/TableHeader'
import OrdRow from '../../../components/CustomTable/TableManagementRow'
import BasicButton from '../../../components/Button'
import receipt_bg from '../../../assets/Icons/receipt_bg.svg'
import { useRestaurant } from '../../../utils/hooks'
import QrCodeModal from './QrCodeModal'

const TableSection = ({ dineManagementSummary, tableSummary }) => {
    const [qrCodeOpen, setQrCodeOpen] = useState(false);
    const [tableLink, setTableLink] = useState("");
    const {
        useGetTable,
        // responseTableData,
        // setResponseTableData,
        // qrScanned,
        // setQrSCanned,
        // isTableDrawer,
        // setIsTableDrawer,
        // OnAddTable,
        // addTableIsLoading,
    } = useRestaurant();

    const { data: tableData, isLoading: tableLoading } = useGetTable();

    const headers = [
        "Table No.",
        "Table Name",
        "No. of seats",
        "QR Code",
        "Current Order Status",
    ];

    const handleQrCode = (link) => {
        setQrCodeOpen(true)
        setTableLink(link)
    }

    return (
        <>
            <div className="overflow-auto">
                <Grid container>
                    <div className='w-full px-8 mb-[21px] grid grid-cols-3 tablet:grid-cols-5 gap-2'>
                        <div className="bg-[#FCFAE9] border border-[#F4F1D7] flex flex-col gap-2 rounded p-5">
                            {tableData?.result ?
                                <>
                                    <h1 className="font-normal text-xs">Total Tables</h1>
                                    <h1 className="font-semibold text-2xl ">{tableData?.result?.length || 0}</h1>
                                </> :
                                <>
                                    <Skeleton sx={{ fontSize: "12px", fontWeight: "400" }} />
                                    <Skeleton sx={{ fontSize: "24px", fontWeight: "600" }} width={"50%"} />
                                </>
                            }
                        </div>
                        <div className="bg-[#FCFAE9] border border-[#F4F1D7] flex flex-col gap-2 rounded p-5">
                            {tableData?.result ?
                                <>
                                    <h1 className="font-normal text-xs">Tables Occupied</h1>
                                    <h1 className="font-semibold text-2xl ">{tableData?.result?.reduce((acc, item) => item?.status !== "available" ? acc + 1 : acc, 0) || 0}</h1>
                                </> :
                                <>
                                    <Skeleton sx={{ fontSize: "12px", fontWeight: "400" }} />
                                    <Skeleton sx={{ fontSize: "24px", fontWeight: "600" }} width={"50%"} />
                                </>
                            }
                        </div>
                        <div className="bg-[#FCFAE9] border border-[#F4F1D7] flex flex-col gap-2 rounded p-5">
                            {tableData?.result ?
                                <>
                                    <h1 className="font-normal text-xs">Tables Available</h1>
                                    <h1 className="font-semibold text-2xl ">{tableData?.result?.reduce((acc, item) => item?.status === "available" ? acc + 1 : acc, 0) || 0}</h1>
                                </> :
                                <>
                                    <Skeleton sx={{ fontSize: "12px", fontWeight: "400" }} />
                                    <Skeleton sx={{ fontSize: "24px", fontWeight: "600" }} width={"50%"} />
                                </>
                            }
                        </div>
                        <div className='hidden tablet:block'></div>
                        <div className="bg-[#E9FCF2] border border-[#D0EDDD] flex flex-col gap-2 rounded p-5">
                            {tableSummary?.total_orders !== undefined ?
                                <>
                                    <h1 className="font-normal text-xs">Total Orders</h1>
                                    <h1 className="font-semibold text-2xl ">{tableSummary?.total_orders || 0}</h1>
                                </> :
                                <>
                                    <Skeleton sx={{ fontSize: "12px", fontWeight: "400" }} />
                                    <Skeleton sx={{ fontSize: "24px", fontWeight: "600" }} width={"50%"} />
                                </>
                            }
                        </div>
                    </div>

                    <Grid container item >
                        <Grid
                            item
                            xs={12}
                            md={12}
                            sx={{ padding: `0px 32px` }}
                        >
                            <Table headers={headers}>
                                {tableLoading ?
                                    Array.from({ length: 8 }).map((_, index) => (
                                        <tr key={index} className={`${index!==7 && "border-b"}`}>
                                            <td className='px-3 py-3 border-r'>
                                                <Skeleton width={"25%"} />
                                            </td>
                                            <td className='px-3 py-3 border-r'>
                                                <Skeleton width={"45%"} />
                                            </td>
                                            <td className='px-3 py-3 border-r'>
                                                <Skeleton width={"25%"} />
                                            </td>
                                            <td className='px-3 py-3 border-r'>
                                                <Skeleton />
                                            </td>
                                            <td className='px-3 py-3 border-r'>
                                                <Skeleton width={"50%"} />
                                            </td>
                                        </tr>
                                    ))
                                    :
                                    tableData?.result?.length === 0 ?
                                        <tr>
                                            <td colSpan={headers.length}>
                                                <div className="flex flex-col justify-center items-center w-full py-3">
                                                    <p className="text-gray-600">No tables found</p>
                                                </div>
                                            </td>
                                        </tr>
                                        :
                                        tableData?.result?.map((ord, index) => (
                                            <OrdRow key={ord.id} ord={ord} index={index} handleQrCode={handleQrCode} />
                                        ))
                                }
                            </Table>
                        </Grid>
                    </Grid>
                </Grid>
            </div>

            {qrCodeOpen && (
                //TODO:Add Table Number in model and jpg
                <QrCodeModal
                    open={qrCodeOpen}
                    handleCancel={() => setQrCodeOpen(false)}
                    link={tableLink}
                    tableNumber={tableLink.split("=")[1]}
                // handleConfirmClick={() => ( navigate(-1))}
                />
            )}
        </>
    )
}

export default TableSection