import React, { useRef, useEffect, useState } from "react";
import ButtonWithIcon from "../../../components/ButtonWithIcon";
import pen from "../../../assets/Icons/pen.svg";
import asteriskWithBg from "../../../assets/Icons/asteriskWithBg.svg";

const WatermarkImage = ({ src, watermarkText, setValue, handleRegenerate }) => {
  const canvasRef = useRef(null);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const img = new Image();

    img.onload = () => {
      const maxWidth = 200;
      const maxHeight = 200;

      const widthRatio = maxWidth / img.width;
      const heightRatio = maxHeight / img.height;
      const ratio = Math.min(widthRatio, heightRatio);

      const targetWidth = img.width * ratio;
      const targetHeight = img.height * ratio;

      canvas.width = maxWidth;
      canvas.height = maxHeight;

      // Center the image
      const xOffset = (maxWidth - targetWidth) / 2;
      const yOffset = (maxHeight - targetHeight) / 2;

      ctx.drawImage(img, xOffset, yOffset, targetWidth, targetHeight);

      // Set watermark style
      ctx.font = "16px serif";
      ctx.fillStyle = "rgba(255,255,255, 0.9)";
      ctx.textAlign = "right";
      ctx.textBaseline = "bottom";

      const padding = 10;
      ctx.fillText(watermarkText, (maxWidth/2)+padding, maxHeight - padding);

      setImageLoaded(true);
    };

    img.src = src;
  }, [src, watermarkText]);

  const handleDelete = () => {
    setValue("image", {
      url: "",
      AiGenerated: false,
    });
  };

  return (
    <div>
      <div className="flex items-center justify-between max-w-[28rem] m-auto lg:m-0 gap-[10px]">
        <div className="w-262 h-47 cursor-pointer mb-2">
          <canvas
            ref={canvasRef}
            style={{
              display: imageLoaded ? "block" : "none",
              width: "8.75rem",
              height: "8.75rem",
              objectFit: "cover",
            }}
            className="rounded-full"
          />
        </div>
        {imageLoaded && <div className="flex flex-1 justify-evenly flex-col lg:flex-row ml-4 lg:ml-0 gap-[15px]">
          {/* <div
            // onClick={handleDelete}
            className="avatarOptions cursor-pointer text-genieRed m-4"
          >
            <p className="text-button delete">Like</p>
          </div>
          <div
            // onClick={handleDelete}
            className="avatarOptions cursor-pointer text-genieRed m-4"
          >
            <p className="text-button delete">Dislike</p>
          </div> */}

          {/* <div
            onClick={handleRegenerate}
            className="avatarOptions cursor-pointer text-genieRed m-4"
          >
            <p className="text-button delete">Gen AI Image</p>
          </div> */}
          <ButtonWithIcon onClick={handleDelete} icon={pen} text="Replace Image" />
          {/* <div
            onClick={handleDelete}
            className="avatarOptions cursor-pointer text-genieRed m-4"
          >
            <p className="text-button delete">Replace Image</p>
          </div> */}
          <ButtonWithIcon
            onClick={handleRegenerate}
            icon={asteriskWithBg}
            text="Gen AI Image"
          />
        </div>}
      </div>
      {!imageLoaded && <p className="text-center">Loading...</p>}
    </div>
  );
};

export default WatermarkImage;
