import React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const StyledButton = styled(Button)(({ theme, disabled }) => ({
  borderRadius: "42px",
  padding: "9px, 16px, 9px, 16px",
  fontWeight: "500",
  '&.Mui-disabled': {
    backgroundColor: "#F8A29A",
    color: theme.palette.secondary.main,
  },
}));

const theme = createTheme({
  palette: {
    primary: {
      main: "#ED1703",
    },
    secondary: {
      main: "#ffffff",
    },
    grey: {
      main: "#777777",
    },
    black: {
      main:"#000000"
    },
    genieRed: {
      main:"#B80E0C"
    },
    lightGray: {
      main: "#ebebeb"
    }
   
  },
});

function BasicButton({
  children,
  variant = "contained",
  color,
  type,
  onClick,
  sx,
  form,
  disabled,
  className,
  ...rest
}) {
  return (
    <ThemeProvider theme={theme}>
      <StyledButton
        {...rest}
        type={type}
        variant={variant}
        color={color}
        onClick={onClick}
        sx={{
          ...(variant === 'outlined' && {
            borderColor:"#B80E0C", 
            color: theme.palette.black.main,
            background:'white'
          }),
          ...(disabled && {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.secondary.main,
          }),
          ...sx,
        }}
        form={form}
        disabled={disabled}
      >
        {children}
      </StyledButton>
    </ThemeProvider>
  );
}
export default BasicButton;
