import React, { useEffect } from "react";
import Logo from "../../assets/images/Isolation_Mode.svg";
import { Link, useLocation } from "react-router-dom";
import delivery from "../../assets/Icons/delivery.svg";
import Dining from "../../assets/Icons/dining.svg";
import PickUp from "../../assets/Icons/concierge.svg";
import menuManagementIcon from "../../assets/Icons/menuManagementIcon.svg"
import insights from "../../assets/Icons/insights.svg"
import { Box, Drawer } from "@mui/material";
import closeIcon from "../../assets/Icons/close.svg"
import { useAuth, useRestaurant } from "../../utils/hooks";
import BasicButton from "../Button";
import NewGenie from "../../assets/Icons/NewGenie.svg"
import { SMALL_SIDEBAR_WIDTH, LARGE_SIDEBAR_WIDTH } from "../../Constants/services";
import { queryClient } from "../../utils/config/queryClient";
import notification_audio from "../../assets/audio/notification_audio.mp3"

function HeaderWrapper({ children }) {
  const {
    useGetBriefPartnerInfo,
    notificationDrawer,
    setNotificationDrawer,
    useRequestedOrders,
    completeRequestedOrder,
  } = useRestaurant()
  const { socket, userData } = useAuth();
  const { data: restaurantInfo } = useGetBriefPartnerInfo()
  // const [openLogoutModal, setOpenLogoutModal] = useState(false);
  // const { removeToken } = useToken();
  // const navigate = useNavigate();
  const location = useLocation();

  // const adminTabs = [
  //   { id: 1, title: "Restaurant List", route: "/dashboard" },
  //   { id: 2, title: "Add New Restaurant", route: "/add" },
  // ];

  const restaurantTabs = [
    {
      id: 1,
      title: "Restaurant Profile",
      route: "/restaurant-profile",
      icons: restaurantInfo?.result?.logoURL,
    },
    { id: 2, title: "Menu Management", route: "/menu-management", icons: menuManagementIcon },
    // { id: 2, title: "Table Management", route: "/table" },
    {
      id: 3, title: "Insights",
      route: "/insights",
      icons: insights, disabled: true
    },
    // { id: 4, title: "Settings", route: "/settings", icons: settings },
  ];
  const restaurantMenuTabs = [
    {
      id: 1, title: "Delivery Management",
      route: "/delivery",
      icons: delivery, disabled: true
    },
    {
      id: 2, title: "Pickup Management",
      route: "/pickup",
      icons: PickUp, disabled: true
    },
    { id: 3, title: "DineIn Management", route: "/dinein-management", icons: Dining },
  ];

  const { data: requestedOrders } = useRequestedOrders()

  useEffect(() => {
    const audio = new Audio(notification_audio);

    socket.on("order-pay-request", (data) => {
      if ((data?.partnerId === userData?._id) && data.data.orders) {
        // Play notification sound when new order request comes in
        audio.play().catch(err => console.log('Audio playback failed:', err));
        queryClient.invalidateQueries(["getRequestedOrders"])
        queryClient.invalidateQueries(["getAllDetails"])
      }
    })

    return () => {
      socket.off("order-pay-request")
    }
  }, [socket, userData])


  // useEffect(() => { 
  //   let intervalId;
  //   const audio = new Audio(notification_audio);

  //   if (requestedOrders?.length > 0) {
  //     // Play sound immediately when orders exist
  //     audio.play().catch(err => console.log('Audio playback failed:', err));

  //     // Set up interval to play sound every 30 seconds
  //     intervalId = setInterval(() => {
  //       audio.play().catch(err => console.log('Audio playback failed:', err));
  //     }, 45000); // 45 seconds
  //   }

  //   // Cleanup interval when component unmounts or requestedOrders changes
  //   return () => {
  //     if (intervalId) {
  //       clearInterval(intervalId);
  //     }
  //   };
  // }, [requestedOrders])

  useEffect(() => {
    queryClient.invalidateQueries(['getRequestedOrders'])
  }, [notificationDrawer])

  return (
    <>
      <div className="flex">
        <div className={`w-[${SMALL_SIDEBAR_WIDTH}] tablet:w-[${LARGE_SIDEBAR_WIDTH}]`}>
          <nav className="p-2 tablet:p-4 bg-[#B80E0C] flex flex-col justify-between h-[100vh] overflow-scroll  w-full  z-[999]  rounded-r-2xl gap-2">
            <div>
              <Box sx={{ display: { xs: "flex", lg: "none" }, justifyContent: "center" }}>
                <img src={NewGenie} alt="" srcSet="" className="h-[40px] relative -right-[4px]" />
              </Box>
              <Box sx={{ display: { xs: "none", lg: "block" } }}>
                <img src={Logo} alt="" srcSet="" className="mx-auto  h-[51px] mb-[20px]" />
              </Box>


              <div className="">
                <Box sx={{ display: { xs: "none", lg: "block" } }}>
                  <h1 className="text-[#FFFFFF99] truncate font-normal">Dashboards</h1>
                </Box>

                <div className="flex flex-col mt-4">
                  <div className="flex gap-2 flex-col ">
                    {!window.location?.host.includes("superadmin") &&
                      restaurantMenuTabs?.map((tab) => (
                        <Link
                          key={tab.id}
                          to={tab.route}
                          replace={!tab.disabled}
                          onClick={(e) => tab.disabled && e.preventDefault()}
                          className={`text-white  flex gap-2 ${location?.pathname !== tab.route
                            ? ""
                            : "bg-[#FFFFFF52] rounded"
                            } px-1.5 py-2  font-[400] text-[14px] justify-center tablet:justify-normal leading-[18px] ${tab.disabled ? "opacity-50 pointer-events-none" : ""} `}
                        >
                          <img src={tab.icons} alt="" width={20} />
                          <Box sx={{ display: { xs: "none", lg: "block" } }}>
                            {/* <Tooltip title={tab.title} arrow> */}
                            <span className="truncate text-wrap">
                              {tab.title}
                            </span>
                            {/* </Tooltip> */}
                          </Box>
                        </Link>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              {restaurantTabs?.map((tab) => (
                <Link
                  key={tab.id}
                  to={tab.route}
                  replace={!tab.disabled}
                  onClick={(e) => tab.disabled && e.preventDefault()}
                  className={`text-white  flex items-center ${tab.id === 1 ? "gap-[6px]" : "gap-2"} ${location?.pathname.indexOf(tab.route)
                    ? ""
                    : "bg-[#FFFFFF52] rounded"
                    } px-1.5 py-2  font-[400] text-[14px] justify-center tablet:justify-normal leading-[18px] ${tab.disabled ? "opacity-50 pointer-events-none" : ""} `}
                >
                  {tab.id === 1
                    ? <img className="rounded-full object-cover h-[24px] w-[24px] my-auto -ml-[2px]" src={tab.icons} alt="" width={20} height={20} />
                    : <img src={tab.icons} alt="" width={20} />
                  }
                  <Box sx={{ display: { xs: "none", lg: "block" } }}>
                    <span className="truncate text-wrap">
                      {tab.title}
                    </span>
                  </Box>
                </Link>
              ))}
            </div>
          </nav>
        </div>
        {/* <div className={`w-[calc(100vw-${SMALL_SIDEBAR_WIDTH})] tablet:w-[calc(100vw-${LARGE_SIDEBAR_WIDTH})]`}> */}
        <div className={`w-[calc(100vw-${SMALL_SIDEBAR_WIDTH})] tablet:w-full`}>
          {children}
        </div>
        <Drawer open={notificationDrawer} onClose={() => { setNotificationDrawer(false) }} anchor="right"
          sx={{
            padding: "22px 16px",
            height: "100vh",
            overflow: "hidden"
          }}
        >
          <div className="sticky top-0 bg-white z-[100]">
            <div className="w-full px-4 h-[74px] border-b border-[#E9E9E9] flex items-center justify-between">
              <h1 className="text-[20px] font-medium text-[#3E3E3E]">Notification Center</h1>
              <img className="cursor-pointer" onClick={() => { setNotificationDrawer(false) }} src={closeIcon} alt="" />
            </div>
          </div>
          {requestedOrders?.requestedOrders?.length > 0 ?
            <div className="flex flex-col gap-1 max-h-[100vh]">
              {requestedOrders?.requestedOrders?.map(order => {
                return (
                  <div className="flex">
                    <div className="h-auto w-[4px] bg-[rgb(184,14,12)]">&nbsp;</div>
                    <div className="py-3 flex w-full gap-[27px] border-b border-[#E9E9E9] px-4">
                      <p className="text-4xl text-[#b80e0c] leading-tight max-w-[20px] w-full">
                        {order?.tableSequence}
                      </p>
                      <div className="flex flex-col gap-3 w-full">
                        <p className="w-3/4">Incoming payment request from table No. {order?.tableSequence} {order?.tableName ? `("${order?.tableName}")` : ""}</p>
                        <div className="flex gap-3 w-full">
                          <BasicButton variant="outline" sx={{ width: "100%", border: `1px solid ${order?.isLoading ? "transparent" : "#377931"}` }}
                            onClick={() => {
                              completeRequestedOrder(order?.orders?.map(item => item?._id), order?._id)
                            }}
                            disabled={order?.isLoading}
                            className={`${order?.isLoading ? "opacity-50" : ""}`}
                          >
                            <span className="capitalize text-sm text-nowrap">
                              Mark as complete
                            </span>
                          </BasicButton>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
            :
            <div className="text-center h-[72px] shrink-0">
              <div className="h-full flex justify-center items-center">
                <h1 className="">No requests available</h1>
              </div>
            </div>
          }
          <div className="h-[72px] shrink-0">
            <div className="w-full px-4 h-full border-b border-[#E9E9E9] flex items-center justify-between">
              <h1 className="text-[20px] font-medium text-[#3E3E3E]">Older Notifications</h1>
            </div>
          </div>
          {requestedOrders?.allOrders?.length > 0 ?
            <div className="flex flex-col gap-1">
              {requestedOrders?.allOrders?.map(order => {
                return (
                  <div className="flex">
                    <div className="py-3 flex w-full gap-[27px] border-b border-[#E9E9E9] px-4">
                      <p className="text-4xl text-[#AFAFAF] leading-tight max-w-[24px] w-full">
                        {order?.tableSequence}
                      </p>
                      <div className="flex flex-col gap-3 w-full">
                        <p className="w-3/4 text-[#AFAFAF]">Incoming payment request from table No. {order?.tableSequence} {order?.tableName && `"${order?.tableName}"`}</p>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
            :
            <div className="text-center h-[72px] shrink-0">
              <div className="h-full flex justify-center items-center">
                <h1 className="">No requests available</h1>
              </div>
            </div>
          }
        </Drawer >
      </div >

      {/* {openLogoutModal && (
        <ConfirmLogoutModal
          open={openLogoutModal}
          handleCancel={() => setOpenLogoutModal(false)}
          handleConfirmClick={() => (removeToken(), navigate(-1))}
        />
      )} */}
    </>
  );
}

export default HeaderWrapper;
