import React, { useState, useEffect } from "react";
import { Grid, Tooltip } from "@mui/material";
import { PageLoader } from "../../../components/loader";
import { useNavigate } from "react-router";
import { useRestaurant } from "../../../utils/hooks";
import ConfirmDeleteModal from "./ConfirmationDelete";
import ReusableIOSSwitch from "../../../components/switch/IOSSwitch";
import deleteIcon from "../../../assets/Icons/delete.svg"
import editIcon from "../../../assets/Icons/edit.svg"
import search_insights from "../../../assets/Icons/search_insights.svg"
import { LazyLoadImage } from "react-lazy-load-image-component";

function MenuComponent({ list, isLoading }) {
  const [itemId, setItemId] = useState("");
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const navigate = useNavigate();
  const { HandleDeleteMenu, OnEditMenuIsActive } =
    useRestaurant();

  const [itemStates, setItemStates] = useState({});

  useEffect(() => {
    if (list) {
      const initialStates = list.reduce((acc, item) => {
        acc[item._id] = {
          delivery: item.delivery || false,
          pickUp: item.pickUp || false,
          dineIn: item.dineIn || false
        };
        return acc;
      }, {});
      setItemStates(initialStates);
    }
  }, [list]);

  const handleConfirmClick = () => {
    HandleDeleteMenu(itemId);
    setOpenConfirmationModal(false);
  };

  const handleSwitchChange = async (itemId, field) => {
    setItemStates(prevStates => ({
      ...prevStates,
      [itemId]: {
        ...prevStates[itemId],
        [field]: !prevStates[itemId][field]
      }
    }));

    try {
      const updatedItem = list.find(item => item._id === itemId);
      await OnEditMenuIsActive({
        ...updatedItem,
        [field]: !itemStates[itemId][field]
      });
    } catch (error) {
      // Revert UI change if the backend call fails
      setItemStates(prevStates => ({
        ...prevStates,
        [itemId]: {
          ...prevStates[itemId],
          [field]: !prevStates[itemId][field]
        }
      }));
      console.error("Failed to update the menu item:", error);
    }
  };

  return (
    <>
      {isLoading || !list ? (
        <PageLoader />
      ) : (
        <div className="py-5 px-8">

          <Grid container>
            {list?.map((item) => (
              <Grid xs={12} sm={4} md={3} lg={2} className="p-1" key={item._id}>
                <div className="flex flex-col px-3 lg:px-5 py-3 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 w-full h-full">
                  <div className="flex justify-between">
                    {/* <img
                      src={item?.image?.url}
                      alt="Menu"
                      className="w-[64px] h-[64px] rounded-[50%]"
                    /> */}
                    <LazyLoadImage
                      src={item?.image?.url}
                      alt="Menu"
                      effect="opacity"
                      className="w-[64px] h-[64px] rounded-[50%]"
                    />

                    <div className="flex  items-center gap-1 md:gap-2 pb-1 h-5 ">
                      <img
                        src={editIcon}
                        onClick={() => navigate(`edit/${item?._id}`)}
                        className="cursor-pointer"
                        alt="edit" />
                      <img
                        src={deleteIcon}
                        className="cursor-pointer"
                        onClick={() => {
                          setOpenConfirmationModal(true);
                          setItemId(item?._id);
                        }}
                        alt="delete" />
                    </div>
                  </div>
                  <h5 className="mt-5   text-gray-600 text-wrap text-[1.1rem]  ">
                    <Tooltip title={item?.item_name} arrow>
                      {item?.item_name}
                    </Tooltip>
                  </h5>
                  <p className="text-gray-900 font-semibold mt-auto">
                    AED {item?.oPrice - item?.dPrice}
                  </p>
                  <div className="font-normal text-[11px] my-1">Availability</div>

                  <div className="flex flex-col gap-1">
                    {/* <div className="flex items-start gap-2">
                      <div>
                        <ReusableIOSSwitch
                          checked={itemStates[item._id]?.delivery}
                          onChange={() => handleSwitchChange(item._id, 'delivery')}
                        />
                      </div>
                      <div className="font-normal">Delivery</div>
                    </div> */}
                    {/* <div className="flex items-start gap-2">
                      <div>
                        <ReusableIOSSwitch
                          checked={itemStates[item._id]?.pickUp}
                          onChange={() => handleSwitchChange(item._id, 'pickUp')}
                        />
                      </div>
                      <div className="font-normal truncate">Pick-up</div>
                    </div> */}
                    <div className="flex items-start gap-2">
                      <div>
                        <ReusableIOSSwitch
                          checked={itemStates[item._id]?.dineIn}
                          onChange={() => handleSwitchChange(item._id, 'dineIn')}
                        />
                      </div>
                      <div className="font-normal truncate">Dine in</div>
                    </div>
                  </div>

                  {/* TODO: insights */}
                  {/* <div className="flex justify-end gap-2 mt-3">
                    <img className="cursor-pointer" src={search_insights} alt="" />
                    <span className="font-medium text-xs text-[#B80E0C] underline cursor-pointer">Insights</span>
                  </div> */}
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      )}
      {openConfirmationModal && (
        <ConfirmDeleteModal
          open={openConfirmationModal}
          handleCancel={() => setOpenConfirmationModal(false)}
          handleConfirmClick={handleConfirmClick}
        />
      )}
    </>
  );
}

export default MenuComponent;
