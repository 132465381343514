import React, { useContext, createContext, useState } from "react";

const DeliveryContext = createContext(undefined);

export const useDelivery = () => useContext(DeliveryContext);

function useProvideDelivery() {
  // const queryClient = useQueryClient();
  const [orderDetails, setOrderDetails] = useState(null);
  const [qrCodeOpen, setQrCodeOpen] = useState(null);

  return {
    orderDetails, setOrderDetails,
    qrCodeOpen, setQrCodeOpen
  };
}

export function ProvideDelivery({ children }) {
  const delivery = useProvideDelivery();
  return (
    <DeliveryContext.Provider value={delivery}>
      {children}
    </DeliveryContext.Provider>
  );
}
