import React, { useEffect, useMemo, useRef, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { TextInput } from "../../components/InputBox";
import BasicButton from "../../components/Button";
// import HeaderWrapper from "../../components/headerwrapper/HeaderWrapper";
import dayjs from "dayjs";
import { useAuth, useRestaurant, useSuperAdmin } from "../../utils/hooks";
import { toast } from "react-toastify";
import MapComponent from "../../Map";
import { Badge, Button, Grid, Tooltip } from "@mui/material";
import { useLoadScript, Autocomplete } from "@react-google-maps/api";
// import SearchIcon from "@mui/icons-material/Search";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
// import mapPointer from "../../assets/Icons/map.svg";
import plus from "../../assets/Icons/plus.svg";
import { BasicModal } from "../../components/Modal/Modal";
import CloseIcon from "@mui/icons-material/Close";
import MultiSelectDropdown from "../../components/InputBox/MultiSelect.tsx";
import { Checkbox, FormControlLabel } from "@mui/material";
import TimePickerComponent from "../../components/TimePicker";
import DragAndDropLogoPic from "../../components/Dropzone/DropAndCrop.jsx";
import penSvg from "../../assets/Icons/pen.svg";
import checked_box from "../../assets/Icons/checked_box.svg"
import mapIcon from "../../assets/Icons/map.svg";
import { CustomTextInput } from "../../components/InputBox/CustomInput.jsx";
import ButtonWithIcon from "../../components/ButtonWithIcon/index.jsx";

import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ReusableSmallIOSSwitch from "../../components/switch/IOSSwitch.jsx";
import { PageLoader } from "../../components/loader/index.jsx";
import search from "../../assets/Icons/search.svg";
import plus_large from "../../assets/Icons/plus_large.svg";
import { titleCase } from "../../utils/helper/index.js";
import LogOutButton from "../../components/Logout/index.jsx";
// import MobileView from "../../components/MobileView/index.jsx";
import { checkCountryHasPostalCode, postalCodeVerification } from "../../utils/helper/postalCodeVerification.js";
import countryList from 'react-select-country-list'
import { SearchableDropDown } from "../../components/SearchableDropdown/index.jsx";
import DinerMobileView from "../Dinner/DinerMobileView.jsx";
import { RWebShare } from "react-web-share";
import share from "../../assets/Icons/shareAlt.svg";
import useToken from "../../utils/hooks/useToken.js";

function RestaurantInformation() {
  const { useGetAllCuisine, OnAddPartner } = useSuperAdmin();
  const { useGetServiceType, setFile, location, setLocation } = useAuth();
  const { getUserDetails } = useToken()
  const dropzoneRef = useRef(null);
  const countryOptions = useMemo(() => countryList().getData(), [])

  const { useGetPartnerInfo, autocomplete, onLoad, onUpdatePartnerInfo, setNotificationDrawer, useRequestedOrders } =
    useRestaurant();

  const [addressModel, setAddressModel] = useState(false);
  const [LogoModel, setLogoModel] = useState(false);
  const [editModeActive, setEditModeActive] = useState(false);
  const [modelType, setModelType] = useState(null);
  const [mobileView, setMobileView] = useState(false);

  const triggerDropzone = () => {
    console.log('Dropzone ref:', dropzoneRef.current);
    if (dropzoneRef.current && dropzoneRef.current.openFileDialog) {
      dropzoneRef.current.openFileDialog();
    } else {
      console.log('Dropzone ref or openFileDialog method is undefined.');
    }
  };

  const handleAddress = () => {
    setAddressModel(!addressModel);
  };

  const toggleLogoModel = () => {
    // setLogoModel(!LogoModel);
    if (LogoModel) {
      if (modelType?.includes("restaurantPhotos")) {
        const currentPhotos = watch("restaurantPhotos") || [];
        if (!currentPhotos[currentPhotos.length - 1]?.file && !currentPhotos[currentPhotos.length - 1]?.url) {
          const updatedPhotos = currentPhotos.slice(0, -1);
          setValue("restaurantPhotos", updatedPhotos);
        }
        // setValue("restaurantPhotos", updatedPhotos);
      }

      setLogoModel(!LogoModel);
      setModelType(null)
    } else {
      setLogoModel(!LogoModel);
    }
  };

  const { data: partnerInfo, isLoading: partnerInfoIsLoading } =
    useGetPartnerInfo();

  const { data: cousine } = useGetAllCuisine();

  const { handleSubmit, control, setValue, watch, reset, getValues, trigger } = useForm({
    mode: "all",
    defaultValues: {
      Timing: [
        {
          day: "Monday",
          schedule: [{ fromTime: "", toTime: "" }],
          isClosed: false,
        },
        {
          day: "Tuesday",
          schedule: [{ fromTime: "", toTime: "" }],
          isClosed: false,
        },
        {
          day: "Wednesday",
          schedule: [{ fromTime: "", toTime: "" }],
          isClosed: false,
        },
        {
          day: "Thursday",
          schedule: [{ fromTime: "", toTime: "" }],
          isClosed: false,
        },
        {
          day: "Friday",
          schedule: [{ fromTime: "", toTime: "" }],
          isClosed: false,
        },
        {
          day: "Saturday",
          schedule: [{ fromTime: "", toTime: "" }],
          isClosed: false,
        },
        {
          day: "Sunday",
          schedule: [{ fromTime: "", toTime: "" }],
          isClosed: false,
        },
      ],
      restaurantPhotos: [{ url: "" }]
    },
  });

  const { fields: timingList } = useFieldArray({
    control,
    name: "Timing",
  });
  const { fields: restaurantPhotos, append: appendRestaurantPhoto, remove: removeRestaurantPhoto } = useFieldArray({
    control,
    name: "restaurantPhotos",
  });


  // console.log(timingList);

  const { data: serviceType, isLoading: serviceTypeIsLoading } = useGetServiceType();

  const handleAppendSchedule = (dayIndex) => {
    const currentSchedule = watch(`Timing.${dayIndex}.schedule`) || [];
    const newSchedule = [...currentSchedule, { fromTime: "", toTime: "" }];

    // Create a copy of the entire form state
    const currentFormState = getValues();

    // Update only the specific day's schedule
    const updatedTimingList = currentFormState.Timing.map((item, index) =>
      index === dayIndex ? { ...item, schedule: newSchedule } : item
    );

    // Update the form state, preserving all other fields
    setValue('Timing', updatedTimingList, { shouldValidate: true });
  };

  const handleRemoveSchedule = (dayIndex, slotIndex) => {
    const currentSchedule = watch(`Timing.${dayIndex}.schedule`);

    if (currentSchedule.length > 1) {
      const newSchedule = currentSchedule.filter((_, index) => index !== slotIndex);

      // Create a copy of the entire form state
      const currentFormState = getValues();

      // Update only the specific day's schedule
      const updatedTimingList = currentFormState.Timing.map((item, index) =>
        index === dayIndex ? { ...item, schedule: newSchedule } : item
      );

      // Update the form state, preserving all other fields
      setValue('Timing', updatedTimingList, { shouldValidate: true });
    }
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        const newLocation = {
          latitude: place.geometry.location.lat(),
          longitude: place.geometry.location.lng(),
        };
        // setLocation(newLocation);
        const location = `https://www.google.com/maps?q=${newLocation?.latitude},${newLocation?.longitude}`;

        const LocationObj = {
          url: location,
          lat: newLocation?.latitude.toString(),
          long: newLocation?.longitude.toString(),
        };
        setValue("locationURL", LocationObj);
        setValue("searchLocation", place?.formatted_address);
        // console.log(LocationObj, 'LocationObj');
        // setLocation({ "latitude":  LocationObj.lat, "longitude": LocationObj.long });

        const apiKey = process.env.REACT_APP_MAP_API_KEY;
        const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${LocationObj.lat},${LocationObj.long}&key=${apiKey}&language=en`;

        fetch(geocodeUrl)
          .then((response) => response.json())
          .then((data) => {
            if (data.status === "OK") {
              const address = data.results[0].formatted_address;
              // setValue("locationURL", LocationObj);
              setValue("searchLocation", address);
              setLocation({ "latitude": Number(LocationObj.lat), "longitude": Number(LocationObj.long), address });
              parseGoogleAddress(data)
            } else {
              toast.error("Unable to fetch address.");
            }
          })
          .catch((error) => {
            console.error("Error fetching address:", error);
            toast.error("Error fetching address.");
          });
      }
    }
  };

  function parseGoogleAddress(addressData) {
    const result = {
      formatted_address: '',
      city: '',
      country: '',
      state: '',
      zipCode: '',
      latitude: '',
      longitude: '',
      street_address: ''
    };

    if (addressData.results && addressData.results.length > 0) {
      const addressComponents = addressData.results[0].address_components;
      result.formatted_address = addressData.results[0].formatted_address;

      let street_number = '';
      let route = '';
      let neighborhood = '';
      let sublocality = '';

      addressComponents.forEach(component => {
        const types = component.types;

        if (types.includes('locality') || types.includes('sublocality')) {
          result.city = component.long_name;
        } else if (types.includes('administrative_area_level_1')) {
          result.state = component.long_name;
        } else if (types.includes('country')) {
          result.country = component.long_name;
        } else if (types.includes('postal_code')) {
          result.zipCode = component.long_name;
        } else if (types.includes('street_number')) {
          street_number = component.long_name;
        } else if (types.includes('route')) {
          route = component.long_name;
        } else if (types.includes('neighborhood')) {
          neighborhood = component.long_name;
        } else if (types.includes('sublocality')) {
          sublocality = component.long_name;
        } else if (types.includes('establishment')) {
          result.establishment = component.long_name;
        }
      });

      result.street_address = [result.establishment, street_number, route, neighborhood, sublocality]
        .filter(Boolean)
        .join(' ');

      if (!result.city) {
        const cityComponent = addressComponents.find(component =>
          component.types.includes('administrative_area_level_2') ||
          component.types.includes('administrative_area_level_3') ||
          component.types.includes('locality') ||
          component.types.includes('sublocality') ||
          component.types.includes('neighborhood')
        );
        if (cityComponent) {
          result.city = cityComponent.long_name;
        }
      }

      if (!result.state) {
        const stateComponent = addressComponents.find(component =>
          component.types.includes('administrative_area_level_1') ||
          component.types.includes('administrative_area_level_2')
        );
        if (stateComponent) {
          result.state = stateComponent.long_name;
        }
      }

      if (addressData.results[0].geometry && addressData.results[0].geometry.location) {
        result.latitude = addressData.results[0].geometry.location.lat;
        result.longitude = addressData.results[0].geometry.location.lng;
      }
    }

    // result.formatted_address = result.formatted_address.split(result.city)[0].trim()

    setValue("Address.addressLine1", result.street_address)
    setValue("Address.addressLine2", "")
    setValue("Address.city", result.city)
    setValue("Address.zip", result.zipCode)
    setValue("Address.state", result.state)
    setValue("Address.country", result.country)

    return result;
  }

  /**
   * useEffect hook that resets form fields with partner information when partnerInfo is updated.
   * @returns None
   */
  useEffect(() => {
    if (partnerInfo?.result?._id) {
      console.log(partnerInfo?.result, "partnerInfo?.result");
      reset({
        ...partnerInfo?.result,
        dateFrom: dayjs(partnerInfo?.result?.workingHours?.from, "hh:mm a"),
        dateTo: dayjs(partnerInfo?.result?.workingHours?.to, "hh:mm a"),
        Address: partnerInfo?.result?.address,
        locationURL: partnerInfo?.result?.locationURL,
        Cuisine: partnerInfo?.result?.cuisineType.map((item) => ({
          value: item,
          label: item,
        })),
        Timing: partnerInfo?.result?.workingHours.map(({ day, isClosed, schedule }) => ({
          day,
          isClosed,
          schedule: schedule.map(({ fromTime, toTime }) => ({ fromTime, toTime }))
        })),
        Description: partnerInfo?.result?.description,
        deliveryRadius: partnerInfo?.result?.deliveryRadius,
        serviceType: partnerInfo?.result?.serviceType,
        googleBusinessURL: partnerInfo?.result?.googleBusinessURL,
        dineInPayment: partnerInfo?.result?.dineInPayment,
        deliveryTime: partnerInfo?.result?.deliveryTime,
        deliveryFee: partnerInfo?.result?.deliveryFee,
        logoURL: partnerInfo?.result?.logoURL,
        bannerURL: partnerInfo?.result?.bannerURL,
        restaurantPhotos: partnerInfo?.result?.restaurantPhotos
      });

      try {
        setLocation({
          latitude: parseFloat(partnerInfo?.result?.locationURL?.lat),
          longitude: parseFloat(partnerInfo?.result?.locationURL?.long),
        });
      } catch (err) {
        toast.error("Unknown Error");
      }
    }
  }, [partnerInfo, reset, setLocation]);

  /**
   * Handles form submission by either editing an existing partner if the partner ID exists,
   * or adding a new partner if the partner ID does not exist.
   * @param {{object}} data - The data to be submitted, typically form data.
   * @returns None
   */
  const onSubmit = async (data) => {
    const isValid = await trigger();
    if (!isValid) {
      // Form is not valid, don't proceed with submission
      return;
    }

    if (!data.logoURL) {
      toast.error("Logo is required");
      return;
    }

    if (!data.bannerURL) {
      toast.error("Banner is required");
      return;
    }

    if (!data.restaurantPhotos.length > 0) {
      toast.error("At least one restaurant photo is required");
      return;
    }


    // Validate timing list
    const isTimingValid = validateTimingList(data.Timing);
    if (!isTimingValid) {
      toast.error("Invalid timing configuration. Please check working hours.");
      return;
    }

    // Check if customDomain has been updated
    const originalCustomDomain = partnerInfo?.result?.customDomain;
    if (data.customDomain !== originalCustomDomain) {
      toast.info("Custom domain updated. Please log out and log in again.");
    }

    if (partnerInfo?.result?._id) {
      // console.log(data, "data")
      onUpdatePartnerInfo(data);
    } else {
      OnAddPartner(data);
    }
    setEditModeActive(false);
  };

  // Add this function to validate the timing list
  const validateTimingList = (timingList) => {
    if (!Array.isArray(timingList) || timingList.length !== 7) {
      return false;
    }

    return timingList.every(day => {
      if (day.isClosed === true) {
        return true;
      }
      if (!Array.isArray(day.schedule) || day.schedule.length === 0) {
        return false;
      }
      return day.schedule.every(slot => {
        return slot.fromTime &&
          slot.toTime &&
          slot.fromTime !== "Invalid Date" &&
          slot.toTime !== "Invalid Date";
      });
    });
  };

  const onCancel = () => {
    reset({
      ...partnerInfo?.result,
      dateFrom: dayjs(partnerInfo?.result?.workingHours?.from, "hh:mm a"),
      dateTo: dayjs(partnerInfo?.result?.workingHours?.to, "hh:mm a"),
      Address: partnerInfo?.result?.address,
      locationURL: partnerInfo?.result?.locationURL,
      Cuisine: partnerInfo?.result?.cuisineType.map((item) => ({
        value: item,
        label: item,
      })),
      Timing: partnerInfo?.result?.workingHours.map(({ day, isClosed, schedule }) => ({
        day,
        isClosed,
        schedule: schedule.map(({ fromTime, toTime }) => ({ fromTime, toTime }))
      })),
      Description: partnerInfo?.result?.description,
      deliveryRadius: partnerInfo?.result?.deliveryRadius,
      serviceType: partnerInfo?.result?.serviceType,
      googleBusinessURL: partnerInfo?.result?.googleBusinessURL,
      dineInPayment: partnerInfo?.result?.dineInPayment,
      deliveryTime: partnerInfo?.result?.deliveryTime,
      deliveryFee: partnerInfo?.result?.deliveryFee,
      logoURL: partnerInfo?.result?.logoURL,
      bannerURL: partnerInfo?.result?.bannerURL,
      restaurantPhotos: partnerInfo?.result?.restaurantPhotos
    });
  }

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          if (latitude && longitude) {
            const locationUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
            const locationObj = {
              url: locationUrl,
              lat: latitude.toString(),
              long: longitude.toString(),
            };

            const apiKey = process.env.REACT_APP_MAP_API_KEY;
            const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

            fetch(geocodeUrl)
              .then((response) => response.json())
              .then((data) => {
                if (data.status === "OK") {
                  const address = data.results[0].formatted_address;
                  setValue("locationURL", locationObj);
                  setValue("searchLocation", address);
                  setLocation({ "latitude": Number(latitude), "longitude": Number(longitude), address });
                  parseGoogleAddress(data)
                } else {
                  toast.error("Unable to fetch address.");
                }
              })
              .catch((error) => {
                console.error("Error fetching address:", error);
                toast.error("Error fetching address.");
              });
          }
        },

        // setValue("Location", locationObj);
        // setLocation({ latitude, longitude });

        (error) => {
          let errorMessage = "An unknown error occurred.";
          switch (error.code) {
            case error.PERMISSION_DENIED:
              errorMessage = "User denied the request for Geolocation.";
              break;
            case error.POSITION_UNAVAILABLE:
              errorMessage = "Location information is unavailable.";
              break;
            case error.TIMEOUT:
              errorMessage = "The request to get user location timed out.";
              break;
            default:
              errorMessage = "An unknown error occurred.";
              break;
          }
          toast.error(errorMessage);
        }
      );
    } else {
      toast.error("Geolocation is not supported by this browser.");
    }
  };
  const handleDragEvent = (event) => {
    const latitude = event.latLng.lat();
    const longitude = event.latLng.lng();
    const location = `https://www.google.com/maps?q=${latitude},${longitude}`;
    const LocationObj = {
      url: location,
      lat: latitude.toString(),
      long: longitude.toString(),
    };
    setValue("Location", LocationObj);
    setLocation({ latitude: latitude, longitude: longitude });
  };

  const handleClick = (event) => {
    const latitude = event.latLng.lat();
    const longitude = event.latLng.lng();
    const location = `https://www.google.com/maps?q=${latitude},${longitude}`;
    const LocationObj = {
      url: location,
      lat: latitude.toString(),
      long: longitude.toString(),
    };
    setValue("locationURL", LocationObj);
    console.log(LocationObj, 'LocationObj');
    setLocation({ latitude: latitude, longitude: longitude });

    const apiKey = process.env.REACT_APP_MAP_API_KEY;
    const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}&language=en`;

    fetch(geocodeUrl)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "OK") {
          const address = data.results[0].formatted_address;
          // setValue("locationURL", LocationObj);
          setLocation({ latitude, longitude, address });
          parseGoogleAddress(data)
        } else {
          toast.error("Unable to fetch address.");
        }
      })
      .catch((error) => {
        console.error("Error fetching address:", error);
        toast.error("Error fetching address.");
      });
  }

  useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY,
    libraries: ["places"],
  });

  const { data: requestedOrders } = useRequestedOrders()



  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* {partnerInfoIsLoading ? (
          <PageLoader />
        ) : ( */}
        <div className="h-[100dvh] overflow-auto ">
          {/* <Button onClick={() => { console.log(getValues()) }} sx={{ position: "absolute" }}>Check Data</Button> */}
          <Grid container>
            <Grid item xs={12} sx={{ borderBottom: "1px solid #1C1C1C1A"}}>
              <div className="  flex  items-center    justify-between py-4 px-8 h-[72px]">
                <h3 className="flex gap-3 items-center">
                  <span className="text-[#1C1C1C66]"> Dashboard </span>{" "}
                  <span className="text-[#1C1C1C66]">/</span>
                  <span>Restaurant Profile</span>
                </h3>
                <div className="max-w-[400px]  flex gap-4 items-center ">
                  {/* <TextInput
                      variant="outlined"
                      preContent={<SearchIcon sx={{ color: "#c8c8c8" }} />}
                      transparentBorder
                      placeholder="Search"
                      // value={searchValue}
                      borderRadius="10px"
                    // onChange={(e) => setSearchValue(e.target.value)}
                    //   label="Description(optional)"
                    /> */}
                  <Badge badgeContent={requestedOrders?.requestedOrders?.length} color="error">
                    <NotificationsNoneOutlinedIcon sx={{ cursor: "pointer" }} onClick={() => setNotificationDrawer(true)} />
                  </Badge>
                  <LogOutButton />
                </div>
              </div>
            </Grid>
            {partnerInfoIsLoading ? (
              <div className="flex flex-col justify-center items-center h-[calc(100vh-60px)] w-full">
                <div className="h-14 w-14 border-4 border-gray-300 border-t-red-600 rounded-full animate-spin mb-4" />
                <p className="text-gray-600">
                  Loading...
                </p>
              </div>
            ) : (
              <Grid
                container
              // item
              // gap={2}
              // xs={8}
              // sx={{ padding: "1rem", borderRight: "2px solid #1C1C1C1A" }}
              >
                <Grid
                  container
                  item
                  xs={12}
                  lg={8}
                  sx={{ padding: "8px 33px", borderRight: { lg: "1px solid #1C1C1C1A" }, gap: "20px" }}
                >
                  <Grid item xs={12}>
                    <div className="  flex  items-center    justify-between  ">
                      <div className="flex gap-2 items-center">
                        <h1 className="text-[24px] ">Restaurant profile</h1>
                        <RWebShare
                          // TODO: fix url
                          data={{
                            url: `https://${window.location.hostname.split('.')[0]}.dev-frontend.grubgenie.ai/menu`
                          }}
                          onClick={() => console.info("share successful!")}
                        >
                          <img className="cursor-pointer" src={share} alt="" />
                        </RWebShare>
                      </div>
                      {/* {!editModeActive ?
                        <div className="flex gap-2">
                          <BasicButton
                            color="genieRed"
                            // variant="outlined"
                            sx={{
                              textTransform: "none",
                              height: "36px",
                              color: "white",
                            }}
                            // disabled={addPartnerIsLoading}
                            onClick={() => setEditModeActive(true)}
                          >
                            Edit info
                          </BasicButton>
                        </div>
                        : */}
                      <div className="flex gap-2">
                        <BasicButton
                          color="genieRed"
                          // variant="outlined"
                          sx={{
                            textTransform: "none",
                            height: "36px",
                            color: "white",
                            display: !editModeActive ? "block" : "none"
                          }}
                          // disabled={addPartnerIsLoading}
                          onClick={() => setEditModeActive(true)}
                        >
                          Edit info
                        </BasicButton>
                        <BasicButton
                          color="genieRed"
                          variant="outlined"
                          sx={{
                            textTransform: "none",
                            height: "36px",
                            display: editModeActive ? "block" : "none"
                          }}
                          // disabled={addPartnerIsLoading}
                          onClick={() => {
                            onCancel()
                            setEditModeActive(false)
                          }}
                        >
                          Cancel
                        </BasicButton>
                        <BasicButton
                          color="genieRed"
                          type="submit"
                          sx={{
                            textTransform: "none",
                            height: "36px",
                            color: "white",
                            display: editModeActive ? "block" : "none"
                          }}
                        // disabled={addPartnerIsLoading}
                        >
                          <div className="text-nowrap">Save Edits</div>
                        </BasicButton>
                      </div>
                      {/* } */}
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="flex ">
                      <h1 className="w-1/3 mt-3 font-[600]  block mb-2 text-[14px] text-[#757373]">
                        RESTAURANT NAME <span className="text-[#b80e0c]">*</span>
                      </h1>
                      <div className="w-1/3">
                        {editModeActive ?
                          <Controller
                            name="name"
                            control={control}
                            rules={{
                              required: "Restaurant Name is Required",
                            }}
                            render={({ field, fieldState: { error } }) => {
                              return (
                                <CustomTextInput
                                  {...field}
                                  variant="outlined"
                                  // label="Name"
                                  disabled
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                />
                              );
                            }}
                          />
                          :
                          <p className="flex items-center h-full font-medium text-base">{getValues("name")}</p>
                        }
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="flex ">
                      <h1 className="w-1/3  mt-3 font-[600]  block mb-2 text-[14px] text-[#757373]">
                        SELECT ALL SERVICE TYPE<span className="text-[#b80e0c]">*</span>
                      </h1>
                      {/* <div className="w-1/3">
                      {editModeActive ?
                        <Controller
                          name="name"
                          control={control}
                          rules={{
                            required: "Restaurant Name is Required",
                          }}
                          render={({ field, fieldState: { error } }) => {
                            return (
                              <TextInput
                                {...field}
                                variant="outlined"
                                // label="Name"
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            );
                          }}
                        />
                        :
                        <p className="flex items-center h-full">{getValues("name")}</p>
                      }
                    </div> */}
                      <div className="flex justify-between items-center max-w-[513px]">
                        {serviceTypeIsLoading ? (
                          <div>
                            <PageLoader />
                          </div>
                        ) : (
                          serviceType?.result?.map((itemsList, index) => (
                            // FIXME: enabled dine in only
                            itemsList?.service_type === "Dining" &&
                            <div
                              key={index}
                            // onClick={() => {
                            //   setSelectedServiceType((prevSelectedType) => {
                            //     if (!prevSelectedType.includes(itemsList?.service_type)) {
                            //       return [...prevSelectedType, itemsList?.service_type];
                            //     } else {
                            //       return prevSelectedType.filter(
                            //         (item) => item !== itemsList?.service_type
                            //       );
                            //     }
                            //   });
                            // }}
                            >
                              {/* <div
                              className={`rounded ${selectedServiceType?.includes(itemsList?.service_type)
                                  ? "bg-red-50"
                                  : "bg-white"
                                } border p-10 flex justify-center gap-2 hover:bg-red-50`}
                            >
                              <RestaurantIcon sx={{ color: "#ED1703" }} />
                              <h1>{itemsList?.service_type}</h1>
                            </div> */}
                              <div className="flex gap-2 items-center">
                                {/* TODO: fix this */}
                                <ReusableSmallIOSSwitch disabled={!editModeActive} checked={watch('serviceType')?.includes(itemsList?.service_type)}
                                  onChange={() => {
                                    if (getValues('serviceType')?.includes(itemsList?.service_type)) {
                                      setValue('serviceType', getValues('serviceType')?.filter(type => type !== itemsList?.service_type))
                                    } else {
                                      setValue('serviceType', [...getValues('serviceType'), itemsList?.service_type])
                                    }
                                  }}
                                />
                                <p>{itemsList?.service_type}</p>
                              </div>
                            </div>
                          ))
                        )}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="flex  items-center ">
                      {/* <h1 className="w-1/3">Youtube Link</h1> */}
                      <h1 className="w-1/3 mt-3 font-[600]  block mb-2 text-[14px] text-[#757373]">
                        CUSTOM ORDERING LINK<span className="text-[#b80e0c]">*</span>
                      </h1>
                      <div className="w-1/3">
                        {editModeActive ?
                          <Controller
                            name="customDomain"
                            control={control}
                            rules={{
                              required: "Custom domain name is Required",
                            }}
                            render={({ field, fieldState: { error } }) => {
                              return (
                                <CustomTextInput
                                  {...field}
                                  variant="outlined"
                                  disabled
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                />
                              );
                            }}
                          />
                          :
                          <p className="flex items-center h-full font-medium text-base">
                            {getValues("customDomain")}.grubgenie.ai
                          </p>
                        }
                      </div>
                      {editModeActive &&
                        <div className="text-base font-normal ml-2">.grubgenie.ai</div>}
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="flex   ">
                      {/* <h1 className="w-1/3 mt-3">
                      Type of Cuisine{" "}
                      <span className="my-auto text-genieRed">*</span>
                    </h1> */}
                      <h1 className="w-1/3 mt-3 font-[600]  block mb-2 text-[14px] text-[#757373]">
                        TYPE OF CUISINE<span className="text-[#b80e0c]">*</span>
                      </h1>
                      <div className="w-2/3">
                        {editModeActive ?
                          <Controller
                            name="Cuisine"
                            control={control}
                            rules={{
                              required: "Cuisine is Required",
                            }}
                            render={({ field, fieldState: { error } }) => {
                              return (
                                <MultiSelectDropdown
                                  name="cuisines"
                                  {...field}
                                  control={control}
                                  options={cousine?.result.map((cuisine) => ({
                                    value: cuisine.cuisine_type,
                                    label: cuisine.cuisine_type,
                                  }))}
                                  placeholder="Select Cuisines"
                                />
                              );
                            }}
                          />
                          :
                          <p className="flex items-center h-full font-medium text-base">
                            {getValues("Cuisine")?.reduce((result, curr, index) => {
                              if (index === 0) return curr.label
                              return result + ", " + curr.label
                            }, "")}
                          </p>
                        }
                        {/* {console.log(cousine)} */}
                      </div>
                    </div>
                  </Grid>
                  {/* <Grid item xs={12}>
                    <div className="flex   ">
                      <h1 className="w-1/3 mt-3">
                        Location
                        <span className="my-auto text-genieRed">*</span>
                      </h1>
                      <div
                        className="w-1/3 flex flex-col  gap-2  "
                        onClick={handleAddress}
                      >
                        <Controller
                          name="Address"
                          control={control}
                          rules={{
                            required: " Address is Required",
                          }}
                          render={({ field, fieldState: { error } }) => {
                            return (
                              <div className="flex w-full  gap-2">
                                <Autocomplete
                                  onLoad={onLoad}
                                  onPlaceChanged={onPlaceChanged}
                                  className="w-full"
                                  restrictions={{
                                    country: "AE",
                                  }}
                                >
                                  <TextInput
                                    {...field}
                                    variant="outlined"
                                    postContent={
                                      <div
                                        className="flex items-center text-genieRed"
                                        onClick={() => {
                                          getCurrentLocation();
                                        }}
                                      >
                                        <img src={mapPointer} />
                                      </div>
                                    }
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                  />
                                </Autocomplete>
                              </div>
                            );
                          }}
                        />
                      </div>
                    </div>
                  </Grid> */}
                  {/* TODO: Fix location */}
                  <Grid item xs={12}>
                    <div className="flex  items-start ">
                      <h1 className="w-1/3 font-[600]  block text-[14px] text-[#757373]">
                        LOCATION<span className="text-[#b80e0c]">*</span>
                      </h1>
                      <div className="w-2/4 relative">
                        {/* <Controller
                        name="Location.url"
                        control={control}
                        render={({ field, fieldState: { error } }) => {
                          return ( */}
                        {editModeActive ?
                          // <>
                          //   <CustomTextInput
                          //     // {...field}
                          //     value={getValues("Location.lat") + "°N, " + getValues("Location.long") + "°E"}
                          //     variant="outlined"
                          //   // error={!!error}
                          //   // helperText={error ? error.message : null}
                          //   />
                          //   <img src={map} alt="" className="absolute top-2 right-2 bg-[#f7f7f7] cursor-pointer" onClick={() => setAddressModel(true)} />
                          // </>
                          <>
                            <Controller
                              name="locationURL"
                              control={control}
                              rules={{
                                required: "Location is Required",
                              }}
                              render={({ field, fieldState: { error } }) => {
                                return (
                                  <div className="flex w-full flex-col gap-2">
                                    <MapComponent
                                      location={location}
                                      draggable={false}
                                    // handleDragEvent={handleDragEvent}
                                    // handleClick={handleClick}
                                    />
                                    <BasicButton
                                      color="genieRed"
                                      // variant="outlined"
                                      sx={{
                                        textTransform: "none",
                                        height: "36px",
                                        color: "white",
                                      }}
                                      // disabled={addPartnerIsLoading}
                                      onClick={() => setAddressModel(true)}
                                    >
                                      Edit Location
                                    </BasicButton>
                                  </div>
                                );
                              }}
                            />
                          </>
                          :
                          // <p className="flex items-center h-full font-medium text-base text-wrap">{getValues("Location.lat") + "°N, " + getValues("Location.long") + "°E"}</p>
                          <>
                            <Controller
                              name="Location"
                              control={control}
                              rules={{
                                required: "Location is Required",
                              }}
                              render={({ field, fieldState: { error } }) => {
                                return (
                                  <div className="flex w-full flex-col gap-2">
                                    <MapComponent
                                      location={location}
                                      draggable={false}
                                    // handleDragEvent={handleDragEvent}
                                    // handleClick={handleClick}
                                    />
                                  </div>
                                );
                              }}
                            />
                          </>
                        }
                        {/* );
                        }}
                      /> */}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="flex   ">
                      {/* <h1 className="w-1/3 mt-3">
                      Address
                      <span className="my-auto text-genieRed">*</span>
                    </h1> */}
                      <h1 className="w-1/3 font-[600] block text-[14px] text-[#757373]">
                        ADDRESS<span className="text-[#b80e0c]">*</span>
                      </h1>
                      {editModeActive ?
                        <div
                          className="w-2/4 flex flex-col  gap-2  "
                        // onClick={handleAddress}
                        >
                          <Controller
                            name="Address.addressLine1"
                            control={control}
                            render={({ field, fieldState: { error } }) => {
                              return (
                                <CustomTextInput
                                  label="Address line 1"
                                  placeholder="Address line 1"
                                  required
                                  {...field}
                                  variant="outlined"
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                />
                              );
                            }}
                          />
                          <Controller
                            name="Address.addressLine2"
                            control={control}
                            render={({ field, fieldState: { error } }) => {
                              return (
                                <CustomTextInput
                                  label="Address line 2"
                                  placeholder="Address line 2 (optional)"
                                  {...field}
                                  variant="outlined"
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                />
                              );
                            }}
                          />
                          <Controller
                            name="Address.city"
                            control={control}
                            render={({ field, fieldState: { error } }) => {
                              return (
                                <CustomTextInput
                                  label="City"
                                  placeholder="City"
                                  required
                                  {...field}
                                  variant="outlined"
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                />
                              );
                            }}
                          />
                          <Controller
                            name="Address.state"
                            control={control}
                            render={({ field, fieldState: { error } }) => {
                              return (
                                <CustomTextInput
                                  label="State/Region"
                                  placeholder="State/Region"
                                  required
                                  {...field}
                                  variant="outlined"
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                />
                              );
                            }}
                          />
                          <Controller
                            name="Address.zip"
                            control={control}
                            rules={{
                              required: {
                                value: checkCountryHasPostalCode(watch("Address.country")?.value || watch("Address.country")) || false,
                                message: "Zip code is required"
                              },
                              validate: (value) => {
                                if (!value || value === '') return true;
                                const country = watch("Address.country")?.value || watch("Address.country");
                                if (!checkCountryHasPostalCode(country)) return 'Your country does not require a postal code';
                                return postalCodeVerification(country, value) || "Invalid postal code format for selected country";
                              }
                            }}
                            render={({ field, fieldState: { error } }) => {
                              return (
                                <CustomTextInput
                                  label="Zip Code"
                                  placeholder={`Zip Code ${checkCountryHasPostalCode(watch("Address.country")?.value || watch("Address.country")) ? "" : "(optional)"}`}
                                  required={checkCountryHasPostalCode(watch("Address.country")?.value || watch("Address.country"))}
                                  disabled={!checkCountryHasPostalCode(watch("Address.country")?.value || watch("Address.country"))}
                                  type="text"
                                  {...field}
                                  variant="outlined"
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                />
                              );
                            }}
                          />

                          {/* <Controller
                            name="category"
                            control={control}
                            rules={{
                              required: {
                                value: true,
                                message: "Category is Required"
                              },
                              validate: (value) => {
                                return value && value.value && value.label ? true : "Category is Required";
                              },
                            }}
                            render={({ field, fieldState: { error } }) => {
                              return (
                                <Grid className="flex justify-between">
                                  <Grid xs={4}>
                                    <p className="text-[#757373] basis-40 font-semibold mt-[8.8px]">CATEGORY<span className="text-[#b80e0c]">*</span></p>
                                  </Grid>
                                  <Grid xs={8}>
                                    <SearchableDropDown
                                      {...field}
                                      sx={{ width: "auto" }}
                                      variant="outlined"
                                      placeholder="Select Category"
                                      options={categoryList?.result?.map((item) => ({
                                        value: item?._id,
                                        label: item?.food_category,
                                      }))}
                                      error={!!error}
                                      helperText={error ? error.message : null}
                                    />
                                  </Grid>
                                </Grid>
                              );
                            }}
                          /> */}

                          <Controller
                            name="Address.country"
                            control={control}
                            render={({ field, fieldState: { error } }) => {
                              // Convert string value to option object format
                              const value = field.value ? {
                                value: field.value,
                                label: field.value
                              } : null;

                              return (
                                // <CustomTextInput
                                //   label="Country"
                                //   placeholder="Country"
                                //   required
                                //   {...field}
                                //   variant="outlined"
                                //   error={!!error}
                                //   helperText={error ? error.message : null}
                                // />

                                <SearchableDropDown
                                  {...field}
                                  value={value} // Pass formatted value
                                  onChange={(option) => {
                                    field.onChange(option?.value || '')
                                    trigger("Address.zip")
                                  }} // Extract value from option
                                  sx={{ width: "auto" }}
                                  required
                                  variant="outlined"
                                  placeholder="Select Country"
                                  options={countryOptions?.map((item) => ({
                                    value: item?.label,
                                    label: item?.label,
                                  }))}
                                  label="Country"
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                />
                              );
                            }}
                          />
                        </div>
                        :
                        <div className="w-2/4">
                          {getValues("Address.addressLine1") && (
                            <div>{getValues("Address.addressLine1")},</div>
                          )}
                          {getValues("Address.addressLine2") && (
                            <div>{getValues("Address.addressLine2")},</div>
                          )}
                          {getValues("Address.city") && (
                            <div>{getValues("Address.city")},</div>
                          )}
                          {getValues("Address.state") && (
                            <div>{getValues("Address.state")},</div>
                          )}
                          {getValues("Address.zip") && getValues("Address.zip") !== "N/A" && (
                            <div>{getValues("Address.zip")},</div>
                          )}
                          {getValues("Address.country") && (
                            <div>{getValues("Address.country")},</div>
                          )}
                        </div>
                      }
                    </div>
                  </Grid>
                  {/* <Grid item xs={12}>
                    <div className="flex  ">
                      <h1 className="w-1/3 mt-3 font-[600]  block mb-2 text-[14px] text-[#757373]">
                        DELIVERY RANGE<span className="text-[#b80e0c]">*</span>
                      </h1>
                      <div className="w-1/3">
                        {editModeActive ?
                          <Controller
                            name="deliveryRadius"
                            control={control}
                            rules={{
                              required: "Delivery range is Required",
                              validate: (value) => {
                                return value > 0 || "Delivery range must be greater than 0";
                              }
                            }}
                            render={({ field, fieldState: { error } }) => {
                              return (
                                <CustomTextInput
                                  {...field}
                                  type="number"
                                  postContent={
                                    <h1>
                                      kms
                                    </h1>
                                  }
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  handleClear={() => {
                                  }}
                                />
                              );
                            }}
                          />
                          :
                          <p className="flex items-center h-full font-medium text-base">
                            {getValues("deliveryRadius") ? `${getValues("deliveryRadius")} Kms` : "-"}
                          </p>
                        }
                      </div>
                    </div>
                  </Grid> */}
                  {/* <Grid item xs={12}>
                    <div className="flex   ">
                      <h1 className="w-1/3 mt-3 font-[600]  block mb-2 text-[14px] text-[#757373]">
                        DELIVERY TIME<span className="text-[#b80e0c]">*</span>
                      </h1>
                      <div className="w-1/3">
                        {editModeActive ?
                          <Controller
                            name="deliveryTime"
                            control={control}
                            rules={{
                              required: "Delivery time is Required",
                              validate: (value) => {
                                return value > 0 || "Delivery time must be greater than 0";
                              }
                            }}
                            render={({ field, fieldState: { error } }) => {
                              return (
                                <CustomTextInput
                                  {...field}
                                  type="number"
                                  postContent={
                                    <h1>
                                      mins
                                    </h1>
                                  }
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  handleClear={() => {
                                  }}
                                />
                              );
                            }}
                          />
                          :
                          <p className="flex items-center h-full font-medium text-base">
                            {getValues("deliveryTime") ? `${getValues("deliveryTime")} mins` : "-"}
                          </p>
                        }
                      </div>
                    </div>
                  </Grid> */}
                  {/* <Grid item xs={12}>
                    <div className="flex   ">
                      <h1 className="w-1/3 mt-3 font-[600]  block mb-2 text-[14px] text-[#757373]">
                        DELIVERY FEE<span className="text-[#b80e0c]">*</span>
                      </h1>
                      <div className="w-1/3">
                        {editModeActive ?
                          <Controller
                            name="deliveryFee"
                            control={control}
                            rules={{
                              required: "Delivery fee is Required",
                              validate: (value) => {
                                return value > 0 || "Delivery fee must be greater than 0";
                              }
                            }}
                            render={({ field, fieldState: { error } }) => {
                              return (
                                <CustomTextInput
                                  {...field}
                                  type="number"
                                  postContent={
                                    <h1>
                                      /km
                                    </h1>
                                  }
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  handleClear={() => {
                                  }}
                                />
                              );
                            }}
                          />
                          :
                          <p className="flex items-center h-full font-medium text-base">
                            {getValues("deliveryFee") ? `${getValues("deliveryTime")} /Km` : "-"}
                          </p>
                        }
                      </div>
                    </div>
                  </Grid> */}
                  <Grid item xs={12}>
                    <div className="flex   ">
                      {/* <h1 className="w-1/3 mt-3">
                      Delivery Fee <span className="text-genieRed">*</span>
                    </h1> */}
                      <h1 className="w-1/3 mt-3 font-[600]  block mb-2 text-[14px] text-[#757373]">
                        PAYMENT (DINE IN)<span className="text-[#b80e0c]">*</span>
                      </h1>
                      {/* TODO: Fix width */}
                      <div className="w-2/4">
                        {editModeActive ?
                          <Controller
                            name="dineInPayment"
                            control={control}
                            defaultValue={false}
                            render={({ field, fieldState: { error } }) => {
                              return (
                                <div className="h-[42px] w-full flex items-center rounded-[4px] bg-[#F7F7F7] border border-[#E2E8F0] font-medium">
                                  <div
                                    onClick={() => field.onChange(true)}
                                    className={`w-full h-full flex items-center justify-center cursor-pointer ${field.value === true ? 'text-[#FFFFFF] bg-[#262626] rounded-[3px] outline outline-1 outline-[#E2E8F0]' : ''}`}
                                  >
                                    Yes
                                  </div>
                                  <div
                                    onClick={() => field.onChange(false)}
                                    className={`w-full h-full flex items-center justify-center cursor-pointer ${field.value === false ? 'text-[#FFFFFF] bg-[#262626] rounded-[3px] outline outline-1 outline-[#E2E8F0]' : ''}`}
                                  >
                                    No
                                  </div>
                                </div>
                              );
                            }}
                          />
                          :
                          <p className="flex items-center h-full font-medium text-base">
                            {getValues("dineInPayment") ? "Yes" : "No"}
                          </p>
                        }
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="flex   ">
                      <h1 className="w-1/3 font-[600]  block mb-2 text-[14px] text-[#757373]">
                        WORKING HOURS<span className="text-[#b80e0c]">*</span>
                      </h1>
                      <div className="w-2/3">
                        {editModeActive ?
                          <div className="flex flex-col ">
                            {timingList?.map((days, dayIndex) => {
                              const isClosed = watch(
                                `Timing[${dayIndex}].isClosed`
                              );

                              return (
                                <div key={days?.id} className="flex flex-col gap-2 pb-4 pt-2 border-b border-[#EDEDED] w-fit">
                                  <label className="font-medium block text-[16px] text-[#4D4D4D]">
                                    {days.day}
                                  </label>
                                  <div className="flex flex-col lg:flex-row lg:items-center gap-3 flex-wrap">
                                    <div className="flex flex-col gap-[12px]">
                                      {days.schedule.map((timeSlot, slotIndex) => (
                                        <div
                                          key={slotIndex}
                                          className="flex items-center gap-2 flex-wrap"
                                        >
                                          <div>
                                            <Controller
                                              name={`Timing[${dayIndex}].schedule[${slotIndex}].fromTime`}
                                              control={control}
                                              render={({
                                                field,
                                                fieldState: { error },
                                              }) => (
                                                <TimePickerComponent
                                                  // type="small"
                                                  {...field}
                                                  error={!!error}
                                                  value={field.value}
                                                  disabled={isClosed}
                                                  helperText={
                                                    error ? error.message : null
                                                  }
                                                  onChange={(time) => {
                                                    const formattedTime =
                                                      dayjs(time).format("hh:mm a");
                                                    field.onChange(formattedTime);
                                                  }}
                                                  postText="From"
                                                />
                                              )}
                                            />
                                          </div>
                                          <div className="">
                                            <Controller
                                              name={`Timing[${dayIndex}].schedule[${slotIndex}].toTime`}
                                              control={control}
                                              render={({
                                                field,
                                                fieldState: { error },
                                              }) => (
                                                <TimePickerComponent
                                                  // type="small"
                                                  {...field}
                                                  error={!!error}
                                                  disabled={isClosed}
                                                  helperText={
                                                    error ? error.message : null
                                                  }
                                                  onChange={(time) => {
                                                    const formattedTime =
                                                      dayjs(time).format("hh:mm a");
                                                    field.onChange(formattedTime);
                                                  }}
                                                  postText="To"
                                                />
                                              )}
                                            />
                                          </div>
                                          <div className="">
                                            {isClosed ||
                                              (days.schedule.length !== 1 && (
                                                <DeleteOutlineOutlinedIcon
                                                  className="text-[#7F7F7F]"
                                                  onClick={() =>
                                                    handleRemoveSchedule(
                                                      dayIndex,
                                                      slotIndex
                                                    )
                                                  }
                                                />
                                              ))}
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                    <div className="flex gap-3 items-center">
                                      <h1 className="flex items-center">OR</h1>
                                      <div className="flex items-center">
                                        <Controller
                                          control={control}
                                          name={`Timing[${dayIndex}].isClosed`}
                                          render={({ field }) => (
                                            <FormControlLabel
                                              sx={{ width: "fit-content", margin: "0px" }}
                                              control={
                                                <Checkbox
                                                  {...field}
                                                  inputProps={{
                                                    "aria-label": "controlled",
                                                  }}
                                                  checked={field.value}
                                                  color="success"
                                                  sx={{ width: "42px" }}
                                                  checkedIcon={<img src={checked_box} className="w-[24px] h-[24px] shrink-0" alt="checked" />}
                                                />
                                              }
                                              label="Closed"
                                            />
                                          )}
                                        />
                                      </div>
                                      <div
                                        className={` ${isClosed ? "invisible" : "flex-shrink-0"
                                          }`}
                                      >
                                        <Tooltip title="Add multiple timings"
                                          arrow
                                          componentsProps={{
                                            tooltip: {
                                              sx: {
                                                bgcolor: 'white',
                                                '& .MuiTooltip-arrow': {
                                                  color: 'white',
                                                },
                                                color: "#4d4d4d",
                                                width: "8rem",
                                                textAlign: "center",
                                              },
                                              boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.2)",
                                            },
                                          }}
                                          placement="top"
                                        >
                                          <img
                                            src={plus}
                                            className="w-[25px] h-[25px] flex flex-shrink-0 items-center cursor-pointer"
                                            onClick={() => handleAppendSchedule(dayIndex)}
                                            alt="Add time slot"
                                          />
                                        </Tooltip>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          :
                          <div className="font-medium text-base">
                            {watch("Timing")?.map(timeList => {
                              return (
                                <div className={`flex ${timeList?.isClosed && "text-[#757373]"}`}>
                                  <div className="w-[120px]">{timeList?.day}</div>
                                  {timeList?.isClosed ? "Closed" :
                                    <div>
                                      {timeList?.schedule.map(time => {
                                        return (
                                          <div className="uppercase flex gap-1">
                                            <div>{time?.fromTime}</div>
                                            <div>-</div>
                                            <div>{time?.toTime}</div>
                                          </div>
                                        )
                                      })}
                                    </div>
                                  }
                                </div>
                              )
                            })}
                          </div>
                        }
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="flex  ">
                      <h1 className="w-1/3 mt-3 font-[600]  block mb-2 text-[14px] text-[#757373]">
                        DESCRIPTION
                      </h1>
                      {editModeActive ?
                        <div className="w-2/3">
                          <Controller
                            name="Description"
                            control={control}
                            render={({ field, fieldState: { error } }) => {
                              return (
                                <CustomTextInput
                                  {...field}
                                  variant="outlined"
                                  error={!!error}
                                  multiline
                                  rows={3}
                                  helperText={error ? error.message : null}
                                />
                              );
                            }}
                          />
                        </div>
                        :
                        <div className="w-1/3">
                          <p className="flex items-center h-full font-medium text-base text-wrap">
                            {getValues("Description")}
                          </p>
                        </div>
                      }
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="flex  items-center ">
                      <h1 className="w-1/3 mt-3 font-[600]  block mb-2 text-[14px] text-[#757373]">
                        YOUTUBE LINK
                      </h1>
                      <div className="w-1/3">
                        {editModeActive ?
                          <Controller
                            name="youtubeURL"
                            control={control}
                            render={({ field, fieldState: { error } }) => {
                              return (
                                <CustomTextInput
                                  {...field}
                                  variant="outlined"
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                />
                              );
                            }}
                          />
                          :
                          <a className="flex items-center h-full font-medium text-base text-wrap" href={getValues("youtubeURL") || "#"} target={getValues("youtubeURL") && "_blank"} rel="noreferrer">
                            {getValues("youtubeURL") || "-"}
                          </a>
                        }
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="flex  items-center ">
                      <h1 className="w-1/3 mt-3 font-[600]  block mb-2 text-[14px] text-[#757373]">
                        INSTAGRAM LINK
                      </h1>
                      <div className="w-1/3">
                        {editModeActive ?
                          <Controller
                            name="instagramURL"
                            control={control}
                            render={({ field, fieldState: { error } }) => {
                              return (
                                <CustomTextInput
                                  {...field}
                                  variant="outlined"
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                />
                              );
                            }}
                          />
                          :
                          <a className="flex items-center h-full font-medium text-base text-wrap" href={getValues("instagramURL") || "#"} target={getValues("instagramURL") && "_blank"} rel="noreferrer">
                            {getValues("instagramURL") || "-"}
                          </a>
                        }
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="flex  items-center ">
                      <h1 className="w-1/3 mt-3 font-[600]  block mb-2 text-[14px] text-[#757373]">
                        FACEBOOK LINK
                      </h1>
                      <div className="w-1/3">
                        {editModeActive ?
                          <Controller
                            name="facebookURL"
                            control={control}
                            render={({ field, fieldState: { error } }) => {
                              return (
                                <CustomTextInput
                                  {...field}
                                  variant="outlined"
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                />
                              );
                            }}
                          />
                          :
                          <a className="flex items-center h-full font-medium text-base text-wrap" href={getValues("facebookURL") || "#"} target={getValues("facebookURL") && "_blank"} rel="noreferrer">
                            {getValues("facebookURL") || "-"}
                          </a>
                        }
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="flex  items-center ">
                      <h1 className="w-1/3 mt-3 font-[600]  block mb-2 text-[14px] text-[#757373]">
                        GOOGLE BUSINESS LINK
                      </h1>
                      <div className="w-1/3">
                        {editModeActive ?
                          <Controller
                            name="googleBusinessURL"
                            control={control}
                            render={({ field, fieldState: { error } }) => {
                              return (
                                <CustomTextInput
                                  {...field}
                                  variant="outlined"
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                />
                              );
                            }}
                          />
                          :
                          <a className="flex items-center h-full font-medium text-base text-wrap" href={getValues("googleBusinessURL") || "#"} target={getValues("googleBusinessURL") && "_blank"} rel="noreferrer">
                            {getValues("googleBusinessURL") || "-"}
                          </a>
                        }
                      </div>
                    </div>
                  </Grid>
                </Grid>
                {/*                 

                right side cod
                
                
                
                
                */}

                <Grid
                  container
                  item
                  xs={12}
                  lg={4}
                  sx={{ display: "flex", flexDirection: "column", width: "100%" }}
                >
                  <Grid item sx={{ display: "flex", flexDirection: "column", gap: "20px", padding: "8px 0px 32px 0px", borderBottom: "1px solid #e8e8e8", alignItems: "center" }}>
                    <div className="flex items-center justify-between w-[343px] px-2">
                      <h1 className=" text-[#3E3E3E] font-poppins text-[24px] font-medium">
                        Logo
                      </h1>
                      <p className="text-[#B80E0C] underline font-medium cursor-pointer" onClick={() => {
                        setMobileView(true);
                      }}>Check mobile view</p>
                    </div>

                    {/* <Controller
                      name="logoURL"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <div className="flex gap-[20px] items-center justify-center border-[#1C1C1C1A] w-[343px] px-2">
                          <div className="">
                            <DragAndDropLogoPic
                              {...field}
                              close={false}
                              value={field.value}
                              error={!!error}
                              helperText={error ? error.message : null}
                            //   handleRegenerate={handleRegenerate}
                            />
                          </div>
                          {field.value && (
                            <ButtonWithIcon
                              disabled={!editModeActive}
                              onClick={() => {
                                setModelType("logo")
                                toggleLogoModel()
                              }}
                              icon={penSvg} text="Replace Logo" sx={{ height: "fit-content" }} />
                          )}
                        </div>
                      )}
                    /> */}
                    {/* {console.log(getValues("logoURL")?.url || getValues("logoURL"))} */}
                    <Controller
                      name="logoURL"
                      control={control}
                      rules={{ required: "Logo is required" }}
                      render={({ field, fieldState: { error } }) => (
                        <div className="px-2">
                          {getValues("logoURL")?.url || getValues("logoURL") ?
                            <div className={`flex gap-[20px] items-center justify-center border-[#1C1C1C1A] w-[343px] px-2`}>
                              <img src={getValues("logoURL")?.url || getValues("logoURL")} alt="" className="rounded-full w-36 h-36 object-cover" />
                              <ButtonWithIcon
                                disabled={!editModeActive}
                                onClick={() => {
                                  setModelType("logo")
                                  toggleLogoModel()
                                }}
                                icon={penSvg} text="Replace Logo"
                                sx={{ height: "fit-content" }}
                              />
                            </div>
                            :
                            <div
                              className={`p-10 border-dashed border-2  border-grey-500 rounded-lg w-[343px] ${error ? 'border-[#B80E0C]' : ''}`}
                              onClick={() => {
                                setModelType("logo")
                                toggleLogoModel()
                              }}
                            >
                              <p className="text-center font-normal">
                                {/* Drag & drop your {modelType === "banner" ? "banner" : "logo"}<br /> OR <br />
                            <span className="text-[#B80E0C]">Browse your files</span> */}
                                <span className="text-[#B80E0C]">Click here to upload</span>
                              </p>
                            </div>
                          }
                          {error && <p className="mt-1 text-xs text-[#B80E0C]">{error.message}</p>}
                        </div>
                      )}
                    />
                  </Grid>

                  <Grid item sx={{ display: "flex", flexDirection: "column", gap: "20px", padding: "32px 0px", borderBottom: "1px solid #e8e8e8", alignItems: "center" }}>
                    <h1 className="text-[#3E3E3E] font-poppins text-[24px] font-medium w-[343px] px-2">
                      Banner image
                    </h1>

                    {/* <Controller
                      name="bannerURL"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <div className="flex flex-col items-start gap-[20px] w-[343px] px-2 justify-center">
                          <DragAndDropLogoPic
                            type="banner"
                            {...field}
                            close={false}
                            error={!!error}
                            helperText={error ? error.message : null}
                          //   handleRegenerate={handleRegenerate}
                          />

                          {field.value && (
                            <ButtonWithIcon
                              disabled={!editModeActive}
                              onClick={() => {
                                setModelType("banner")
                                toggleLogoModel()
                              }}
                              icon={penSvg} text="Replace Banner Image" sx={{ height: "fit-content" }} />
                          )}
                        </div>
                      )}
                    /> */}
                    <Controller
                      name="bannerURL"
                      control={control}
                      rules={{ required: "Banner is required" }}
                      render={({ field, fieldState: { error } }) => (
                        <div className="px-2">
                          {getValues("bannerURL")?.url || getValues("bannerURL") ?
                            <div className="flex flex-col gap-[20px] items-center justify-center border-[#1C1C1C1A] w-[343px] px-2">
                              <img src={getValues("bannerURL")?.url || getValues("bannerURL")} alt="" className="w-full h-fit object-cover" />
                              <ButtonWithIcon
                                disabled={!editModeActive}
                                onClick={() => {
                                  setModelType("banner")
                                  toggleLogoModel()
                                }}
                                icon={penSvg} text="Replace Banner"
                                sx={{ height: "fit-content", alignSelf: "flex-start" }}
                              />
                            </div>
                            :
                            <div
                              className={`p-10 border-dashed border-2  border-grey-500 rounded-lg w-[343px] ${error ? 'border-[#B80E0C]' : ''}`}
                              onClick={() => {
                                setModelType("banner")
                                toggleLogoModel()
                              }}
                            >
                              <p className="text-center font-normal">
                                {/* Drag & drop your {modelType === "banner" ? "banner" : "logo"}<br /> OR <br />
                            <span className="text-[#B80E0C]">Browse your files</span> */}
                                <span className="text-[#B80E0C]">Click here to upload</span>
                              </p>
                            </div>
                          }
                          {error && <p className="mt-1 text-xs text-[#B80E0C]">{error.message}</p>}
                        </div>
                      )}
                    />
                  </Grid>

                  <Grid item sx={{ display: "flex", flexDirection: "column", gap: "20px", padding: "32px 0px", alignItems: "center" }}>
                    <h1 className="text-[#3E3E3E] font-poppins text-[24px] font-medium w-[343px] px-2">
                      Restaurant Photos
                    </h1>

                    <div className="flex flex-wrap justify-start gap-[20px] w-[343px] px-2">
                      {watch("restaurantPhotos")?.map((photo, index) => {
                        return (
                          <div className={`rounded-[9.49px] border-[#FFDCDC] border-[0.79px] cursor-pointer ${editModeActive ? "pointer-events-auto" : "pointer-events-none"}`}
                            onClick={() => {
                              if (editModeActive) {
                                setModelType(`restaurantPhotos[${index}]`)
                                toggleLogoModel()
                              }
                            }}
                          >
                            <img className="h-[100px] w-[87.68px] rounded-[9.49px]" src={photo?.url} alt="" />
                          </div>
                        )
                      })}
                      <div className="h-[100px] w-[87.68px] rounded-[9.49px] bg-[#F7F7F7] border-[#D9D9D9] border-[0.79px] flex items-center justify-center cursor-pointer"
                        onClick={() => {
                          if (editModeActive) {
                            setModelType(`restaurantPhotos[${watch("restaurantPhotos")?.length}]`)
                            toggleLogoModel()
                          }
                        }}
                        style={{ opacity: editModeActive ? 1 : 0.5, pointerEvents: editModeActive ? 'auto' : 'none' }}
                      >
                        <img src={plus_large} alt="" />
                      </div>

                    </div>

                    {/* <Controller
                    name="bannerURL"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <div className="flex flex-col items-start gap-[20px] w-[343px] px-2 justify-center">
                        <DragAndDropLogoPic
                          type="banner"
                          {...field}
                          close={false}
                          error={!!error}
                          helperText={error ? error.message : null}
                        //   handleRegenerate={handleRegenerate}
                        />

                        {field.value && (
                          <ButtonWithIcon
                            onClick={() => {
                              setModelType("banner")
                              toggleLogoModel()
                            }}
                            icon={penSvg} text="Replace Banner Image" sx={{ height: "fit-content" }} />
                        )}
                      </div>
                    )}
                  /> */}
                  </Grid>
                </Grid>
              </Grid>)}
          </Grid>
        </div>
        {/* )} */}
      </form>

      {/* Image model */}
      <BasicModal
        open={LogoModel}
        close={() => { toggleLogoModel(); setFile(null) }}
        maxWidth="398px"
        width="fit-content"
      >
        <div className="flex flex-col gap-6">
          <div className="flex justify-between">
            <p className=" text-sm font-medium text-[18px]">{modelType?.includes("restaurantPhotos") ? "Restaurant" : modelType === "logo" ? "Logo" : "Banner"} Image</p>
            <CloseIcon sx={{ fontSize: "18px", cursor: "pointer" }} onClick={() => { toggleLogoModel(); setFile(null) }} />
          </div>
          <div>
            <div className=" flex  flex-col items-center bg-[#F8F8F8] gap-2">
              <Controller
                name={modelType === "logo" ? "logoURL" : modelType === "banner" ? "bannerURL" : modelType}
                control={control}
                render={({ field, fieldState: { error }, name }) => (
                  <div className="flex justify-around ">
                    <div className={`${modelType === "logo" ? "p-[1rem]" :
                      modelType === "banner" ? "p-[1rem]" :
                        modelType.includes("restaurantPhotos") ? "p-[30px]" :
                          ""
                      }`}>
                      <DragAndDropLogoPic
                        {...field}
                        close={false}
                        error={!!error}
                        // helperText={error ? error.message : null}
                        type={modelType}
                        name={name}
                        ref={dropzoneRef}
                        disableInfo
                      />
                    </div>
                  </div>
                )}
              />
            </div>
            <div className="flex justify-between items-center">
              <h6 className=" max-w-1/6 font-base text-xs">
                .png and .jpeg only,<br /> up to 5MB{" "}
              </h6>
              <h1
                className="font-semibold underline cursor-pointer"
                onClick={() => {
                  // setValue(modelType === "logo" ? "logoURL" : modelType === "banner" ? "bannerURL" : modelType, null);
                  triggerDropzone();
                  setFile(null);
                }}
              >
                Browse {modelType?.includes("restaurantPhotos") ? "restaurant" : modelType || ""} Image
              </h1>
            </div>
          </div>

          <div className="flex justify-end items-center gap-2">
            {/* {console.log(watch(modelType === "logo" ? "logoURL" : modelType === "banner" ? "bannerURL" : modelType, null) !== null)} */}
            <BasicButton
              onClick={() => {
                toggleLogoModel();
                if (modelType.includes("restaurantPhotos")) {
                  const index = parseInt(modelType.split("[")[1].split("]")[0], 10);
                  if (!isNaN(index) && index >= 0) {
                    const updatedPhotos = [...getValues('restaurantPhotos')];
                    updatedPhotos.splice(index, 1);
                    setValue('restaurantPhotos', updatedPhotos);
                  }
                } else {
                  setValue(modelType === "logo" ? "logoURL" : "bannerURL", null);
                }
                setFile(null);
              }}
              color={"genieRed"}
              sx={{
                display: modelType?.includes("restaurantPhotos") ? "block" : "none",
                textTransform: "none",
                width: "100%",
                height: "36px",
                color: "white",
                opacity: watch(modelType === "logo" ? "logoURL" : modelType === "banner" ? "bannerURL" : modelType, null) !== null ? "1" : "0.5",
              }}
              disabled={watch(modelType === "logo" ? "logoURL" : modelType === "banner" ? "bannerURL" : modelType, null) === null}
            >
              Delete {titleCase(modelType?.includes("restaurantPhotos") ? "image" : modelType || "")}
            </BasicButton>
            <BasicButton
              onClick={() => {
                // if (modelType.includes("restaurantPhotos")) {
                //   setValue(modelType + ".url", null)
                // }
                setFile(null)
                toggleLogoModel()
              }}
              color={"genieRed"}
              sx={{
                textTransform: "none",
                width: "100%",
                height: "36px",
                color: "white",
                opacity: watch(modelType === "logo" ? "logoURL" : modelType === "banner" ? "bannerURL" : modelType)?.file ? 1 : 0.5,
                pointerEvents: watch(modelType === "logo" ? "logoURL" : modelType === "banner" ? "bannerURL" : modelType)?.file ? "auto" : "none",
              }}
              disabled={!watch(modelType === "logo" ? "logoURL" : modelType === "banner" ? "bannerURL" : modelType)?.file}
            >
              {(() => {
                const value = watch(modelType);
                if (modelType?.includes("restaurantPhotos")) {
                  return value?.url ? "Update Image" : "Add Image";
                } else {
                  return `Update ${titleCase(modelType || "")}`
                }
              })()}
            </BasicButton>
          </div>
        </div>
      </BasicModal>

      {/* Mobile view model */}
      <BasicModal
        open={mobileView}
        // open={true}
        close={() => {
          setMobileView(false);
        }}
        maxWidth="auto"
      >
        <div className="flex flex-col gap-3">
          <div className="flex justify-between">
            <p className=" text-sm font-medium text-[18px]">Mobile View</p>
            <CloseIcon sx={{ fontSize: "18px", cursor: "pointer" }} onClick={() => {
              setMobileView(false);
            }} />
          </div>

          {/* <div className="w-[222.29px] h-[482px]">
            <MobileView
              logoURL={watch("logoURL")}
              bannerURL={watch("bannerURL")}
              restaurantName={watch("name")}
              restaurantPhotos={watch("restaurantPhotos")}
              restaurantTiming={watch("Timing")}
              youtubeURL={watch("youtubeURL")}
              instagramURL={watch("instagramURL")}
              facebookURL={watch("facebookURL")}
              googleBusinessURL={watch("googleBusinessURL")}
              address={watch("Address")}
              deliveryTime={watch("deliveryTime")}
            />
          </div> */}

          <div className="w-[222.29px] h-[482px] relative overflow-hidden bg-white shadow-lg">
            {/* Mobile frame wrapper with scaling transform */}
            <div className="absolute top-0 left-0 w-full h-full origin-top-left" style={{
              transform: 'scale(0.46)',
              width: '483px',
              height: '1048px',
            }}>
              <div style={{ width: '483px' }}>
                <DinerMobileView
                  logoURL={watch("logoURL")}
                  bannerURL={watch("bannerURL")}
                  address={watch("Address")}
                  restaurantName={watch("name")}
                  youtubeURL={watch("youtubeURL")}
                  instagramURL={watch("instagramURL")}
                  facebookURL={watch("facebookURL")}
                />
              </div>
            </div>
          </div>

          <BasicButton
            onClick={() => {
              setMobileView(false);
            }}
            color={"genieRed"}
            sx={{
              textTransform: "none",
              width: "100%",
              height: "36px",
              color: "white",
            }}
          >
            Done
          </BasicButton>
        </div>
      </BasicModal>

      {/* Location Model */}
      <BasicModal
        open={addressModel}
        // open={true}
        close={() => {
          setAddressModel(false);
        }}
        // maxWidth="600px"
        sx={{ width: "600px" }}
      >
        <div className="flex flex-col gap-[20px]">
          <div className="flex justify-between">
            <p className=" text-sm font-medium text-[18px]">Location</p>
            <CloseIcon sx={{ fontSize: "18px", cursor: "pointer" }} onClick={handleAddress} />
          </div>

          <div className=" flex  flex-col items-center gap-[20px]">
            <Controller
              name="searchLocation"
              control={control}
              // rules={{
              //   required: " Address is Required",
              // }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <div className="flex w-full  gap-2">
                    {/* <Autocomplete
                      onLoad={onLoad}
                      onPlaceChanged={onPlaceChanged}
                      className="w-full"
                      restrictions={{
                        country: "AE",
                      }}
                    >
                      <TextInput
                        {...field}
                        variant="outlined"
                        postContent={
                          <div
                            className="flex items-center text-genieRed"
                            onClick={() => {
                              getCurrentLocation();
                            }}
                          >
                            <img src={mapPointer} alt="mapPointer" />
                          </div>
                        }
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    </Autocomplete> */}
                    <Autocomplete
                      onLoad={onLoad}
                      onPlaceChanged={onPlaceChanged}
                      className="w-full"
                      options={{
                        zIndex: 1000 // Set a higher z-index for the dropdown
                      }}
                    // restrictions={{
                    //   country: "AE",
                    // }}
                    >
                      {/* <TextInput
                          {...field}
                          variant="outlined"
                          // label="Address"
                          error={!!error}
                          helperText={error ? error.message : null}
                        /> */}
                      <TextInput
                        {...field}
                        variant="outlined"
                        placeholder="Search your restaurant location"
                        // value={searchValue}
                        // onChange={handleSearchChange}
                        // onFocus={handleSearchFocus}
                        // onBlur={handleSearchBlur}
                        // onKeyDown={handleSearchKeyDown}
                        borderRadius="30px"
                        transparentBorder
                        backgroundColor="#fff"
                        preContent={<img src={search} alt="" />}
                        // postContent={
                        //   <img src={close} alt=""
                        //     onClick={() => {
                        //       setSearchValue("")
                        //       setShowNewScreen(false)
                        //       setSearchResultIsActive(false)
                        //     }}
                        //   />
                        // }
                        sx={{
                          width: "100%",
                          borderRadius: "30px",
                          backgroundColor: "#fff",
                          '& .MuiInputBase-input::placeholder': {
                            color: '#545454'
                          },
                          border: "1px solid #D9D9D9"
                        }}
                      />
                    </Autocomplete>
                    <div
                      className="text-[#b80e0c] self-center"
                      onClick={() => {
                        getCurrentLocation();
                      }}
                    >
                      <img src={mapIcon} alt="" />
                    </div>
                  </div>
                );
              }}
            />
            <Controller
              name="locationURL"
              control={control}
              rules={{
                required: "Location is Required",
              }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <div className="flex w-full flex-col gap-2">
                    <MapComponent
                      location={location}
                      handleDragEvent={handleDragEvent}
                      handleClick={handleClick}
                    />
                  </div>
                );
              }}
            />
          </div>
          <div className="flex justify-end items-center gap-2">
            <BasicButton
              onClick={handleAddress}
              color={"genieRed"}
              sx={{
                textTransform: "none",
                width: "100%",
                height: "36px",
                color: "white",
              }}
            >
              Confirm
            </BasicButton>
          </div>
        </div>
      </BasicModal>
    </>
  );
}

export default RestaurantInformation;
