import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import React, { useState } from 'react'

const DetailsDropdown = ({ headerText, content, width = "w-[168px]", className }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div
            className={`${isOpen ? "w-auto" : width} ${className} border border-[#d1d1d1] ${!isOpen ? "rounded-full" : "rounded-lg"} px-2 cursor-pointer`}
            onClick={() => setIsOpen(!isOpen)}
        >
            <div className="flex justify-between items-center">
                <span className={`${isOpen ? "text-[#757373]" : "text-[#3e3e3e]"} font-normal text-xs leading-[22px]`}>{headerText}</span>
                <ArrowDropDownIcon sx={{ color: "#ed1703", transform: isOpen ? "rotate(180deg)" : "rotate(0deg)", transition: "transform 0.2s ease-in-out", height: "22px" }} />
            </div>

            <div className={`text-wrap pb-1 ${isOpen ? 'block' : 'hidden'}`}>
                {content}
            </div>
        </div>
    )
}

export default DetailsDropdown