import React, { useContext, createContext, useState } from "react";
import {
  AddAllergyPath,
  AddCategoryPath,
  AddMenu,
  AddTypePath,
  addSpicePath,
  addTable,
  updateTable,
  aiPhotoGeneration,
  changeSequence,
  editMenu,
  getAllAllergies,
  getAllFoodType,
  getAllInsights,
  getAllMenuByID,
  getAllOrderDetails,
  getAllSpiceLevel,
  getAllTabContent,
  getAllTabList,
  getAllTable,
  getDeleteAllergyList,
  getDeleteCategoryList,
  getDeleteMenu,
  getDeleteSpiceList,
  getDeleteTypeList,
  getMenuDetails,
  getOrderHistory,
  updateOrderStatus,
  uploadImage,
  uploadImageAsUrl,
  getPartnerInfo,
  getBriefPartnerInfo,
  updatePartnerInfo,
  getUserDetailsFromAccess,
  getOrderById,
  updateCategory,
  getRequestedOrders,
  setOrderAsComplete,
  searchByItemName,
  searchOrderByNumber
} from "../../controllers/Restaurant";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import useToken from "../../hooks/useToken";
import axios from "axios";
import { convertToFile } from "../../helper";

const RestaurantContext = createContext(undefined);

export const useRestaurant = () => useContext(RestaurantContext);

function useProvideRestaurant() {
  const { getUserDetails, setToken, saveUser } = useToken();

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState(false);
  const [listArray, setListArray] = useState([]);
  const [headerText, setHeaderText] = useState("");
  const [modelText, setModelText] = useState("");
  const [addListFormValue, setAddListFormValue] = useState("");
  const [addList, setAddList] = useState(false);
  const [responseTableData, setResponseTableData] = useState([]);
  const [AiPhoto, setAiPhoto] = useState([]);
  const [qrScanned, setQrSCanned] = useState([]);
  const [isTableDrawer, setIsTableDrawer] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [gptCheck, setGptCheck] = useState(false);
  const [autocomplete, setAutocomplete] = useState(null);
  const [tokenAvailable, setTokenAvailable] = useState(false);
  const [location, setLocation] = useState({ latitude: "", longitude: "" });
  const [notificationDrawer, setNotificationDrawer] = useState(false)
  // const [uploadUrl, setUploadUrl] = useState("");

  const user = getUserDetails();
  const mealTimingOptions = [
    {
      value: "Breakfast",
      label: "Breakfast",
    },
    {
      value: "Brunch",
      label: "Brunch",
    },
    {
      value: "Lunch",
      label: "Lunch",
    },
    {
      value: "Afternoon/Tea Time",
      label: "Afternoon/Tea Time",
    },
    {
      value: "Dinner",
      label: "Dinner",
    },
    {
      value: "Late Night",
      label: "Late Night",
    },
  ];

  const useGetTabList = () =>
    useQuery(["getAllTabList"], () => getAllTabList(), {
      enabled: true,
      select: (data) => data?.data,
      onSuccess: (data) => {
        setListArray(data?.result);
      },
    });

  const useGetInsights = () => {
    useQuery(["getInsights"], () => getAllInsights(), {
      enabled: true,
      select: (data) => data?.data,
      onSuccess: (data) => { },
    });
  };

  const useGetFoodType = (id) =>
    useQuery(["getAllFoodType"], () => getAllFoodType(id), {
      enabled: true,
      select: (data) => data?.data,
      onSuccess: (data) => { },
    });

  const useGetSpiceLevel = () =>
    useQuery(["getAllSpiceLevel"], () => getAllSpiceLevel(), {
      enabled: true,
      select: (data) => data?.data,
      onSuccess: (data) => { },
    });
  const useGetAllergies = () =>
    useQuery(["getAllAllergies"], () => getAllAllergies(), {
      enabled: true,
      select: (data) => data?.data,
      onSuccess: (data) => { },
    });

  const useGetPartnerInfo = () =>
    useQuery(["getPartnerInfo"], () => getPartnerInfo(), {
      enabled: true,
      select: (data) => data?.data,
      onSuccess: (data) => { },
    });
  const useGetBriefPartnerInfo = () =>
    useQuery(["getBriefPartnerInfo"], () => getBriefPartnerInfo(), {
      enabled: !!getUserDetails()?._id,
      select: (data) => data?.data,
      onSuccess: (data) => {
      },
    })
  const useGetTabListContent = (id) =>
    useQuery(["getAllContentData", id], () => getAllTabContent(id), {
      enabled: !!id,
      select: (data) => data?.data,
      onSuccess: (data) => { },
    });
  const useSearchByItemName = (query) =>
    useQuery(["useSearchByItemName", query], () => searchByItemName(query), {
      enabled: !!query,
      select: (data) => data?.data,
      onSuccess: (data) => { },
    });
  const useSearchOrderByNumber = (query) =>
    useQuery(["useSearchOrderByNumber", query], () => searchOrderByNumber(query), {
      enabled: !!query,
      select: (data) => data?.data,
      onSuccess: (data) => { },
    });
  const useGetMenuById = (id) =>
    useQuery(["getAllMenuByID", id], () => getAllMenuByID(id), {
      enabled: !!id,
      select: (data) => data?.data,
      onSuccess: (data) => { },
      // cacheTime: 0,
    });

  const useGetTable = () =>
    useQuery(["getAllTable"], () => getAllTable(), {
      enabled: true,
      select: (data) => data?.data,
      onSuccess: (data) => { },
      cacheTime: 0,
    });
  const useGetOrderDetails = ({ page, pageSize, toDate, fromDate, partnerId }) =>
    useQuery(["getAllDetails", page, pageSize, toDate || null, fromDate || null], () => getAllOrderDetails({ page, pageSize, toDate: toDate || undefined, fromDate: fromDate || undefined, partnerId }), {
      enabled: !!partnerId && !!page && !!pageSize,
      select: (data) => data?.data,
      onSuccess: (data) => { },
      cacheTime: 0,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
    });
  const useGetOrderById = (orderId) =>
    useQuery(["getOrderById", orderId], () => getOrderById(orderId), {
      enabled: !!orderId,
      select: (data) => data?.data,
      onSuccess: (data) => { },
      cacheTime: 0
    });

  const useAiMenuDetails = (data) =>
    useQuery(["menuDetails", data], () => getMenuDetails(data), {
      enabled: !!data && gptCheck,
      select: (data) => data?.data,
      onSuccess: (data) => {
        onAIPhotoGeneration(data?.result);
        setGptCheck(false);
      },
      onError: () => {
        setGptCheck(false);
      },
      cacheTime: 0,
    });

  const useOrderHistory = ({ page, pageSize, toDate, fromDate }) =>
    useQuery(
      ["getOrderHistory", page, pageSize, toDate, fromDate],
      () => getOrderHistory({ page, pageSize, toDate, fromDate }),
      {
        enabled: !!page && !!pageSize,
        select: (data) => data?.data,
        onSuccess: (data) => { },
        cacheTime: 0,
      }
    );

  const useRequestedOrders = () =>
    useQuery(
      ["getRequestedOrders"],
      () => getRequestedOrders(),
      {
        enabled: !!getUserDetails()?._id,
        select: (data) => data?.data?.result,
        onSuccess: (data) => { },
        cacheTime: 0,
      }
    )

  const { mutate: mutateOrderComplete, isLoading: orderCompleteIsLoading } = useMutation(setOrderAsComplete, {
    onMutate: async (data) => {
      // console.log(data)
      await queryClient.cancelQueries(["getRequestedOrders"])
      const previousRequestedOrders = queryClient.getQueryData(["getRequestedOrders"])

      queryClient.setQueryData(["getRequestedOrders"], (oldData) => {
        return {
          ...oldData,
          data: {
            ...oldData?.data,
            // result: [
            //   ...oldData?.data?.result?.filter(order => order._id !== data?.tableId)
            // ]
            result: {
              ...oldData?.data?.result,
              requestedOrders: [
                ...oldData?.data?.result?.requestedOrders?.filter(order => order._id !== data?.tableId),
                {
                  ...oldData?.data?.result?.requestedOrders?.find(order => order._id === data?.tableId) || {},
                  isLoading: true
                }
              ]
            }
          }
        }
      })

      return { previousRequestedOrders }
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries(['getRequestedOrders'])
      console.log(res)
    },
    onError: (err, variables, context) => {
      queryClient.setQueryData(["getRequestedOrders"], context.previousRequestedOrders)
    }
  })

  const completeRequestedOrder = async (orderIds, tableId) => {
    await Promise.all(orderIds.map(orderId => mutateOrderComplete({ orderId, tableId })))
  }

  const { mutate: mutateUpdateOrderStatus, isLoading: updateOrderStatusIsLoading } = useMutation(updateOrderStatus, {
    onSuccess: async (res) => {
      console.log(res)
      // toast.success("Order status updated successfully");
      queryClient.invalidateQueries(["getAllDetails"])
    },
  });

  const OnUpdateOrderStatus = (orderId, data) => {
    mutateUpdateOrderStatus({ orderId, data })
  }

  const {
    mutate: mutateAIPhotoGeneration,
    isLoading: aiPhotoGenerationIsLoading,
  } = useMutation(aiPhotoGeneration, {
    onSuccess: async (res) => {
      setAiPhoto(res?.data?.result?.data);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const onAIPhotoGeneration = (data) => {
    // const alteredData = {
    //   prompt: `${JSON.parse(data).dish_name}${JSON.parse(data)?.description}`,
    // };
    const alteredData = {
      prompt: `${data.dish_name}${data?.description}`,
    };

    mutateAIPhotoGeneration(alteredData);
  };

  const { mutateAsync: mutateUpdatePartnerInfo, isLoading: updatePartnerInfoIsLoading } = useMutation(
    updatePartnerInfo,
    {
      onSuccess: async (res) => {
        toast.success("Profile updated successfully");
        queryClient.invalidateQueries("getPartnerInfo");
        queryClient.refetchQueries("getBriefPartnerInfo");
      },
      onError: (error) => {
        console.error("Error updating partner info:", error);
      },
    }
  );
  const { mutate: mutateAddTable, isLoading: addTableIsLoading } = useMutation(
    addTable,
    {
      onSuccess: async (res) => {
        // setOpenAddModal(false)
        toast.success("Table added Successfully");
        // navigate("restaurant/menu");
        queryClient.refetchQueries("getAllTable");
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message || "Error adding table");
        console.log(error);
      },
    }
  );
  const OnAddTable = (data) => {
    mutateAddTable(data || {});
  };

  const { mutate: mutateUpdateTable, isLoading: updateTableIsLoading } = useMutation(updateTable, {
    onSuccess: async (res) => {
      // toast.success("Table updated Successfully");
      queryClient.refetchQueries("getAllTable");
    },
  });

  const OnUpdateTable = (tableId, data) => {
    mutateUpdateTable({ tableId, data });
  };

  const { mutate: mutateAddMenu } = useMutation(AddMenu, {
    onSuccess: async (res) => {
      toast.success("Menu added Successfully");
      navigate("menu");
      queryClient.resetQueries("getAllTabList");
      queryClient.refetchQueries("getAllContentData");
    },
    onError: (error) => {
      toast.error("Error adding menu");
      console.log(error);
    },
  });
  const { mutateAsync: mutateUploadImage } = useMutation(uploadImage, {
    onSuccess: async (res) => {
      console.log("Image upload successful")
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const { mutateAsync: mutateUploadImageAsUrl } = useMutation(uploadImageAsUrl, {
    onSuccess: async (res) => {
      console.log("Image upload successful")
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const onUpdatePartnerInfo = async (data) => {
    const uploadImageIfNeeded = async (imageData) => {
      if (!imageData?.file) return imageData?.url || null;
      const file = await convertToFile(imageData);
      const { data } = await mutateUploadImage(file);
      return data?.result?.s3URL;
    };

    const [restaurantPhotos, logoURL, bannerURL] = await Promise.all([
      Promise.all((data?.restaurantPhotos || []).map(uploadImageIfNeeded)),
      uploadImageIfNeeded(data?.logoURL),
      uploadImageIfNeeded(data?.bannerURL)
    ]);

    const transformedData = {
      _id: data?._id,
      name: data?.name,
      workingHours: data?.Timing?.map(({ _id, ...day }) => ({
        ...day,
        schedule: day.schedule.map(({ _id, ...rest }) => rest)
      })),
      address: data?.Address,
      locationURL: data?.locationURL,
      logoURL: logoURL || data?.logoURL,
      bannerURL: bannerURL || data?.bannerURL,
      customDomain: data?.customDomain,
      deliveryRadius: data?.deliveryRadius,
      cuisineType: data?.Cuisine?.map(cuisine => cuisine.value),
      deliveryFee: data?.deliveryFee,
      deliveryTime: data?.deliveryTime,
      description: data?.description,
      facebookURL: data?.facebookURL,
      instagramURL: data?.instagramURL,
      youtubeURL: data?.youtubeURL,
      zomatoURL: data?.zomatoURL,
      googleBusinessURL: data?.googleBusinessURL,
      dineInPayment: data?.dineInPayment,
      serviceType: data?.serviceType,
      restaurantPhotos: restaurantPhotos.map(url => ({ url })),
    };

    try {
      // const currentPartnerInfo = queryClient.getQueryData("getPartnerInfo");
      // const isCustomDomainUpdated = currentPartnerInfo?.result?.customDomain !== data.customDomain && data.customDomain !== undefined;

      await mutateUpdatePartnerInfo(transformedData);

      // if (isCustomDomainUpdated) {
      //   toast.info("Custom domain has been updated. Please log out and log back in for the changes to take effect.");
      // }
    } catch (error) {
      toast.error("Error updating partner info");
      queryClient.resetQueries("getPartnerInfo");
    }
  }

  const OnAddMenu = async (data, ParsedJSON) => {
    let response = ""
    if (data?.image instanceof File) {
      response = await mutateUploadImage(data?.image)
    } else {
      response = await mutateUploadImageAsUrl({ itemName: data?.item_name, url: data?.image?.url })
    }

    const transformedData = {
      partnerId: user?._id,
      foodCategoryId: data?.category?.value,
      item_name: data?.item_name,
      description: data?.description,
      oPrice: data?.oPrice,
      dPrice: data?.dPrice,
      portion: {
        min: data?.min,
        max: data?.max,
      },
      foodTypeId: data?.type?.value,
      spicinessLevel: data?.spicinessLevel,
      // TODO: Fix this
      delivery: data?.delivery,
      pickUp: data?.pickUp,
      dineIn: data?.dineIn || false,
      image: data?.image instanceof File ? {
        url: response.data.result.s3URL,
        AiGenerated: false,
      } : {
        url: response.data.result.s3URL,
        AiGenerated: data?.image?.AiGenerated,
      },
      ingredients: data?.ingredients,
      allergies: data?.allergies,
      customization: data?.customization,
    };

    addListFormValue &&
      transformedData?.items?.ingredients?.push(addListFormValue);
    mutateAddMenu(transformedData);
  };

  const { mutate: mutateEditMenu, isLoading: editMenuIsLoading } = useMutation(
    editMenu,
    {
      onSuccess: async (res) => {
        setAddListFormValue("");
        setAddList(false);
        queryClient.invalidateQueries(["getAllMenuByID"]);
        queryClient.refetchQueries("getAllTabList");

        queryClient.refetchQueries("getAllContentData");
        toast.success("Menu Item Updated Successfully");

      },
      onError: (error) => {
        console.log(error);
      },
    }
  );
  const OnEditMenu = async (data) => {
    let response = ""
    if (data?.image instanceof File) {
      response = await mutateUploadImage(data?.image)
    }
    else {
      response = data?.image?.url.indexOf(process.env.REACT_APP_S3_BUCKET_URL) === -1 ?
        await mutateUploadImageAsUrl({ itemName: data?.item_name, url: data?.image?.url })
        : data?.image?.url
    }

    const transformedData = {
      id: data._id,
      partnerId: user?._id,
      foodCategoryId: data?.category?.value,
      item_name: data?.item_name,
      description: data?.description,
      oPrice: data?.oPrice,
      dPrice: data?.dPrice,
      portion: {
        min: data?.min,
        max: data?.max,
      },
      foodTypeId: data?.type?.value,
      spicinessLevel: data?.spicinessLevel,
      delivery: data?.delivery,
      pickUp: data?.pickUp,
      dineIn: data?.dineIn,
      image: data?.image instanceof File ? {
        url: response.data.result.s3URL,
        AiGenerated: false,
      } : {
        url: response.data ? response.data.result.s3URL : response,
        AiGenerated: data?.image?.AiGenerated,
      },
      ingredients: data?.ingredients,
      allergies: data?.allergies,
      customization: data?.customization,
    };
    mutateEditMenu(transformedData);
  };

  const OnEditMenuIngredients = (data, ParsedJSON) => {
    console.log(data);
    const transformedData = {
      id: data._id,
      partnerId: user?._id,
      foodCategoryId: data?.foodCategory?._id,
      mealTiming: data?.mealTiming,
      items: {
        item_name: data?.items?.item_name,
        description: data?.items?.description,
        oPrice: data?.items?.oPrice,
        dPrice: data?.items?.dPrice,
        portion: {
          min: data?.items?.portion?.min,
          max: data?.items?.portion?.max,
        },
        spiceLevelId: data?.items?.spiceLevel?._id,
        foodTypeId: data?.items.foodType?._id,
        ingredients: ParsedJSON?.ingredients || [],
        image: {
          url: data?.image?.url,
          AiGenerated: data?.image?.AiGenerated,
        },
      },
    };

    addListFormValue &&
      transformedData?.items?.ingredients?.push(addListFormValue);

    mutateEditMenu(transformedData);
  };
  const OnEditMenuIsActive = (data) => {
    const transformedData = {
      id: data._id,
      partnerId: user?._id,
      foodCategoryId: data?.category?.value,
      delivery: data?.delivery,
      pickUp: data?.pickUp,
      dineIn: data?.dineIn,
    };

    mutateEditMenu(transformedData);
  };

  const { mutate: mutateDeleteMenu } = useMutation(getDeleteMenu, {
    onSuccess: () => {
      toast.success("Menu deleted Successfully");
      queryClient.invalidateQueries("getAllContentData");
      queryClient.resetQueries("getAllTabList")
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const HandleDeleteMenu = (id) => {
    mutateDeleteMenu(id);
    // queryClient.invalidateQueries("getAllTabList")
  };

  const { mutateAsync: mutateDeleteCategoryList, isLoading: deleteCategoryListIsLoading } = useMutation(
    getDeleteCategoryList,
    {
      onSuccess: () => {
        toast.success("Category deleted Successfully");
        // setOpenModal(false);
        // queryClient.invalidateQueries("getAllTabList")
        queryClient.resetQueries("getAllTabList")

      },
      onError: (err) => {
        toast.error(err?.response?.data?.message || "Error deleting category");
        console.log(err);
      },
    }
  );

  const HandleDeleteCategoryList = async (id) => {
    return await mutateDeleteCategoryList(id);
  };

  const { mutateAsync: mutateUpdateCategory, isLoading: updateCategoryIsLoading } = useMutation(updateCategory, {
    onSuccess: () => {
      toast.success("Category updated Successfully");
      queryClient.resetQueries("getAllTabList")
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message || "Error updating category");
      // toast.error(err?.response?.data?.message || "Error updating category");
      // console.log(err);
    },
  });

  const HandleUpdateCategory = async (id, data) => {
    return await mutateUpdateCategory({ categoryId: id, data })
  }

  const { mutateAsync: mutateDeleteTypeList, isLoading: deleteTypeListIsLoading } = useMutation(getDeleteTypeList, {
    onSuccess: () => {
      toast.success("Type deleted Successfully");
      // setOpenModal(false);
      queryClient.refetchQueries("getAllFoodType");
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message || "Error deleting type");
      console.log(err);
    },
  });

  const HandleDeleteType = async (id) => {
    return await mutateDeleteTypeList(id);
  };
  const { mutate: mutateDeleteSpiceList } = useMutation(getDeleteSpiceList, {
    onSuccess: () => {
      toast.success("Spice Level deleted Successfully");
      setOpenModal(false);
      queryClient.refetchQueries("getAllSpiceLevel");
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const HandleDeleteSpice = (id) => {
    mutateDeleteSpiceList(id);
  };
  const { mutate: mutateDeleteAllergyList } = useMutation(
    getDeleteAllergyList,
    {
      onSuccess: () => {
        toast.success("Ingredients causing allergies deleted Successfully");
        setOpenModal(false);
        queryClient.refetchQueries("getAllAllergies");
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );

  const HandleDeleteAllergy = (id) => {
    mutateDeleteAllergyList(id);
  };

  const { mutateAsync: mutateAddCategory, isLoading: addCategoryIsLoading } = useMutation(AddCategoryPath, {
    onSuccess: async (res) => {
      toast.success("Category added Successfully");
      queryClient.resetQueries("getAllTabList")
      setOpenModal(false);
      setAddList(false);
      setAddListFormValue("");
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message || "Error adding category");
      console.log(error);
    },
  });
  const AddCategory = async () => {
    const data = {
      food_category: addListFormValue,
    };
    return await mutateAddCategory(data);
  };

  const { mutateAsync: mutateAddType, isLoading: addTypeIsLoading } = useMutation(AddTypePath, {
    onSuccess: async (res) => {
      toast.success("Type added Successfully");
      queryClient.refetchQueries("getAllFoodType");
      setOpenModal(false);
      setAddList(false);
      setAddListFormValue("");
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message || "Error adding type");
      console.log(error);
    },
  });

  const AddType = async () => {
    const data = {
      food_type: addListFormValue,
    };
    return await mutateAddType(data);
  };

  const { mutate: mutateAddAllergy } = useMutation(AddAllergyPath, {
    onSuccess: async (res) => {
      toast.success("Ingredients causing allergies added Successfully");
      queryClient.refetchQueries("getAllAllergies");
      setOpenModal(false);
      setAddList(false);
      setAddListFormValue("");
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const addAllergy = () => {
    const data = {
      allergen: addListFormValue,
    };
    mutateAddAllergy(data);
  };

  const { mutate: mutateAddSpice } = useMutation(addSpicePath, {
    onSuccess: async (res) => {
      toast.success("Spice added Successfully");
      queryClient.refetchQueries("getAllSpiceLevel");
      setOpenModal(false);
      setAddList(false);
      setAddListFormValue("");
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const AddSpice = () => {
    const data = {
      spice_level: addListFormValue,
    };
    mutateAddSpice(data);
  };

  const { mutate: mutateChangeSequence, isLoading: changeSequenceIsLoading } = useMutation(changeSequence, {
    onSuccess: async (res) => {
      queryClient.invalidateQueries("getAllTabList")
    },
    onError: (error, rollback) => {
      rollback();
      console.log(error);
    },
  });

  const OnChangeSequence = (data) => {
    const addingSequence = data.map((item, index) => ({
      _id: item?._id,
      sequence: index + 1,
    }));

    const AlteredData = {
      categories: addingSequence,
    };
    mutateChangeSequence(AlteredData);
  };
  // maps

  const onLoad = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  };

  const { mutate: mutateGetUser } = useMutation(getUserDetailsFromAccess, {
    onSuccess: async (res) => {


      setToken({
        access_token: res?.data?.result?.accessToken,
        refresh_token: res?.data?.result?.refreshToken,
      });
      saveUser(res?.data?.result);

      axios.defaults.headers.common.Authorization = `Bearer ${res.data.result.accessToken}`

      setTokenAvailable(true)
      queryClient.refetchQueries("getPartnerInfo");

    },
    onError: (error) => {
      console.log(error);
    },
  });

  const onGetUserDetailsFromAccessToken = (data) => {
    mutateGetUser(data);
  };

  return {
    useGetTabList,
    useGetTabListContent,
    useSearchByItemName,
    useSearchOrderByNumber,
    OnAddMenu,
    useGetFoodType,
    useGetSpiceLevel,
    HandleDeleteMenu,
    HandleDeleteCategoryList,
    HandleUpdateCategory,
    updateCategoryIsLoading,
    headerText,
    setHeaderText,
    listArray,
    setListArray,
    openModal,
    setOpenModal,
    modalType,
    setModalType,
    modelText,
    setModelText,
    AddCategory,
    addCategoryIsLoading,
    deleteCategoryListIsLoading,
    addListFormValue,
    setAddListFormValue,
    addList,

    setAddList,
    AddType,
    addTypeIsLoading,
    deleteTypeListIsLoading,
    HandleDeleteType,
    AddSpice,
    HandleDeleteSpice,
    tokenAvailable,
    setTokenAvailable,
    onGetUserDetailsFromAccessToken,

    // allergies
    useGetAllergies,
    addAllergy,
    HandleDeleteAllergy,

    // Restaurant info
    useGetPartnerInfo,
    onUpdatePartnerInfo,
    updatePartnerInfoIsLoading,
    useGetBriefPartnerInfo,

    // Menu Management
    useGetMenuById,
    OnEditMenu,
    OnEditMenuIsActive,
    editMenuIsLoading,
    // Table MenuManagement
    useGetTable,
    OnAddTable,
    OnUpdateTable,
    updateTableIsLoading,
    addTableIsLoading,
    openAddModal,
    setOpenAddModal,
    isTableDrawer,
    setIsTableDrawer,
    qrScanned,
    setQrSCanned,
    responseTableData,
    setResponseTableData,
    // orderHistory
    useOrderHistory,
    OnUpdateOrderStatus,
    updateOrderStatusIsLoading,
    useGetOrderDetails,
    useGetOrderById,
    OnChangeSequence,
    changeSequenceIsLoading,
    // data from gpt
    useAiMenuDetails,
    gptCheck,
    setGptCheck,

    OnEditMenuIngredients,
    mealTimingOptions,
    // AIPhoto
    onAIPhotoGeneration,
    AiPhoto,
    setAiPhoto,
    aiPhotoGenerationIsLoading,

    //insights
    useGetInsights,

    // maps
    autocomplete,
    setAutocomplete,
    onLoad,
    location,
    setLocation,
    // handleDragEvent

    // Notification Drawer
    notificationDrawer,
    setNotificationDrawer,
    useRequestedOrders,
    completeRequestedOrder,
    orderCompleteIsLoading
  };
}

export function ProvideRestaurant({ children }) {
  const Restaurant = useProvideRestaurant();
  return (
    <RestaurantContext.Provider value={Restaurant}>
      {children}
    </RestaurantContext.Provider>
  );
}
