import React, { useEffect, useRef, useState } from "react";
import RestaurantInfo from "./RestaurantInfo";
import { Badge, Grid, Paper } from "@mui/material";
import { TextInput } from "../../components/InputBox";
import CustomTabs from "../../components/Tabs";
import CustomTabPanel from "../../components/Tabs/CustomtabPanels";
import MenuCard from "./MenuCard";
import { useDiner } from "../../utils/Context/Dinner";
import { useParams, useLocation } from "react-router";
import { useRestaurant, useSuperAdmin } from "../../utils/hooks";
import useToken from "../../utils/hooks/useToken";
import getBrowserFingerprint from "get-browser-fingerprint";
import Isolation_Mode from "../../assets/images/Isolation_Mode.svg";
import cart from "../../assets/Icons/Cart.svg";
import food_image from "../../assets/images/food_image.png";
import search from "../../assets/Icons/search.svg";
import ButtonWithIcon from "../../components/ButtonWithIcon";
import spiceIcon from "../../assets/Icons/spiceIcon.png";
import broccoli from "../../assets/Icons/broccoli.png";
import { titleCase } from "../../utils/helper";
import Counter from "../../components/Counter";
// import OfferCard from "../../components/OfferCard";
import close from "../../assets/Icons/close.svg";
import { useDinerStore } from "../../stores";
import { useShallow } from "zustand/shallow";
import { useDebounce } from "../../useDebaunce";
import backArrow from "../../assets/Icons/backArrow.svg";
import BasicButton from "../../components/Button";
import AddIcon from "@mui/icons-material/Add";
import emptyCart from "../../assets/images/emptyCart.png";

function DinerMobileView({
  bannerURL,
  logoURL,
  address,
  restaurantName,
  youtubeURL,
  instagramURL,
  facebookURL
}) {
  const [searchValue, setSearchValue] = useState("");
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [isDrawerOpenValue, setDrawerOpenValue] = useState(false);
  const [foodTypeValue, setFoodTypeValue] = useState("");
  const [, setIsSearchFocused] = useState(false);
  const [showNewScreen, setShowNewScreen] = useState(false);
  const [cartViewActive, setCartViewActive] = useState(false)
  const bannerImageRef = useRef(null)

  const restaurantId = useParams();
  const { getUserDetails } = useToken();

  const location = useLocation();
  const tableId = new URLSearchParams(location.search).get("tableId");

  const { tabValue } = useSuperAdmin();
  const {
    useGetDinerDetails,
    useGetRestaurantInfo,
    restaurantInfoHeight,
    // useGetSpiceLevel,
    // useGetFoodCategory,
    useGetFoodMenuByCategory,
    OrderModel,
    setOrderModel,
    useGetCartItem,
    // countDiner,
    // decrementAdultCount,
    // incrementAdultCount,
    useGetFoodCategory,
    useGetFoodItemsByQuery
  } = useDiner();

  const {
    cartList,
    dinerCount,
    setDinerCount,
  } = useDinerStore(useShallow(state => ({
    cartList: state.cartList,
    dinerCount: state.dinerCount,
    setDinerCount: state.setDinerCount,
  })))

  const {
    useGetFoodType,
    // useGetTabList
  } = useRestaurant()

  useGetCartItem({
    // cartID: getCartId(),
    // partnerId: id,
    dinerId: getUserDetails()?.id,
  });

  /**
   * Generates a unique fingerprint for the current browser.
   * @returns A string representing the browser fingerprint.
   */
  const fingerprint = getBrowserFingerprint();

  /**
   * Toggles the state of the drawer and order model between open and closed.
   * @returns None
   */
  const toggleDrawer = () => {
    setOrderModel(!OrderModel);
    setDrawerOpen(!isDrawerOpen);
    setDrawerOpenValue(!isDrawerOpenValue);
  };

  // const { data: dinerDetails, isLoading: dinerDetailsIsLoading } =
  useGetDinerDetails({
    dinerId: fingerprint.toString(),
    userAgent: window.navigator?.userAgent,
  });

  const { data: restaurantInfo } = useGetRestaurantInfo({
    restId: restaurantId?.id,
    tableId: tableId,
  });

  const { data: foodType, isLoading: foodTypeIsLoading } = useGetFoodType();
  // const { data: menuCategory, isLoading: menuCategoryIsLoading } = useGetFoodCategory(restaurantId?.id);
  const { data: menuCategory, isLoading: menuCategoryIsLoading } = useGetFoodCategory(getUserDetails()?._id);

  const [isLoading, setIsLoading] = useState(true)
  const [searchResultIsActive, setSearchResultIsActive] = useState(false)

  const debouncedValue = useDebounce(searchValue, 300);


  const { data: menuList, isLoading: menuListIsLoading } =
    useGetFoodMenuByCategory({
      restId: getUserDetails()?._id,
      menuId: menuCategory?.result[tabValue]?._id,
      foodTypeId: foodTypeValue,
    });

  const { data: searchResult, isLoading: searchResultIsLoading } =
    useGetFoodItemsByQuery({
      restId: getUserDetails()?._id,
      // menuId: menuCategory?.result[tabValue]?._id,
      query: debouncedValue,
      foodTypeId: foodTypeValue,
    });
  const handleSearchFocus = () => {
    setIsSearchFocused(true);
    setShowNewScreen(true);
  };

  const handleSearchBlur = () => {
    setIsSearchFocused(false);
  };

  const handleSearchKeyDown = (e) => {
    if (e.key === 'Enter') {
      setShowNewScreen(false);
      if (e.target.value !== "")
        setSearchResultIsActive(true)
      else
        setSearchResultIsActive(false)
      e.target.blur()
    }
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
    setShowNewScreen(true);
  };

  useEffect(() => {
    const isDataLoaded =
      !foodTypeIsLoading &&
      !menuCategoryIsLoading

    setIsLoading(!isDataLoaded);
  }, [
    foodTypeIsLoading,
    menuCategoryIsLoading,
  ]);

  useEffect(() => {
    if (bannerImageRef.current && restaurantInfoHeight !== 94) {
      bannerImageRef.current.style.maxHeight = `${restaurantInfoHeight + 53}px`
    }
  }, [restaurantInfoHeight])

  return (
    <>
      <Grid container sx={12} direction="column" className="font-poppins">
        <Grid
          item
          sx={12}
          className=" bg-[#b80e0c]  flex justify-between items-end  px-[1.25rem] py-[0.75rem] sticky top-0 z-50 w-full"
        >
          <img
            src={Isolation_Mode}
            alt=""
            srcSet=""
            className=""
            height={"36px"}
          />
          <Badge
            badgeContent={0}
            sx={{ cursor: "pointer", "& .MuiBadge-badge": { backgroundColor: "white", color: "#B80E0C", fontWeight: 600, position: "absolute", top: "3px", right: "4px" } }}
            onClick={() => {
              setCartViewActive(true)
            }}
          >
            <img src={cart} alt="cart" className={`cursor-pointer`} />
          </Badge>
        </Grid>

        {isLoading ?
          <div className="flex flex-col justify-center items-center h-[calc(100vh-60px)]">
            <div className="h-14 w-14 border-4 border-gray-300 border-t-red-600 rounded-full animate-spin mb-4" />
            <p className="text-gray-600">
              Loading...
            </p>
          </div>
          :
          cartViewActive
            ?
            <>
              <Grid
                container
                item
                xs={12}
                direction="row"
                gap="12px"
                alignItems="center"
                className="p-4"
              >
                <img className="h-[20px] w-[20px] cursor-pointer" src={backArrow} alt=""
                  onClick={() => {
                    setCartViewActive(false)
                  }}
                />
                <h1 className="  font-[500] text-[14px]">
                  My Orders
                </h1>
              </Grid>
              <div className="flex flex-col items-center justify-center h-[calc(1048px-180px)] flex-grow px-4">
                <img className="max-w-[231px]" src={emptyCart} alt="" />
                <p className="font-medium text-[18px] mb-[20px]">Your basket is empty</p>
                <BasicButton
                  color="genieRed"
                  sx={{
                    textTransform: "none",
                    color: "#fff",
                    padding: "12px 16px",
                    fontWeight: "500",
                    width: "100%",
                  }}
                  onClick={() => {
                    setCartViewActive(false)
                  }}
                >
                  <span className="font-medium flex items-center gap-[8px]">
                    <AddIcon height={"14px"} width={"14px"} />
                    <span className="leading-snug">Add Items</span>
                  </span>
                </BasicButton>
              </div>
            </>
            :
            <Grid sx={{
              position: "relative",
              marginBottom: cartList?.reduce((acc, curr) => { return acc + curr.orderDetails.length }, 0) > 0 ? "72.67px" : "0px",
              width: "100%"
            }}>
              <Grid xs={12} sx={{ position: "absolute", zIndex: "-1" }} className={`${showNewScreen ? "hidden" : ""}`}>
                <img
                  src={bannerURL?.url || bannerURL}
                  alt={`${restaurantInfo?.result?.name || 'Restaurant'} banner`}
                  ref={bannerImageRef}
                  loading="eager"
                  onError={(e) => {
                    e.target.src = food_image; // Fallback to default image
                    e.target.alt = 'Default restaurant banner';
                  }}
                  className={`
                    max-h-[135px] w-[100vw] 
                    object-cover 
                    transition-[max-height] ease-in-out duration-200
                    bg-gray-50
                  `}
                />
              </Grid>
              <Grid item sx={12} className={`${showNewScreen ? "hidden" : ""}`}>
                <RestaurantInfo
                  data={{
                    result: {
                      logoURL: logoURL?.url || logoURL,
                      address,
                      name: restaurantName,
                      youtubeURL,
                      instagramURL,
                      facebookURL,
                      _id: getUserDetails()?._id
                    }
                  }}
                  toggleDrawer={toggleDrawer}
                  isDrawerOpenValue={isDrawerOpenValue}
                />
              </Grid>

              <div className={`${searchResultIsActive ? "p-0  z-[100]" : ""}`}>
                <Grid item sx={12}>
                  <Grid container sx={12} className=" px-[16px] mt-[1.375rem] mb-2 ">
                    <Grid item xs={12} className="flex" alignItems={"center"} classes="shadow-md relative">
                      <Paper elevation={2}
                        sx={{ width: "100%", borderRadius: "30px", backgroundColor: "#fff" }}>
                        <TextInput
                          variant="outlined"
                          placeholder="What's on your mind?"
                          value={searchValue}
                          onChange={handleSearchChange}
                          onFocus={handleSearchFocus}
                          onBlur={handleSearchBlur}
                          onKeyDown={handleSearchKeyDown}
                          borderRadius="30px"
                          transparentBorder
                          backgroundColor="#fff"
                          preContent={<img className="w-[18px] h-[18px]" src={search} alt="" />}
                          postContent={
                            <img src={close} alt=""
                              onClick={() => {
                                setSearchValue("")
                                setFoodTypeValue("")
                                setShowNewScreen(false)
                                setSearchResultIsActive(false)
                              }}
                            />
                          }
                          sx={{
                            width: "100%",
                            borderRadius: "30px",
                            backgroundColor: "#fff",
                            '& .MuiInputBase-input::placeholder': {
                              color: '#545454'
                            },
                            '& .MuiInputBase-input:-webkit-autofill': {
                              '-webkit-box-shadow': '0 0 0 100px transparent inset',
                              transition: 'background-color 5000s ease-in-out 0s'
                            }
                          }}
                        />
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>

                {showNewScreen && debouncedValue.length > 0 && searchResult?.result?.length > 0 &&
                  <div className="px-4 border-b-8 pt-6 pb-[20px] flex flex-col gap-[10px]">
                    <span className="font-medium text-[14px] text-[#545454]">Looking for...</span>
                    {/* TODO: set limit */}
                    {searchResult?.result.slice(0, 5).map(item => {
                      return (
                        <div className="flex items-center gap-2 py-3 border-t cursor-pointer"
                          onClick={() => {
                            setSearchValue(item?.item_name)
                            setShowNewScreen(false)
                            setSearchResultIsActive(true)
                          }}
                        >
                          <span className="font-medium">{item?.item_name}</span>
                          {/* TODO: Add spice level and food type icons */}
                          <div className="flex items-center gap-1">
                            <img className="w-3 h-3" src={broccoli} alt="" />
                            <img className="w-3 h-3" src={spiceIcon} alt="" />
                          </div>
                        </div>
                      )
                    })}
                  </div>
                }

                <Grid item className={`relative ${showNewScreen ? "border-b-8 border-[#F0F0F0]" : ""}`} sx={{ marginTop: showNewScreen ? "26px" : "0px" }}>
                  {showNewScreen && <span className="font-medium text-[14px] text-[#545454] px-4">Craving?</span>}
                  <div className={`max-w-[100vw] my-[12px] px-4 flex items-center gap-2 ${showNewScreen ? "flex-wrap" : "flex-nowrap overflow-x-auto after:content-[''] after:w-10 after:h-full after:absolute after:right-0 after:bg-gradient-to-r after:from-transparent after:to-[#fff]"}`}>
                    {foodType?.result.map((item, index) =>
                      <ButtonWithIcon
                        sx={{
                          border: "1.5px solid #C0C0C0",
                          color: "#3f3f3f",
                          flexShrink: 0,
                        }}
                        imageClass="max-h-[18px]"
                        backgroundColor="#fff"
                        text={titleCase(item?.food_type)}
                        key={index}
                        onClick={() => {
                          setFoodTypeValue(prevValue => prevValue === item?._id ? "" : item?._id);
                          // if (debouncedValue.length === 0)
                          //   setSearchResultIsActive(true)
                          // if (foodTypeValue === item?._id) {
                          //   setFoodTypeValue("")
                          //   if (debouncedValue.length === 0)
                          //     setSearchResultIsActive(false)
                          // }
                          // else {
                          //   setFoodTypeValue(item?._id)
                          //   if (debouncedValue.length === 0)
                          //     setSearchResultIsActive(true)
                          // }
                        }}
                        active={foodTypeValue === item?._id}
                      // icon={spiceIcon}
                      />
                    )}
                  </div>
                </Grid>

                {!showNewScreen && searchResultIsActive && debouncedValue.length > 0 &&
                  <div className="px-4 flex items-center gap-[12px] mt-[6px] mb-[6px]">
                    <img
                      className="h-4 cursor-pointer"
                      src={backArrow} alt=""
                      onClick={() => {
                        setSearchResultIsActive(false);
                        setSearchValue("")
                        setFoodTypeValue("")
                      }}
                    />
                    <span className="font-medium">Result for ‘{searchValue}’</span>
                  </div>
                }
              </div>

              {showNewScreen &&
                // TODO: add border-b-8 for bottom line
                <Grid item xs={12} className="w-[100vw] py-[22px] flex  gap-4" sx={{ flexDirection: "column" }}>
                  <span className="font-medium text-[14px] text-[#545454] px-4">Popular Dishes</span>
                  <div className="relative h-fit">
                    <div className="flex flex-nowrap overflow-x-auto after:content-[''] after:w-10 after:h-full after:absolute after:right-0 after:bg-gradient-to-r after:from-transparent after:to-[#fff]">
                      {menuCategory?.result.map(Category => {
                        return (
                          <div className="flex flex-col items-center px-[20px] py-[11px] cursor-pointer">
                            {/* <img className="max-w-[37px] max-h-[33px]" src={fire} alt="" /> */}
                            <span className="font-medium text-[14px] text-nowrap">{Category.food_category}</span>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </Grid>
              }

              {!showNewScreen && !searchResultIsActive && tableId &&
                <Grid item xs={12} className="px-4 py-[12px] bg-[#FFFEEF] flex justify-between items-center">
                  <p className="font-medium text-[#262626]">
                    No. of Diners
                  </p>
                  <Counter increment={() => { setDinerCount(dinerCount + 1) }} decrement={() => { setDinerCount(dinerCount - 1) }} value={dinerCount} />
                </Grid>
              }

              {!showNewScreen && !searchResultIsActive &&
                <Grid item xs={12} sx={{ marginBottom: "16px" }}>
                  <Grid container direction="column">
                    <CustomTabs
                      tabs={
                        menuCategory?.result.map((item) => ({
                          label: item.food_category,
                          value: item._id,
                        })) || []
                      }
                      maxWidth="483px"
                      disableIndicator
                    >
                      {(value) => {
                        return (
                          <>
                            <CustomTabPanel value={value} index={value}>
                              <MenuCard
                                list={menuList?.result}
                                isLoading={menuListIsLoading}
                                cartList={cartList}
                                disableDrawer
                              />
                            </CustomTabPanel>
                          </>
                        );
                      }}
                    </CustomTabs>
                  </Grid>
                </Grid>
              }

              {!showNewScreen && searchResultIsActive &&
                <Grid item xs={12} sx={{ marginBottom: "16px" }}>
                  <Grid container direction="column">
                    <MenuCard
                      list={searchResult?.result}
                      isLoading={searchResultIsLoading}
                      cartList={cartList}
                      disableDrawer
                    />
                  </Grid>
                </Grid>
              }


              {/* <Box display={"none"}>
              {(showNewScreen || !searchResultIsActive) &&
                <Grid
                  sx={{
                    marginBottom: "16px",
                    paddingLeft: "16px",
                    paddingRight: "16px",
                    display: "flex",
                    gap: "9px",
                    width: "100vw",
                    overflowX: "auto",
                    flexWrap: "nowrap",
                    marginTop: showNewScreen ? "18px" : "0px"
                  }}
                >
                  <OfferCard image={food_image}
                    text="Order from these restaurants and save"
                    colorHex="bg-[#ffd951]"
                    buttonText="Browse offer"
                  />
                  <OfferCard
                    image={food_image}
                    text="Have cleaning supplies and other convenience items delivered"
                    colorHex="bg-[#63f099]"
                    buttonText="Shop convenience"
                  />
                </Grid>
              }
            </Box> */}

            </Grid>
        }
      </Grid>
    </>
  );
}

export default DinerMobileView;

