import { useStripe, useElements, PaymentElement, ExpressCheckoutElement } from '@stripe/react-stripe-js';
import BasicButton from '../../../components/Button';
import { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Badge, Grid } from '@mui/material';
import Isolation_Mode from "../../../assets/images/Isolation_Mode.svg";
import cart from "../../../assets/Icons/Cart.svg";
import { useDinerStore } from '../../../stores';
import { useNavigate } from 'react-router-dom';
import { useShallow } from 'zustand/shallow';
import backArrow from "../../../assets/Icons/backArrow.svg";
import { useDiner } from '../../../utils/hooks';
import DrawerComponent from '../../../components/Drawer/Index';
import cancel_dark from "../../../assets/Icons/cancel_dark.svg"
import { ExpandMore } from "@mui/icons-material";
import { SERVICE_FEE_PERCENTAGE } from '../../../Constants/services';

const CheckoutForm = () => {
  const navigate = useNavigate()
  const {
    PayBill,
    cancelStripePaymentIntent
  } = useDiner()
  const {
    tableId,
    getCartCount,
    partnerId,
    getCartTotal,
    serviceFee,
    customDomain,
    cartList,
    paymentIntentSecret,
  } = useDinerStore(useShallow(state => ({
    tableId: state.tableId,
    getCartCount: state.getCartCount,
    partnerId: state.partnerId,
    getCartTotal: state.getCartTotal,
    serviceFee: state.serviceFee,
    customDomain: state.customDomain,
    cartList: state.cartList,
    paymentIntentSecret: state.paymentIntentSecret
  })))
  const [paymentState, setPaymentState] = useState({
    ready: false,
  })
  const [cancelPaymentModel, setCancelPaymentModel] = useState(false)
  const [navigateTo, setNavigateTo] = useState(null)
  const [orderSummaryExpanded, setOrderSummaryExpanded] = useState(false)
  const stripe = useStripe();
  const elements = useElements({
    mode: "payment",
    currency: "aed",
    appearance: {
      theme: 'stripe',
    }
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    setPaymentState(prev => ({ ...prev, processing: true }));

    if (!stripe || !elements) {
      return;
    }

    const domain = window.location.hostname

    const result = await stripe.confirmPayment({
      elements,
      redirect: 'if_required',
      confirmParams: {
        return_url: domain === `${customDomain}.localhost` ? `http://${customDomain}.localhost:3001/menu/bill/success?tableId=${tableId}` : `https://${customDomain}.dev-frontend.grubgenie.ai/menu/bill/success?tableId=${tableId}`,
      },
    });

    if (result.error) {
      setPaymentState(prev => ({
        ...prev,
        error: result.error.message,
        processing: false
      }));
    } else if (result.paymentIntent) {
      setPaymentState(prev => ({
        ...prev,
        paymentIntent: result.paymentIntent,
        confirmingPayment: true,
        processing: false
      }));
    }

    if (result?.paymentIntent?.status === "succeeded") {
      PayBill(cartList, partnerId, tableId)
    }
  };

  const onConfirm = async (event) => {
    if (!stripe) {
      return;
    }

    const { error: submitError } = await elements.submit();
    if (submitError) {
      setPaymentState(prev => ({
        ...prev,
        error: submitError.message,
        processing: false
      }));
      return;
    }

    const domain = window.location.hostname

    const result = await stripe.confirmPayment({
      elements,
      redirect: 'if_required',
      confirmParams: {
        return_url: domain === `${customDomain}.localhost` ? `http://${customDomain}.localhost:3001/menu/bill/success?tableId=${tableId}` : `https://${customDomain}.dev-frontend.grubgenie.ai/menu/bill/success?tableId=${tableId}`,
      },
    });

    if (result.error) {
      setPaymentState(prev => ({
        ...prev,
        error: result.error.message,
        processing: false
      }));
    } else if (result.paymentIntent) {
      setPaymentState(prev => ({
        ...prev,
        paymentIntent: result.paymentIntent,
        confirmingPayment: true,
        processing: false
      }));
    }

    if (result?.paymentIntent?.status === "succeeded") {
      PayBill(cartList, partnerId, tableId)
    }
  };

  const handleCancelModal = () => {
    setCancelPaymentModel(true)
  }

  const handleClose = () => {
    setCancelPaymentModel(false)
  }

  const handleCancelPayment = () => {
    cancelStripePaymentIntent(paymentIntentSecret)
    setCancelPaymentModel(false)
    navigate(navigateTo)
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container sx={12} direction="column" className="font-poppins">
        <Grid
          item
          sx={12}
          className=" bg-[#b80e0c]  flex justify-between items-end  px-[1.25rem] py-[0.75rem] sticky top-0 z-50"
        >
          <img
            src={Isolation_Mode}
            alt=""
            srcSet=""
            className=""
            height={"36px"}
          />
          <Badge
            badgeContent={getCartCount()}
            onClick={() => {
              if (tableId) {
                // cancelStripePaymentIntent(paymentIntentSecret)
                // navigate(`/menu/cart/?tableId=${tableId}`)
                setNavigateTo(`/menu/cart/?tableId=${tableId}`)
                handleCancelModal()
              }
            }}
            sx={{ cursor: "pointer", "& .MuiBadge-badge": { backgroundColor: "white", color: "#B80E0C", fontWeight: 600, position: "absolute", top: "3px", right: "4px" } }}
            className={`${paymentState?.processing && 'pointer-events-none opacity-50'}`}
          >
            <img src={cart} alt="cart" />
          </Badge>
        </Grid>

        {paymentState?.confirmingPayment ?
          <>
            <div className="flex flex-col justify-center items-center h-[calc(100vh-60px)] px-4">
              <div className="h-14 w-14 border-4 border-gray-300 border-t-red-600 rounded-full animate-spin mb-4" />
              <p className="text-gray-600">
                Please wait while we process your payment.
              </p>
            </div>
          </>
          :
          <>
            <Grid
              container
              item
              xs={12}
              direction="row"
              gap="12px"
              alignItems="center"
              className={`p-4 ${paymentState?.processing && 'pointer-events-none opacity-50'}`}
            >
              <img className="h-[20px] w-[20px] cursor-pointer" src={backArrow} alt=""
                onClick={() => {
                  if (!paymentState.processing) {
                    // cancelStripePaymentIntent(paymentIntentSecret)
                    // navigate(`menu/bill?tableId=${tableId}`)
                    setNavigateTo(`/menu/bill?tableId=${tableId}`)
                    handleCancelModal()
                  }
                }}
              />
              <h1 className="  font-[500] text-[14px]">
                Cancel Payment
              </h1>
            </Grid>

            <Grid sx={{ width: "100%", padding: "0px 16px" }}>
              <Accordion
                expanded={orderSummaryExpanded}
                onChange={() => setOrderSummaryExpanded(prev => !prev)}
                sx={{
                  width: "100%",
                  boxShadow: "none",
                  borderBottom: "1px solid #EBEBEB",
                  borderRadius: "0px",
                  paddingBottom: orderSummaryExpanded ? "8px" : "0px",
                  '&::before': {
                    display: "none",
                  },
                  '&.Mui-expanded': {
                    margin: 0,
                  },
                  '&.Mui-expanded + .MuiAccordion-root::before': {
                    opacity: '1 !important',
                    display: 'block !important'
                  },
                  '& .MuiAccordionDetails-root': {
                    paddingTop: '0px',
                  },
                }}
              >
                {/* {console.log(order, "order")} */}
                <AccordionSummary
                  expandIcon={<ExpandMore sx={{ color: "#1C1B1F", transform: "scale(1.3)" }} />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  sx={{
                    padding: "0px",
                    fontWeight: "500",
                    fontSize: "16px",
                    minHeight: "0px",
                    margin: 0,
                    '& .MuiAccordionSummary-content': {
                      margin: "8px 0px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    },
                    '& .MuiAccordionSummary-content.Mui-expanded': {
                      margin: '8px 0px !important'
                    },
                    '&.Mui-expanded': {
                      minHeight: 'fit-content',
                      height: 'fit-content',
                      margin: '0 !important'
                    },
                    '& .MuiPaper-root': {
                      margin: "0"
                    }
                  }}
                >
                  <p className='font-medium text-base'>Order Breakdown</p>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0, display: "flex", flexDirection: "column" }}>
                  {cartList.map((order, orderIndex) => {
                    return (
                      <div key={orderIndex} className='flex flex-col w-full gap-1 border-b border-[#EBEBEB] py-2'>
                        <p className='font-medium text-[0.875rem]'>ORDER #{orderIndex + 1}</p>
                        <div className='flex flex-col ml-4'>
                          {order?.orderDetails?.map((item, itemIndex) => {
                            return (
                              <div className='flex flex-col'>
                                <div className='flex justify-between items-center font-medium'>
                                  <p><span className='text-[#b80e0c]'>{item?.quantity} X</span> {item?.item_name}</p>
                                  <p className='text-base'>
                                    <span className='text-xs text-[#4D4D4D]'>AED</span> {((item?.oPrice - item?.dPrice + item?.customization?.reduce((acc, customization) => acc + customization.items.reduce((itemAcc, item) => itemAcc + item.price, 0), 0)) * item?.quantity).toFixed(2)}
                                  </p>
                                </div>
                                <div className='font-normal text-[#757373] text-wrap max-w-[60vw]'>
                                  {item?.customization?.map(customization => customization.items.map(item => item.itemName).join(", ")).join(", ")}
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    )
                  })}
                  <div className='flex flex-col pt-2 gap-2'>
                    <div className='flex justify-between items-center'>
                      <p>Subtotal</p>
                      <p className='text-base'>
                        <span className='text-xs text-[#4D4D4D]'>AED</span> {getCartTotal()}
                      </p>
                    </div>
                    <div className='flex justify-between items-center'>
                      <p>Service Fee@{SERVICE_FEE_PERCENTAGE}%</p>
                      <p className='text-base'>
                        <span className='text-xs text-[#4D4D4D]'>AED</span> {serviceFee.toFixed(2)}
                      </p>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid xs={12}
              sx={{
                padding: "0px 16px",
                marginTop: "8px"
              }}

              className={`${orderSummaryExpanded && 'flex justify-between items-center'}`}
            >
              <p className='text-[#757373] text-[14px]'>Payment Amount</p>
              <h1 className='text-[32px] font-medium'>AED {(Number(getCartTotal()) + serviceFee).toFixed(2)}</h1>
            </Grid>

            <div className='w-full bg-[#F0F0F0] h-2 my-3'>&nbsp;</div>

            <Grid xs={12} sx={{
              padding: "0px 16px 16px 16px",
              marginBottom: "72.67px",
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}>
              <p className='text-[#757373] text-[14px]'>Select a payment method</p>
              {paymentState.error && (
                <div className="text-red-500 text-sm">{paymentState.error}</div>
              )}
              <ExpressCheckoutElement
                onConfirm={onConfirm}
                onReady={(event) => {
                  console.log("express element", event)
                  if (event?.availablePaymentMethods) {
                    setPaymentState(prev => ({ ...prev, expressElementReady: true }))
                  }
                }}
              />

              {paymentState.ready && paymentState?.expressElementReady &&
                <div className='flex justify-between items-center'>
                  <hr className='w-full' />
                  <p className='mx-3'>or</p>
                  <hr className='w-full' />
                </div>
              }

              <PaymentElement
                options={{
                  layout: {
                    type: 'auto',
                  }
                }}
                onReady={() => setPaymentState(prev => ({ ...prev, ready: true }))}
              />
            </Grid>

            <Grid
              item
              sx={12}
              className=" bg-[#FFFFFF]  flex justify-between items-end  px-[1rem] py-[0.75rem] fixed w-[100vw] bottom-0 border-t border-[#D1D1D1] z-[101]"
            >
              <BasicButton color="genieRed"
                disabled={!paymentState.ready || paymentState.processing}
                type='submit'
                sx={{
                  color: "#fff",
                  textTransform: "none",
                  fontWeight: 500,
                  width: "100%",
                  height: "48px"
                }}
              >
                <span className="flex items-baseline">
                  {paymentState.processing ? 'Processing...' : 'Pay Now'}
                </span>
              </BasicButton>
            </Grid>

            <DrawerComponent
              position="bottom"
              visiblePuller={true}
              open={cancelPaymentModel}
              onClose={handleClose}
              sx={{
                "& .MuiDrawer-paper": {
                  borderRadius: "20px 20px 0 0",
                  maxHeight: "90vh", // Added max height
                  boxShadow: "none", // Remove shadow
                },
              }}
            >
              <div className={`flex flex-col justify-around w-[100vw] bg-[#f4f3f3] max-h-[90vh] overflow-hidden`}>
                <div className="self-start w-full">
                  <div className="w-full px-4 pt-4 flex justify-between border-b-[2px] border-[#F8F8F8] pb-[8px] gap-2">
                    <div>
                      <p className="text-[16px] font-medium max-w-[228px]">
                        Are you sure you want to cancel this payment?
                      </p>
                    </div>
                    <img className="cursor-pointer self-baseline" onClick={handleClose} src={cancel_dark} alt="" style={{ color: '#4A4747' }} />
                  </div>
                  <div className="px-4 flex gap-2 pb-4">
                    <BasicButton variant="outlined"
                      sx={{
                        width: "100%",
                        textTransform: "none"
                      }}
                      onClick={handleClose}
                      color="genieRed"
                    >
                      Go Back
                    </BasicButton>
                    <BasicButton
                      sx={{
                        width: "100%",
                        textTransform: "none",
                        color: "#fff"
                      }}
                      color="genieRed"
                      onClick={handleCancelPayment}
                    >
                      Confirm
                    </BasicButton>
                  </div>
                </div>
              </div>
            </DrawerComponent>
          </>
        }
      </Grid>
    </form>

  )
};

export default CheckoutForm;
