import React, { useEffect, useState } from 'react'
// import HeaderWrapper from '../../../components/headerwrapper/HeaderWrapper'
import { Badge, Grid } from '@mui/material'
import { TextInput } from '../../../components/InputBox'
import LogOutButton from "../../../components/Logout";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import DatePicker from "../../../components/DatePicker";
import dayjs from "dayjs";
import DineInSection from './DineInSection';
import TableSection from './TableSection';
import BasicButton from '../../../components/Button';
import AddIcon from '@mui/icons-material/Add';
import { BasicModal } from '../../../components/Modal/Modal';
import { useAuth, useRestaurant } from '../../../utils/hooks';
import closeIcon from "../../../assets/Icons/close.svg"
import { Controller, useForm } from 'react-hook-form';
import { CustomTextInput } from '../../../components/InputBox/CustomInput';
import { queryClient } from '../../../utils/config/queryClient';
import { useDebounce } from '../../../useDebaunce';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useNavigate } from 'react-router-dom';
import receipt_bg_red from "../../../assets/Icons/receipt_bg_red.svg"

const Index = () => {
    const [selectedTab, setSelectedTab] = useState("dineIn");
    const [taleSummary, setTableSummary] = useState({})
    const [searchValue, setSearchValue] = useState("");
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [orderViewActive, setOrderViewActive] = useState(false);
    const [dateRange, setDateRange] = useState(() => {
        const today = dayjs().toDate();
        const tomorrow = dayjs().add(1, 'day').toDate();
        return [today, tomorrow];
    });
    const debouncedValue = useDebounce(searchValue, 300);
    const [startDate, endDate] = dateRange;
    const navigate = useNavigate();

    const {
        openModal,
        setOpenModal,
        OnAddTable,
        setNotificationDrawer,
        useRequestedOrders,
        useSearchOrderByNumber
    } = useRestaurant()
    const { socket, userData } = useAuth();

    const { control, handleSubmit, reset } = useForm({
        mode: "onChange"
    })

    const onSubmit = (data) => {
        OnAddTable({ tableSequence: data.tableSequence.toString(), numberOfSeats: Number(data.numberOfSeats), tableName: data?.tableName?.toString() })
        setOpenModal(false)
        reset()
    }

    const { data: requestedOrders } = useRequestedOrders()

    const handleOrderSelect = (orderId) => {
        setOrderViewActive(true)
        setSelectedOrder(orderId)
    }

    const handleOrderClose = () => {
        setOrderViewActive(false)
        setSelectedOrder(null)
    }

    useEffect(() => {
        // socket.emit("request", {
        //     en: "DS",
        //     data: {
        //         partnerId: userData?._id,
        //         fromDate: dayjs(startDate).format("YYYY-MM-DD"),
        //         toDate: dayjs(endDate).format("YYYY-MM-DD")
        //     }
        // });

        // socket.on("DS", (res) => {
        //     setDineManagementSummary(res?.data)
        // })

        socket.emit("request", {
            en: "TS",
            data: {
                partnerId: `${userData?._id}`,
                fromDate: dayjs(startDate).format("YYYY-MM-DD"),
                toDate: dayjs(endDate).format("YYYY-MM-DD")
            },
        });

        socket.on("TS", (data) => {
            setTableSummary(data?.data)
        });

        socket.on("orders-update", (data) => {
            queryClient.invalidateQueries(["getAllDetails"])
            queryClient.invalidateQueries(["getOrderById"])
        })

        socket.on("table-update", (data) => {
            queryClient.invalidateQueries(["getAllTable"])
        })

        return () => {
            // socket.off("DS");
            socket.off("TS");
            socket.off("dineManagementCount");
            socket.off("orders-update");
            socket.off("table-update");
            // socket.off("order-pay-request")
        }
    }, [socket, userData, startDate, endDate])

    const { data: searchResult, isLoading: searchResultIsLoading } = useSearchOrderByNumber(debouncedValue)

    // useEffect(() => {
    //     console.log(dayjs(startDate).format("YYYY-MM-DD"), dayjs(endDate).format("YYYY-MM-DD"))
    // }, [startDate, endDate])

    return (
        <>
            <Grid
                container
                xs={12}
                sx={{ height: "100vh", display: "block" }}
            >
                <Grid item xs={12} sx={{ borderBottom: "1px solid #1C1C1C1A" }}>
                    <div className="  flex flex-col sm:flex-row  sm:items-center h-[72px]   sm:justify-between py-4 px-8 gap-2 ">
                        <h3 className="hidden sm:flex gap-3 items-center">
                            <span className="text-[#1C1C1C66]"> Dashboard </span>{" "}
                            <span className="text-[#1C1C1C66]">/</span>
                            <span>{selectedTab === "dineIn" ? "Dine In Management" : "Table Management"}</span>
                        </h3>
                        <div className="w-full sm:max-w-[500px] flex gap-4 items-center justify-end">
                            <div className="relative w-full h-full">
                                <TextInput
                                    variant="outlined"
                                    preContent={<SearchIcon sx={{ color: "#c8c8c8" }} />}
                                    transparentBorder
                                    placeholder="Search Order Number"
                                    // value={searchValue}
                                    borderRadius="10px"
                                    sx={{
                                        fontFamily: "Poppins",
                                        width: "100%",
                                        backgroundColor: "#fff",
                                        '& .MuiInputBase-input::placeholder': {
                                            color: '#545454'
                                        },
                                        '& .MuiInputBase-input:-webkit-autofill': {
                                            '-webkit-box-shadow': '0 0 0 100px transparent inset',
                                            transition: 'background-color 5000s ease-in-out 0s'
                                        },
                                        // '& .css-1ua80n0-MuiInputBase-input-MuiOutlinedInput-input': {
                                        //   padding: "4px 0px"
                                        // }
                                    }}
                                    onChange={(e) => setSearchValue(e.target.value)}
                                //   label="Description(optional)"

                                />
                                {
                                    searchValue &&
                                    <img className='absolute top-2 right-2 bg-[#f7f7f7] cursor-pointer' src={closeIcon} alt=""
                                        onClick={() => {
                                            setSearchValue("")
                                        }}
                                    />
                                }
                                {debouncedValue.length > 0 && searchValue &&
                                    <div
                                        className="absolute w-full bg-white top-11 rounded-[8px] p-4 border border-[#E3E3E3] shadow-md z-[100] flex flex-col gap-[6px]"
                                    >
                                        {debouncedValue && searchResult?.result?.length > 0 &&
                                            <>
                                                <p className="font-medium text-[14px] text-[#757373]">Results for “{debouncedValue}”</p>
                                                <hr />
                                            </>
                                        }
                                        <div className="flex flex-col max-h-[398px] overflow-auto scrollbar-thin">
                                            {searchResult?.result?.length ?
                                                searchResult?.result?.map(item =>
                                                    <div className="flex items-center gap-4 hover:bg-gray-200 p-1 rounded cursor-pointer justify-between p-2"
                                                        onClick={() => {
                                                            // navigate(`/menu/edit/${item?._id}`)
                                                            setSelectedTab("dineIn")
                                                            handleOrderSelect(item?._id)
                                                            setSearchValue("")
                                                        }}
                                                    >
                                                        <div className='flex gap-2 items-center'>
                                                            <img src={receipt_bg_red} alt="" />
                                                            <p className="text-[#4D4D4D] text-base font-medium">{item?.orderNumber}</p>
                                                        </div>
                                                        <div className="flex flex-col">
                                                            <p className="text-[#1C1B1F] text-[14px] font-semibold">AED {item?.totalOrderValue.toFixed(2)}</p>
                                                        </div>
                                                    </div>
                                                )
                                                :
                                                <div className="text-center text-[#757373]">{searchResultIsLoading ? "Searching..." : "Result not found"}</div>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                            <Badge badgeContent={requestedOrders?.requestedOrders?.length} color="error" >
                                <NotificationsNoneOutlinedIcon sx={{ cursor: "pointer" }} onClick={() => setNotificationDrawer(true)} />
                            </Badge>
                            <LogOutButton />
                        </div>
                    </div>
                </Grid>

                <Grid item xs={12} sx={{ padding: { xs: "10px 32px", lg: "10px 32px 0px 32px" }, display: "flex", justifyContent: "space-between", alignItems: { xs: "start", lg: "center" }, flexDirection: { xs: "column", lg: "row" }, gap: { xs: "16px", lg: "0px" } }}>
                    <div className='flex w-fit text-xl'>
                        <div className={`pt-4 flex items-center justify-center px-[28px] ${selectedTab === "dineIn" ? "text-[#3E3E3E] font-medium border-b-[4px] border-[#FFC0BF] pb-[12px]" : "text-[#757373] font-normal pb-4"}`}
                            onClick={() => setSelectedTab("dineIn")}
                            role="button"
                            tabIndex={0}
                            onKeyPress={(e) => e.key === 'Enter' && setSelectedTab("dineIn")}
                        >
                            Dine in Management
                        </div>
                        <div className={`pt-4 flex items-center justify-center px-[28px] ${selectedTab === "table" ? "text-[#3E3E3E] font-medium border-b-[4px] border-[#FFC0BF] pb-[12px]" : "text-[#757373] font-normal pb-4"}`}
                            onClick={() => setSelectedTab("table")}
                            role="button"
                            tabIndex={0}
                            onKeyPress={(e) => e.key === 'Enter' && setSelectedTab("table")}
                        >
                            Table Management
                        </div>
                    </div>

                    {selectedTab === "dineIn" ? (
                        <DatePicker
                            // value={dayjs().format("YYYY-MM-DD")}
                            value={dateRange}
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(update) => setDateRange(update)}
                            isClearable={true}
                            placeholderText="Select date range"
                        />
                    ) :
                        <BasicButton
                            color="genieRed"
                            sx={{
                                textTransform: "none",
                                color: "white",
                                display: "flex",
                                gap: "8px",
                                fontWeight: "500",
                                padding: "8px 16px",
                                fontSize: "16px"
                            }}
                            onClick={() => setOpenModal(true)}
                        >
                            <AddIcon />
                            Add New Table
                        </BasicButton>
                    }
                </Grid>

                <Grid item xs={12} sx={{ padding: "20px 0px 20px 0px", height: { xs: `calc(100vh - ${selectedTab === "dineIn" ? "200px" : "212px"})`, lg: "calc(100vh - 144px)" }, overflowY: "auto" }}>
                    {selectedTab === "dineIn" && <DineInSection startDate={startDate} endDate={endDate} selectedOrder={selectedOrder} orderViewActive={orderViewActive} handleOrderSelect={handleOrderSelect} handleOrderClose={handleOrderClose} />}
                    {selectedTab === "table" && <TableSection tableSummary={taleSummary} />}
                </Grid>

            </Grid>

            {openModal && (
                <BasicModal open={openModal} close={() => { setOpenModal(false) }} maxWidth="553px">
                    <form className='flex flex-col gap-[20px]' onSubmit={handleSubmit(onSubmit)}>
                        <div className="flex justify-between items-center">
                            <p className="font-semibold">Add New Table</p>
                            <img className="cursor-pointer" onClick={() => setOpenModal(false)} src={closeIcon} alt="" />
                        </div>

                        <Grid container xs={12} sx={{ gap: "20px" }}>
                            <Grid container xs={12} sx={{ display: "flex" }}>
                                <Grid xs={4}>
                                    <p className="text-[#757373] basis-40 font-semibold mt-[8.8px]">TABLE NUMBER<span className="text-[#b80e0c]">*</span></p>
                                </Grid>
                                <Grid xs={8}>
                                    <Controller
                                        name="tableSequence"
                                        control={control}
                                        rules={{
                                            required: "Table Number is required",
                                            validate: {
                                                positive: (value) => value > 0 || "Table number must be greater than 0",
                                                integer: (value) => Number.isInteger(Number(value)) || "Table number must be a whole number"
                                            }
                                        }}
                                        render={({ field, fieldState: { error } }) => (
                                            <CustomTextInput
                                                {...field}
                                                placeholder="Table Number"
                                                type="text"
                                                variant="outlined"
                                                error={!!error}
                                                helperText={error ? error.message : null}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container xs={12} sx={{ display: "flex" }}>
                                <Grid xs={4}>
                                    <p className="text-[#757373] basis-40 font-semibold mt-[8.8px]">NUMBER OF SEATS<span className="text-[#b80e0c]">*</span></p>
                                </Grid>
                                <Grid xs={8}>
                                    <Controller
                                        name="numberOfSeats"
                                        control={control}
                                        rules={{
                                            required: "Number of Seats is required",
                                            validate: (value) => {
                                                return value > 0 || "Number of seats must be greater than 0";
                                            }
                                        }}
                                        render={({ field, fieldState: { error } }) => (
                                            <CustomTextInput
                                                {...field}
                                                placeholder="Number of Seats"
                                                type="number"
                                                variant="outlined"
                                                error={!!error}
                                                helperText={error ? error.message : null}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container xs={12} sx={{ display: "flex" }}>
                                <Grid xs={4}>
                                    <p className="text-[#757373] basis-40 font-semibold mt-[8.8px]">TABLE NAME</p>
                                </Grid>
                                <Grid xs={8}>
                                    <Controller
                                        name="tableName"
                                        control={control}
                                        rules={{
                                            // required: "Table Number is required",
                                            // validate: {
                                            //     positive: (value) => value > 0 || "Table number must be greater than 0",
                                            //     integer: (value) => Number.isInteger(Number(value)) || "Table number must be a whole number"
                                            // }
                                        }}
                                        render={({ field, fieldState: { error } }) => (
                                            <CustomTextInput
                                                {...field}
                                                placeholder="Table Name (Optional)"
                                                type="text"
                                                variant="outlined"
                                                error={!!error}
                                                helperText={error ? error.message : null}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>

                        </Grid>

                        <Grid container xs={12}>
                            <Grid xs={6} sx={{ paddingRight: "10px" }}>
                                <BasicButton
                                    variant="outlined"
                                    color="genieRed"
                                    sx={{
                                        textTransform: "none",
                                        display: "flex",
                                        width: "100%"
                                    }}
                                    onClick={() => setOpenModal(false)}
                                >
                                    Cancel
                                </BasicButton>
                            </Grid>
                            <Grid xs={6} sx={{ paddingLeft: "10px" }}>
                                <BasicButton
                                    color="genieRed"
                                    type="submit"
                                    sx={{
                                        textTransform: "none",
                                        display: "flex",
                                        color: "white",
                                        width: "100%",
                                    }}
                                // onClick={handleSave}
                                >
                                    Add Table
                                </BasicButton>
                            </Grid>
                        </Grid>
                    </form>
                </BasicModal>
            )}
        </>
    )
}

export default Index
