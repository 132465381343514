import LocalStorageService from "../Constants/localStorage";
import { jwtDecode } from "jwt-decode";
import { getLoginUrl } from "../utils/helper";

const PublicRoute = ({ children }) => {
  const LocalStorage = LocalStorageService.getService();

  const accessLoginToken = LocalStorage.getAccessToken();

  try {
    const decoded = jwtDecode(accessLoginToken);

    if (accessLoginToken && decoded?.userType === "super-admin") {
      return (window.location.href = getLoginUrl("superadmin"));
    }

    // TODO:Custom Domain Route navigation

    // if (accessLoginToken && decoded?.userType === "restaurant-partner") {
    //   return window.location.href = getLoginUrl("superadmin");
    // }
  } catch (error) {
    console.log(error);
  }
  return children;
};

export default PublicRoute;
