import React, { useState } from "react";
import { useSuperAdmin } from "../../utils/hooks";
import { styled } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Logo from "../../assets/images/FrameTrimmed2.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DrawerComponent from "../Drawer/Index";
import useToken from "../../utils/hooks/useToken";
import ConfirmLogoutModal from "./ConfirmLogoutmodal";
import LogoutIcon from "@mui/icons-material/Logout";

const CustomComponent = styled("div")({
  "@media (min-width: 1124px)": {
    display: "none",
  },
});

function HeaderWrapper({ children }) {
  const { toggleDrawer, isDrawerOpen } = useSuperAdmin();
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const { removeToken } = useToken();
  const navigate = useNavigate();
  const location = useLocation();

  const adminTabs = [
    { id: 1, title: "Restaurant List", route: "/dashboard" },
    { id: 2, title: "Add New Restaurant", route: "/add" },
  ];

  const restaurantTabs = [
    { id: 1, title: "Restaurant information", route: "/" },
    { id: 2, title: "Menu Management", route: "/menu" },
    { id: 2, title: "Table Management", route: "/table" },
    { id: 2, title: "Insights", route: "/insights" },
  ];

  return (
    <>
      <nav className=" bg-[#ED1703] flex justify-between lg:justify-normal   h-[51px]  z-[999] ">
        <img src={Logo} alt="" srcSet="" className="px-5  h-[51px]" />

        <div className="max-w-7xl  px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-[52px] ">
            <div className="hidden lg:block">
              <div className="ml-10 flex items-center space-x-4">
                {window.location?.host.includes("superadmin")
                  ? adminTabs?.map((tab) => (
                    <Link
                      key={tab.id}
                      to={tab.route}
                      replace
                      className={`text-white  ${location?.pathname !== tab.route
                        ? "opacity-65 "
                        : "border-b-2"
                        } px-3 py-2 font-quicksand font-[700] text-[16px]  hover:border-b-2 hover:ease-out `}
                    >
                      {tab.title}
                    </Link>
                  ))
                  : restaurantTabs?.map((tab) => (
                    <Link
                      key={tab.id}
                      to={tab.route}
                      replace
                      className={`text-white  ${(tab.route === "/" && location.pathname === "/") ||
                        (tab.route !== "/" &&
                          location.pathname.startsWith(tab.route))
                        ? "border-b-2"
                        : "opacity-65 "
                        } px-3 py-2 font-quicksand font-[700] text-[16px] `}
                    >
                      {tab.title}
                    </Link>
                  ))}
              </div>
            </div>
          </div>
        </div>

        <div className="  items-center  hidden lg:flex absolute right-[10px] h-[52px]">
          <button
            className={`text-white opacity-[80%] px-3 py-2 font-quicksand font-[700] text-[16px]  hover:border-b-2 hover:ease-out `}
            onClick={() => setOpenLogoutModal(true)}
          >
            <LogoutIcon sx={{ width: "20px" }} /> Logout
          </button>
        </div>

        <CustomComponent>
          <IconButton
            color="inherit"
            onClick={toggleDrawer}
            sx={{ marginRight: "12px", color: "white", marginTop: "5px" }}
          >
            <MenuIcon />
          </IconButton>
        </CustomComponent>
      </nav>
      {children}

      <DrawerComponent
        position="right"
        open={isDrawerOpen}
        onClose={toggleDrawer}
      >
        <Box
          sx={{
            width: 250,
            // textAlign: "center",
            // paddingTop: "52px",
            display: "flex",
            flexDirection: "column ",
            // alignItems: "center",
          }}
        >
          {/* <img src={Logo} alt="" srcSet="" className="p" /> */}
          <div
            className="flex  w-full  justify-between my-8  px-3 pb-3  text-red-900 border-b-2 "
            onClick={toggleDrawer}
          >
            <h1 className="font-quicksand font-[700] text-[20px] ">Menu</h1>
            <CloseIcon className=" rounded-[50%] border text-[#9b1a1a] m-1    " />
          </div>

          {window.location?.host.includes("superadmin")
            ? adminTabs.map((tab) => (
              <Link
                key={tab.id}
                to={tab.route}
                replace
                activeClassName="text-red-900  px-3 py-2 font-quicksand font-[700] text-[16px]"
                className="text-red-900 opacity-75 px-3 py-2 font-quicksand font-[700] text-[16px] hover:opacity-100"
                onClick={toggleDrawer}
              >
                {tab.title}
              </Link>
            ))
            : restaurantTabs.map((tab) => (
              <Link
                key={tab.id}
                to={tab.route}
                replace
                onClick={toggleDrawer}
                activeClassName="text-red-900  px-3 py-2 font-quicksand font-[700] text-[16px]"
                className="text-red-900 hover:opacity-75 px-3 py-2 font-quicksand font-[700] text-[16px] "
              // className={`${
              //   location.pathname === tab.route ? 'text-red-900' : ''
              // } hover:opacity-75 px-3 py-2 font-quicksand font-[700] text-[16px]`}
              >
                {tab.title}
              </Link>
            ))}
          <div className="  flex  items-center justify-center  lg:hidden absolute bottom-[10px] h-[52px]">
            <button
              className={`text-red-900 hover:opacity-[80%] px-3 py-2 font-quicksand font-[700] text-[16px]  `}
              onClick={() => {
                setOpenLogoutModal(true)
                toggleDrawer()
              }}
            >
              <LogoutIcon sx={{ color: "#9b1a1a", width: "20px" }} /> Logout
            </button>
          </div>
        </Box>
      </DrawerComponent>
      {openLogoutModal && (
        <ConfirmLogoutModal
          open={openLogoutModal}
          handleCancel={() => setOpenLogoutModal(false)}
          handleConfirmClick={() => {
            removeToken()
            navigate(-1)
          }}
        />
      )}
    </>
  );
}

export default HeaderWrapper;
