import { SERVICE_FEE_PERCENTAGE } from "../../Constants/services"

const initialState = {
    user: {},
    userId: "",
    partnerId: "",
    isAuthenticated: false,
    cartList: [],
    serviceFee: 0,
    tableId: new URLSearchParams(window.location.search).get("tableId"),
    customDomain: window.location.hostname.split('.')[0],
    scanStatus: false,
    paymentAmount: 0,
    paymentIntentSecret: ''
}

export const createDinerSlice = (set, get) => ({
    ...initialState,
    dinerCount: 1,
    setUser: (data) => set((state) => { state.user = data }),
    setIsAuthenticated: (data) => set((state) => { state.isAuthenticated = data }),
    setUserId: (data) => set((state) => { state.userId = data }),
    setTableId: (tableId) => set((state) => { state.tableId = tableId }),
    setCustomDomain: (customDomain) => set((state) => { state.customDomain = customDomain }),
    setPartnerId: (partnerId) => set((state) => { state.partnerId = partnerId }),
    setCartList: (cartList) => set((state) => { state.cartList = cartList }),
    resetAllStates: () => set(initialState),
    getCartCount: () => get().cartList?.reduce((acc, curr) => acc + curr?.orderDetails?.length, 0),
    setPaymentAmount: (data) => set(state => { state.paymentAmount = data }),
    getCartTotal: () => {
        const total = get().cartList?.reduce((acc, curr) => acc + curr?.orderDetails?.reduce((acc, curr) => acc + (curr.oPrice - curr.dPrice + curr.customization.reduce((customizationAcc, customization) => customizationAcc + customization.items.reduce((itemAcc, item) => itemAcc + item.price, 0), 0)) * curr.quantity, 0), 0)?.toFixed(2)
        const totalPayment = get().cartList?.filter(order => order._id !== "new_order_id")?.reduce((acc, curr) => acc + curr?.orderDetails?.reduce((acc, curr) => acc + (curr.oPrice - curr.dPrice + curr.customization.reduce((customizationAcc, customization) => customizationAcc + customization.items.reduce((itemAcc, item) => itemAcc + item.price, 0), 0)) * curr.quantity, 0), 0)?.toFixed(2)
        // const tempServiceFee = Number(total) < 10 ? 0 : Number(total) < 20 ? 0.15 : 0.25
        // Apply 5% service fee
        const tempServiceFee = (Number(total) * SERVICE_FEE_PERCENTAGE) / 100
        set(state => {
            state.serviceFee = tempServiceFee
            state.paymentAmount = (Number(totalPayment) + Number(tempServiceFee)).toFixed(2)
        })
        return total
    },
    setDinerCount: (count) => set((state) => { state.dinerCount = count }),
    setScanStatus: (data) => set((state) => { state.scanStatus = data }),
    setPaymentIntentSecret: (data) => set((state) => { state.paymentIntentSecret = data }),
})