import React from "react";
import Logo from "../../assets/Icons/NewGenie.svg";
import { getBackgroundColor } from "../../utils/helper";
import { useDelivery } from "../../utils/hooks";

const OrdRow = ({ ord, index, onClick }) => {
  const { setOrderDetails } = useDelivery();
  return (
    <tr
      key={index}
      className={`border-b `}
      onClick={() => (onClick ? onClick() : setOrderDetails(ord))}
    >
      <td className="px-4 py-3  font-normal text-[#4D4D4D]  border-r  leading-snug">
        {ord.ordNo}
      </td>
      <td className=" px-4 py-3  font-normal text-[#4D4D4D]  border-r  leading-snug ">
        {ord.item_n}
      </td>
      <td className=" px-4 py-3  font-normal text-[#4D4D4D]  border-r  leading-snug ">
        AED {ord.ord_total}
      </td>
      <td className=" px-4 py-3  font-normal text-[#4D4D4D]  border-r  leading-snug">
        <p className="flex gap-2">
          {ord.experience.map((exp, index) => (
            <span
              key={index}
              className={`   gap-1 flex rounded-full px-2 p-1 text-white ${
                exp === "Genie"
                  ? "bg-[#B80E0C]"
                  : exp === "Regular"
                  ? "bg-[#6596BF]"
                  : ""
              }`}
            >
              {exp === "Genie" && <img src={Logo} alt="logo" />}
              {exp}
            </span>
          ))}
        </p>
      </td>
      <td className=" px-4 py-3  font-normal text-[#4D4D4D]  border-r  leading-snug">
        <p>{ord.pay_method}</p>
      </td>
      <td className=" px-4 py-3  font-normal text-[#4D4D4D]  border-r  leading-snug">
        {ord.riderAssigned}
      </td>

      <td
        className={`px-4 py-3  font-normal text-[#4D4D4D]  border-r  leading-snug`}
      >
        <p
          className=" px-3 py-1 rounded-full w-fit text-white"
          style={{ backgroundColor: getBackgroundColor(ord.status) }}
        >
          {ord.status}
        </p>
      </td>

      {/* <td className=" px-4 py-3 text-blueDark text-sm 2xl:text-md leading-snug">
        <div className="w-full justify-center flex gap-6">
          <div className="w-full flex items-center justify-center gap-6">
            <Menu
              open={menuState}
              setOpen={setMenuState}
              list={getOptionMenuList()}
            />
          </div>
        </div>
      </td> */}
    </tr>
  );
};

export default OrdRow;
